import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../../utilities/url";
import axios from "axios";
import AuthHeader from "../../services/auth.header";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Controller, useForm } from "react-hook-form";
import {
  FaPenAlt,
  FaPencilAlt,
  FaRegCopy,
  FaTimes,
  FaTrash,
  FaTrashAlt,
} from "react-icons/fa";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import CustomStyles from "../../components/customStyles";
import { ThemeContext } from "../../provider/themeContext";
import storage from "../../services/storage";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import { ToastrContext } from "../../provider/toastrContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddBroadcast = () => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
    CryptoJS.enc.Utf8
  );
  const workspace = JSON.parse(decryptWS);
  const [hideSpiner, setHideSpiner] = useState(true);
  const [load, setLoad] = useState(false);
  const dt_user = JSON.parse(localStorage.getItem("user"));
  const [device, setDevice] = useState([]);

  useEffect(() => {
    // Get Device
    axios
      .post(
        `${API_URL}list-device`,
        {
          idt_workspace: workspace.idt_user_workspace,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          const maps = response.data.data.map(function (d) {
            const map = {
              value: d.idt_device,
              label: d.name + " (" + d.msisdn + ")",
            };
            return map;
          });
          setDevice(maps);
        }
      })
      .catch((error) => {});
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    reset,
    setValue,
    getValues,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    const createJsonSchemas = (functions) => {
      // Array untuk menyimpan schema dari tiap objek functionS
      const schemas = functions.map((func) => {
        const schema = {
          type: "object",
          properties: {},
          required: [],
        };
        // Proses setiap parameter di dalam array parameters
        func.parameters.forEach((param) => {
          schema.properties[param.name] = {
            type: param.type,
            description: param.description,
          };
          // Hanya tambahkan enum jika tidak kosong
          if (param.enum && param.enum.length > 0) {
            schema.properties[param.name].enum = param.enum;
          }
          // Jika required adalah true, tambahkan ke array required
          if (param.required) {
            schema.required.push(param.name);
          }
        });
        return {
          name: func.name,
          description: func.description,
          exec_type: func.exec_type,
          active: func.active,
          exec_type: func.exec_type,
          endpoint: func.endpoint,
          method: func.method,
          headers: func.headers,
          body_type: func.body_type,
          body: func.body,
          credentials: func.credentials,
          response_filter: func.response_filter,
          response_prompt: func.response_prompt,
          response_media_type: func.response_media_type,
          response_media_url: func.response_media_url,
          parameters: JSON.stringify(schema),
        };
      });
      return schemas;
    };

    // console.log(data);
    const datas = {
      name: data.broadcastName,
      description: data.chatbotDescription,
      expired: data.expired,
      chatbotKnowledge: data.chatbotKnowledge,
      chatbotInstruction: data.chatbotInstruction,
      externalKnowledge: data.externalKnowledge,
      webchatLink: data.w_link,
      webchatWidget: data.w_widget,
      // functions: createJsonSchemas(functionS),
      // operator: rightItems.map((op) => op.id),
      device: data.device ? data.device.map((device) => device.value) : [],
      assistant: data.assistant.value,
      // serviceType: selectedST,
      // serviceAccess: selectedSA,
      workspace: workspace.idt_user_workspace,
    };
    //console.log(datas);

    // axios
    //   .post(`${API_URL}add-chatbot`, datas, {
    //     headers: {
    //       Authorization: `Bearer ${storage.get("access_token")}`,
    //     },
    //   })
    //   .then((response) => {
    //     if (response.data.success === true) {
    //       localStorage.setItem("alert", true);
    //       navigate("/chatbot");
    //     } else {
    //       toast.error(response.data.message, {
    //         position: "top-right",
    //         theme: "colored",
    //         transition: Slide,
    //         autoClose: 4000,
    //         hideProgressBar: true,
    //         closeOnClick: true,
    //         draggable: true,
    //         progress: undefined,
    //       });
    //     }
    //   })
    //   .catch((error) => {});
  };

  // Generate
  const handleGenerateWCL = () => {
    const newUUID = uuidv4();
    setValue("w_link", "https://webchat.otokata.id/" + newUUID);
    clearErrors("w_link");
  };

  // Modal Add Function
  const [show, setShow] = useState(false);
  const [showU, setShowU] = useState(false);
  const handleShow = () => setShow(true);
  const handleShowU = () => setShowU(true);
  const handleClose = () => {
    setShow(false);
  };
  const handleCloseU = () => {
    setShowU(false);
    setShowEditModal(false);
  };
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [functionIndex, setFunctionIndex] = useState(null);
  const handleEditFunction = (index, row) => {
    setShowU(true);
    setFunctionIndex(index);
    setSelectedFunction(row);
    setShowEditModal(true);
  };

  return (
    <div className="container-fluid p-0">
      <div className="row mb-2 mb-xl-3">
        <div className="col-auto">
          <h5>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/broadcast">Broadcast</Link>
                </li>
                <li className="breadcrumb-item active">Add Broadcast</li>
              </ol>
            </nav>
          </h5>
        </div>
        <ToastContainer />
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card rounded-4 shadow-lg">
            <div className="col-md-11 col-sm-12 mx-auto">
              <div className="card-header rounded-4">
                <h5 className="card-title text-center">Add Broadcast</h5>
                <ToastContainer />
              </div>
              <div className="card-body">
                <div className="card-body h-100">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-md-10">
                        <div className="circle-number-container mb-4">
                          <div className="circle-number">1</div>
                          <div className="circle-text">
                            <span className="main-text">
                              Broadcast Description
                            </span>
                            <span className="sub-text">
                              Fill in the broadcast name and assistant to be
                              used.
                            </span>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Broadcast Name
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-4 error-placeholder">
                            <input
                              type="text"
                              {...register("broadcastName", {
                                required: "Service Name is required",
                              })}
                              className={`form-control ${
                                errors.broadcastName ? "is-invalid" : ""
                              }`}
                            />
                            {errors.broadcastName && (
                              <div className="invalid-feedback">
                                {errors.broadcastName.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Broadcast Type
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-4 error-placeholder">
                            <select
                              {...register("typeBroadcast", {
                                required: "Broadcast Type is required",
                              })}
                              className={`form-select ${
                                errors.expired ? "is-invalid" : ""
                              }`}
                            >
                              <option value="">Select Type</option>
                              <option value="1">One-Way </option>
                              <option value="2">Interactive</option>
                            </select>
                            {errors.typeBroadcast && (
                              <div className="invalid-feedback">
                                {errors.typeBroadcast.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Device Setting
                          </label>
                          <div className="col-sm-4 error-placeholder">
                            <Controller
                              name="device"
                              control={control}
                              className={`${errors.device ? "is-invalid" : ""}`}
                              rules={{
                                required: "Device is required.",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  isMulti
                                  styles={CustomStyles(theme)}
                                  className="custom-select"
                                  maxMenuHeight={200}
                                  options={device}
                                  placeholder="Select Device"
                                  onBlur={field.onBlur}
                                  onChange={(selected) => {
                                    field.onChange(selected);
                                    clearErrors("device");
                                  }}
                                  isSearchable={true}
                                  // isDisabled={!selectedCategory}
                                />
                              )}
                            />
                            {errors.device && (
                              <p className="errorMsg text-danger fs-7">
                                {errors.device.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className="circle-number-container mb-4">
                          <div className="circle-number">2</div>
                          <div className="circle-text">
                            <span className="main-text">Broadcast Content</span>
                            <span className="sub-text">
                              Fill in the content to be used.
                            </span>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Content Name
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-4 error-placeholder">
                            <input
                              type="text"
                              {...register("contentName", {
                                required: "Content is required",
                              })}
                              className={`form-control ${
                                errors.contentName ? "is-invalid" : ""
                              }`}
                            />
                            {errors.contentName && (
                              <div className="invalid-feedback">
                                {errors.contentName.message}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Chatbot Knowledge
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <textarea
                              {...register("chatbotKnowledge")}
                              className={`form-control ${
                                errors.chatbotKnowledge ? "is-invalid" : ""
                              }`}
                              rows={10}
                            />
                            {errors.chatbotKnowledge && (
                              <div className="invalid-feedback">
                                {errors.chatbotKnowledge.message}
                              </div>
                            )}
                          </div>
                        </div> */}
                        <hr />
                        <div className="circle-number-container mb-4">
                          <div className="circle-number">3</div>
                          <div className="circle-text">
                            <span className="main-text">
                              Recipient's purpose
                            </span>
                            <span className="sub-text">
                              Fill define specific recipient and order
                              broadcast.
                            </span>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Order of Recipient
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-4 error-placeholder">
                            <select
                              {...register("orderRep", {
                                required: "Order is required",
                              })}
                              className={`form-select ${
                                errors.expired ? "is-invalid" : ""
                              }`}
                            >
                              <option value="">Select Order</option>
                              <option value="1">Randomized</option>
                              <option value="2">Ordered</option>
                            </select>
                            {errors.orderRep && (
                              <div className="invalid-feedback">
                                {errors.orderRep.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Recipient Setting
                          </label>
                          <div className="col-sm-9 error-placeholder">
                            <table className="table table-striped w-100">
                              <thead>
                                <tr>
                                  <th>Order</th>
                                  <th>Group Name</th>
                                  <th className="d-none d-md-table-cell">
                                    Total Contact
                                  </th>
                                  <th>Settings</th>
                                  <th align="center" className="text-center">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody></tbody>
                            </table>
                          </div>
                        </div>
                        <hr />
                        <div className="circle-number-container mb-4">
                          <div className="circle-number">4</div>
                          <div className="circle-text">
                            <span className="main-text">
                              Delivery Schedule Configuration
                            </span>
                            <span className="sub-text">
                              Fill delivery schedule configuration to be used.
                            </span>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Delivery Schedule
                          </label>
                          <div className="col-sm-9 error-placeholder">
                            <table className="table table-striped w-100">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Day</th>
                                  <th>Time</th>
                                  <th className="d-none d-md-table-cell">
                                    Start Time
                                  </th>
                                  <th className="d-none d-md-table-cell">
                                    End Time
                                  </th>
                                  <th>Broadcast Estimate</th>
                                  <th align="center" className="text-center">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody></tbody>
                            </table>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                    {/* <hr className="mb-4"></hr> */}
                    <div className="mt-4">
                      <Link to="/broadcast" className="btn btn-danger btn-md">
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-success btn-md float-end"
                      >
                        <span
                          className={`spinner-border spinner-border-xs me-2 ${
                            hideSpiner ? "d-none" : ""
                          }`}
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBroadcast;
