import { React, useContext, useEffect, useState } from "react";
import { useLocation, NavLink, Link } from "react-router-dom";
import {
  MdTab,
  MdContacts,
  MdSmartphone,
  MdOutlineAccountTree,
} from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { PiChatsFill } from "react-icons/pi";
import logo from "../assets/logo.png";
import logoM from "../assets/logo-m.png";
import otomessaging from "../assets/otomessaging.png";
import { FaUserAstronaut } from "react-icons/fa";
import CryptoJS from "crypto-js";
import storage from "../services/storage";
import { WorkspaceContext } from "../provider/workspaceContext";
import { HiOutlineDesktopComputer } from "react-icons/hi";
import Profile from "../assets/avatar-broken.png";
import Skeleton from "react-loading-skeleton";

const Sidebar = () => {
  const location = useLocation();
  const { workspace, compact } = useContext(WorkspaceContext);
  const [currentPath, setCurrentPath] = useState();
  useEffect(() => {
    setCurrentPath(location.pathname.split("/")[1]);
  }, [location]);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  // Listener untuk memantau perubahan ukuran layar
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLinkClick = () => {
    if (isMobile) {
      const sidebar = document.getElementById("sidebar");
      const navbar = document.querySelector(".navbar"); // Ambil elemen navbar

      // Toggle sidebar class
      if (sidebar.classList.contains("collapsed")) {
        sidebar.classList.remove("collapsed");
        navbar.classList.remove("collapsed"); // Menghapus kelas navbar jika sidebar tidak collapsed
      } else {
        sidebar.classList.add("collapsed");
        navbar.classList.add("collapsed"); // Menambahkan kelas navbar jika sidebar collapsed
      }
    }
  };

  return (
    <nav id="sidebar" className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <div className="sidebar-content js-simplebar" data-simplebar="init">
        <div className="simplebar-wrapper" style={{ margin: 0 }}>
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer"></div>
          </div>
          <div className="simplebar-mask">
            <div className="simplebar-offset" style={{ right: 0, bottom: 0 }}>
              <div
                className="simplebar-content-wrapper"
                tabIndex="0"
                role="region"
                aria-label="scrollable content"
              >
                <div className="simplebar-content" style={{ padding: 0 }}>
                  <Link to="#" className="sidebar-brand text-white">
                    {/* <span className="align-middle me-3">AppStack</span> */}
                    <div className="text-center">
                      {!compact ? (
                        <>
                          <img
                            className="img-responsive"
                            height="30"
                            width="138"
                            src={logo}
                            alt="logo"
                          />
                        </>
                      ) : (
                        <img
                          className="img-responsive"
                          height="30"
                          width="30"
                          src={logoM}
                          alt="logo"
                        />
                      )}
                    </div>
                    {!compact ? (
                      <Link
                        to="/account?tab=subscription"
                        className="btn btn-lg mt-3 me-2 btn-success rounded-4"
                      >
                        <HiOutlineDesktopComputer size={20} className="me-2" />
                        My Subscription
                      </Link>
                    ) : (
                      <Link
                        to="/account?tab=subscription"
                        className="btn btn-lg mt-3 btn-success rounded-4 p-1"
                        style={{ marginLeft: "-5px" }}
                      >
                        <HiOutlineDesktopComputer
                          size={20}
                          style={{ marginLeft: "2px" }}
                        />
                      </Link>
                    )}
                  </Link>
                  <ul className="sidebar-nav">
                    <li
                      className="sidebar-header"
                      style={{
                        borderBottom: "1.5px solid #fff",
                        padding: "0.8rem 1.5rem",
                      }}
                    >
                      <img
                        className="rounded-2 border border-2 border-orange me-2"
                        width="28"
                        height="28"
                        src={
                          workspace
                            ? workspace.profile_pic
                              ? workspace.profile_pic
                              : Profile
                            : Profile
                        }
                      />
                      {workspace ? (
                        <span className="fs-14 text-orange fw-bold pt-2">
                          {workspace.name}
                        </span>
                      ) : (
                        <Skeleton width={100} />
                      )}
                    </li>
                    <li
                      className={
                        currentPath == "dashboard" || currentPath == ""
                          ? "mt-3 sidebar-item active"
                          : "mt-3 sidebar-item"
                      }
                    >
                      <NavLink
                        to="/dashboard"
                        onClick={handleLinkClick}
                        className={
                          currentPath == "dashboard" || currentPath == ""
                            ? "mt-3 sidebar-link active"
                            : "mt-3 sidebar-link"
                        }
                      >
                        <MdTab className="align-middle" size={20} />{" "}
                        <span>Dashboard</span>
                      </NavLink>
                    </li>
                    <li className="sidebar-item v2">
                      <NavLink
                        to=""
                        data-bs-target="#otomessaging"
                        data-bs-toggle="collapse"
                        className={({ isActive }) =>
                          isActive
                            ? "sidebar-link collapsed"
                            : "sidebar-link collapsed"
                        }
                      >
                        <img
                          className="img-responsive"
                          height="20"
                          width="20"
                          src={otomessaging}
                          alt="logo"
                        />{" "}
                        <span>Otomessaging</span>
                      </NavLink>
                      <ul
                        id="otomessaging"
                        className="sidebar-dropdown list-unstyled collapse "
                        data-bs-parent="#sidebar"
                      >
                        <li
                          className={
                            currentPath == "broadcast"
                              ? "sidebar-item-2 active"
                              : "sidebar-item-2"
                          }
                        >
                          <NavLink
                            to="/broadcast"
                            onClick={handleLinkClick}
                            className={({ isActive }) =>
                              isActive
                                ? "ms-05 mt-2 sidebar-link collapsed active"
                                : "ms-05 mt-2 sidebar-link collapsed"
                            }
                          >
                            Broadcast
                          </NavLink>
                        </li>
                        <li
                          className={
                            currentPath == "chatbot"
                              ? "sidebar-item-2 active"
                              : "sidebar-item-2"
                          }
                        >
                          <NavLink
                            to="/chatbot"
                            onClick={handleLinkClick}
                            className={({ isActive }) =>
                              isActive
                                ? "ms-05 mt-2 sidebar-link collapsed active"
                                : "ms-05 mt-2 sidebar-link collapsed"
                            }
                          >
                            Chatbot
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={
                        currentPath == "device"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <NavLink
                        to="/device"
                        onClick={handleLinkClick}
                        className={({ isActive }) =>
                          isActive ? "sidebar-link active" : "sidebar-link"
                        }
                      >
                        <MdSmartphone className="align-middle" size={20} />{" "}
                        <span>Device</span>
                      </NavLink>
                    </li>
                    <li
                      className={
                        currentPath == "contact"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <NavLink
                        to="/contact"
                        onClick={handleLinkClick}
                        className={({ isActive }) =>
                          isActive ? "sidebar-link active" : "sidebar-link"
                        }
                      >
                        <MdContacts className="align-middle" size={20} />{" "}
                        <span>Contact</span>
                      </NavLink>
                    </li>
                    {workspace && workspace.name !== "Personal" && (
                      <li
                        className={
                          currentPath == "workspace-member"
                            ? "sidebar-item active"
                            : "sidebar-item"
                        }
                      >
                        <NavLink
                          to="/workspace-member"
                          onClick={handleLinkClick}
                          className={({ isActive }) =>
                            isActive ? "sidebar-link active" : "sidebar-link"
                          }
                        >
                          <FiUsers className="align-middle" size={20} />{" "}
                          <span>Workspace Member</span>
                        </NavLink>
                      </li>
                    )}
                    <li
                      className={
                        currentPath == "assistant"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <NavLink
                        to="/assistant"
                        onClick={handleLinkClick}
                        className={({ isActive }) =>
                          isActive ? "sidebar-link active" : "sidebar-link"
                        }
                      >
                        <FaUserAstronaut className="align-middle" size={20} />{" "}
                        <span>Personal Assistant</span>
                      </NavLink>
                    </li>
                    <li
                      className={
                        currentPath == "chat"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <NavLink
                        to="/chat"
                        onClick={handleLinkClick}
                        className={({ isActive }) =>
                          isActive ? "sidebar-link active" : "sidebar-link"
                        }
                      >
                        <PiChatsFill className="align-middle" size={22} />
                        <span>Live Chat</span>
                      </NavLink>
                    </li>
                    <li
                      className={
                        currentPath == "integration"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <NavLink
                        to="/integration"
                        onClick={handleLinkClick}
                        className={({ isActive }) =>
                          isActive ? "sidebar-link active" : "sidebar-link"
                        }
                      >
                        <MdOutlineAccountTree
                          className="align-middle"
                          size={20}
                        />{" "}
                        <span>Integration</span>
                      </NavLink>
                    </li>
                    {/* <li
                      className={
                        currentPath == "page404"
                          ? "sidebar-item active"
                          : "sidebar-item"
                      }
                    >
                      <NavLink
                        to="/page"
                        className={({ isActive }) =>
                          isActive ? "sidebar-link active" : "sidebar-link"
                        }
                      >
                        <FiTriangle className="align-middle" size={20} /> Page
                        404
                      </NavLink>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
