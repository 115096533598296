import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import { API_URL } from "../../utilities/url";
import { FiPlus } from "react-icons/fi";
import { Button } from "react-bootstrap";
import storage from "../../services/storage";
import TransactionChart from "./component/transactionChart";

var $ = require("jquery");

const Transaction = () => {
  const location = useLocation();
  const [activeFilter, setActiveFilter] = useState("Semua");

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  useEffect(() => {
    if (localStorage.getItem("alert")) {
      toast.success("Success !", {
        position: "top-right",
        theme: "colored",
        transition: Slide,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      localStorage.removeItem("alert");
    }

    $("#datatables-reponsive").DataTable({
      dom:
        "<'row'<'col-sm-6'l><'col-sm-6'B>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: true,
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-table-users",
        type: "post",
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            AuthService.logout();
          }
        },
      },
      columns: [
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = moment(data).format("DD-MM-YYYY");

            return data;
          },
        },
        { data: "user_profile.name_first" },
        { data: "user_profile.name_last" },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
      ],
      initComplete: function () {
        var api = this.api();
        $("#search")
          .off(".DT")
          .on("keyup.DT", function (e) {
            api.search(this.value).draw();
          });
      },
    });
  }, [location.key]);

  return (
    <div className="container-fluid p-0">
      <div className="row mb-2 mb-xl-3">
        <div className="col-auto">
          <h5>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/chatbot">Chatbot</Link>
                </li>
                <li className="breadcrumb-item active">Transaction</li>
              </ol>
            </nav>
          </h5>
        </div>
        <ToastContainer />
      </div>
      <TransactionChart />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between mb-3">
                <div>
                  <h5 className="card-title mt-2">Balance Transfer</h5>
                </div>
              </div>
              <div className="row">
                <div className="d-flex justify-content-between mt-2 mb-0">
                  {/* Filter Buttons */}
                  <div>
                    {["Semua", "Active", "Non-Active"].map((filter) => (
                      <Button
                        key={filter}
                        variant={activeFilter === filter ? "warning" : "light"}
                        className={`me-2 ${
                          activeFilter === filter ? "text-dark" : ""
                        }`}
                        onClick={() => handleFilterClick(filter)}
                      >
                        {filter}
                      </Button>
                    ))}
                  </div>
                  {/* <div>
                    <Link to="/Transaction/add" className="btn btn-primary btn-md">
                      <FiPlus
                        className="align-middle"
                        size={14}
                        strokeWidth={4}
                      />{" "}
                      Add Transaction
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="card-body table-responsive">
              <table
                id="datatables-reponsive"
                className="table table-striped table-responsive dt-bootstrap5 no-footer"
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Service Name</th>
                    <th>Activity</th>
                    <th>Label Ref</th>
                    <th>Total Transfer</th>
                    <th>Total Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
