import { React, useContext, useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { API_URL } from "../../utilities/url";
import { format_number } from "../../utilities/ext-function";
import axios from "axios";
import check from "../../assets/check.png";
import cross from "../../assets/cross.png";
import Skeleton from "react-loading-skeleton";
import { SubscriptionContext } from "../../provider/subscriptionContext";

const Subscription = () => {
  const { subscription } = useContext(SubscriptionContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({
    dataset: "",
  });

  useEffect(() => {
    if (location.state) {
      delete location.state;
    }
    axios
      .get(API_URL + "product-subscription-plan")
      .then((response) => {
        if (response.data.success === true) {
          setState((prevState) => ({
            ...prevState,
            ["dataset"]: response.data.data,
          }));
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-md-12 col-lg-8 mx-auto">
            <div className="mt-4">
              <h2 className="text-center pb-2">We have a plan for everyone</h2>
              <div className="row justify-content-center mt-3 mb-2">
                <div className="col-auto">
                  <nav className="nav btn-group">
                    <Link
                      to="#monthly"
                      className="btn btn-outline-secondary active"
                      data-bs-toggle="tab"
                    >
                      Monthly billing
                    </Link>
                    <Link
                      to="#annual"
                      className="btn btn-outline-secondary"
                      data-bs-toggle="tab"
                    >
                      Annual billing
                    </Link>
                  </nav>
                </div>
              </div>

              <div className="tab-content">
                <div className="tab-pane fade show active" id="monthly">
                  <div className="row py-4">
                    <div className="col-sm-4 mb-3 mb-md-0">
                      <div className="card rounded-4 bg-secondary h-100">
                        <div className="card-body rounded-4 d-flex flex-column">
                          <div className="text-center">
                            {state.dataset.length > 0 ? (
                              state.dataset.map((d) =>
                                d.subscription.idt_product_subscription ===
                                1 ? (
                                  <div>
                                    <h3 className="text-white">
                                      {d.subscription.name}
                                    </h3>
                                    <div className="text-orange mb-3">
                                      <span className="fs-16">Rp</span>
                                      <span className="fs-34 fw-bold">
                                        {format_number(
                                          d.price_monthly_offering
                                        )}
                                      </span>
                                      <div className="fs-16">&nbsp;</div>
                                    </div>
                                    <ul className="text-start text-white list-unstyled mb-5">
                                      {JSON.parse(
                                        d.subscription.description
                                      ).map(
                                        (dd, index) =>
                                          index < 6 && (
                                            <li
                                              key={index}
                                              className="d-flex justify-content-between ps-2 pe-2 mb-3"
                                            >
                                              <small className="text-break fs-13">
                                                {dd.name}
                                              </small>
                                              <small>
                                                {dd.type === "boolean" ? (
                                                  dd.value === true ? (
                                                    <img
                                                      src={check}
                                                      alt="check"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={cross}
                                                      alt="cross"
                                                    />
                                                  )
                                                ) : (
                                                  <small className="text-break fs-13 me-1">
                                                    {dd.value}
                                                  </small>
                                                )}
                                              </small>
                                            </li>
                                          )
                                      )}
                                    </ul>
                                    <div className="text-center mt-auto">
                                      {subscription ? (
                                        subscription.idt_product_subscription <=
                                        1 ? (
                                          <Link
                                            to="#"
                                            className="btn btn-md btn-primary disabled"
                                          >
                                            Current Plan
                                          </Link>
                                        ) : null
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null
                              )
                            ) : (
                              <div>
                                <Skeleton height={28} className="w-75 mb-1" />
                                <Skeleton height={44} className="mb-3" />
                                <Skeleton
                                  height={20}
                                  count={6}
                                  className="mb-1"
                                />
                                <Skeleton height={38} className="mt-5 w-50" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 mb-3 mb-md-0">
                      <div className="card rounded-4 bg-secondary h-100">
                        <div className="card-body rounded-4 d-flex flex-column">
                          <div className="text-center">
                            {state.dataset.length > 0 ? (
                              state.dataset.map((d) =>
                                d.subscription.idt_product_subscription === 2 &&
                                d.plan_length < 12 ? (
                                  <div>
                                    <h3 className="text-white">
                                      {d.subscription.name}
                                    </h3>
                                    <div className="text-orange mb-3">
                                      <span className="fs-16">Rp</span>
                                      <span className="fs-34 fw-bold">
                                        {format_number(
                                          d.price_monthly_offering *
                                            d.plan_length
                                        )}
                                      </span>
                                      <div className="fs-16">
                                        {d.plan_length} month
                                      </div>
                                    </div>
                                    <ul className="text-start text-white list-unstyled mb-5">
                                      {JSON.parse(
                                        d.subscription.description
                                      ).map(
                                        (dd, index) =>
                                          index < 6 && (
                                            <li
                                              key={index}
                                              className="d-flex justify-content-between ps-2 pe-2 mb-3"
                                            >
                                              <small className="text-break fs-13">
                                                {dd.name}
                                              </small>
                                              <small>
                                                {dd.type === "boolean" ? (
                                                  dd.value === true ? (
                                                    <img
                                                      src={check}
                                                      alt="check"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={cross}
                                                      alt="cross"
                                                    />
                                                  )
                                                ) : (
                                                  <small className="text-break fs-13 me-1">
                                                    {dd.value}
                                                  </small>
                                                )}
                                              </small>
                                            </li>
                                          )
                                      )}
                                    </ul>
                                    <div className="text-center mt-auto">
                                      {subscription ? (
                                        subscription.idt_product_subscription ===
                                          2 && d.plan_length < 12 ? (
                                          <Link
                                            to="#"
                                            className="btn btn-md btn-primary disabled"
                                          >
                                            Current Plan
                                          </Link>
                                        ) : (
                                          <Link
                                            to={`/subscription-payment/${d.idt_product_subscription_price_plan}`}
                                            className="btn btn-md btn-primary"
                                          >
                                            Select Plan
                                          </Link>
                                        )
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null
                              )
                            ) : (
                              <div>
                                <Skeleton height={28} className="w-75 mb-1" />
                                <Skeleton height={44} className="mb-3" />
                                <Skeleton
                                  height={20}
                                  count={6}
                                  className="mb-1"
                                />
                                <Skeleton height={38} className="mt-5 w-50" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 mb-3 mb-md-0">
                      <div className="card rounded-4 bg-secondary h-100">
                        <div className="card-body rounded-4 d-flex flex-column">
                          <div className="text-center">
                            {state.dataset.length > 0 ? (
                              state.dataset.map((d) =>
                                d.subscription.idt_product_subscription === 3 &&
                                d.plan_length < 12 ? (
                                  <div>
                                    <h3 className="text-white">
                                      {d.subscription.name}
                                    </h3>
                                    <div className="text-orange mb-3">
                                      <span className="fs-16">Rp</span>
                                      <span className="fs-34 fw-bold">
                                        {format_number(
                                          d.price_monthly_offering *
                                            d.plan_length
                                        )}
                                      </span>
                                      <div className="fs-16">
                                        {d.plan_length} month
                                      </div>
                                    </div>
                                    <ul className="text-start text-white list-unstyled mb-5">
                                      {JSON.parse(
                                        d.subscription.description
                                      ).map(
                                        (dd, index) =>
                                          index < 6 && (
                                            <li
                                              key={index}
                                              className="d-flex justify-content-between ps-2 pe-2 mb-3"
                                            >
                                              <small className="text-break fs-13">
                                                {dd.name}
                                              </small>
                                              <small>
                                                {dd.type === "boolean" ? (
                                                  dd.value === true ? (
                                                    <img
                                                      src={check}
                                                      alt="check"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={cross}
                                                      alt="cross"
                                                    />
                                                  )
                                                ) : (
                                                  <small className="text-break fs-13 me-1">
                                                    {dd.value}
                                                  </small>
                                                )}
                                              </small>
                                            </li>
                                          )
                                      )}
                                    </ul>
                                    <div className="text-center mt-auto">
                                      {subscription ? (
                                        subscription.idt_product_subscription ===
                                          3 && d.plan_length < 12 ? (
                                          <Link
                                            to="#"
                                            className="btn btn-md btn-primary disabled"
                                          >
                                            Current Plan
                                          </Link>
                                        ) : (
                                          <Link
                                            to={`/subscription-payment/${d.idt_product_subscription_price_plan}`}
                                            className="btn btn-md btn-primary"
                                          >
                                            Select Plan
                                          </Link>
                                        )
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null
                              )
                            ) : (
                              <div>
                                <Skeleton height={28} className="w-75 mb-1" />
                                <Skeleton height={44} className="mb-3" />
                                <Skeleton
                                  height={20}
                                  count={6}
                                  className="mb-1"
                                />
                                <Skeleton height={38} className="mt-5 w-50" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="annual">
                  <div className="row py-4">
                    <div className="col-sm-4 mb-3 mb-md-0">
                      <div className="card rounded-4 bg-secondary h-100">
                        <div className="card-body rounded-4 d-flex flex-column">
                          <div className="text-center">
                            {state.dataset.length > 0 ? (
                              state.dataset.map((d) =>
                                d.subscription.idt_product_subscription ===
                                1 ? (
                                  <div>
                                    <h3 className="text-white">
                                      {d.subscription.name}
                                    </h3>
                                    <div className="text-orange mb-3">
                                      <span className="fs-16">Rp</span>
                                      <span className="fs-34 fw-bold">
                                        {format_number(
                                          d.price_monthly_offering
                                        )}
                                      </span>
                                      <div className="fs-16">&nbsp;</div>
                                    </div>
                                    <ul className="text-start text-white list-unstyled mb-5">
                                      {JSON.parse(
                                        d.subscription.description
                                      ).map(
                                        (dd, index) =>
                                          index < 6 && (
                                            <li
                                              key={index}
                                              className="d-flex justify-content-between ps-2 pe-2 mb-3"
                                            >
                                              <small className="text-break fs-13">
                                                {dd.name}
                                              </small>
                                              <small>
                                                {dd.type === "boolean" ? (
                                                  dd.value === true ? (
                                                    <img
                                                      src={check}
                                                      alt="check"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={cross}
                                                      alt="cross"
                                                    />
                                                  )
                                                ) : (
                                                  <small className="text-break fs-13 me-1">
                                                    {dd.value}
                                                  </small>
                                                )}
                                              </small>
                                            </li>
                                          )
                                      )}
                                    </ul>
                                    <div className="text-center mt-auto">
                                      {subscription ? (
                                        subscription.idt_product_subscription <=
                                        1 ? (
                                          <Link
                                            to="#"
                                            className="btn btn-md btn-primary disabled"
                                          >
                                            Current Plan
                                          </Link>
                                        ) : null
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null
                              )
                            ) : (
                              <div>
                                <Skeleton height={28} className="w-75 mb-1" />
                                <Skeleton height={44} className="mb-3" />
                                <Skeleton
                                  height={20}
                                  count={6}
                                  className="mb-1"
                                />
                                <Skeleton height={38} className="mt-5 w-50" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 mb-3 mb-md-0">
                      <div className="card rounded-4 bg-secondary h-100">
                        <div className="card-body rounded-4 d-flex flex-column">
                          <div className="text-center">
                            {state.dataset.length > 0 ? (
                              state.dataset.map((d) =>
                                d.subscription.idt_product_subscription === 2 &&
                                d.plan_length >= 12 ? (
                                  <div>
                                    <h3 className="text-white">
                                      {d.subscription.name}
                                    </h3>
                                    <div className="text-orange mb-3">
                                      <span className="fs-16">Rp</span>
                                      <span className="fs-34 fw-bold">
                                        {format_number(
                                          d.price_monthly_offering *
                                            d.plan_length
                                        )}
                                      </span>
                                      <div className="fs-16">1 year</div>
                                    </div>
                                    <ul className="text-start text-white list-unstyled mb-5">
                                      {JSON.parse(
                                        d.subscription.description
                                      ).map(
                                        (dd, index) =>
                                          index < 6 && (
                                            <li
                                              key={index}
                                              className="d-flex justify-content-between ps-2 pe-2 mb-3"
                                            >
                                              <small className="text-break fs-13">
                                                {dd.name}
                                              </small>
                                              <small>
                                                {dd.type === "boolean" ? (
                                                  dd.value === true ? (
                                                    <img
                                                      src={check}
                                                      alt="check"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={cross}
                                                      alt="cross"
                                                    />
                                                  )
                                                ) : (
                                                  <small className="text-break fs-13 me-1">
                                                    {dd.value}
                                                  </small>
                                                )}
                                              </small>
                                            </li>
                                          )
                                      )}
                                    </ul>
                                    <div className="text-center mt-auto">
                                      {subscription ? (
                                        subscription.idt_product_subscription ===
                                          2 && d.plan_length >= 12 ? (
                                          <Link
                                            to="#"
                                            className="btn btn-md btn-primary disabled"
                                          >
                                            Current Plan
                                          </Link>
                                        ) : (
                                          <Link
                                            to={`/subscription-payment/${d.idt_product_subscription_price_plan}`}
                                            className="btn btn-md btn-primary"
                                          >
                                            Select Plan
                                          </Link>
                                        )
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null
                              )
                            ) : (
                              <div>
                                <Skeleton height={28} className="w-75 mb-1" />
                                <Skeleton height={44} className="mb-3" />
                                <Skeleton
                                  height={20}
                                  count={6}
                                  className="mb-1"
                                />
                                <Skeleton height={38} className="mt-5 w-50" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 mb-3 mb-md-0">
                      <div className="card rounded-4 bg-secondary h-100">
                        <div className="card-body rounded-4 d-flex flex-column">
                          <div className="text-center">
                            {state.dataset.length > 0 ? (
                              state.dataset.map((d) =>
                                d.subscription.idt_product_subscription === 3 &&
                                d.plan_length >= 12 ? (
                                  <div>
                                    <h3 className="text-white">
                                      {d.subscription.name}
                                    </h3>
                                    <div className="text-orange mb-3">
                                      <span className="fs-16">Rp</span>
                                      <span className="fs-34 fw-bold">
                                        {format_number(
                                          d.price_monthly_offering *
                                            d.plan_length
                                        )}
                                      </span>
                                      <div className="fs-16">1 year</div>
                                    </div>
                                    <ul className="text-start text-white list-unstyled mb-5">
                                      {JSON.parse(
                                        d.subscription.description
                                      ).map(
                                        (dd, index) =>
                                          index < 6 && (
                                            <li
                                              key={index}
                                              className="d-flex justify-content-between ps-2 pe-2 mb-3"
                                            >
                                              <small className="text-break fs-13">
                                                {dd.name}
                                              </small>
                                              <small>
                                                {dd.type === "boolean" ? (
                                                  dd.value === true ? (
                                                    <img
                                                      src={check}
                                                      alt="check"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={cross}
                                                      alt="cross"
                                                    />
                                                  )
                                                ) : (
                                                  <small className="text-break fs-13 me-1">
                                                    {dd.value}
                                                  </small>
                                                )}
                                              </small>
                                            </li>
                                          )
                                      )}
                                    </ul>
                                    <div className="text-center mt-auto">
                                      {subscription ? (
                                        subscription.idt_product_subscription ===
                                          3 && d.plan_length >= 12 ? (
                                          <Link
                                            to="#"
                                            className="btn btn-md btn-primary disabled"
                                          >
                                            Current Plan
                                          </Link>
                                        ) : (
                                          <Link
                                            to={`/subscription-payment/${d.idt_product_subscription_price_plan}`}
                                            className="btn btn-md btn-primary"
                                          >
                                            Select Plan
                                          </Link>
                                        )
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null
                              )
                            ) : (
                              <div>
                                <Skeleton height={28} className="w-75 mb-1" />
                                <Skeleton height={44} className="mb-3" />
                                <Skeleton
                                  height={20}
                                  count={6}
                                  className="mb-1"
                                />
                                <Skeleton height={38} className="mt-5 w-50" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mx-auto mt-1">
                <p className="fs-14">
                  Can’t decide which plan is right for you?{" "}
                  <Link
                    to="/comparation-subs"
                    className="d-block d-md-inline-block text-orange"
                  >
                    See the comparison
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
