import { Modal } from "react-bootstrap";

const ModalExpired = ({ show, handleClose }) => {
  const redirectLogin = () => {
    window.location.replace("/login");
  };
  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Header
          className="p-3 bg-danger-subtle"
          closeButton
          onClick={redirectLogin}
        >
          <h5 className="modal-title text-danger">Warning</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-2 text-center">
            <div className="mb-4 pt-2 fs-15 mx-4 mx-sm-5">
              <p className="mx-4 mb-2">Your session has expired</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalExpired;
