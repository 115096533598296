import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ToastContainer, toast, Slide } from "react-toastify";
import { API_URL } from "../utilities/url";
import storage from "../services/storage";
import axios from "axios";
import { WorkspaceContext } from "../provider/workspaceContext";
import upload from "../assets/upload.png";
import { HiOutlineCamera } from "react-icons/hi";

const ModalAddWorkspace = ({ show, setShowModal }) => {
  const { setWorkspaceList } = useContext(WorkspaceContext);
  // Fungsi handle show/hide di dalam WorkspaceModal
  const [imagePreview, setImagePreview] = useState(upload);
  const [hideSpiner, setHideSpiner] = useState(true);
  const [load, setLoad] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
    reset();
    setValue("profile_pic", null);
    setImagePreview(upload);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    reset,
    setValue,
    getValues,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    setHideSpiner(false);
    axios
      .post(`${API_URL}add-workspace`, data, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          setHideSpiner(true);
          handleCloseModal();
          toast.success("Add Workspace Success", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          setWorkspaceList((prevList) => [...prevList, response.data.data]);
        } else {
          setHideSpiner(true);
          toast.error(response.data.message, {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {});
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setValue("profile_pic", file);
    }
  };

  return (
    <>
      {/* Modal */}
      <Modal show={show} onHide={handleCloseModal} centered>
        {show && <ToastContainer toastClassName="toaster" />}
        <Modal.Header closeButton>
          <Modal.Title>Create Workspace</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                {/* <div className="profile-user d-flex justify-content-center mx-auto mb-4">
                  <img
                    src={imagePreview ? imagePreview : upload}
                    className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                    alt="user-profile-image"
                    style={{ height: "11rem", width: "11rem" }}
                  />
                  <div
                    className="avatar-xs p-0 rounded-circle profile-photo-edit"
                    style={{ right: "auto" }}
                  >
                    <input
                      id="profile-img-file-input"
                      type="file"
                      name="photo_url"
                      className="profile-img-file-input"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    <label
                      htmlFor="profile-img-file-input"
                      className="profile-photo-edit-2 avatar-xs"
                      style={{ left: "55px" }}
                    >
                      <span className="avatar-title rounded-circle bg-light text-body">
                        <i className="fas fa-fw fa-camera"></i>
                      </span>
                    </label>
                  </div>
                </div> */}
                <div className="mb-3 row">
                  <div className="col-md-4">
                    <div className="profile-ava2 mb-2">
                      <label className="-label rounded-4" htmlFor="avatar2">
                        <HiOutlineCamera />
                        <span className="me-1"></span>
                        <span className="fs-11"> Change Image</span>
                      </label>
                      <input
                        id="avatar2"
                        type="file"
                        name="photo_url"
                        className="profile-img-file-input"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                      <img
                        src={imagePreview ? imagePreview : upload}
                        id="avatar"
                        className="img-fluid rounded-4 avatar-p border border-2 border-secondary"
                        alt="avatar"
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <label className="col-form-label col-sm-12 text-sm-right">
                      Workspace Name
                      <span className="required">*</span>
                    </label>
                    <div className="col-sm-10 error-placeholder">
                      <input
                        type="text"
                        {...register("name", {
                          required: "Workspace Name is required",
                        })}
                        className={`form-control ${
                          errors.name ? "is-invalid" : ""
                        }`}
                      />
                      {errors.name && (
                        <div className="invalid-feedback">
                          {errors.name.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleCloseModal}>
              Cancel
            </Button>
            <button type="submit" className="btn btn-primary btn-md float-end">
              <span
                className={`spinner-border spinner-border-xs me-2 ${
                  hideSpiner ? "d-none" : ""
                }`}
                role="status"
                aria-hidden="true"
              ></span>
              Create
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalAddWorkspace;
