import { Helmet } from "react-helmet";
import { Routes, Route, useLocation } from "react-router-dom";
import { Footer, Sidebar, TopNavbar } from "../layouts";
import {
  Dashboard,
  Login,
  Page404,
  Device,
  AddDevice,
  EditDevice,
  DeviceGroup,
  Contact,
  AddContact,
  EditContact,
  ContactGroup,
  Member,
  AddMember,
  Broadcast,
  Chatbot,
  Integration,
  Register,
  RegisterSuccess,
  RegisterWizardFree,
  RegisterWizardPremium,
  RegisterWizardEnterprise,
  ForgotPassword,
  ComparationPlans,
  OTPVerification,
  ResetPassword,
  ResetPasswordSuccess,
  Account,
  Chat,
  Subscriptions,
  ComparationPlansOP,
  SubscriptionPayment,
  AddChatbot,
  DetailChatbot,
  AddBroadcast,
  RedirectLogin,
  ContactProfiler,
} from "../pages";
import Assistant from "../pages/assistant/assistant";
import { AssistantProvider } from "../provider/assistantContext";
import { LanguageProvider } from "../provider/languageContext";
import { ThemeProvider } from "../provider/themeContext";
import { ToastrProvider } from "../provider/toastrContext";
import { WorkspaceProvider } from "../provider/workspaceContext";
import storage from "../services/storage";
import Credit from "../pages/credit/credit";
import Topup from "../pages/credit/topup";
import Transaction from "../pages/transactionChatbot/transaction";
import { CreatePassword } from "../pages";
import { ActivationSuccess } from "../pages";
import CryptoJS from "crypto-js";
import BgParticles from "../components/bgParticles";
import TransactionPayment from "../pages/transaction/transaction_payment";
import TransactionStatus from "../pages/transaction/transaction_status";
import { SubscriptionProvider } from "../provider/subscriptionContext";
import { LiveChatProvider } from "../provider/liveChatContext";
import { MetadataProvider } from "../provider/metadataContext";

const Router = () => {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const isLoggedIn = !!storage.get("access_token");

  // Capitalize Format for Title
  const pageTitle = currentPath
    ? currentPath
        .replace(/-/g, " ") // Escape dihapus
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : "Login";

  const workspace = storage.get("workspace")
    ? JSON.parse(
        CryptoJS.AES.decrypt(storage.get("workspace"), "workspace").toString(
          CryptoJS.enc.Utf8
        )
      )
    : null;

  const RedirectRouter = (
    <>
      <LanguageProvider>
        <Routes>
          <Route path="/redirect" element={<RedirectLogin />} />
          <Route path="*" element={<RedirectLogin />} />
        </Routes>
      </LanguageProvider>
    </>
  );

  const publicRoutes = (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route
        path="/reset-password-success"
        element={<ResetPasswordSuccess />}
      />
      <Route path="/comparation-plans" element={<ComparationPlans />} />
      <Route path="/register" element={<Register />} />
      <Route path="/create-password" element={<CreatePassword />} />
      <Route path="/activation-success" element={<ActivationSuccess />} />
      <Route path="/otp-verification/:token" element={<OTPVerification />} />
      <Route path="/register-success" element={<RegisterSuccess />} />
      <Route path="/register-free" element={<RegisterWizardFree />} />
      <Route path="/register-premium" element={<RegisterWizardPremium />} />
      <Route
        path="/register-enterprise"
        element={<RegisterWizardEnterprise />}
      />
      <Route path="*" element={<Login />} />
    </Routes>
  );

  const privateRoutes = (
    <LanguageProvider>
      <ToastrProvider>
        <ThemeProvider>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/device" element={<Device />} />
            <Route path="/device/add" element={<AddDevice />} />
            <Route path="/device/edit/:id" element={<EditDevice />} />
            <Route path="/device/group" element={<DeviceGroup />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/contact/profiler" element={<ContactProfiler />} />
            <Route path="/contact/add" element={<AddContact />} />
            <Route path="/contact/edit/:id" element={<EditContact />} />
            <Route path="/contact/group" element={<ContactGroup />} />
            {workspace && workspace.name !== "Personal" && (
              <>
                <Route path="/workspace-member" element={<Member />} />
                {workspace &&
                  workspace.role !== "operator" &&
                  workspace.name !== "Personal" && (
                    <Route
                      path="/workspace-member/add"
                      element={<AddMember />}
                    />
                  )}
              </>
            )}
            <Route path="/broadcast" element={<Broadcast />} />
            <Route path="/broadcast/add" element={<AddBroadcast />} />
            <Route path="/chatbot" element={<Chatbot />} />
            <Route path="/chatbot/add" element={<AddChatbot />} />
            <Route path="/chatbot/detail/:id" element={<DetailChatbot />} />
            <Route path="/transaction" element={<Transaction />} />
            <Route path="/assistant" element={<Assistant />} />
            <Route path="/integration" element={<Integration />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/account" element={<Account />} />
            <Route path="/credit" element={<Credit />} />
            {workspace && workspace.role !== "operator" && (
              <>
                <Route path="/topup-credit" element={<Topup />} />
                <Route
                  path="/transaction-status/:orderId"
                  element={<TransactionStatus />}
                />
                <Route
                  path="/transaction-payment/:orderId"
                  element={<TransactionPayment />}
                />
                <Route path="/subscriptions" element={<Subscriptions />} />
                <Route
                  path="/subscription-payment/:subscriptionPlanId"
                  element={<SubscriptionPayment />}
                />
              </>
            )}
            <Route path="/comparation-subs" element={<ComparationPlansOP />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </ThemeProvider>
      </ToastrProvider>
    </LanguageProvider>
  );

  const renderContent = () => {
    if (isLoggedIn) {
      return (
        <div className="wrapper">
          <SubscriptionProvider>
            <WorkspaceProvider>
              <LiveChatProvider>
                <AssistantProvider>
                  <MetadataProvider>
                    <Sidebar />
                    <div className="main">
                      <TopNavbar />
                      <main className="content">{privateRoutes}</main>
                      <Footer />
                    </div>
                  </MetadataProvider>
                </AssistantProvider>
              </LiveChatProvider>
            </WorkspaceProvider>
          </SubscriptionProvider>
        </div>
      );
    } else {
      return (
        <>
          <BgParticles />
          {publicRoutes}
        </>
      );
    }
  };

  return (
    <div>
      <Helmet>
        <title>{pageTitle} - Otokata</title>
      </Helmet>
      {pageTitle === "Redirect" ? RedirectRouter : renderContent()}
    </div>
  );
};

export default Router;
