import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AuthService from "../../services/auth.service";
import moment from "moment";
import { API_URL } from "../../utilities/url";

import storage from "../../services/storage";
import DashboardPanel from "./component/dashboardPanel";

var $ = require("jquery");

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    $("#datatables-reponsive").DataTable({
      dom:
        "<'row'<'col-sm-6'l><'col-sm-6'B>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: true,
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-table-users",
        type: "post",
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            localStorage.clear();
            navigate("/login");
          }
        },
      },
      columns: [
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = moment(data).format("DD-MM-YYYY");

            return data;
          },
        },
        { data: "user_profile.name_first" },
        { data: "user_profile.name_last" },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
      ],
      initComplete: function () {
        var api = this.api();
        $("#search")
          .off(".DT")
          .on("keyup.DT", function (e) {
            api.search(this.value).draw();
          });
      },
    });
  }, [location.key]);

  return (
    <div className="container-fluid p-0">
      <div className="row mb-2 mb-xl-3">
        <h3>Dashboard</h3>
      </div>
      <DashboardPanel />
      <div className="card flex-fill rounded-4 shadow-lg">
        <div className="card-header rounded-4">
          <div className="card-actions float-end">
            <div className="dropdown position-relative">
              <a href="#" data-bs-toggle="dropdown" data-bs-display="static">
                {/* <MoreHorizontal className="align-middle" /> */}
              </a>

              <div className="dropdown-menu dropdown-menu-end">
                <a className="dropdown-item" href="#">
                  Action
                </a>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </div>
            </div>
          </div>
          <h5 className="card-title mb-0">
            <span className="text-primary-2">Ongoing</span>{" "}
            <span className="text-orange">Broadcast List</span>
          </h5>
        </div>
        <div className="card-body table-responsive">
          <table
            id="datatables-reponsive"
            className="table table-striped table-responsive dt-bootstrap5 no-footer"
          >
            <thead>
              <tr>
                <th>Tanggal</th>
                <th>Name</th>
                <th>Email</th>
                <th>Age</th>
                <th>Start date</th>
                <th>Salary</th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
