import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import { API_URL } from "../../utilities/url";
import BroadcastChart from "./component/broadcastChart";
import { Button } from "react-bootstrap";
import storage from "../../services/storage";

var $ = require("jquery");

const Broadcast = () => {
  const location = useLocation();
  const [activeFilter, setActiveFilter] = useState("Semua");

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  useEffect(() => {
    if (localStorage.getItem("alert")) {
      toast.success("Success !", {
        position: "top-right",
        theme: "colored",
        transition: Slide,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      localStorage.removeItem("alert");
    }

    $("#datatables-reponsive").DataTable({
      dom:
        "<'row'<'col-sm-6'l><'col-sm-6'B>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: true,
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-table-users",
        type: "post",
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            AuthService.logout();
          }
        },
      },
      columns: [
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = moment(data).format("DD-MM-YYYY");

            return data;
          },
        },
        { data: "user_profile.name_first" },
        { data: "user_profile.name_last" },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
      ],
      initComplete: function () {
        var api = this.api();
        $("#search")
          .off(".DT")
          .on("keyup.DT", function (e) {
            api.search(this.value).draw();
          });
      },
    });
  }, [location.key]);

  return (
    <div className="container-fluid p-0">
      <BroadcastChart />
      <div className="row">
        <ToastContainer />
        <div className="col-12">
          <div className="card rounded-4 shadow-lg">
            <div className="card-header rounded-4">
              <div className="d-flex justify-content-between mb-3">
                <div>
                  <h5 className="card-title mt-2">Broadcast Services</h5>
                </div>
                <div>
                  {/* <Link to="/test/add" className="btn btn-primary btn-md">
                    Tambah{" "}
                    <FiPlus
                      className="align-middle"
                      size={14}
                      strokeWidth={4}
                    />
                  </Link> */}
                </div>
              </div>
              <div className="row">
                <div className="d-flex mt-2 mb-0">
                  {/* Filter Buttons */}
                  {["Semua", "Berjalan", "Selesai", "Batal", "Draft"].map(
                    (filter) => (
                      <Button
                        key={filter}
                        variant={activeFilter === filter ? "orange-2" : "light"}
                        className={`me-2 ${
                          activeFilter === filter ? "text-white" : ""
                        }`}
                        onClick={() => handleFilterClick(filter)}
                      >
                        {filter}
                      </Button>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="card-body table-responsive">
              <table
                id="datatables-reponsive"
                className="table table-striped table-responsive dt-bootstrap5 no-footer"
              >
                <thead>
                  <tr>
                    <th>Broadcast Name</th>
                    <th>Content</th>
                    <th>Type</th>
                    <th>Device</th>
                    <th>Contact</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Status</th>
                    <th>PIC</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Broadcast;
