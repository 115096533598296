import React, { createContext } from "react";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ToastrContext = createContext();

export const ToastrProvider = ({ children }) => {
  const showSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      theme: "colored",
      transition: Slide,
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showError = (message) => {
    toast.error(message, {
      position: "top-right",
      theme: "colored",
      transition: Slide,
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showWarning = (message) => {
    toast.warning(message, {
      position: "top-right",
      theme: "colored",
      transition: Slide,
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showCopy = (message) => {
    toast.info(message, {
      position: "top-center",
      theme: "colored",
      transition: Slide,
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <ToastrContext.Provider
      value={{ showSuccess, showError, showWarning, showCopy }}
    >
      {children}
    </ToastrContext.Provider>
  );
};
