import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import { API_URL } from "../../utilities/url";
import { Button } from "react-bootstrap";
import storage from "../../services/storage";
import {
  MdCardGiftcard,
  MdCardMembership,
  MdCreditCard,
  MdOutlineAccountBalanceWallet,
} from "react-icons/md";
import CryptoJS from "crypto-js";
import axios from "axios";
import authHeader from "../../services/auth.header";
import authService from "../../services/auth.service";
import { format_number } from "../../utilities/ext-function";
import Skeleton from "react-loading-skeleton";

var $ = require("jquery");

const Credit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
    CryptoJS.enc.Utf8
  );
  const workspace = JSON.parse(decryptWS);
  const [activeFilter, setActiveFilter] = useState("Semua");
  const [dataCredit, setDataCredit] = useState(null);
  const [activeCredit, setActiveCredit] = useState("main");

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  const handleClickCredit = (credit) => {
    setActiveCredit(credit);
  };

  useEffect(() => {
    loadBalance();
  }, [location.key]);

  useEffect(() => {
    if (activeCredit !== null) {
      loadData();
    }
  }, [activeCredit, location.key]);

  const loadBalance = () => {
    axios
      .post(
        API_URL + "get-user-balance",
        { workspace: workspace.idt_user_workspace },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setDataCredit(response.data.data);
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 401") {
          authService.logout();
          navigate("/login");
        }
      });
  };

  const loadData = () => {
    $("#datatables-reponsive").DataTable({
      dom:
        "<'row'<'col-sm-6'l><'col-sm-6'B>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: true,
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-table-balance",
        type: "post",
        data: function (data) {
          data.workspace = workspace.idt_user_workspace;
          data.type = activeCredit;
        },
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            AuthService.logout();
          }
        },
      },
      order: [[0, "desc"]],
      columnDefs: [
        {
          className: "dt-center",
          targets: [2, 3, 4],
        },
      ],
      columns: [
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = moment(data).format("DD-MM-YYYY HH:mm:ss");

            return data;
          },
        },
        { data: "description" },
        {
          data: "credit",
          render: function (data, type, row) {
            if (data) {
              return `<span style="color: #4bbf73;">${format_number(
                data
              )}</span>`;
            } else {
              return "-";
            }
          },
        },
        {
          data: "debit",
          render: function (data, type, row) {
            if (data) {
              return `<span style="color: #d9534f;">${format_number(
                data
              )}</span>`;
            } else {
              return "-";
            }
          },
        },
        {
          data: "balance",
          render: function (data, type, row) {
            return format_number(data);
          },
        },
      ],
      initComplete: function () {
        var api = this.api();
        $("#search")
          .off(".DT")
          .on("keyup.DT", function (e) {
            api.search(this.value).draw();
          });
      },
    });
  };

  return (
    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-12">
          <div className="card py-2 px-3">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="card-body">
                  <div className="d-flex overflow-hidden">
                    <div
                      className="icon-panel-circle bg-primary-2 me-3"
                      style={{ width: "45px", height: "45px" }}
                    >
                      <MdOutlineAccountBalanceWallet size={26} />
                    </div>
                    <div className="flex-grow-1">
                      <h4 className="fs-18 mb-4">Total Credit</h4>
                      <h1 className="fs-22 text-end">
                        {dataCredit ? (
                          <>
                            {format_number(
                              dataCredit.reduce((sum, item) => {
                                return sum + (item.balance || 0);
                              }, 0)
                            )}
                            <span className="fs-12 ms-1">credit</span>
                          </>
                        ) : (
                          <Skeleton width={60} />
                        )}
                      </h1>
                    </div>
                  </div>
                  <hr />
                  {workspace && workspace.role !== "operator" && (
                    <Link
                      to="/topup-credit"
                      className="btn btn-lg me-2 mb-3 btn-primary w-100"
                    >
                      <MdOutlineAccountBalanceWallet
                        size={22}
                        className="me-2"
                      />
                      Top Up Credit
                    </Link>
                  )}
                  {dataCredit &&
                    dataCredit.map((item) => (
                      <div
                        key={item.type}
                        className={`card fill-subscription mb-3 border border-2 rounded-4 c-pointer ${
                          activeCredit === item.type ? "active" : ""
                        }`}
                        onClick={() => handleClickCredit(item.type)}
                      >
                        <div className="card-body p-3">
                          <div className="d-flex overflow-hidden">
                            <div
                              className={`icon-panel-circle me-3 ${
                                activeCredit === item.type
                                  ? "bg-orange"
                                  : "bg-primary-2"
                              }`}
                            >
                              {item.type === "main" && (
                                <MdCreditCard size={22} />
                              )}
                              {item.type === "subscription" && (
                                <MdCardMembership size={22} />
                              )}
                              {item.type !== "main" &&
                                item.type !== "subscription" && (
                                  <MdCardGiftcard size={22} />
                                )}
                            </div>
                            <div className="flex-grow-1">
                              <h4 className="fs-14 mb-4">{item.label}</h4>
                              <h1 className="fs-22 text-end">
                                {item.balance !== undefined ? (
                                  <>
                                    {format_number(item.balance)}
                                    <span className="fs-12 ms-1">credit</span>
                                  </>
                                ) : (
                                  <Skeleton width={60} />
                                )}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-md-9">
                <div className="card-body table-responsive">
                  <h4 className="fs-18 mb-2">History Balance</h4>
                  <hr />
                  <table
                    id="datatables-reponsive"
                    className="table table-striped table-responsive dt-bootstrap5 no-footer"
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Activity</th>
                        <th>Credit</th>
                        <th>Debit</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credit;
