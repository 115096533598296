import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import axios from "axios";
import { API_URL } from "../../../utilities/url";
import { MetadataContext } from "../../../provider/metadataContext";
import { FaFileAlt, FaFileExcel, FaFilePdf, FaFileWord } from "react-icons/fa";

const CardMeta = React.memo(
  ({ href, children }) => {
    const { metadataCache, setMetadataCache } = useContext(MetadataContext);
    const [metadata, setMetadata] = useState(null);
    const [metafile, setMetafile] = useState(null);

    const isFetching = useRef(false); // Refs untuk melacak status fetching

    const fetchMetadata = useCallback(async () => {
      // Cek jika href tidak ada atau sedang fetching, maka keluar dari fungsi
      if (!href || isFetching.current) return;

      // Set isFetching menjadi true
      isFetching.current = true;

      // Periksa apakah metadata sudah ada di cache
      const cached = metadataCache.find((item) => item.href === href);
      if (cached) {
        // Jika ada, gunakan metadata dari cache
        setMetadata(cached.metadata);
        isFetching.current = false; // Reset fetching status
      } else {
        // Jika tidak ada di cache, ambil dari API
        // try {
        //   const response = await axios.get(
        //     `${API_URL}metadata?url=${encodeURIComponent(href)}`
        //   );
        //   const metadata = response.data;
        //   // Simpan metadata ke cache
        //   setMetadataCache((prevItems) => [...prevItems, { href, metadata }]);
        //   setMetadata(metadata);
        // } catch (error) {
        //   console.error("Error fetching metadata:", error);
        //   setMetadataCache((prevItems) => [...prevItems, { href, metadata }]);
        // } finally {
        //   // Reset fetching status
        //   isFetching.current = false;
        // }
      }
    }, [href, metadataCache, setMetadataCache]);

    useEffect(() => {
      const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|svg)$/i;
      const videoExtensions = /\.(mp4|webm|ogg|avi|mkv)$/i;
      const fileExtensions = /\.(pdf|doc|docx|xls|xlsx|ppt|pptx|txt|zip|rar)$/i;

      if (imageExtensions.test(href)) {
        setMetafile("image");
      } else if (videoExtensions.test(href)) {
        setMetafile("video");
      } else if (fileExtensions.test(href)) {
        setMetafile("file");
      } else {
        fetchMetadata();
      }
    }, [fetchMetadata]);

    const truncateText = (text, maxLength) =>
      text.length <= maxLength ? text : text.slice(0, maxLength) + "...";

    const getFileIcon = (href) => {
      const extension = href.split(".").pop().toLowerCase();
      switch (extension) {
        case "pdf":
          return <FaFilePdf size={40} color="#ff0000" />;
        case "doc":
        case "docx":
          return <FaFileWord size={40} color="#007bff" />;
        case "xls":
        case "xlsx":
          return <FaFileExcel size={40} color="#28a745" />;
        default:
          return <FaFileAlt size={40} color="#6c757d" />;
      }
    };

    return (
      <>
        <span>{children}</span>
        <a
          href={href}
          style={{
            display: "block",
            padding: "10px",
            margin: "10px 0",
            border: "1px solid #ddd",
            borderRadius: "8px",
            textDecoration: "none",
            backgroundColor: "#f9f9f9",
            transition: "background-color 0.3s",
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {metafile === "image" && (
            <img
              src={href}
              alt="Card Image"
              className="img-responsive mb-3"
              style={{
                width: "100%",
                maxHeight: "250px",
                objectFit: "cover",
                borderRadius: "5px",
              }}
            />
          )}
          {metafile === "video" && (
            <div
              className="video-container w-100"
              style={{
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <video
                controls
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "5px",
                }}
              >
                <source src={href} />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
          {metafile === "file" && (
            <div className="file-container d-flex align-items-center">
              <div className="file-icon me-3">{getFileIcon(href)}</div>
              <div className="file-info">
                <p className="fw-bold mb-1" style={{ color: "#007bff" }}>
                  {href.split("/").pop()}
                </p>
                <p className="text-muted">File</p>
              </div>
            </div>
          )}
          {metadata && metadata.image && (
            <>
              <div className="fs-14 mb-3">
                <a href={href} style={{ color: "#007bff" }} className="fw-bold">
                  {metadata.site}
                </a>
                {metadata.username && metadata.site === "Instagram" && (
                  <p className="text-dark">@{metadata.username}</p>
                )}
              </div>
              <img
                src={metadata.image}
                alt={metadata.title || "Card Image"}
                className="img-responsive mb-3"
                style={{
                  width: "100%",
                  maxHeight: "250px",
                  objectFit: "cover",
                  borderRadius: "5px",
                }}
              />
            </>
          )}
          {metadata ? (
            <p className="fw-bold" style={{ color: "#007bff" }}>
              {truncateText(metadata.title, 100)}
            </p>
          ) : metafile !== "file" ? (
            <p className="fw-bold" style={{ color: "#007bff" }}>
              {children}
            </p>
          ) : null}
          {metadata ? (
            <p className="mt-2" style={{ color: "#555" }}>
              {truncateText(metadata.description, 300)}
            </p>
          ) : null}
        </a>
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.href === nextProps.href &&
    prevProps.children === nextProps.children
);

export default CardMeta;
