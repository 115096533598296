import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Profile from "../assets/avatar-broken.png";
import {
  FiAlertCircle,
  FiBell,
  FiHome,
  FiPieChart,
  FiUser,
  FiUserPlus,
} from "react-icons/fi";
import axios from "axios";
import { API_URL } from "../utilities/url";
import authHeader from "../services/auth.header";
import {
  MdDashboardCustomize,
  MdOutlineAccountBalanceWallet,
  MdOutlineSpaceDashboard,
  MdWorkspaces,
  MdWorkspacesOutline,
} from "react-icons/md";
import { ToastContainer, toast, Slide } from "react-toastify";
import CryptoJS from "crypto-js";
import storage from "../services/storage";
import { FaCheck } from "react-icons/fa";
import { TiArrowUnsorted } from "react-icons/ti";
import { WorkspaceContext } from "../provider/workspaceContext";
import { HiOutlineDesktopComputer } from "react-icons/hi";
import ModalAddWorkspace from "../components/modalAddWorkspace";
import { encryptData, format_number } from "../utilities/ext-function";
import { LiveChatContext } from "../provider/liveChatContext";
import { IoMdApps } from "react-icons/io";
import moment from "moment";

const TopNavbar = () => {
  const { getLiveChatContact, closeSocket, setDataMessage } =
    useContext(LiveChatContext);
  const { workspace, workspaceList, credit, changeWorkspace, setCompact } =
    useContext(WorkspaceContext);
  const navigate = useNavigate();
  const location = useLocation();
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const dt_user = JSON.parse(decryptUSR);
  const [showModal, setShowModal] = useState(false);
  const [isCompact, setIsCompact] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownClick = (e) => {
    // Menjaga agar dropdown tetap terbuka saat link dropdown diklik
    setIsDropdownOpen(true);
    e.stopPropagation();
  };

  // useEffect(() => {
  //   if (storage.get("change_workspace")) {
  //     toast.success("Workspace Changed Successful", {
  //       position: "top-center",
  //       theme: "colored",
  //       transition: Slide,
  //       autoClose: 4000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     storage.remove("change_workspace");
  //   }
  // }, []);

  //Toggle
  const showToogle = () => {
    setIsCompact(!isCompact);
  };

  const showToogle2 = () => {
    const sidebar = document.getElementById("sidebar");
    const navbar = document.querySelector(".navbar"); // Ambil elemen navbar

    // Toggle sidebar class
    if (sidebar.classList.contains("collapsed")) {
      sidebar.classList.remove("collapsed");
      navbar.classList.remove("collapsed"); // Menghapus kelas navbar jika sidebar tidak collapsed
    } else {
      sidebar.classList.add("collapsed");
      navbar.classList.add("collapsed"); // Menambahkan kelas navbar jika sidebar collapsed
    }
  };

  useEffect(() => {
    // Mengubah atribut pada elemen <html> sesuai state
    if (isCompact) {
      setCompact(true);
      document.body.setAttribute("data-sidebar-behavior", "compact");
    } else {
      setCompact(false);
      document.body.setAttribute("data-sidebar-behavior", "sticky");
    }
  }, [isCompact]);

  // const getUrl = () => {
  //   console.log();
  //   toast.success("Workspace Changed Successful", {
  //     position: "top-center",
  //     theme: "colored",
  //     transition: Slide,
  //     autoClose: 4000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     draggable: true,
  //     progress: undefined,
  //   });
  // };

  // Logout
  const Logout = () => {
    axios
      .post(
        API_URL + "logout",
        {},
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        localStorage.clear();
        closeSocket();
        navigate("/login");
      })
      .catch((error) => {});
  };

  const handleToggleClick = () => {
    if (window.matchMedia("(min-width: 768px)").matches) {
      setIsCompact(false);
      showToogle();
    } else {
      setIsCompact(false);
      showToogle2();
    }
  };

  const changeApp = (link) => {
    const currentDomain = window.location.hostname;
    const targetDomain = new URL(link).hostname;
    if (currentDomain === targetDomain) {
      return false;
    }
    window.open(
      link + "/redirect?data=" + storage.get("access_token"),
      "_blank"
    );
  };

  return (
    <nav className="navbar navbar-expand navbar-light navbar-bg">
      <a className="sidebar-toggle" onClick={handleToggleClick}>
        <i className="hamburger align-self-center"></i>
      </a>
      <span className="fs-14 d-none d-md-block">
        Welcome back,{" "}
        <b>
          {dt_user.user_profile.name_first} {dt_user.user_profile.name_last}
        </b>
      </span>
      <ToastContainer toastClassName="toaster" />

      {/* <form className="d-none d-sm-inline-block">
        <div className="input-group input-group-navbar">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            aria-label="Search"
          />
          <button className="btn" type="button">
            <FiSearch className="align-middle" size={16} />
          </button>
        </div>
      </form> */}

      <div className="navbar-collapse collapse">
        <ul className="navbar-nav navbar-align">
          <li className="nav-item d-none d-sm-block">
            <Link to="/credit" className="btn btn-lg me-2 btn-primary">
              <MdOutlineAccountBalanceWallet size={20} className="me-2" />
              Credit: {credit ? format_number(credit) : 0}
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              to="/upgrade-plan"
              className="btn btn-outline-primary btn-md btn-block mt-1"
            >
              {dt_user.user_workspace.name}
            </Link>
          </li> */}
          <li
            // className={
            //   workspaceHide == true
            //     ? "nav-item dropdown d-none"
            //     : "nav-item dropdown"
            // }
            className="nav-item dropdown me-2"
          >
            <Link
              to="#"
              className="btn btn-lg btn-light shadow-sm btn-block d-sm-inline-block"
              style={{ backgroundColor: "#eaeaea", border: "1px solid #ccc" }}
              data-bs-toggle="dropdown"
            >
              <img
                className="rounded-2 border-2 border-dark me-2"
                width="22"
                height="22"
                src={
                  workspace
                    ? workspace.profile_pic
                      ? workspace.profile_pic
                      : Profile
                    : Profile
                }
              />
              {workspace ? workspace.name : null}
              <TiArrowUnsorted className="ms-2" />
            </Link>
            <div
              className="dropdown-menu top-profile dropdown-menu-end mr"
              style={{ minWidth: "300px" }}
            >
              <div
                className="card p-2 mx-3 my-2 bg-orange-soft"
                style={{ border: "1px solid orange" }}
              >
                <div className="d-flex">
                  <img
                    className="rounded-circle border-2 border-dark me-3"
                    width="45"
                    height="45"
                    src={
                      workspace
                        ? workspace.profile_pic
                          ? workspace.profile_pic
                          : Profile
                        : Profile
                    }
                  />
                  <div>
                    <p className="mb-0 fs-14 fw-bold text-dark">
                      {workspace ? workspace.name : null}
                    </p>
                    <p className="mt-0 mb-0 fs-12 fw-bold text-dark">
                      {workspace
                        ? workspace.role
                          ? workspace.role.charAt(0).toUpperCase() +
                            workspace.role.slice(1).toLowerCase()
                          : null
                        : null}
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ padding: "0.35rem 1rem" }}>
                <Link
                  to="/account?tab=my-workspace"
                  className="btn btn-lg btn-primary fs-13 w-100"
                >
                  <MdWorkspacesOutline size={20} className="me-2" />
                  Manage Workspace
                </Link>
              </div>
              <div
                className="card mx-3 my-2"
                style={{
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Shadow kustom
                  borderRadius: "5px", // Opsional: Radius sudut membulat
                }}
              >
                {workspaceList.map(
                  (d) =>
                    d.idt_user_workspace !== workspace.idt_user_workspace && (
                      <a
                        className="dropdown-item"
                        key={d.id}
                        onClick={() => {
                          changeWorkspace(d.idt_user_workspace);
                          setDataMessage([]);
                          getLiveChatContact();
                        }}
                      >
                        <div className="d-flex py-1">
                          <img
                            className="rounded-circle border-2 border-dark me-3"
                            width="25"
                            height="25"
                            src={d.profile_pic ? d.profile_pic : Profile}
                            alt="profile"
                          />
                          <div>
                            <p className="mb-0 text-dark">{d.name}</p>
                          </div>
                        </div>
                      </a>
                    )
                )}
                <a className="dropdown-item" onClick={() => setShowModal(true)}>
                  <div className="d-flex py-1">
                    <MdDashboardCustomize
                      size={18}
                      className="me-3"
                      style={{ marginLeft: "4px", color: "#1a84b0" }}
                    />
                    <div>
                      <p className="mb-0 text-primary">Create Workspace</p>
                    </div>
                  </div>
                </a>
                <ModalAddWorkspace
                  show={showModal}
                  setShowModal={setShowModal}
                />
              </div>
              {/* <div className="dropdown-divider"></div> */}
            </div>
          </li>
          {/* Otokata Suite */}
          <li className="nav-item dropdown">
            <Link
              to="#"
              className="btn btn-lg btn-light shadow-sm btn-block d-sm-inline-block"
              style={{
                backgroundColor: "#eaeaea",
                border: "1px solid #ccc",
                padding: "0.28rem 0.3rem",
              }}
              data-bs-toggle="dropdown"
            >
              <IoMdApps size={24} />
            </Link>
            <div
              className="dropdown-menu top-profile dropdown-menu-end mr"
              style={{ minWidth: "300px" }}
            >
              <div
                className="card mx-3 my-2"
                style={{
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Shadow kustom
                  borderRadius: "5px", // Opsional: Radius sudut membulat
                }}
              >
                <a
                  className="dropdown-item"
                  onClick={() => {
                    changeApp("http://baca.otokata.id");
                  }}
                >
                  <div className="d-flex py-1">
                    {/* <img
                      className="rounded-circle border-2 border-dark me-3"
                      width="25"
                      height="25"
                      src={d.profile_pic ? d.profile_pic : Profile}
                      alt="profile"
                    /> */}
                    <div>
                      <p className="mb-0 text-dark">Otobaca</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link
              to="#"
              id="alertsDropdown"
              className="nav-icon dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <div className="position-relative">
                <FiBell className="align-middle" />
              </div>
            </Link>
            <div
              className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0 mt-2"
              aria-labelledby="alertsDropdown"
            >
              <div className="dropdown-menu-header">4 New Notifications</div>
              <div className="list-group">
                <Link to="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <FiAlertCircle className="text-warning" />
                    </div>
                    <div className="col-10">
                      <div className="text-dark">Update completed</div>
                      <div className="text-muted small mt-1">
                        Restart server 12 to complete the update.
                      </div>
                      <div className="text-muted small mt-1">2h ago</div>
                    </div>
                  </div>
                </Link>
                <Link to="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <FiBell className="text-primary" />
                    </div>
                    <div className="col-10">
                      <div className="text-dark">Lorem ipsum</div>
                      <div className="text-muted small mt-1">
                        Aliquam ex eros, imperdiet vulputate hendrerit et.
                      </div>
                      <div className="text-muted small mt-1">6h ago</div>
                    </div>
                  </div>
                </Link>
                <Link to="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <FiHome className="text-danger" />
                    </div>
                    <div className="col-10">
                      <div className="text-dark">Login from 192.186.1.1</div>
                      <div className="text-muted small mt-1">8h ago</div>
                    </div>
                  </div>
                </Link>
                <Link to="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <FiUserPlus className="text-success" />
                    </div>
                    <div className="col-10">
                      <div className="text-dark">New connection</div>
                      <div className="text-muted small mt-1">
                        Anna accepted your request.
                      </div>
                      <div className="text-muted small mt-1">12h ago</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="dropdown-menu-footer">
                <Link to="#" className="text-muted">
                  Show all notifications
                </Link>
              </div>
            </div>
          </li>
          {/* <li className="nav-item dropdown">
            <a
              className="nav-flag dropdown-toggle"
              to="#"
              id="languageDropdown"
              data-bs-toggle="dropdown"
            >
              <img src={BASE_URL + "img/flags/us.png"} alt="English" />
            </Link>
            <div
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="languageDropdown"
            >
              <Link className="dropdown-item" to="#">
                <img
                  src={BASE_URL + "img/flags/us.png"}
                  alt="English"
                  width="20"
                  className="align-middle me-1"
                />
                <span className="align-middle">English</span>
              </Link>
              <Link className="dropdown-item" to="#">
                <img
                  src={BASE_URL + "img/flags/es.png"}
                  alt="Spanish"
                  width="20"
                  className="align-middle me-1"
                />
                <span className="align-middle">Spanish</span>
              </Link>
              <Link className="dropdown-item" to="#">
                <img
                  src={BASE_URL + "img/flags/de.png"}
                  alt="German"
                  width="20"
                  className="align-middle me-1"
                />
                <span className="align-middle">German</span>
              </Link>
              <Link className="dropdown-item" to="#">
                <img
                  src={BASE_URL + "img/flags/nl.png"}
                  alt="Dutch"
                  width="20"
                  className="align-middle me-1"
                />
                <span className="align-middle">Dutch</span>
              </Link>
            </div>
          </li> */}
          <li className="nav-item dropdown">
            <Link
              to="#"
              className="nav-icon dropdown-toggle d-inline-block d-sm-none"
              data-bs-toggle="dropdown"
              onClick={handleDropdownClick}
            >
              <FiUser className="text-middle" />
            </Link>
            <Link
              to="#"
              className="nav-link dropdown-toggle top-profile d-none d-sm-inline-block"
              data-bs-toggle="dropdown"
              onClick={handleDropdownClick}
            >
              <img
                src={
                  dt_user.user_profile.photo_url
                    ? dt_user.user_profile.photo_url
                    : Profile
                }
                className="avatar img-top-profile rounded-circle border border-2 border-secondary me-2"
                style={{ objectFit: "cover" }}
                alt="Image Profile"
                id="img-top-profile"
              />
            </Link>
            <div
              className={`dropdown-menu top-profile dropdown-menu-end mr ${
                isDropdownOpen ? "show" : ""
              }`}
              style={{ width: "300px" }}
            >
              <div
                className="text-center"
                style={{ padding: "15px 15px 0 15px" }}
              >
                <p>{dt_user.email}</p>
                <img
                  src={
                    dt_user.user_profile.photo_url
                      ? dt_user.user_profile.photo_url
                      : Profile
                  }
                  className="img-top-profile rounded-circle border border-2 border-secondary"
                  height={70}
                  width={70}
                  style={{ objectFit: "cover" }}
                />
                <p className="fs-14 pt-2">
                  {dt_user.user_profile.name_first}{" "}
                  {dt_user.user_profile.name_last}
                </p>
                <Link
                  to="/account?tab=profile"
                  className="btn btn-orange w-100"
                >
                  Manage Account
                </Link>
              </div>
              {/* <div
                className="accordion"
                id="accountAccordion"
                data-bs-parent="#accountAccordion"
                onClick={handleAccordionClick}
                style={{ padding: "15px" }}
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button btn-secondary-fill"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded={isAccordionOpen ? "true" : "false"}
                      aria-controls="collapseOne"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Workspace
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className={`accordion-collapse collapse ${
                      isAccordionOpen ? "show" : ""
                    }`}
                    aria-labelledby="headingOne"
                    data-bs-parent="#accountAccordion"
                  >
                    <div className="accordion-body">
                      <ul className="list-group mb-3 c-pointer">
                        {workspaceList.map(
                          (account) =>
                            account.active === "N" && (
                              <li
                                key={account.id}
                                onClick={() => {
                                  handleItemClick();
                                  changeWorkspace(account.idt_user_workspace);
                                }}
                                className="list-group-item"
                              >
                                {account.name}
                              </li>
                            )
                        )}
                      </ul>
                      <div className="input-group">
                        <button
                          className="btn btn-primary w-100"
                          type="button"
                          // onClick={addAccount}
                        >
                          Create Workspace
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="mt-2" style={{ padding: "0 15px" }}>
                <a className="btn btn-danger w-100" onClick={Logout}>
                  Sign out
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default TopNavbar;
