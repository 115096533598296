import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import { API_URL } from "../../utilities/url";
import { FiEye, FiPlus } from "react-icons/fi";
import { Button } from "react-bootstrap";
import storage from "../../services/storage";
import ChatbotChart from "./component/chatbotChart";
import ChatbotPanel from "./component/chatbotPanel";
import CryptoJS from "crypto-js";
import { FaCog, FaTrash } from "react-icons/fa";

var $ = require("jquery");

const Chatbot = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const decryptWS = CryptoJS.AES.decrypt(
    storage.get("workspace"),
    "workspace"
  ).toString(CryptoJS.enc.Utf8);
  const workspace = JSON.parse(decryptWS);
  const [activeFilter, setActiveFilter] = useState("Semua");
  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  useEffect(() => {
    if (localStorage.getItem("alert")) {
      toast.success("Success add OSA Chatbot!", {
        position: "top-right",
        theme: "colored",
        transition: Slide,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      localStorage.removeItem("alert");
    }

    $("#datatables-reponsive").DataTable({
      dom:
        "<'row'<'col-sm-6'l><'col-sm-6'B>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: true,
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-chatbot",
        data: function (data) {
          data.idt_user_workspace = workspace.idt_user_workspace;
        },
        type: "post",
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            AuthService.logout();
          }
        },
      },
      columnDefs: [
        // {
        //   className: "dt-center",
        //   targets: [5, 6, 7],
        // },
        {
          targets: 6,
          createdCell: (td, cellData, rowData, row, col) =>
            ReactDOM.render(
              <div className="d-grid gap-2 d-md-flex justify-content-md">
                <a
                  // onClick={() => editTable(cellData)}
                  className="btn btn-sm btn-primary btn-rounded"
                  title="Webchat View"
                >
                  <FiEye size={14} />
                </a>
                <a
                  // onClick={() => deleteTable(cellData)}
                  className="btn btn-sm btn-tertiary btn-rounded"
                  title="Delete"
                >
                  <FaTrash />
                </a>
                <a
                  onClick={() => navigate(`/chatbot/detail/${rowData.idt_osa}`)}
                  className="btn btn-sm btn-info btn-rounded"
                  title="Settings"
                >
                  <FaCog size={14} />
                </a>
              </div>,
              td
            ),
        },
      ],
      columns: [
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = moment(data).format("DD-MM-YYYY");

            return data;
          },
        },
        { data: "chatbot_name" },
        { data: "devices" },
        {
          data: "workspace_member_count",
        },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `-`;

            return data;
          },
        },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `Active`;

            return data;
          },
        },
        { data: "idt_osa" },
      ],
      initComplete: function () {
        var api = this.api();
        $("#search")
          .off(".DT")
          .on("keyup.DT", function (e) {
            api.search(this.value).draw();
          });
      },
    });
  }, [location.key]);

  return (
    <div className="container-fluid p-0">
      <ChatbotPanel />
      <ChatbotChart />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between mb-3">
                <div>
                  <h5 className="card-title mt-2">Chatbot Services</h5>
                  <ToastContainer />
                </div>
              </div>
              <div className="row">
                <div className="d-flex justify-content-between mt-2 mb-0">
                  {/* Filter Buttons */}
                  <div>
                    {["Semua", "Active", "Non-Active"].map((filter) => (
                      <Button
                        key={filter}
                        variant={activeFilter === filter ? "orange-2" : "light"}
                        className={`me-2 ${
                          activeFilter === filter ? "text-white" : ""
                        }`}
                        onClick={() => handleFilterClick(filter)}
                      >
                        {filter}
                      </Button>
                    ))}
                  </div>
                  {/* <div>
                    <Link to="/chatbot/add" className="btn btn-primary btn-md">
                      <FiPlus
                        className="align-middle"
                        size={14}
                        strokeWidth={4}
                      />{" "}
                      Add Chatbot
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="card-body table-responsive">
              <table
                id="datatables-reponsive"
                className="table table-striped table-responsive dt-bootstrap5 no-footer"
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Service Name</th>
                    <th>Device Name</th>
                    <th>Total Member</th>
                    <th>Type Service</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
