import { React, useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { API_URL } from "../../utilities/url";
import axios from "axios";
import logo from "../../assets/logo.png";
import { format_number } from "../../utilities/ext-function";
import Skeleton from "react-loading-skeleton";

const ComparationPlans = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({
    dataset: "",
    datasubs: "",
    countsubs: "",
  });

  useEffect(() => {
    if (location.state) {
      delete location.state;
    }
    axios
      .get(API_URL + "product-subscription-plan")
      .then((response) => {
        if (response.data.success === true) {
          setState((prevState) => ({
            ...prevState,
            ["dataset"]: response.data.data,
          }));
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });

    // Datasubs
    axios
      .get(API_URL + "product-subscription-desc-table")
      .then((response) => {
        if (response.data.success === true) {
          setState((prevState) => ({
            ...prevState,
            ["datasubs"]: response.data.data,
          }));
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  return (
    <div className="main d-flex justify-content-center w-100 bg-full-login">
      <main className="content d-flex p-4">
        <div className="container d-flex flex-column">
          <Link to="/" style={{ width: 100 }}>
            <img
              className="img-logo-login d-none d-sm-block"
              src={logo}
              alt="logo"
            />
          </Link>
          <div className="row h-100">
            <div className="col-12 mx-auto d-block h-100">
              <div className="mt-4">
                <div className="text-center ms-2 me-2 mb-5">
                  <Link to="/" style={{ width: 100 }}>
                    <img
                      className="img-logo-login d-md-none d-lg-none"
                      src={logo}
                      alt="logo"
                    />
                  </Link>
                </div>
                <h2 className="text-center pb-2">Choose Plans</h2>
                <div className="row justify-content-center mt-3 mb-2">
                  <div className="col-auto">
                    <nav className="nav btn-group">
                      <Link
                        to="#monthly"
                        className="btn btn-outline-secondary active"
                        data-bs-toggle="tab"
                      >
                        Monthly billing
                      </Link>
                      <Link
                        to="#annual"
                        className="btn btn-outline-secondary"
                        data-bs-toggle="tab"
                      >
                        Annual billing
                      </Link>
                    </nav>
                  </div>
                </div>
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="monthly">
                    <div className="row py-4">
                      <div className="card rounded-4 p-4">
                        <div className="table-responsive">
                          <table className="table table-striped text-successtable-border border-light">
                            <thead className="border-light text-center">
                              <tr>
                                <th scope="col" className="w-25"></th>
                                <th scope="col" className="w-25">
                                  {state.dataset.length > 0 ? (
                                    state.dataset.map((d) =>
                                      d.subscription
                                        .idt_product_subscription === 1 ? (
                                        <div>
                                          <h3>{d.subscription.name}</h3>
                                          <div className="text-orange mb-2">
                                            <span className="fs-16">Rp</span>
                                            <span className="fs-34 fw-bold">
                                              {format_number(
                                                d.price_monthly_offering
                                              )}
                                            </span>
                                            <span className="fs-16"></span>
                                          </div>
                                          <Link
                                            to=""
                                            onClick={(e) => {
                                              e.preventDefault();
                                              navigate("/register-free", {
                                                state: {
                                                  id: d.idt_product_subscription_price_plan,
                                                },
                                              });
                                            }}
                                            className="btn btn-md btn-primary"
                                          >
                                            Select
                                          </Link>
                                        </div>
                                      ) : null
                                    )
                                  ) : (
                                    <div>
                                      <Skeleton
                                        height={30}
                                        className="w-75 mb-1"
                                      />
                                      <Skeleton
                                        height={42}
                                        className="w-50 mb-1"
                                      />
                                      <Skeleton
                                        height={30}
                                        className="w-50 mb-1"
                                      />
                                    </div>
                                  )}
                                </th>
                                <th scope="col" className="w-25">
                                  {state.dataset.length > 0 ? (
                                    state.dataset.map((d) =>
                                      d.subscription
                                        .idt_product_subscription === 2 &&
                                      d.plan_length < 12 ? (
                                        <div>
                                          <h3>{d.subscription.name}</h3>
                                          <div className="text-orange mb-2">
                                            <span className="fs-16">Rp</span>
                                            <span className="fs-34 fw-bold">
                                              {format_number(
                                                d.price_monthly_offering *
                                                  d.plan_length
                                              )}
                                            </span>
                                            <span className="fs-16">
                                              / {d.plan_length} month
                                            </span>
                                          </div>
                                          <Link
                                            to=""
                                            onClick={(e) => {
                                              e.preventDefault();
                                              navigate("/register-premium", {
                                                state: {
                                                  id: d.idt_product_subscription_price_plan,
                                                },
                                              });
                                            }}
                                            className="btn btn-md btn-primary"
                                          >
                                            Select
                                          </Link>
                                        </div>
                                      ) : null
                                    )
                                  ) : (
                                    <div>
                                      <Skeleton
                                        height={30}
                                        className="w-75 mb-1"
                                      />
                                      <Skeleton
                                        height={42}
                                        className="w-50 mb-1"
                                      />
                                      <Skeleton
                                        height={30}
                                        className="w-50 mb-1"
                                      />
                                    </div>
                                  )}
                                </th>
                                <th scope="col" className="w-25">
                                  {state.dataset.length > 0 ? (
                                    state.dataset.map((d) =>
                                      d.subscription
                                        .idt_product_subscription === 3 &&
                                      d.plan_length < 12 ? (
                                        <div>
                                          <h3>{d.subscription.name}</h3>
                                          <div className="text-orange mb-2">
                                            <span className="fs-16">Rp</span>
                                            <span className="fs-34 fw-bold">
                                              {format_number(
                                                d.price_monthly_offering *
                                                  d.plan_length
                                              )}
                                            </span>
                                            <span className="fs-16">
                                              / {d.plan_length} month
                                            </span>
                                          </div>
                                          <Link
                                            to=""
                                            onClick={(e) => {
                                              e.preventDefault();
                                              navigate("/register-enterprise", {
                                                state: {
                                                  id: d.idt_product_subscription_price_plan,
                                                },
                                              });
                                            }}
                                            className="btn btn-md btn-primary"
                                          >
                                            Select
                                          </Link>
                                        </div>
                                      ) : null
                                    )
                                  ) : (
                                    <div>
                                      <Skeleton
                                        height={30}
                                        className="w-75 mb-1"
                                      />
                                      <Skeleton
                                        height={42}
                                        className="w-50 mb-1"
                                      />
                                      <Skeleton
                                        height={30}
                                        className="w-50 mb-1"
                                      />
                                    </div>
                                  )}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {state.datasubs.length > 0 ? (
                                state.datasubs.map((d) => (
                                  <tr>
                                    <td scope="row" className="fw-bold">
                                      {d.Free_name}
                                    </td>
                                    <td className="text-center">
                                      {d.Free_type === "boolean" ? (
                                        d.Free_value === true ? (
                                          <i className="fas fa-check text-success"></i>
                                        ) : (
                                          <i className="fas fa-times text-danger"></i>
                                        )
                                      ) : (
                                        <small className="text-break fs-13 me-1">
                                          {d.Free_value}
                                        </small>
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {d.Premium_type === "boolean" ? (
                                        d.Premium_value === true ? (
                                          <i className="fas fa-check text-success"></i>
                                        ) : (
                                          <i className="fas fa-times text-danger"></i>
                                        )
                                      ) : (
                                        <small className="text-break fs-13 me-1">
                                          {d.Premium_value}
                                        </small>
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {d.Enterprise_type === "boolean" ? (
                                        d.Enterprise_value === true ? (
                                          <i className="fas fa-check text-success"></i>
                                        ) : (
                                          <i className="fas fa-times text-danger"></i>
                                        )
                                      ) : (
                                        <small className="text-break fs-13 me-1">
                                          {d.Enterprise_value}
                                        </small>
                                      )}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    scope="row"
                                    className="text-center fw-bold"
                                  >
                                    <Skeleton
                                      height={30}
                                      count={7}
                                      className="w-75 mb-1"
                                    />
                                  </td>
                                  <td
                                    scope="row"
                                    className="text-center fw-bold"
                                  >
                                    <Skeleton
                                      height={30}
                                      count={7}
                                      className="w-50 mb-1"
                                    />
                                  </td>
                                  <td
                                    scope="row"
                                    className="text-center fw-bold"
                                  >
                                    <Skeleton
                                      height={30}
                                      count={7}
                                      className="w-50 mb-1"
                                    />
                                  </td>
                                  <td
                                    scope="row"
                                    className="text-center fw-bold"
                                  >
                                    <Skeleton
                                      height={30}
                                      count={7}
                                      className="w-50 mb-1"
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="annual">
                    <div className="row py-4">
                      <div className="card rounded-4 p-4">
                        <div className="table-responsive">
                          <table className="table table-striped text-successtable-border border-light">
                            <thead className="border-light text-center">
                              <tr>
                                <th scope="col" className="w-25"></th>
                                <th scope="col" className="w-25">
                                  {state.dataset.length > 0 ? (
                                    state.dataset.map((d) =>
                                      d.subscription
                                        .idt_product_subscription === 1 ? (
                                        <div>
                                          <h3>{d.subscription.name}</h3>
                                          <div className="text-orange mb-2">
                                            <span className="fs-16">Rp</span>
                                            <span className="fs-34 fw-bold">
                                              {format_number(
                                                d.price_monthly_offering
                                              )}
                                            </span>
                                            <span className="fs-16"></span>
                                          </div>
                                          <Link
                                            to=""
                                            onClick={(e) => {
                                              e.preventDefault();
                                              navigate("/register-free", {
                                                state: {
                                                  id: d.idt_product_subscription_price_plan,
                                                },
                                              });
                                            }}
                                            className="btn btn-md btn-primary"
                                          >
                                            Select
                                          </Link>
                                        </div>
                                      ) : null
                                    )
                                  ) : (
                                    <div>
                                      <Skeleton
                                        height={30}
                                        className="w-75 mb-1"
                                      />
                                      <Skeleton
                                        height={42}
                                        className="w-50 mb-1"
                                      />
                                      <Skeleton
                                        height={30}
                                        className="w-50 mb-1"
                                      />
                                    </div>
                                  )}
                                </th>
                                <th scope="col" className="w-25">
                                  {state.dataset.length > 0 ? (
                                    state.dataset.map((d) =>
                                      d.subscription
                                        .idt_product_subscription === 2 &&
                                      d.plan_length >= 12 ? (
                                        <div>
                                          <h3>{d.subscription.name}</h3>
                                          <div className="text-orange mb-2">
                                            <span className="fs-16">Rp</span>
                                            <span className="fs-34 fw-bold">
                                              {format_number(
                                                d.price_monthly_offering *
                                                  d.plan_length
                                              )}
                                            </span>
                                            <span className="fs-16">
                                              / year
                                            </span>
                                          </div>
                                          <Link
                                            to=""
                                            onClick={(e) => {
                                              e.preventDefault();
                                              navigate("/register-premium", {
                                                state: {
                                                  id: d.idt_product_subscription_price_plan,
                                                },
                                              });
                                            }}
                                            className="btn btn-md btn-primary"
                                          >
                                            Select
                                          </Link>
                                        </div>
                                      ) : null
                                    )
                                  ) : (
                                    <div>
                                      <Skeleton
                                        height={30}
                                        className="w-75 mb-1"
                                      />
                                      <Skeleton
                                        height={42}
                                        className="w-50 mb-1"
                                      />
                                      <Skeleton
                                        height={30}
                                        className="w-50 mb-1"
                                      />
                                    </div>
                                  )}
                                </th>
                                <th scope="col" className="w-25">
                                  {state.dataset.length > 0 ? (
                                    state.dataset.map((d) =>
                                      d.subscription
                                        .idt_product_subscription === 3 &&
                                      d.plan_length >= 12 ? (
                                        <div>
                                          <h3>{d.subscription.name}</h3>
                                          <div className="text-orange mb-2">
                                            <span className="fs-16">Rp</span>
                                            <span className="fs-34 fw-bold">
                                              {format_number(
                                                d.price_monthly_offering *
                                                  d.plan_length
                                              )}
                                            </span>
                                            <span className="fs-16">
                                              / year
                                            </span>
                                          </div>
                                          <Link
                                            to=""
                                            onClick={(e) => {
                                              e.preventDefault();
                                              navigate("/register-enterprise", {
                                                state: {
                                                  id: d.idt_product_subscription_price_plan,
                                                },
                                              });
                                            }}
                                            className="btn btn-md btn-primary"
                                          >
                                            Select
                                          </Link>
                                        </div>
                                      ) : null
                                    )
                                  ) : (
                                    <div>
                                      <Skeleton
                                        height={30}
                                        className="w-75 mb-1"
                                      />
                                      <Skeleton
                                        height={42}
                                        className="w-50 mb-1"
                                      />
                                      <Skeleton
                                        height={30}
                                        className="w-50 mb-1"
                                      />
                                    </div>
                                  )}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {state.datasubs.length > 0 ? (
                                state.datasubs.map((d) => (
                                  <tr>
                                    <td scope="row" className="fw-bold">
                                      {d.Free_name}
                                    </td>
                                    <td className="text-center">
                                      {d.Free_type === "boolean" ? (
                                        d.Free_value === true ? (
                                          <i className="fas fa-check text-success"></i>
                                        ) : (
                                          <i className="fas fa-times text-danger"></i>
                                        )
                                      ) : (
                                        <small className="text-break fs-13 me-1">
                                          {d.Free_value}
                                        </small>
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {d.Premium_type === "boolean" ? (
                                        d.Premium_value === true ? (
                                          <i className="fas fa-check text-success"></i>
                                        ) : (
                                          <i className="fas fa-times text-danger"></i>
                                        )
                                      ) : (
                                        <small className="text-break fs-13 me-1">
                                          {d.Premium_value}
                                        </small>
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {d.Enterprise_type === "boolean" ? (
                                        d.Enterprise_value === true ? (
                                          <i className="fas fa-check text-success"></i>
                                        ) : (
                                          <i className="fas fa-times text-danger"></i>
                                        )
                                      ) : (
                                        <small className="text-break fs-13 me-1">
                                          {d.Enterprise_value}
                                        </small>
                                      )}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    scope="row"
                                    className="text-center fw-bold"
                                  >
                                    <Skeleton
                                      height={30}
                                      count={7}
                                      className="w-75 mb-1"
                                    />
                                  </td>
                                  <td
                                    scope="row"
                                    className="text-center fw-bold"
                                  >
                                    <Skeleton
                                      height={30}
                                      count={7}
                                      className="w-50 mb-1"
                                    />
                                  </td>
                                  <td
                                    scope="row"
                                    className="text-center fw-bold"
                                  >
                                    <Skeleton
                                      height={30}
                                      count={7}
                                      className="w-50 mb-1"
                                    />
                                  </td>
                                  <td
                                    scope="row"
                                    className="text-center fw-bold"
                                  >
                                    <Skeleton
                                      height={30}
                                      count={7}
                                      className="w-50 mb-1"
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ComparationPlans;
