import { React, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import ck_success from "../../assets/check-success.gif";
import storage from "../../services/storage";

const ActivationSuccess = () => {
  //Navigate
  const navigate = useNavigate();

  useEffect(() => {
    storage.remove("dform");
  }, []);

  return (
    <div className="main d-flex justify-content-center w-100 bg-full-login">
      <main className="content d-flex p-4">
        <div className="container d-flex flex-column">
          <Link to="/" style={{ width: 100 }}>
            <img
              className="img-logo-login d-none d-sm-block"
              src={logo}
              alt="logo"
            />
          </Link>
          <div className="row h-100">
            <div className="col-sm-10 col-md-8 col-lg-5 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center ms-2 me-2 mb-3">
                  <img
                    className="img-logo-login d-md-none d-lg-none"
                    src={logo}
                    alt="logo"
                  />
                </div>
                <div className="card bg-white-transparent rounded-4">
                  <div className="text-center mt-4">
                    <h3 className="h3 text-orange">Activation Success</h3>
                  </div>
                  <div className="card-body pt-0">
                    <div className="text-center mt-2">
                      <div className="text-center">
                        <img
                          className="mt-3 mb-5"
                          src={ck_success}
                          alt="success"
                        />
                      </div>
                      <div className="text-center text-dark col-md-10 mx-auto">
                        <p className="mb-4">
                          You can now use your Otokata account.
                        </p>
                      </div>
                      <div className="text-center mt-7">
                        <Link to="/login" className="btn btn-md btn-primary">
                          Sign In
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ActivationSuccess;
