import { React, useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { API_URL } from "../../utilities/url";
import axios from "axios";
import AuthService from "../../services/auth.service";
import logo from "../../assets/logo.png";
import { FiEye, FiEyeOff, FiLock } from "react-icons/fi";
import { TiWarningOutline } from "react-icons/ti";

var $ = require("jquery");

const ResetPassword = () => {
  //Navigate
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.split("/")[2];
  const [state, setState] = useState({
    email: "",
  });

  useEffect(() => {
    //Auth
    if (AuthService.auth()) {
      navigate("/dashboard");
    }

    // Check Token
    axios
      .post(API_URL + "exp-token-password", { token: currentPath })
      .then((response) => {
        if (response.data.success === true) {
          setState((prevState) => ({
            ...prevState,
            ["email"]: response.data.data.email,
          }));
        } else {
          $("#btn-modal").trigger("click");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });

    // Validation Form
    var $validationForm = $("#reset-password");
    $validationForm.validate({
      errorPlacement: function errorPlacement(error, element) {
        $(element)
          .parents(".error-placeholder")
          .append(error.addClass("invalid-feedback small d-block"));
      },
      highlight: function (element) {
        $(element).addClass("is-invalid");
      },
      unhighlight: function (element) {
        $(element).removeClass("is-invalid");
      },
      onfocusout: function (element) {
        return $(element).valid();
      },
      rules: {
        confirm_password: {
          equalTo: 'input[name="password"]',
        },
        password: {
          minlength: 6,
        },
      },
    });

    $validationForm.find("#btn-submit").on("click", function () {
      if (!$validationForm.valid()) {
        return;
      }
      setHideSpiner(false);
      $("#btn-submit").addClass("disabled");
      const data = {
        email: $("#email").val(),
        token: currentPath,
        password: $("[name='password']").val(),
      };
      axios
        .post(API_URL + "reset-password", data)
        .then((response) => {
          if (response.data.success === true) {
            navigate("/reset-password-success");
          }
        })
        .catch((error) => {
          setHideSpiner(true);
          $("#b-submit").removeClass("disabled");
        });
    });
  }, []);

  const [hideSpiner, setHideSpiner] = useState(true);
  const [hidePassword, setHidePassword] = useState("password");
  const [hidePassword2, setHidePassword2] = useState("password");
  const togglePassword = () => {
    if (hidePassword === "password") {
      setHidePassword("text");
      return;
    }
    setHidePassword("password");
  };
  const togglePassword2 = () => {
    if (hidePassword2 === "password") {
      setHidePassword2("text");
      return;
    }
    setHidePassword2("password");
  };

  const backLogin = () => {
    navigate("/login");
  };

  return (
    <div className="main d-flex justify-content-center w-100 bg-full-login">
      <main className="content d-flex p-4">
        <div className="container d-flex flex-column">
          <Link to="/" style={{ width: 100 }}>
            <img
              className="img-logo-login d-none d-sm-block"
              src={logo}
              alt="logo"
            />
          </Link>
          <div className="row h-100">
            <div className="col-sm-10 col-md-8 col-lg-5 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center ms-2 me-2 mb-3">
                  <img
                    className="img-logo-login d-md-none d-lg-none"
                    src={logo}
                    alt="logo"
                  />
                </div>
                <div className="card bg-white-transparent rounded-4">
                  <div className="text-center mt-4">
                    <h3 className="h2 text-orange">RESET PASSWORD</h3>
                    <p className="mb-0">Create New Password</p>
                  </div>
                  <div className="card-body pt-0">
                    <div className="m-sm-5">
                      <form id="reset-password">
                        <div className="mb-3">
                          <div className="mb-3 error-placeholder">
                            <label className="form-label">
                              Password
                              <span className="text-danger">*</span>
                            </label>
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-text">
                                <FiLock size={20} />
                              </div>
                              <input
                                type="hidden"
                                id="email"
                                value={state.email}
                              />
                              <input
                                name="password"
                                type={hidePassword}
                                className="form-control form-control-lg required"
                              />
                              <div className="input-group-text">
                                <Link
                                  to=""
                                  className="text-dark"
                                  onClick={togglePassword}
                                >
                                  {hidePassword === "password" ? (
                                    <FiEye size={20} />
                                  ) : (
                                    <FiEyeOff size={20} />
                                  )}
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="mb-0 error-placeholder">
                            <label className="form-label">
                              Confirm Password
                              <span className="text-danger">*</span>
                            </label>
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-text">
                                <FiLock size={20} />
                              </div>
                              <input
                                name="confirm_password"
                                type={hidePassword2}
                                className="form-control form-control-lg required"
                              />
                              <div className="input-group-text">
                                <Link
                                  to=""
                                  className="text-dark"
                                  onClick={togglePassword2}
                                >
                                  {hidePassword2 === "password" ? (
                                    <FiEye size={20} />
                                  ) : (
                                    <FiEyeOff size={20} />
                                  )}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-5">
                          <div className="col-md-12">
                            <div className="d-grid gap-1">
                              <Link
                                to=""
                                id="btn-submit"
                                className="btn btn-tertiary btn-lg btn-block"
                              >
                                <span
                                  className={`spinner-border spinner-border-sm me-2 ${
                                    hideSpiner ? "d-none" : ""
                                  }`}
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Reset Password
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>

                      {/* Modal */}
                      <button
                        type="button"
                        className="btn btn-primary my-1 d-none"
                        data-bs-toggle="modal"
                        data-bs-target="#centeredModal"
                        id="btn-modal"
                      ></button>
                      <div
                        className="modal fade"
                        id="centeredModal"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        role="dialog"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-sm modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              {/* <h5 className="modal-title">Centered modal</h5> */}
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={backLogin}
                              ></button>
                            </div>
                            <div className="modal-body m-2">
                              <div className="text-center">
                                <TiWarningOutline size={40} className="mb-2" />
                                <p className="mb-0 fs-14 mb-2">
                                  Your token has expired or invalid token.
                                </p>
                                <p className="mb-0 fs-12">
                                  Please repeat the forgot password process.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ResetPassword;
