import { React, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { API_URL } from "../../utilities/url";
import axios from "axios";
import AuthService from "../../services/auth.service";
import logo from "../../assets/logo.png";
import { HiOutlineMail } from "react-icons/hi";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

var $ = require("jquery");

const ForgotPassword = () => {
  //Navigate
  const navigate = useNavigate();
  const [hideSpiner, setHideSpiner] = useState(true);

  useEffect(() => {
    // Set Captcha
    loadCaptchaEnginge(5, "grey", "white");

    //Auth
    if (AuthService.auth()) {
      navigate("/dashboard");
    }

    // Validation Form
    var $validationForm = $("#forgot-password");
    $validationForm.validate({
      errorPlacement: function errorPlacement(error, element) {
        $(element)
          .parents(".error-placeholder")
          .append(error.addClass("invalid-feedback small d-block"));
      },
      highlight: function (element) {
        $(element).addClass("is-invalid");
      },
      unhighlight: function (element) {
        $(element).removeClass("is-invalid");
      },
      onfocusout: function (element) {
        return $(element).valid();
      },
      rules: {
        confirm_password: {
          equalTo: 'input[name="password"]',
        },
        password: {
          minlength: 6,
        },
      },
    });

    $validationForm.find("#btn-submit").on("click", function () {
      if (!$validationForm.valid()) {
        return;
      }
      if (validateCaptcha($("#captcha").val(), false) === true) {
        setHideAlertCaptcha(true);
        setHideSpiner(false);
        $("#btn-submit").addClass("disabled");
        const data = {
          email: $("[name='email']").val(),
        };
        axios
          .post(API_URL + "forgot-password", data)
          .then((response) => {
            if (response.data.success === true) {
              setHideAlertSuccess(false);
              $validationForm.trigger("reset");
              loadCaptchaEnginge(5, "grey", "white");
              setHideSpiner(true);
              $("#btn-submit").removeClass("disabled");
            } else {
              setHideAlert(false);
              setHideSpiner(true);
              $("#btn-submit").removeClass("disabled");
            }
          })
          .catch((error) => {
            setHideSpiner(true);
            $("#b-submit").removeClass("disabled");
          });
      } else {
        setHideAlertCaptcha(false);
        loadCaptchaEnginge(5, "grey", "white");
        $("#captcha").val("");
      }
    });
  }, []);

  const [hideAlert, setHideAlert] = useState(true);
  const [hideAlertSuccess, setHideAlertSuccess] = useState(true);
  const [hideAlertCaptcha, setHideAlertCaptcha] = useState(true);

  return (
    <div className="main d-flex justify-content-center w-100 bg-full-login">
      <main className="content d-flex p-4">
        <div className="container d-flex flex-column">
          <img className="img-logo-login d-none d-sm-block" src={logo} />
          <div className="row h-100">
            <div className="col-sm-10 col-md-8 col-lg-5 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center ms-2 me-2 mb-3">
                  <img
                    className="img-logo-login d-md-none d-lg-none"
                    src={logo}
                  />
                </div>
                <div className="card bg-white-transparent rounded-4">
                  <div className="text-center mt-4">
                    <h3 className="h2 text-orange">FORGOT PASSWORD</h3>
                    <p className="mb-0">
                      We will send a link to reset your password
                    </p>
                  </div>
                  <div className="card-body pt-0">
                    <div className="m-sm-5 m-4">
                      <form id="forgot-password">
                        <div
                          className={`alert alert-danger alert-dismissible ${
                            hideAlertCaptcha ? "d-none" : "d-block"
                          }`}
                          role="alert"
                        >
                          <button
                            onClick={() => setHideAlertCaptcha(true)}
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                          <div className="alert-message">
                            <strong className="text-danger">
                              Captcha don't match.
                            </strong>
                          </div>
                        </div>
                        <div
                          className={`alert alert-success alert-dismissible ${
                            hideAlertSuccess ? "d-none" : "d-block"
                          }`}
                          role="alert"
                        >
                          <button
                            onClick={() => setHideAlertSuccess(true)}
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                          <div className="alert-message">
                            <strong className="text-success">
                              Reset password has been sent to email.
                            </strong>
                          </div>
                        </div>
                        <div
                          className={`alert alert-danger alert-dismissible ${
                            hideAlert ? "d-none" : "d-block"
                          }`}
                          role="alert"
                        >
                          <button
                            onClick={() => setHideAlert(true)}
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                          <div className="alert-message">
                            <strong className="text-danger">
                              Email not registered.
                            </strong>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="mb-3 error-placeholder">
                            <label className="form-label">Email</label>
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-text">
                                <HiOutlineMail size={20} />
                              </div>
                              <input
                                name="email"
                                type="text"
                                className="form-control form-control-lg required email"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3 text-center">
                          <LoadCanvasTemplateNoReload />
                          <div className="col-md-12 col-lg-12">
                            <div className="error-placeholder">
                              <input
                                id="captcha"
                                type="text"
                                className="form-control w-50 mx-auto form-control-lg required"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4 mb-3">
                          <div className="col-md-12">
                            <div className="d-grid gap-1 mb-2">
                              <Link
                                to=""
                                id="btn-submit"
                                className="btn btn-tertiary btn-lg btn-block"
                              >
                                <span
                                  className={`spinner-border spinner-border-sm me-2 ${
                                    hideSpiner ? "d-none" : ""
                                  }`}
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Send Link Reset Password
                              </Link>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="d-grid gap-1">
                              <Link
                                to="/login"
                                className="btn btn-outline-primary btn-lg btn-block"
                              >
                                Back to Login
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ForgotPassword;
