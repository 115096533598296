import { React, useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { API_URL } from "../../utilities/url";
import axios from "axios";
import AuthService from "../../services/auth.service";
import logo from "../../assets/logo.png";
import OTPInput, { ResendOTP } from "otp-input-react";

const OTPVerification = () => {
  //Navigate
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.split("/")[2];
  const [OTP, setOTP] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [hideAlert, setHideAlert] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const numberOTP = 5;

  useEffect(() => {
    axios
      .post(API_URL + "user-verification-check", { token: currentPath })
      .then((response) => {
        if (response.data.success === false) {
          navigate("/login");
        } else {
          setIsVerified(true); // Set verified to true to render the component
        }
      })
      .catch((error) => {
        console.log("There was an error!", error);
        setIsVerified(false);
      });
  }, []);

  const handleChange = (data) => {
    setOTP(data);
  };
  const isOtpComplete = OTP.length === numberOTP;
  const resendOTP = () => {
    axios
      .post(API_URL + "resend-otp", { token: currentPath })
      .then((response) => {
        if (response.data.success === false) {
          setHideAlert(false);
          setAlertMessage("Token not valid.");
        }
      })
      .catch((error) => {
        console.log("There was an error!", error);
      });
  };
  const onSubmit = () => {
    axios
      .post(API_URL + "user-verification", { token: currentPath, otp: OTP })
      .then((response) => {
        if (response.data.success === true) {
          navigate("/activation-success");
        } else if (response.data.message == "Token not valid") {
          setHideAlert(false);
          setAlertMessage("Token not valid.");
        } else {
          setHideAlert(false);
          setAlertMessage("OTP is wrong.");
        }
      })
      .catch((error) => {
        console.log("There was an error!", error);
      });
    // setHideAlert(false);
    // setAlertMessage("OTP is wrong.");
  };

  if (!isVerified) {
    return null; // or you can return a loading indicator here
  }

  return (
    <div className="main d-flex justify-content-center w-100 bg-full-login">
      <main className="content d-flex p-4">
        <div className="container d-flex flex-column">
          <Link to="/" style={{ width: 100 }}>
            <img
              className="img-logo-login d-none d-sm-block"
              src={logo}
              alt="logo"
            />
          </Link>
          <div className="row h-100">
            <div className="col-sm-10 col-md-8 col-lg-8 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center ms-2 me-2 mb-3">
                  <img
                    className="img-logo-login d-md-none d-lg-none"
                    src={logo}
                  />
                </div>
                <div className="card bg-white-transparent rounded-4">
                  <div className="text-center mt-4">
                    <h4 className="h2 text-orange">OTP Verification</h4>
                    <hr className="w-75 border border-1 border-dark mx-auto"></hr>
                  </div>
                  <div className="card-body">
                    <div className="text-center col-md-10 mx-auto">
                      <p className="mb-2">
                        To verified your phone number, please enter 5 digit code
                        sent to:
                      </p>
                      {/* <h4 className="p-0 text-orange">email@otokata.id</h4> */}
                    </div>
                    <div
                      className={`alert alert-danger alert-dismissible fade show mt-3 col-md-10 mx-auto ${
                        hideAlert ? "d-none" : "d-block"
                      }`}
                      role="alert"
                    >
                      <button
                        onClick={() => setHideAlert(true)}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                      ></button>
                      <div className="alert-message">
                        <strong className="text-danger">{alertMessage}</strong>
                      </div>
                    </div>
                    <div className="text-center mt-3" id="otp-verification">
                      <OTPInput
                        value={OTP}
                        onChange={handleChange}
                        //   onClick={handleChange}
                        autoFocus
                        OTPLength={numberOTP}
                        otpType="number"
                        disabled={false}
                        // secure
                      />
                      <ResendOTP onResendClick={resendOTP} />
                    </div>
                    <hr className="w-75 border border-1 border-dark mx-auto"></hr>
                    <div className="d-block col-md-10 mx-auto text-center">
                      <button
                        onClick={onSubmit}
                        disabled={!isOtpComplete}
                        className="btn btn-md btn-primary"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default OTPVerification;
