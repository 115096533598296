import { React, useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { API_URL } from "../../utilities/url";
import axios from "axios";
import logo from "../../assets/logo.png";
import {
  HiOutlineCreditCard,
  HiOutlineOfficeBuilding,
  HiOutlineUser,
} from "react-icons/hi";
import { HiOutlineDocumentMagnifyingGlass } from "react-icons/hi2";
import { RiContactsBookLine } from "react-icons/ri";
import Select from "react-select";

var $ = require("jquery");

const RegisterWizardEnterprise = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(location.state);
    // Datepicker
    $(".datepicker")
      .datepicker({
        format: "dd-mm-yyyy",
        autoclose: true,
        todayHighlight: true,
        endDate: "+0d",
      })
      .on("changeDate", function (e) {
        $("#wizard-dob").val(e.target.value);
        $(this).valid();
      });

    // Validation Wizard
    var $validationForm = $("#smartwizard-validation");
    $.validator.addMethod(
      "regex_phone",
      function (value, element, regexp) {
        if (regexp.constructor != RegExp) regexp = new RegExp(regexp);
        else if (regexp.global) regexp.lastIndex = 0;
        return this.optional(element) || regexp.test(value);
      },
      "Please enter a valid phone number."
    );
    $validationForm.validate({
      errorPlacement: function errorPlacement(error, element) {
        $(element)
          .parents(".error-placeholder")
          .append(error.addClass("invalid-feedback small d-block"));
      },
      highlight: function (element) {
        $(element).addClass("is-invalid");
      },
      unhighlight: function (element) {
        $(element).removeClass("is-invalid");
      },
      onfocusout: function (element) {
        return $(element).valid();
      },
      rules: {
        remote: {
          url: API_URL + "exist_user_v2",
          type: "post",
          data: {
            email: function () {
              return $("[name='email']").val();
            },
          },
        },
        confirm_password: {
          equalTo: 'input[name="password"]',
        },
        msisdn: {
          regex_phone: /^\+?[1-9][0-9]{7,14}$/,
          minlength: 9,
        },
        password: {
          minlength: 6,
        },
      },
      messages: {
        email: {
          remote: "Email is already taken.",
        },
      },
    });

    // Init Wizard
    $validationForm
      .smartWizard({
        theme: "arrows",
        autoAdjustHeight: false,
        backButtonSupport: false,
        useURLhash: false,
        showStepURLhash: false,
        toolbar: {
          extraHtml:
            '<button class="btn sw-btn-finish sw-btn btn-success d-none" id="btn-finished" type="button">Finish</button>', // Extra html to show on toolbar
        },
        anchor: {
          enableNavigation: true, // Enable/Disable anchor navigation
          enableNavigationAlways: false, // Activates all anchors clickable always
          enableDoneState: true, // Add done state on visited steps
        },
      })
      .on("showStep", function (e, anchorObject, stepNumber, stepDirection) {
        if (stepNumber === 4) {
          $(".sw-btn-finish").removeClass("d-none");
        } else {
          $(".sw-btn-finish").addClass("d-none");
        }
        if (stepNumber === 0) {
          $(".sw-btn-prev").addClass("d-none");
        } else {
          $(".sw-btn-prev").removeClass("d-none");
          $(".sw-btn-prev").addClass("float-start");
        }
      })
      .on("leaveStep", function (e, anchorObject, stepNumber, stepDirection) {
        if (stepDirection <= 4) {
          return $validationForm.valid();
        }
        return true;
      });
    $validationForm.find(".sw-btn-finish").on("click", function () {
      if (!$validationForm.valid()) {
        return;
      }
      const data = {
        idt_product_subscription_plan: location.state.id,
        email: $("[name='email']").val(),
        password: $("[name='password']").val(),
        name_first: $("[name='firstname']").val(),
        name_last: $("[name='firstname']").val(),
        dob: $("[name='dob']").val(),
        country: $("[name='country']").val(),
        msisdn: $("[name='msisdn']").val(),
      };
      axios
        .post(API_URL + "register", data)
        .then((response) => {
          if (response.data.success === true) {
            navigate("/register-success");
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    });

    // Get Country + Phone
    axios
      .get(API_URL + "countries")
      .then((res) => {
        if (res.data.success === true) {
          const maps = res.data.data.map(function (d) {
            const map = {
              value: d.idt_md_country,
              label: d.name,
              phone: "+" + d.phonecode,
            };
            if (d.name === "Indonesia") {
              setSelectedOption(map);
              setSelectedOptionCompany(map);
              setState((prevState) => ({
                ...prevState,
                ["msisdn"]: "+" + d.phonecode,
              }));
            }
            return map;
          });
          setState((prevState) => ({
            ...prevState,
            ["country"]: maps,
          }));
        } else {
          console.log(res.data.success);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);

  // Set Data
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOptionCompany, setSelectedOptionCompany] = useState();
  const handleSelect = (data) => {
    setSelectedOption(data);
    setState((prevState) => ({
      ...prevState,
      ["msisdn"]: data.phone,
    }));
  };
  const handleSelectCompany = (data) => {
    setSelectedOptionCompany(data);
  };
  const [state, setState] = useState({
    email: "",
    password: "",
    confirm_password: "",
    firstname: "",
    lastname: "",
    dob: "",
    country: "",
    msisdn: "",
    company: "",
    country_company: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      borderRadius: 4,
      minHeight: 32,
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      padding: 0,
      margin: -2,
    }),
  };

  return (
    <div className="main d-flex justify-content-center w-100 bg-full-login">
      <main className="content d-flex p-4">
        <div className="container d-flex flex-column">
          <Link to="/" style={{ width: 100 }}>
            <img
              className="img-logo-login d-none d-sm-block"
              src={logo}
              alt="logo"
            />
          </Link>
          <div className="row h-100">
            <div className="col-sm-12 col-md-10 col-lg-10 mx-auto h-100">
              <div className="d-block align-middle">
                <div className="text-center ms-2 me-2 mb-3">
                  <Link to="/" style={{ width: 100 }}>
                    <img
                      className="img-logo-login d-md-none d-lg-none"
                      src={logo}
                    />
                  </Link>
                </div>
                <div className="mt-5 p-2 bg-white rounded">
                  <form
                    id="smartwizard-validation"
                    className="wizard wizard-primary border-0 mb-0"
                    action=""
                  >
                    <ul className="nav d-none d-md-flex d-lg-flex">
                      <li className="nav-item">
                        <a className="nav-link">
                          <div className="num p-2">
                            <HiOutlineUser />
                            <small className="ms-2 fs-13">Account</small>
                          </div>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link">
                          <div className="num p-2">
                            <RiContactsBookLine />
                            <small className="ms-2 fs-13">Personal</small>
                          </div>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link">
                          <div className="num p-2">
                            <HiOutlineOfficeBuilding />
                            <small className="ms-2 fs-13">Company</small>
                          </div>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link">
                          <div className="num p-2">
                            <HiOutlineDocumentMagnifyingGlass />
                            <small className="ms-2 fs-13">Review</small>
                          </div>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link">
                          <div className="num p-2">
                            <HiOutlineCreditCard />
                            <small className="ms-2 fs-13">Payment</small>
                          </div>
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content">
                      <div id="step-1" className="tab-pane" role="tabpanel">
                        <div className="row">
                          <div className="col-sm-12 col-md-5 mx-auto">
                            <h4 className="mt-2 mb-4 text-center">Account</h4>
                            <div className="mb-3 error-placeholder">
                              <label className="form-label">
                                Email
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name="email"
                                type="text"
                                value={state.email}
                                className="form-control required email"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="mb-3 error-placeholder">
                              <label className="form-label">
                                Password
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name="password"
                                type="password"
                                value={state.password}
                                className="form-control required"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="mb-0 error-placeholder">
                              <label className="form-label">
                                Confirm Password
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name="confirm_password"
                                type="password"
                                value={state.confirm_password}
                                className="form-control required"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="step-2" className="tab-pane" role="tabpanel">
                        <div className="row">
                          <div className="col-sm-12 col-md-10 mx-auto">
                            <h4 className="mt-2 mb-4 text-center">Personal</h4>
                            <div className="row">
                              <div className="col-sm-12 col-md-5 mx-auto">
                                <div className="mb-3 error-placeholder">
                                  <label className="form-label">
                                    First name
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    name="firstname"
                                    type="text"
                                    value={state.firstname}
                                    className="form-control required"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="mb-3 error-placeholder">
                                  <label className="form-label">
                                    Last name
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    name="lastname"
                                    type="text"
                                    value={state.lastname}
                                    className="form-control required"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="mb-3 error-placeholder">
                                  <label className="form-label">
                                    Date of Birth
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    name="dob"
                                    type="text"
                                    className="form-control datepicker required"
                                    readOnly="readonly"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-5 mx-auto">
                                <div className="mb-3 error-placeholder">
                                  <label className="form-label">
                                    Country
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    name="country"
                                    options={state.country}
                                    placeholder="Select Country"
                                    value={selectedOption}
                                    onChange={handleSelect}
                                    isSearchable={true}
                                    selected={selectedOption}
                                    styles={customStyles}
                                    maxMenuHeight={145}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        // primary25: "#fca311",
                                        // primary50: "#fca311",
                                        primary: "#fca311",
                                        // neutral80: "#000",
                                      },
                                    })}
                                  />
                                </div>
                                <div className="mb-0 error-placeholder">
                                  <label className="form-label">
                                    Phone Number
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    name="msisdn"
                                    type="text"
                                    value={state.msisdn}
                                    className="form-control required"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="step-3" className="tab-pane" role="tabpanel">
                        <div className="row">
                          <div
                            className="col-sm-12 col-md-5 mx-auto"
                            style={{ height: 300 }}
                          >
                            <h4 className="mt-2 mb-4 text-center">Company</h4>
                            <div className="mb-3 error-placeholder">
                              <label className="form-label">
                                Company Name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name="company"
                                type="text"
                                value={state.company}
                                className="form-control required"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="mb-3 error-placeholder">
                              <label className="form-label">
                                Company Country
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                name="country"
                                options={state.country}
                                placeholder="Select Country"
                                value={selectedOptionCompany}
                                onChange={handleSelectCompany}
                                isSearchable={true}
                                selected={selectedOption}
                                styles={customStyles}
                                maxMenuHeight={145}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    // primary25: "#fca311",
                                    // primary50: "#fca311",
                                    primary: "#fca311",
                                    // neutral80: "#000",
                                  },
                                })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="step-4" className="tab-pane" role="tabpanel">
                        <div className="row">
                          <div className="col-sm-12 col-md-5 mt-2 mb-4 mx-auto h-auto">
                            <h4 className="mb-3 text-center">Personal</h4>
                            <div className="mb-3 error-placeholder">
                              <label className="form-label">First name</label>
                              <input
                                id="wizard-firstname"
                                type="text"
                                value={state.firstname}
                                className="form-control"
                                readOnly="readonly"
                              />
                            </div>
                            <div className="mb-3 error-placeholder">
                              <label className="form-label">Last name</label>
                              <input
                                id="wizard-lastname"
                                type="text"
                                value={state.lastname}
                                className="form-control"
                                readOnly="readonly"
                              />
                            </div>
                            <div className="mb-3 error-placeholder">
                              <label className="form-label">
                                Date of Birth
                              </label>
                              <input
                                id="wizard-dob"
                                type="text"
                                className="form-control"
                                readOnly="readonly"
                              />
                            </div>
                            <div className="mb-3 error-placeholder">
                              <label className="form-label">Country</label>
                              <input
                                id="wizard-country"
                                type="text"
                                value={
                                  selectedOption ? selectedOption.label : ""
                                }
                                className="form-control"
                                readOnly="readonly"
                              />
                            </div>
                            <div className="mb-0 error-placeholder">
                              <label className="form-label">Phone Number</label>
                              <input
                                id="wizard-phone"
                                type="text"
                                value={state.msisdn}
                                className="form-control"
                                readOnly="readonly"
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-5 mt-2 mb-4 mx-auto h-auto">
                            <h4 className="mb-3 text-center">Company</h4>
                            <div className="mb-3 error-placeholder">
                              <label className="form-label">Company Name</label>
                              <input
                                id="wizard-company"
                                type="text"
                                value={state.company}
                                className="form-control"
                                readOnly="readonly"
                              />
                            </div>
                            <div className="mb-3 error-placeholder">
                              <label className="form-label">
                                Company Country
                              </label>
                              <input
                                id="wizard-company-country"
                                type="text"
                                value={
                                  selectedOptionCompany
                                    ? selectedOptionCompany.label
                                    : ""
                                }
                                className="form-control"
                                readOnly="readonly"
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-5 mt-2 mb-4 mx-auto h-auto">
                            <h4 className="mt-3 text-center">Account</h4>
                            <div className="mb-3 error-placeholder">
                              <label className="form-label">Email</label>
                              <input
                                id="wizard-email"
                                type="text"
                                value={state.email}
                                className="form-control"
                                readOnly="readonly"
                              />
                            </div>
                            <div className="mb-3 error-placeholder">
                              <label className="form-label">Password</label>
                              <input
                                id="wizard-password"
                                type="text"
                                value={state.password}
                                className="form-control"
                                readOnly="readonly"
                              />
                            </div>
                            <div className="mt-3 mb-3 error-placeholder">
                              <label className="form-check">
                                <input
                                  type="checkbox"
                                  name="wizard-aggrement"
                                  className="form-check-input required"
                                />
                                <span className="form-check-label">
                                  I agree with Terms and Conditions
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-5 mx-auto"></div>
                        </div>
                      </div>
                      <div id="step-5" className="tab-pane" role="tabpanel">
                        <div className="row">
                          <h4 className="mt-2 mb-4 text-center">Pembayaran</h4>
                          <div className="col-sm-12 col-md-6 mx-auto">
                            <h4 className="mt-2 mb-4 fs-14 text-center">
                              Metode Pembayaran
                            </h4>
                          </div>
                          <div className="col-sm-12 col-md-6 mx-auto">
                            <h4 className="mt-2 mb-4 fs-14 text-center">
                              Ringkasan Pembayaran
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default RegisterWizardEnterprise;
