import { BrowserRouter } from "react-router-dom";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-dt/js/dataTables.dataTables.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/css/app.css";
import "./assets/css/style.css";
import "./App.css";
import Router from "./utilities/router";

require("jquery-validation");
require("smartwizard");
require("smartwizard/dist/css/smart_wizard_all.min.css");
require("bootstrap-datepicker");
require("bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css");

function App() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
    // <BrowserRouter>
    //   <Routes>
    //     // Login
    //     <Route path="/" element={<Login />}>
    //       <Route index element={<Login />} />
    //     </Route>
    //     <Route path="/login" element={<Login />}></Route>
    //     // Dashboard
    //     <Route path="/dashboard" element={<Dashboard />}></Route>
    //     // Test
    //     <Route path="/test" element={<Test />}></Route>
    //     <Route path="/test/add" element={<AddTest />}></Route>
    //   </Routes>
    // </BrowserRouter>
  );
}

export default App;
