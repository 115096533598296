import { React, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { API_URL } from "../../utilities/url";
import { FiPlus } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import axios from "axios";
import NavLinkDevice from "./nav-link-device";
import authHeader from "../../services/auth.header";
import Modal from "react-bootstrap/Modal";
import { FaPencilAlt } from "react-icons/fa";
import { Button } from "react-bootstrap";
import storage from "../../services/storage";
import CryptoJS from "crypto-js";

var $ = require("jquery");
var QRCode = require("qrcode");

const DeviceGroup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const dt_user = JSON.parse(decryptUSR);
  const decryptWS = CryptoJS.AES.decrypt(
    storage.get("workspace"),
    "workspace"
  ).toString(CryptoJS.enc.Utf8);
  const workspace = JSON.parse(decryptWS);

  useEffect(() => {
    if (localStorage.getItem("alert")) {
      toast.success("Register Device Successfull", {
        position: "top-right",
        theme: "colored",
        transition: Slide,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      localStorage.removeItem("alert");
    }

    dt_init();
  }, [location.key]);

  const dt_init = () => {
    $("#datatables-reponsive").DataTable({
      dom:
        "<'row'<'col-sm-6'><'col-sm-6'B>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: true,
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-table-device-groups",
        data: function (data) {
          data.idt_user_workspace = workspace.idt_user_workspace;
        },
        type: "post",
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            localStorage.clear();
            navigate("/login");
          }
        },
      },
      columnDefs: [
        {
          targets: 3,
          createdCell: (td, cellData, rowData, row, col) =>
            ReactDOM.render(
              <div className="d-grid gap-2 d-md-flex justify-content-md">
                <a
                  onClick={() =>
                    showModalTable(
                      rowData.idt_device_group,
                      rowData.idt_user,
                      rowData.idt_user_workspace,
                      rowData.name,
                      rowData.description,
                      rowData.status
                    )
                  }
                  data-bs-toggle="modal"
                  data-bs-target="#centeredModal"
                  className="btn btn-sm btn-primary btn-rounded"
                >
                  <FaPencilAlt />
                </a>
                {/* <a
                  onClick={() => deleteTable(cellData)}
                  className="btn btn-sm btn-tertiary btn-rounded"
                >
                  <FaTrash />
                </a> */}
              </div>,
              td
            ),
        },
      ],
      columns: [
        { data: "name" },
        { data: "description" },
        {
          data: "status",
          render: function (data, type, row) {
            if (data == 1) {
              var data = "Active";
            } else {
              var data = "Not Active";
            }

            return data;
          },
        },
        {
          data: "idt_device_group",
        },
      ],
      initComplete: function () {
        var api = this.api();
        $("#btn-dt-search")
          .off(".DT")
          .on("click.DT", function (e) {
            api.search($("#dt-search").val()).draw();
          });
        $("#btn-dt-reload")
          .off(".DT")
          .on("click.DT", function (e) {
            $("#dt-search").val(null);
            api.search($("#dt-search").val()).draw();
          });
      },
    });
  };

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function showModalTable(
    idt_device_group,
    idt_user,
    idt_user_workspace,
    name,
    description,
    status
  ) {
    $("#dd_device").val(idt_device_group);
    $("#dd_user").val(idt_user);
    $("#dd_workspace").val(idt_user_workspace);
    $("#dd_name").val(name);
    $("#dd_description").val(description);
    $("#dd_status").val(status);
  }

  const addDeviceGroup = () => {
    axios
      .post(
        API_URL + "add-device-group",
        {
          idt_user: dt_user.idt_user,
          idt_user_workspace: workspace.idt_user_workspace,
          name: $("[name='device_group_add']").val(),
          description: $("[name='description_add']").val(),
          status: $("[name='status_add']").val(),
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          toast.success("Add Device Group Successful", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          handleClose();
          $("#btn-dt-reload").trigger("click");
        } else {
          toast.error("Add Device Group Failed", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          handleClose();
        }
      })
      .catch((error) => {
        handleClose();
      });
  };

  const updateDeviceGroup = () => {
    axios
      .post(
        API_URL + "update-device-group",
        {
          idt_device_group: $("[name='device_group_id']").val(),
          idt_user: dt_user.idt_user,
          idt_user_workspace: workspace.idt_user_workspace,
          name: $("[name='device_group_update']").val(),
          description: $("[name='description_update']").val(),
          status: $("[name='status_update']").val(),
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          toast.success("Add Device Group Successful", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          $("#btn-update-close").trigger("click");
          $("#btn-dt-reload").trigger("click");
        } else {
          toast.error("Add Device Group Failed", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          $("#btn-update-close").trigger("click");
        }
      })
      .catch((error) => {
        $("#btn-update-close").trigger("click");
      });
  };

  return (
    <div className="container-fluid p-0">
      <NavLinkDevice />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="float-start">
                <h5 className="card-title mt-2">Device Group</h5>
                <ToastContainer />
              </div>
            </div>
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Header closeButton>
                <Modal.Title>Add Device Group</Modal.Title>
              </Modal.Header>
              <form id="form-add">
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3 row">
                        <label className="col-form-label col-sm-4 text-sm-right">
                          Name
                          <span className="required">*</span>
                        </label>
                        <div className="col-sm-6 error-placeholder">
                          <input
                            type="text"
                            name="device_group_add"
                            className="form-control"
                            placeholder="Device Group Name"
                          />
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label className="col-form-label col-sm-4 text-sm-right">
                          Description
                        </label>
                        <div className="col-sm-6 error-placeholder">
                          <input
                            type="text"
                            name="description_add"
                            className="form-control"
                            placeholder="Description"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Link
                    to=""
                    id="b-submit-add"
                    onClick={() => addDeviceGroup()}
                    className="btn btn-primary btn-md float-end"
                  >
                    Add
                  </Link>
                </Modal.Footer>
              </form>
            </Modal>
            <div className="card-body table-responsive">
              <div className="d-block mb-3">
                <div className="d-inline-block mt-1 mb-2">
                  <div className="input-group input-group-navbar border">
                    <input
                      type="text"
                      id="dt-search"
                      className="form-control form-control-sm h-auto"
                      placeholder="Search"
                      aria-label="Search"
                    />
                    <button
                      className="btn btn-light h-auto"
                      id="btn-dt-search"
                      style={{ backgroundColor: "#e2e2e2" }}
                    >
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="align-middle"
                        height="16"
                        width="16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </button>
                    <button
                      className="btn btn-secondary h-auto"
                      id="btn-dt-reload"
                      style={{
                        backgroundColor: "#e2e2e2",
                        borderLeft: "2px solid #f3f6fb",
                        color: "#fff",
                      }}
                    >
                      <HiOutlineRefresh size={15} />
                    </button>
                  </div>
                </div>
                <div className="d-sm-block float-md-end">
                  <Button variant="primary" onClick={handleShow}>
                    <FiPlus
                      className="align-middle"
                      size={14}
                      strokeWidth={4}
                    />{" "}
                    Add Device Group
                  </Button>
                </div>
              </div>
              <table
                id="datatables-reponsive"
                className="table table-striped table-responsive dt-bootstrap5 no-footer"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <button
        type="button"
        className="btn btn-primary my-1 d-none"
        data-bs-toggle="modal"
        data-bs-target="#centeredModal"
        id="btn-modal"
      ></button>
      <div
        className="modal fade"
        id="centeredModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Update Device Group</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="btn-update-close"
              ></button>
            </div>
            <div className="modal-body m-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4 text-sm-right">
                      Name
                      <span className="required">*</span>
                    </label>
                    <div className="col-sm-6 error-placeholder">
                      <input
                        type="hidden"
                        id="dd_device"
                        name="device_group_id"
                        className="form-control"
                      />
                      <input
                        type="text"
                        id="dd_name"
                        name="device_group_update"
                        className="form-control"
                        placeholder="Device Group Name"
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4 text-sm-right">
                      Description
                    </label>
                    <div className="col-sm-6 error-placeholder">
                      <input
                        type="text"
                        id="dd_description"
                        name="description_update"
                        className="form-control"
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-form-label col-sm-4 text-sm-right">
                      Status
                      <span className="required">*</span>
                    </label>
                    <div className="col-sm-6 error-placeholder">
                      <select
                        type="text"
                        id="dd_status"
                        name="status_update"
                        className="form-select"
                      >
                        <option value="1">Active</option>
                        <option value="0">Not Active</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer m-2">
              <Link
                to=""
                id="b-submit-add"
                onClick={() => updateDeviceGroup()}
                className="btn btn-primary btn-md float-end"
              >
                Update
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceGroup;
