import moment from "moment";
import CryptoJS from "crypto-js";

export const encryptData = (plaintext) => {
  // Generate random secret key (32 bytes)
  const secretKey = CryptoJS.lib.WordArray.random(32).toString(
    CryptoJS.enc.Base64
  );
  // Generate random IV (16 bytes)
  const iv = CryptoJS.lib.WordArray.random(16);

  // Get current timestamp (in seconds)
  const timestamp = Math.floor(Date.now() / 1000); // Timestamp in seconds

  // Combine plaintext with timestamp
  const dataToEncrypt = `${plaintext}:${timestamp}`;

  // Encrypt data
  const encrypted = CryptoJS.AES.encrypt(
    dataToEncrypt,
    CryptoJS.enc.Base64.parse(secretKey),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  // Combine ciphertext, IV, secretKey, and timestamp
  const encryptedData = {
    ciphertext: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
    iv: iv.toString(CryptoJS.enc.Base64),
    key: secretKey,
    timestamp: timestamp, // Add timestamp to the encrypted data
  };

  // Format the final combined data
  const combinedData = `${encryptedData.ciphertext}:${encryptedData.iv}:${encryptedData.key}:${encryptedData.timestamp}`;
  // console.log("Encrypted String:", combinedData); // Debugging
  return combinedData;
};

/* Number Format */
export const format_number = (value) => {
  if (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    return 0;
  }
};

export const formatTimestamp = (timestamp) => {
  const now = moment();
  const date = moment(timestamp);

  if (date.isSame(now, "day")) {
    return date.format("HH:mm"); // Hari ini
  } else if (date.isSame(moment().subtract(1, "day"), "day")) {
    return "Yesterday"; // Kemarin
  } else if (date.isSame(now, "week")) {
    return date.format("dddd"); // Dalam minggu yang sama
  } else if (date.isSame(now, "year")) {
    return date.format("DD MMM"); // Dalam tahun yang sama
  } else {
    return date.format("DD.MM.YYYY"); // Luar tahun ini
  }
};

export const formatText = (input) => {
  if (!input) return "";

  // Daftar pengecualian dengan format khusus
  const exceptions = {
    qris: "QRIS", // Jika input adalah "qris", ubah menjadi "QRIS"
  };

  // Ganti jika input cocok dengan pengecualian
  if (exceptions[input.toLowerCase()]) {
    return exceptions[input.toLowerCase()];
  }

  // Replace underscores or dashes with spaces
  let formatted = input.replace(/[_-]/g, " ");

  // Capitalize each word
  formatted = formatted.replace(/\b\w/g, (char) => char.toUpperCase());

  return formatted;
};

export const generateRandomString = (length) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
};

export const base64_enc = (value) => {
  return btoa(value);
};

export const base64_dec = (value) => {
  return atob(value);
};

export const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const truncate = (text, maxLength) => {
  if (!text) return ""; // Jika teks kosong, kembalikan string kosong
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};
