import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../utilities/url";
import axios from "axios";
import logo from "../../assets/logo.png";
import storage from "../../services/storage";
import CryptoJS from "crypto-js";
import authHeader from "../../services/auth.header";
import { Spinner } from "react-bootstrap";

const RedirectLogin = () => {
  const navigate = useNavigate();
  // const { messagesLang } = useLanguage();

  useEffect(() => {
    // Proses pengecekan atau pengambilan data
    const urlParams = new URLSearchParams(window.location.search);
    const dataParam = urlParams.get("data");
    if (dataParam) {
      axios
        .post(
          API_URL + "validate-from-baca",
          {},
          {
            headers: {
              Authorization: `Bearer ${dataParam}`, // Perbaikan: Authorization harus berupa objek
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            storage.set(
              "user",
              CryptoJS.AES.encrypt(
                JSON.stringify(response.data.data),
                "user"
              ).toString()
            );
            storage.set(
              "workspace",
              CryptoJS.AES.encrypt(
                JSON.stringify(response.data.data.user_workspace),
                "workspace"
              ).toString()
            );
            storage.set("access_token", response.data.data.token);
            window.location.replace("dashboard");
          } else {
            Logout();
          }
        })
        .catch((error) => {
          Logout();
        });
    } else {
      navigate("/login");
    }
  }, []);

  const Logout = () => {
    axios
      .post(
        API_URL + "logout",
        {},
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        localStorage.clear();
        navigate("/login");
      })
      .catch((error) => {
        navigate("/login");
      });
  };

  return (
    <div className="main d-flex justify-content-center w-100 bg-full-login">
      <main className="content d-flex p-4">
        <div className="container d-flex flex-column">
          <img
            className="img-logo-login d-none d-sm-block"
            src={logo}
            alt="logo"
          />
          <div className="row h-100">
            <div className="col-sm-10 col-md-8 col-lg-5 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center ms-2 me-2 mb-3">
                  <img
                    className="img-logo-login d-md-none d-lg-none"
                    src={logo}
                    alt="logo"
                  />
                </div>
                <div className="card bg-white-transparent rounded-4">
                  <div className="text-center mt-4">
                    <h3 className="text-dark">Redirect to Otokata</h3>
                    <Spinner
                      className="m-5 text-orange"
                      style={{ width: "3rem", height: "3rem" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default RedirectLogin;
