import React from "react";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ height: "70vh" }}
    >
      <div className="text-center">
        <h1 className="display-1 fw-bold">404</h1>
        <p className="h1">Page not found.</p>
        <p className="h2 fw-normal mt-3 mb-4">
          The page you are looking for might have been removed.
        </p>
      </div>
    </div>
  );
};

export default Page404;
