import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { API_URL } from "../utilities/url";
import storage from "../services/storage";
import CryptoJS from "crypto-js";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import authHeader from "../services/auth.header";

export const WorkspaceContext = createContext();

export const WorkspaceProvider = ({ children }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const wrsGet = storage.get("wrs")
    ? JSON.parse(
        CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
          CryptoJS.enc.Utf8
        )
      )
    : null;
  const [workspace, setWorkspace] = useState(wrsGet);
  const [workspaceList, setWorkspaceList] = useState([]);
  const [credit, setCredit] = useState(null);
  const [compact, setCompact] = useState(false);
  const tabValue = searchParams.get("tab");

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    axios
      .post(
        API_URL + "workspace-list",
        {
          status: 1,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          const res = response.data.data;
          const selectedWorkspace = res.find(
            (workspace) => workspace.name === "Personal"
          );
          if (wrsGet) {
            setWorkspace(wrsGet);
            getBalance(wrsGet.idt_user_workspace);
          } else {
            storage.set(
              "wrs",
              CryptoJS.AES.encrypt(
                JSON.stringify(selectedWorkspace),
                "wrs"
              ).toString()
            );
            getBalance(selectedWorkspace.idt_user_workspace);
            setWorkspace(selectedWorkspace);
          }
          setWorkspaceList(res);
        }
      })
      .catch((error) => {
        localStorage.clear();
        navigate("/login");
      });
  }

  const changeWorkspace = (id) => {
    const selectedWorkspace = workspaceList.find(
      (workspace) => workspace.idt_user_workspace === id
    );
    getBalance(selectedWorkspace.idt_user_workspace);
    setWorkspace(selectedWorkspace);
    storage.set(
      "workspace",
      CryptoJS.AES.encrypt(
        JSON.stringify(selectedWorkspace),
        "workspace"
      ).toString()
    );
    storage.set(
      "wrs",
      CryptoJS.AES.encrypt(JSON.stringify(selectedWorkspace), "wrs").toString()
    );
    if (window.location.pathname.split("/")[2] !== undefined) {
      navigate("/" + window.location.pathname.split("/")[1]);
    } else {
      if (tabValue) {
        navigate(location.pathname + "?tab=" + tabValue);
      } else {
        navigate(location.pathname, { replace: true });
      }
    }
  };

  const getBalance = (id) => {
    axios
      .post(
        API_URL + "get-user-balance",
        {
          workspace: id,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setCredit(
            response.data.data.reduce((sum, item) => {
              return sum + (item.balance || 0);
            }, 0)
          );
        }
      })
      .catch((error) => {
        localStorage.clear();
        navigate("/login");
      });
  };

  return (
    <WorkspaceContext.Provider
      value={{
        workspace,
        setWorkspace,
        workspaceList,
        setWorkspaceList,
        changeWorkspace,
        compact,
        setCompact,
        credit,
        setCredit,
        getBalance,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};
