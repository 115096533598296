import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { API_URL, BASE_URL } from "../../utilities/url";
import axios from "axios";
import AuthService from "../../services/auth.service";
import logo from "../../assets/logo.png";
import { FiEye, FiEyeOff, FiLock, FiUser } from "react-icons/fi";
import { getCookie } from "../../utilities/ext-function";
import { useForm } from "react-hook-form";
import storage from "../../services/storage";
import CryptoJS from "crypto-js";
import { useGoogleLogin } from "@react-oauth/google";
import authHeader from "../../services/auth.header";
import { FaGoogle } from "react-icons/fa";
import moment from "moment";

const Login = () => {
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");
  const [hideAlert, setHideAlert] = useState(true);
  const [hideSpiner, setHideSpiner] = useState(true);
  const [hidePassword, setHidePassword] = useState("password");

  const {
    register, // Register inputs
    handleSubmit, // Handle form submit
    setValue,
    formState: { errors }, // Get form errors
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    getcookiedata();
  }, []);

  const getcookiedata = () => {
    var user = getCookie("email");
    var pswd = getCookie("password");
    var remember = getCookie("remember_me");

    if (user && pswd) {
      setValue("email", user);
      setValue("password", pswd);
    }

    if (remember === "yes") {
      document.getElementById("remember_me").checked = true;
    } else {
      document.getElementById("remember_me").checked = false;
    }
  };

  const onSubmit = (data) => {
    setHideSpiner(false);

    axios
      .post(API_URL + "login", data)
      .then((response) => {
        if (response.data.success) {
          const res = response.data.data;
          storage.set(
            "user",
            CryptoJS.AES.encrypt(JSON.stringify(res.user), "user").toString()
          );
          storage.set(
            "workspace",
            CryptoJS.AES.encrypt(
              JSON.stringify(res.user.user_workspace),
              "workspace"
            ).toString()
          );
          storage.set("access_token", res.access_token);

          if (document.getElementById("remember_me").checked) {
            document.cookie = `remember_me=yes;path=${BASE_URL}/login`;
            document.cookie = `email=${data.email};path=${BASE_URL}/login`;
            document.cookie = `password=${data.password};path=${BASE_URL}/login`;
          } else {
            document.cookie = `remember_me=no;path=${BASE_URL}/login`;
            document.cookie = `email=;path=${BASE_URL}/login`;
            document.cookie = `password=;path=${BASE_URL}/login`;
          }

          window.location.replace("dashboard");
        } else {
          setAlertMessage("Incorrect email or password.");
          setHideAlert(false);
          setHideSpiner(true);
        }
      })
      .catch(() => {
        setHideSpiner(true);
      });
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `${response.token_type} ${response.access_token}`,
          },
        })
        .then((response) => {
          /* Post to API */
          axios
            .post(
              API_URL + "login-with-google",
              { email: response.data.email },
              { headers: authHeader() }
            )
            .then((response) => {
              if (response.data.success === true) {
                const res = response.data.data;
                storage.set(
                  "user",
                  CryptoJS.AES.encrypt(
                    JSON.stringify(res.user),
                    "user"
                  ).toString()
                );
                storage.set(
                  "workspace",
                  CryptoJS.AES.encrypt(
                    JSON.stringify(res.user.user_workspace),
                    "workspace"
                  ).toString()
                );
                storage.set("access_token", res.access_token);
                window.location.replace("dashboard");
              } else {
                setAlertMessage("Email account not registered.");
                setHideAlert(false);
              }
            })
            .catch((error) => {
              console.log("There was an error!", error);
            });
        })
        .catch((error) => {
          console.log("There was an error!", error);
        });
    },
  });

  const togglePassword = () => {
    setHidePassword((prev) => (prev === "password" ? "text" : "password"));
  };

  return (
    <div className="main d-flex justify-content-center w-100 bg-full-login">
      <main className="content d-flex p-4">
        <div className="container d-flex flex-column">
          <img
            className="img-logo-login d-none d-sm-block"
            src={logo}
            alt="logo"
          />
          <div className="row h-100">
            <div className="col-sm-10 col-md-8 col-lg-5 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center ms-2 me-2 mb-3">
                  <img
                    className="img-logo-login d-md-none d-lg-none"
                    src={logo}
                    alt="logo"
                  />
                </div>
                <div className="card bg-white-transparent rounded-4">
                  <div className="text-center mt-4">
                    <h2 className="h2 text-orange">LOGIN</h2>
                  </div>
                  <div className="card-body pt-0">
                    <div className="m-sm-5">
                      <div
                        className={`alert alert-danger alert-dismissible ${
                          hideAlert ? "d-none" : "d-block"
                        }`}
                        role="alert"
                      >
                        <button
                          onClick={() => setHideAlert(true)}
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                        ></button>
                        <div className="alert-message">
                          <strong className="text-danger">
                            {alertMessage}
                          </strong>
                        </div>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3 error-placeholder">
                          <label className="form-label">Email</label>
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text">
                              <FiUser size={20} />
                            </div>
                            <input
                              className={`form-control form-control-lg ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              type="text"
                              name="email"
                              placeholder="Email"
                              {...register("email", {
                                required: true,
                                pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                              })}
                            />
                            {errors.email &&
                              errors.email.type === "required" && (
                                <div className="invalid-feedback">
                                  Email is required.
                                </div>
                              )}
                            {errors.email &&
                              errors.email.type === "pattern" && (
                                <div className="invalid-feedback">
                                  Email is not valid.
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="mb-3 error-placeholder">
                          <label className="form-label">Password</label>
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text">
                              <FiLock size={20} />
                            </div>
                            <input
                              className={`form-control form-control-lg ${
                                errors.password ? "is-invalid" : ""
                              }`}
                              type={hidePassword}
                              name="password"
                              placeholder="Password"
                              {...register("password", {
                                required: "Enter your Password",
                              })}
                            />
                            <div className="input-group-text">
                              <Link
                                to=""
                                className="text-dark"
                                onClick={togglePassword}
                              >
                                {hidePassword === "password" ? (
                                  <FiEye size={20} />
                                ) : (
                                  <FiEyeOff size={20} />
                                )}
                              </Link>
                            </div>
                            {errors.password &&
                              errors.password.type === "required" && (
                                <div className="invalid-feedback">
                                  Password is required.
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-6 space-grid">
                            <div className="d-grid gap-2">
                              <label className="form-check align-items-center float-start">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name="remember-me"
                                  id="remember_me"
                                />
                                <span className="form-check-label">
                                  Remember me
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 space-grid">
                            <div className="d-grid gap-1 text-md-end">
                              <Link to="/forgot-password">
                                Forgot password?
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4 mb-3">
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary btn-lg w-100 waves-effect waves-light mb-2"
                            >
                              <span
                                className={`spinner-border spinner-border-sm me-2 ${
                                  hideSpiner ? "d-none" : ""
                                }`}
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Sign In
                            </button>
                            <button
                              type="button"
                              onClick={() => loginGoogle()}
                              className="btn btn-lg btn-tertiary w-100 waves-effect waves-light mb-2"
                            >
                              <FaGoogle className="me-2" size={18} /> Sign in
                              with Google
                            </button>
                            {/* <Link
                              to="/register"
                              className="btn btn-lg btn-secondary w-100 waves-effect waves-light mb-2"
                            >
                              Register
                            </Link>
                            <button
                              type="button"
                              onClick={() => registerGoogle()}
                              className="btn btn-lg btn-info w-100 waves-effect waves-light mb-2"
                            >
                              <FaGoogle className="me-2" size={18} /> Register
                              with Google
                            </button> */}
                          </div>
                        </div>
                        <div className="text-center">
                          <p className="mb-0 text-dark">
                            Don't have an account ?
                            <Link
                              to="/register"
                              className="fw-semibold text-primary text-decoration-underline link-underline-opacity-100-hover"
                            >
                              {" "}
                              Create an account
                            </Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Login;
