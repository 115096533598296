import { React, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { API_URL } from "../../utilities/url";
import axios from "axios";
import AuthService from "../../services/auth.service";
import logo from "../../assets/logo.png";
import ck_success from "../../assets/check-success.gif";
import { FiLock, FiUser } from "react-icons/fi";

const RegisterSuccess = () => {
  //Navigate
  const navigate = useNavigate();

  useEffect(() => {
    //Auth
    if (AuthService.auth()) {
      navigate("/dashboard");
    }
  }, []);

  const [hideAlert, setHideAlert] = useState(true);

  //Form validate
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const formSubmit = (data) => {
    axios
      .post(API_URL + "login", data)
      .then((response) => {
        if (response.data.success === true) {
          const res = response.data.data;
          localStorage.setItem("user", JSON.stringify(res.user));
          localStorage.setItem("access_token", res.access_token);
          window.location.replace("/dashboard");
        } else {
          setHideAlert(false);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <div className="main d-flex justify-content-center w-100 bg-full-login">
      <main className="content d-flex p-4">
        <div className="container d-flex flex-column">
          <Link to="/" style={{ width: 100 }}>
            <img
              className="img-logo-login d-none d-sm-block"
              src={logo}
              alt="logo"
            />
          </Link>
          <div className="row h-100">
            <div className="col-sm-10 col-md-8 col-lg-8 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center ms-2 me-2 mb-3">
                  <img
                    className="img-logo-login d-md-none d-lg-none"
                    src={logo}
                  />
                </div>
                <div className="card bg-white-transparent rounded-4">
                  <div className="text-center mt-4">
                    <h4 className="h2 text-orange">Registration Success</h4>
                  </div>
                  <div className="card-body">
                    <div className="text-center">
                      <img
                        className="mt-3 mb-5"
                        src={ck_success}
                        alt="success"
                      />
                    </div>
                    <div className="text-center col-md-10 mx-auto">
                      <p className="mb-0">
                        You can now use your Otokata account.
                      </p>
                      <p className="p-0">
                        Activate using link sent to your registered email
                        account for first time login.
                      </p>
                    </div>
                    {/* <div className="text-center mt-7">
                      <Link to="/login" className="btn btn-lg btn-success">
                        Login
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default RegisterSuccess;
