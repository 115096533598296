import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { API_URL } from "../utilities/url";
import storage from "../services/storage";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import authHeader from "../services/auth.header";

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState([]);

  useEffect(() => {
    getSubs();
  }, []);

  const getSubs = () => {
    axios
      .post(
        API_URL + "get-user-subscription",
        {
          status: "Y",
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setSubscription(response.data.data);
        }
      })
      .catch((error) => {
        localStorage.clear();
        navigate("/login");
      });
  };

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        setSubscription,
        getSubs,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
