import React, { useContext, useEffect, useRef, useState } from "react";
import { FaUserAstronaut } from "react-icons/fa";
import { RiDeleteBinFill } from "react-icons/ri";
import SimpleBar from "simplebar-react";
import upload from "../../assets/upload.png";
import { Controller, useForm } from "react-hook-form";
import Select, { components } from "react-select";
import "rc-slider/assets/index.css";
import Slider from "rc-slider";
import CustomStyles from "../../components/customStyles";
import { ThemeContext } from "../../provider/themeContext";
import { useLanguage } from "../../provider/languageContext";
import { AssistantContext } from "../../provider/assistantContext";
import axios from "axios";
import { API_URL, API_URL_CORE } from "../../utilities/url";
import storage from "../../services/storage";
import { Dropdown, Spinner } from "react-bootstrap";
import { ModalRemoveAssistant } from "../../components";
import TagInput from "./component/tagInput";
import { ToastContainer } from "react-toastify";
import { ToastrContext } from "../../provider/toastrContext";
// import { SessionContext } from "../../provider/sessionContext";
import Ava from "../../assets/img/avatars/avatar.jpg";
import Ava2 from "../../assets/img/avatars/avatar-2.jpg";
import Ava3 from "../../assets/img/avatars/avatar-3.jpg";
import { FiMoreHorizontal, FiPhone, FiPlus, FiVideo } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { WorkspaceContext } from "../../provider/workspaceContext";
import { SubscriptionContext } from "../../provider/subscriptionContext";

const Assistant = () => {
  const {
    assistantList,
    setAssistantList,
    setAssistantSessionList,
    selectedAssistant,
    setSelectedAssistant,
    selectedSessionAssistant,
    setSelectedSessionAssistant,
    loadingAs,
    setLoadingAs,
    activeAssistant,
    setActiveAssistant,
    setDefaultAssistant,
  } = useContext(AssistantContext);
  const { workspace } = useContext(WorkspaceContext);
  const { subscription } = useContext(SubscriptionContext);
  const featureCustomProfile = subscription?.subscription_feature
    ?.filter((item) => item.idt_product_feature === "ASSISTANT_CUSTOM_PROFILE")
    .map((item) => item.value);
  // const { sessionList, setSessionList, selectedSession, setSelectedSession } =
  //   useContext(SessionContext);
  const { theme } = useContext(ThemeContext);
  const { showSuccess, showError } = useContext(ToastrContext);
  const location = useLocation();
  const simpleBarRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(upload);
  const [isMobileChatActive, setIsMobileChatActive] = useState(false);
  const [mode, setMode] = useState("show");
  const [loadItem, setLoadItem] = useState(true);
  const [searchItem, setSearchItem] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [state, setState] = useState({
    name: "",
    gender: "",
    language: "",
  });
  const [isDefault, setIsDefault] = useState(false);
  const handleIsDefault = (event) => {
    const isChecked = event.target.checked;
    setIsDefault(isChecked);
    setValue("is_default", isChecked);
  };
  const genders = [
    {
      value: "M",
      label: "Male",
    },
    {
      value: "F",
      label: "Female",
    },
  ];
  const ageGroups = [
    {
      value: "13 - 16",
      label: "Teenager (13 - 16)",
    },
    {
      value: "17 - 24",
      label: "Youth (17 - 24)",
    },
    {
      value: "25 - 30",
      label: "Young Adults (25 - 30)",
    },
    {
      value: "31 - 45",
      label: "Middle Aged Adults (31 - 45)",
    },
    {
      value: "45 - 59",
      label: "Old Adults (45 - 59)",
    },
    {
      value: "60 - Above",
      label: "Elderly (60 above)",
    },
    {
      value: "",
      label: "No Constraints",
    },
  ];
  const [creativity, setCreativity] = useState(0);
  const handleCreativity = (value) => {
    setCreativity(value.toFixed(2));
    setValue("creativity", value.toFixed(2));
  };
  const clarities = ["to_the_point", "descriptive", "narative"];
  const [clarity, setClarity] = useState(1);
  const clarity_mark = {
    1: "To The Point",
    2: "Descriptive",
    3: "Narative",
  };
  const handleClarity = (value) => {
    setClarity(value);
    setValue("clarity", clarities[value - 1]);
  };
  const emoticons = ["never", "rarely", "sometimes", "often", "always"];
  const [emoticon, setEmoticon] = useState(1);
  const emoticon_marks = {
    1: "Never",
    2: "Rarely",
    3: "Sometimes",
    4: "Often",
    5: "Always",
  };
  const handleEmoticon = (value) => {
    setEmoticon(value);
    setValue("use_emoji", emoticons[value - 1]);
  };
  const [areaTags, setAreaTags] = useState([]);
  const [salutationTags, setSalutationTags] = useState([]);
  const [conversationTags, setConversationTags] = useState([]);
  const [appearanceTags, setAppearanceTags] = useState([]);

  const handleClickAdd = () => {
    setActiveAssistant(null);
    setIsDefault(false);
    scrollToTop();
    setImagePreview(upload);
    setValue("idt_user_assistant", null);
    setValue("name", null);
    setValue("gender", null);
    setValue("age_group", null);
    setAreaTags([]);
    setSalutationTags([]);
    setConversationTags([]);
    setValue("area_of_expertise", null);
    setValue("salutation", null);
    setValue("conversation_style", null);
    setValue("appearance", null);
    setValue("custom_profile", null);
    // Emoticon
    setEmoticon(emoticons[0]);
    setValue("use_emoji", null);
    // Clarity
    setClarity(clarities[0]);
    setValue("clarity", null);
    // Creativity
    setCreativity(0);
    setValue("creativity", null);
    reset({
      idt_user_assistant: null,
      name: null,
      gender: null,
      age_group: null,
      custom_profile: null,
    });
    setMode("add");
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown")) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    setMode("show");
    callPersonalAssistant();
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [location.key]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [mode]);

  const callPersonalAssistant = () => {
    setLoadingAs(true);
    axios
      .post(
        API_URL + "get-personal-assistant-list",
        { idt_workspace: workspace.idt_user_workspace, page: 1, limit: 500 },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setAssistantList(
            response.data.data.data ? response.data.data.data : []
          );
          // setState((prevState) => ({
          //   ...prevState,
          //   name: response.data.data.data[0].name,
          //   gender: response.data.data.data[0].sex,
          //   age_group: response.data.data.data[0].age_group,
          // }));
          setValue("name", response.data.data.data[0].name);
          // Emoticon
          setEmoticon(
            emoticons.indexOf(response.data.data.data[0].use_emoji) + 1
          );
          setValue("use_emoji", response.data.data.data[0].use_emoji);
          // Clarity
          setClarity(clarities.indexOf(response.data.data.data[0].clarity) + 1);
          setValue("clarity", response.data.data.data[0].clarity);
          // Creativity
          setCreativity(response.data.data.data[0].creativity);
          setValue("creativity", response.data.data.data[0].creativity);
          setImagePreview(response.data.data.data[0].profile_pic);
          // Gender
          setValue(
            "gender",
            genders.find((p) => p.value === response.data.data.data[0].sex)
          );
          setValue(
            "age_group",
            ageGroups.find(
              (p) => p.value === response.data.data.data[0].age_group
            )
          );
          setActiveAssistant(response.data.data.data[0].idt_user_assistant);
          setSelectedAssistant(response.data.data.data[0]);
          const areaExpertise = response.data.data.data[0].area_of_expertise;
          const DataArea = areaExpertise
            ? areaExpertise.split(",").map((item) => item.trim())
            : [];
          setAreaTags(DataArea);
          setValue("area_of_expertise", areaExpertise);
          const salutation = response.data.data.data[0].salutation;
          const DataSalutation = salutation
            ? salutation.split(",").map((item) => item.trim())
            : [];
          setSalutationTags(DataSalutation);
          setValue("salutation", salutation);
          const conversation = response.data.data.data[0].conversation_style;
          const DataConversation = conversation
            ? conversation.split(",").map((item) => item.trim())
            : [];
          setConversationTags(DataConversation);
          setValue("conversation_style", conversation);
          const appearance = response.data.data.data[0].appearance;
          const DataAppearance = appearance
            ? appearance.split(",").map((item) => item.trim())
            : [];
          setAppearanceTags(DataAppearance);
          setValue("appearance", appearance);
          setValue("custom_profile", response.data.data.data[0].custom_profile);
          adjustTextareaHeight();
          setFirstLoad(true);
        }
        setLoadingAs(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filteredItems =
    assistantList.length > 0
      ? assistantList.filter((item) =>
          item.name.toLowerCase().includes(searchItem.toLowerCase())
        )
      : [];

  const adjustTextareaHeight = () => {
    const elements = document.getElementsByName("custom_profile");
    if (elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        if (element) {
          element.style.height = "auto";
          element.style.height = `${element.scrollHeight}px`;
        }
      }
    }
  };

  const toggleMobileChatClass = async (item) => {
    if (activeAssistant !== item.idt_user_assistant) {
      item.idt_user === 0 ? setMode("show") : setMode("update");
      scrollToTop();
      clearErrors();
      setIsMobileChatActive(!isMobileChatActive);
      setActiveAssistant(item.idt_user_assistant);
      setIsDefault(item.default ? true : false);
      setValue("profile_pic", null);
      setImagePreview(item.profile_pic ? item.profile_pic : upload);
      setLoadItem(false);
      setSelectedAssistant(item);
      setValue("idt_user_assistant", item.idt_user_assistant);
      setValue("name", item.name);
      // Emoticon
      setEmoticon(emoticons.indexOf(item.use_emoji) + 1);
      setValue("use_emoji", item.use_emoji);
      // Clarity
      setClarity(clarities.indexOf(item.clarity) + 1);
      setValue("clarity", item.clarity);
      // Creativity
      setCreativity(item.creativity);
      setValue("creativity", item.creativity);
      // Gender
      setValue(
        "gender",
        genders.find((p) => p.value === item.sex)
      );
      setValue(
        "age_group",
        ageGroups.find((p) => p.value === item.age_group)
      );
      const areaExpertise = item.area_of_expertise;
      const DataArea = areaExpertise
        ? areaExpertise.split(",").map((item) => item.trim())
        : [];
      setAreaTags(DataArea);
      setValue("area_of_expertise", areaExpertise);
      const salutation = item.salutation;
      const DataSalutation = salutation
        ? salutation.split(",").map((item) => item.trim())
        : [];
      setSalutationTags(DataSalutation);
      setValue("salutation", salutation);
      const conversation = item.conversation_style;
      const DataConversation = conversation
        ? conversation.split(",").map((item) => item.trim())
        : [];
      setConversationTags(DataConversation);
      setValue("conversation_style", conversation);
      const appearance = item.appearance;
      const DataAppearance = appearance
        ? appearance.split(",").map((item) => item.trim())
        : [];
      setAppearanceTags(DataAppearance);
      setValue("appearance", appearance);
      setValue("custom_profile", item.custom_profile);
      adjustTextareaHeight();
    }
  };

  const handleSearch = (event) => {
    setSearchItem(event.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setValue("profile_pic", file);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    clearErrors(event.target.name);
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setError,
    clearErrors,
    watch,
    setValue,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    setLoading(true);
    const formData = {
      ...data,
      idt_workspace: workspace.idt_user_workspace,
    };
    axios
      .post(API_URL + "add-personal-assistant", formData, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          if (response.data.data.default) {
            const updatedList = assistantList.map((assistant) => {
              if (assistant.default) {
                return { ...assistant, default: null };
              }
              return assistant;
            });
            setDefaultAssistant(response.data.data.idt_user_assistant);
            setAssistantList([...updatedList, response.data.data]);
          } else {
            setAssistantList([...assistantList, response.data.data]);
          }
          setAssistantSessionList((prev) => [...prev, response.data.data]);
          toggleMobileChatClass(response.data.data);
          setLoading(false);
          // loadData();
        } else {
          showError(response.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        showError("An error occurred. Please try again later.");
        setLoading(false);
      });
  };

  const onSubmitUpdate = (data) => {
    setLoading(true);
    axios
      .post(API_URL + "update-personal-assistant", data, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          setAssistantList((prevList) =>
            prevList.map((data) =>
              data.idt_user_assistant === response.data.data.idt_user_assistant
                ? response.data.data
                : data
            )
          );
          setAssistantSessionList((prevList) =>
            prevList.map((data) =>
              data.idt_user_assistant === response.data.data.idt_user_assistant
                ? response.data.data
                : data
            )
          );
          selectedSessionAssistant.value ===
            response.data.data.idt_user_assistant &&
            setSelectedSessionAssistant({
              value: response.data.data.idt_user_assistant,
              label: response.data.data.name,
            });
          toggleMobileChatClass(response.data.data);
          setLoading(false);
        } else {
          showError(response.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onSetDefaultAssistant = (id) => {
    axios
      .post(
        API_URL + "set-default-assistant",
        { idt_user_assistant: id },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          const updatedList = assistantList.map((assistant) => {
            if (assistant.default) {
              return { ...assistant, default: null };
            }
            if (assistant.idt_user_assistant === id) {
              return {
                ...assistant,
                default: response.data.data,
              };
            }
            return assistant;
          });
          setDefaultAssistant(id);
          setAssistantList(updatedList);
          setLoading(false);
        }
        setOpenDropdown(null);
      })
      .catch((error) => {
        setOpenDropdown(null);
      });
  };

  const scrollToTop = () => {
    if (simpleBarRef.current) {
      simpleBarRef.current
        .getScrollElement()
        .scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const DropdownIndicator = (props) => {
    return props.selectProps.isDisabled ? null : (
      <components.DropdownIndicator {...props} />
    );
  };

  const handleArea = (data) => {
    setValue("area_of_expertise", data);
  };

  const handleSalutation = (data) => {
    setValue("salutation", data);
  };

  const handleConversation = (data) => {
    setValue("conversation_style", data);
  };

  const handleAppearance = (data) => {
    setValue("appearance", data);
  };

  const custom_profile = watch("custom_profile", "");
  const handleCustomeProfile = (event) => {
    setValue("custom_profile", event.target.value);
    adjustTextareaHeight();
  };

  const [openDropdown, setOpenDropdown] = useState(null); // Track which dropdown is open
  const handleDropdownToggle = (index) => {
    // Close the currently open dropdown if the same dropdown is clicked
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleGenImg = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(API_URL_CORE + "image/avatar", {
        gender: data.gender.label,
        age_group: data.age_group.value,
        expertise: data.area_of_expertise,
        conversation_style: data.conversation_style,
        specific_styling: data.appearance,
        style: "realistic",
      });

      if (response.data.errormsg === "OK") {
        const base64String = response.data.data.images[0];
        setImagePreview(`data:image/png;base64,${base64String}`);
        const base64Data = base64String.split(",")[0];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteNumbers], { type: "image/png" });
        const file = new File([blob], "profile_pic.png", { type: "image/png" });
        setValue("profile_pic", file);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error generating image:", error);
      setImagePreview(null);
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid p-0">
      <div className="card rounded-4 shadow-lg mb-0">
        <div className="card-header rounded-4">
          <div className="d-flex justify-content-between">
            <div>
              <h5 className="card-title mt-2">Personal Assistant</h5>
            </div>
          </div>
          <div className="row"></div>
        </div>
        <div className="row g-0">
          <SimpleBar
            className="col-12 col-lg-4 col-xl-2 border-top border-end list-group"
            style={{ borderRadius: "0" }}
          >
            <div className="px-4 d-none d-md-block">
              <div className="d-flex align-items-start align-items-center">
                <div className="flex-grow-1">
                  <input
                    type="text"
                    className="form-control my-3"
                    placeholder="Search..."
                    id="searchName"
                    // onKeyUp={filterName}
                  />
                </div>
              </div>
              <div className="mb-3">
                <button
                  className="btn btn-md btn-primary w-100"
                  onClick={() => handleClickAdd()}
                >
                  Add Assistant
                </button>
              </div>
            </div>
            <ul
              className="list-unstyled chat-list chat-user-list"
              id="userList"
            >
              {loadingAs ? (
                <div className="loading-session-list">
                  <Spinner animation="border" />
                </div>
              ) : filteredItems.length > 0 ? (
                filteredItems.map((item, index) => (
                  <li
                    data-name="direct-message"
                    key={index}
                    className={
                      item.idt_user_assistant === activeAssistant ||
                      (loadItem === true && index === true)
                        ? "list-group-item list-group-item-action border-0 active"
                        : "list-group-item list-group-item-action border-0"
                    }
                  >
                    <a className="chats rounded-2">
                      <div
                        className="d-flex align-items-center"
                        onClick={() => toggleMobileChatClass(item)}
                      >
                        <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                          {item.profile_pic ? (
                            <img
                              src={item.profile_pic}
                              className="rounded-circle me-1"
                              width={40}
                              height={40}
                              style={{ objectFit: "cover" }}
                            />
                          ) : (
                            <img
                              src={upload}
                              className="rounded-circle me-1"
                              width={40}
                              height={40}
                              style={{ objectFit: "cover" }}
                            />
                          )}
                        </div>

                        <div className="flex-grow-1 overflow-hidden">
                          <p
                            className="texted text-truncate fs-14"
                            style={{ marginBottom: "-3px" }}
                          >
                            {item.name}
                          </p>
                          <p
                            className="texted text-muted text-truncate fs-12"
                            style={{ marginBottom: "-3px" }}
                          >
                            {item.idt_user === 0
                              ? "System Assistant"
                              : "Personal Assistant"}{" "}
                            {item.default && "(Default)"}
                          </p>
                        </div>
                        <div>
                          {item.idt_user !== 0 ? (
                            <Dropdown
                              show={openDropdown === index}
                              className="card-btn-dropdown"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Dropdown.Toggle
                                className="btn delete c-icon mt-n1 p-0 dropdown"
                                variant="none"
                                onClick={() => handleDropdownToggle(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 5 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  data-lucide="more-vertical"
                                  class="lucide lucide-more-vertical align-middle"
                                >
                                  <circle cx="12" cy="12" r="1"></circle>
                                  <circle cx="12" cy="5" r="1"></circle>
                                  <circle cx="12" cy="19" r="1"></circle>
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu align="end" flip={false}>
                                {/* {!item.default && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      onSetDefaultAssistant(
                                        item.idt_user_assistant
                                      )
                                    }
                                  >
                                    Set as Default
                                  </Dropdown.Item>
                                )} */}
                                <ModalRemoveAssistant
                                  item={item}
                                  onDropdown={setOpenDropdown}
                                  setId={selectedAssistant.idt_user_assistant}
                                  itemFirst={toggleMobileChatClass}
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          ) : item.idt_user === 0 && !item.default ? (
                            <></>
                          ) : // <Dropdown
                          //   show={openDropdown === index}
                          //   className="card-btn-dropdown"
                          //   onClick={(e) => e.stopPropagation()}
                          // >
                          //   <Dropdown.Toggle
                          //     className="btn delete c-icon mt-n1 p-0 dropdown"
                          //     variant="none"
                          //     onClick={() => handleDropdownToggle(index)}
                          //   >
                          //     <svg
                          //       xmlns="http://www.w3.org/2000/svg"
                          //       width="14"
                          //       height="14"
                          //       viewBox="0 0 5 24"
                          //       fill="none"
                          //       stroke="currentColor"
                          //       stroke-width="2"
                          //       stroke-linecap="round"
                          //       stroke-linejoin="round"
                          //       data-lucide="more-vertical"
                          //       class="lucide lucide-more-vertical align-middle"
                          //     >
                          //       <circle cx="12" cy="12" r="1"></circle>
                          //       <circle cx="12" cy="5" r="1"></circle>
                          //       <circle cx="12" cy="19" r="1"></circle>
                          //     </svg>
                          //   </Dropdown.Toggle>
                          //   <Dropdown.Menu align="end" flip={false}>
                          //     {!item.default && (
                          //       <Dropdown.Item
                          //         onClick={() =>
                          //           onSetDefaultAssistant(
                          //             item.idt_user_assistant
                          //           )
                          //         }
                          //       >
                          //         Set as Default
                          //       </Dropdown.Item>
                          //     )}
                          //   </Dropdown.Menu>
                          // </Dropdown>
                          null}
                        </div>
                      </div>
                    </a>
                  </li>
                ))
              ) : null}
            </ul>
            {/* <ul
              className="position-relative chat-user overflow-auto"
              id="listName"
            >
              <li>
                <a
                  href="#"
                  className="list-group-item list-group-item-action border-0"
                >
                  <div className="badge bg-success float-end">5</div>
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1 ms-3">
                      Ashley Briggs
                      <div className="small">
                        <span className="fas fa-circle chat-online"></span>{" "}
                        Online
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="list-group-item list-group-item-action border-0"
                >
                  <div className="badge bg-success float-end">2</div>
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1 ms-3">
                      Carl Jenkins
                      <div className="small">
                        <span className="fas fa-circle chat-online"></span>{" "}
                        Online
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul> */}
            <hr className="d-block d-lg-none mt-1 mb-0" />
          </SimpleBar>
          <div className="col-12 col-lg-8 col-xl-10">
            <div className="py-2 px-4 border-top d-none d-lg-block">
              <div className="d-flex align-items-start align-items-center py-1">
                <div className="position-relative">
                  {mode === "update" ? (
                    <strong>Update Personal Assistant</strong>
                  ) : mode === "add" ? (
                    <strong>Add Personal Assistant</strong>
                  ) : (
                    <strong>Personal Assistant</strong>
                  )}
                </div>
              </div>
            </div>

            <div className="position-relative">
              {mode === "update" ? (
                <SimpleBar
                  className="nav-assistant border-end border-bottom p-4"
                  ref={simpleBarRef}
                >
                  <div className="mx-n3 pt-4 px-4">
                    <div id="folder-list" className="mb-2">
                      <form onSubmit={handleSubmit(onSubmitUpdate)}>
                        <div className="row mt-4">
                          <div className="col-md-4 col-sm-6">
                            <div className="profile-user d-flex justify-content-center mx-auto mb-4">
                              <img
                                src={imagePreview ? imagePreview : upload}
                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                alt="user-profile-image"
                                style={{ height: "11rem", width: "11rem" }}
                              />
                              <div
                                className="avatar-xs p-0 rounded-circle profile-photo-edit"
                                style={{ right: "auto" }}
                              >
                                <input
                                  id="profile-img-file-input"
                                  type="file"
                                  name="photo_url"
                                  className="profile-img-file-input"
                                  accept="image/*"
                                  onChange={handleImageChange}
                                />
                                <label
                                  htmlFor="profile-img-file-input"
                                  className="profile-photo-edit-2 avatar-xs"
                                  style={{ left: "55px" }}
                                >
                                  <span className="avatar-title rounded-circle bg-light text-body">
                                    <i className="fas fa-fw fa-camera"></i>
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn btn-primary bg-gradient waves-effect waves-light"
                                style={{ width: "180px" }}
                                onClick={handleSubmit(handleGenImg)}
                                disabled={loading}
                              >
                                {loading ? (
                                  <>
                                    <Spinner
                                      className="flex-shrink-0"
                                      animation="border"
                                      size="sm"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <FaUserAstronaut
                                      className="me-2"
                                      size={14}
                                    />
                                    Generate AI Image
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6">
                            <div className="mb-4">
                              <label className="form-label w-100">
                                Assistant Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                {...register("name", {
                                  required: true,
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.name &&
                                errors.name.type === "required" && (
                                  <p className="errorMsg text-danger fs-7">
                                    Name is required.
                                  </p>
                                )}
                            </div>
                            <div className="mb-4">
                              <label className="form-label w-100">Gender</label>
                              <Controller
                                name="gender"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    styles={CustomStyles(theme)}
                                    className="custom-select"
                                    maxMenuHeight={200}
                                    options={genders}
                                    placeholder="Select Gender"
                                    onBlur={field.onBlur}
                                    onChange={(selectedGender) => {
                                      field.onChange(selectedGender);
                                      clearErrors("gender");
                                    }}
                                    isSearchable={true}
                                  />
                                )}
                              />
                            </div>
                            <div className="mb-4">
                              <label className="form-label w-100">
                                Language
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="language"
                                value="Auto Language"
                                disabled
                              />
                            </div>
                          </div>
                          <hr></hr>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Age Group</h5>
                            <p>
                              Atur kisaran umur asisten AI Anda ketika melakukan
                              interaksi
                            </p>
                          </div>
                          <div className="col-md-4 col-sm-6 mb-4">
                            <Controller
                              name="age_group"
                              control={control}
                              rules={{
                                required: "Age is required.",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  styles={CustomStyles(theme)}
                                  className="custom-select"
                                  maxMenuHeight={200}
                                  options={ageGroups}
                                  placeholder="Select Age Group"
                                  onBlur={field.onBlur}
                                  onChange={(selectedAge) => {
                                    field.onChange(selectedAge);
                                    clearErrors("age_group");
                                  }}
                                  isSearchable={true}
                                />
                              )}
                            />
                            {errors.age_group && (
                              <p className="errorMsg text-danger fs-7">
                                {errors.age_group.message}
                              </p>
                            )}
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Area of Expertise</h5>
                            <p>
                              Area of Expertise Atur keahlian asisten AI Anda
                              ketika melakukan interaksi
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleArea}
                              tags={areaTags}
                              setTags={setAreaTags}
                              onDelete={true}
                            />
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Salutation</h5>
                            <p>
                              Atur panggilan yang akan digunakan oleh asisten AI
                              Anda ketika melakukan interaksi
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleSalutation}
                              tags={salutationTags}
                              setTags={setSalutationTags}
                              onDelete={true}
                            />
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Conversation Style</h5>
                            <p>
                              Atur gaya bahasa yang akan digunakan oleh asisten
                              AI Anda Intimate Casual Consultative Formal
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleConversation}
                              tags={conversationTags}
                              setTags={setConversationTags}
                              onDelete={true}
                            />
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Appearance</h5>
                            <p>
                              Atur persona asisten AI Anda, termasuk fisik,
                              warna rambut, warna kulit dan lainnya
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleAppearance}
                              tags={appearanceTags}
                              setTags={setAppearanceTags}
                              onDelete={true}
                            />
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Emoji Usage</h5>
                            <p>
                              Atur frekuensi penggunaan emoji yang akan
                              digunakan asisten AI Anda saat berinteraksi Never,
                              Rarely, Sometimes, Often, dan Always
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <Slider
                              className="mt-2"
                              min={1}
                              max={5}
                              marks={emoticon_marks}
                              step={null}
                              onChange={handleEmoticon}
                              value={emoticon}
                              tipFormatter={(value) => emoticon_marks[value]}
                            />
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Clarity Level</h5>
                            <p>
                              Atur jawaban dari setiap pertanyaan yang diberikan
                              oleh asisten AI Anda selama melakukan interaksi To
                              the point, Descriptive, dan Narative
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <Slider
                              className="mt-2"
                              min={1}
                              max={3}
                              marks={clarity_mark}
                              step={null} // Makes the slider snap to the marks
                              onChange={handleClarity}
                              value={clarity}
                              tipFormatter={(value) => clarity_mark[value]} // Show tooltip with mark label
                            />
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Creativity Level</h5>
                            <p>
                              Atur tingkat kebebasan menjawab asisten AI Anda
                              selama melakukan interaksi Low dan High
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <div className="rc slider-container">
                              <Slider
                                min={0}
                                max={1}
                                step={0.01}
                                value={creativity}
                                onChange={handleCreativity}
                              />
                              <div className="rc slider-label">
                                <span>Low</span>
                                <span>{creativity}</span>
                                <span>High</span>
                              </div>
                            </div>
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          {featureCustomProfile == "yes" && (
                            <>
                              <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                                <h5>Custom Profile</h5>
                                <p className="mb-0">
                                  Atur kebebasan menjawab asisten AI Anda.
                                </p>
                              </div>
                              <div className="col-md-7 col-sm-6 mb-4">
                                <textarea
                                  className="form-control mb-1"
                                  name="custom_profile"
                                  value={custom_profile}
                                  maxLength={5000}
                                  {...register("custom_profile", {
                                    required: false,
                                  })}
                                  placeholder="Max. 5000 Character"
                                  onChange={handleCustomeProfile}
                                  style={{
                                    minHeight: "150px",
                                    overflow: "hidden",
                                  }}
                                />
                                <div className="text-end">
                                  {custom_profile ? custom_profile.length : 0}
                                  /5000
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-md-11 mb-2">
                            <button
                              type="submit"
                              className="btn btn-success waves-effect waves-light float-end w-sm"
                              disabled={loading}
                            >
                              {loading ? (
                                <>
                                  <Spinner
                                    className="flex-shrink-0"
                                    animation="border"
                                    size="sm"
                                  />
                                </>
                              ) : (
                                "Update"
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </SimpleBar>
              ) : mode === "add" ? (
                <SimpleBar
                  className="nav-assistant border-end border-bottom p-4"
                  ref={simpleBarRef}
                >
                  <div className="mx-n3 pt-4 px-4">
                    <div id="folder-list" className="mb-2">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mt-4">
                          <div className="col-md-4 col-sm-6">
                            <div className="profile-user d-flex justify-content-center mx-auto mb-4">
                              <img
                                src={imagePreview ? imagePreview : upload}
                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                alt="user-profile-image"
                                style={{ height: "11rem", width: "11rem" }}
                              />
                              <div
                                className="avatar-xs p-0 rounded-circle profile-photo-edit"
                                style={{ right: "auto" }}
                              >
                                <input
                                  id="profile-img-file-input"
                                  type="file"
                                  name="photo_url"
                                  className="profile-img-file-input"
                                  accept="image/*"
                                  onChange={handleImageChange}
                                />
                                <label
                                  htmlFor="profile-img-file-input"
                                  className="profile-photo-edit-2 avatar-xs"
                                  style={{ left: "55px" }}
                                >
                                  <span className="avatar-title rounded-circle bg-light text-body">
                                    <i className="fas fa-fw fa-camera"></i>
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn btn-primary bg-gradient waves-effect waves-light"
                                style={{ width: "180px" }}
                                onClick={handleSubmit(handleGenImg)}
                                disabled={loading}
                              >
                                {loading ? (
                                  <>
                                    <Spinner
                                      className="flex-shrink-0"
                                      animation="border"
                                      size="sm"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <FaUserAstronaut
                                      className="me-2"
                                      size={14}
                                    />
                                    Generate AI Image
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6">
                            <div className="mb-4">
                              <label className="form-label w-100">
                                Assistant Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                {...register("name", {
                                  required: true,
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.name &&
                                errors.name.type === "required" && (
                                  <p className="errorMsg text-danger fs-7">
                                    Name is required.
                                  </p>
                                )}
                            </div>
                            <div className="mb-4">
                              <label className="form-label w-100">Gender</label>
                              <Controller
                                name="gender"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    styles={CustomStyles(theme)}
                                    className="custom-select"
                                    maxMenuHeight={200}
                                    options={genders}
                                    placeholder="Select Gender"
                                    onBlur={field.onBlur}
                                    onChange={(selectedGender) => {
                                      field.onChange(selectedGender);
                                      clearErrors("gender");
                                    }}
                                    isSearchable={true}
                                  />
                                )}
                              />
                            </div>
                            <div className="mb-4">
                              <label className="form-label w-100">
                                Language
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="language"
                                value="Auto Language"
                                disabled
                              />
                            </div>
                          </div>
                          <hr></hr>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Age Group</h5>
                            <p>
                              Atur kisaran umur asisten AI Anda ketika melakukan
                              interaksi
                            </p>
                          </div>
                          <div className="col-md-4 col-sm-6 mb-4">
                            <Controller
                              name="age_group"
                              control={control}
                              rules={{
                                required: "Age is required.",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  styles={CustomStyles(theme)}
                                  className="custom-select"
                                  maxMenuHeight={200}
                                  options={ageGroups}
                                  placeholder="Select Age Group"
                                  onBlur={field.onBlur}
                                  onChange={(selectedAge) => {
                                    field.onChange(selectedAge);
                                    clearErrors("age_group");
                                  }}
                                  isSearchable={true}
                                />
                              )}
                            />
                            {errors.age_group && (
                              <p className="errorMsg text-danger fs-7">
                                {errors.age_group.message}
                              </p>
                            )}
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Area of Expertise</h5>
                            <p>
                              Area of Expertise Atur keahlian asisten AI Anda
                              ketika melakukan interaksi
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleArea}
                              tags={areaTags}
                              setTags={setAreaTags}
                              onDelete={true}
                            />
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Salutation</h5>
                            <p>
                              Atur panggilan yang akan digunakan oleh asisten AI
                              Anda ketika melakukan interaksi
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleSalutation}
                              tags={salutationTags}
                              setTags={setSalutationTags}
                              onDelete={true}
                            />
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Conversation Style</h5>
                            <p>
                              Atur gaya bahasa yang akan digunakan oleh asisten
                              AI Anda Intimate Casual Consultative Formal
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleConversation}
                              tags={conversationTags}
                              setTags={setConversationTags}
                              onDelete={true}
                            />
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Appearance</h5>
                            <p>
                              Atur persona asisten AI Anda, termasuk fisik,
                              warna rambut, warna kulit dan lainnya
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <TagInput
                              onTagsChange={handleAppearance}
                              tags={appearanceTags}
                              setTags={setAppearanceTags}
                              onDelete={true}
                            />
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Emoji Usage</h5>
                            <p>
                              Atur frekuensi penggunaan emoji yang akan
                              digunakan asisten AI Anda saat berinteraksi Never,
                              Rarely, Sometimes, Often, dan Always
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <Slider
                              className="mt-2"
                              min={1}
                              max={5}
                              marks={emoticon_marks}
                              step={null}
                              onChange={handleEmoticon}
                              value={emoticon}
                              tipFormatter={(value) => emoticon_marks[value]}
                            />
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Clarity Level</h5>
                            <p>
                              Atur jawaban dari setiap pertanyaan yang diberikan
                              oleh asisten AI Anda selama melakukan interaksi To
                              the point, Descriptive, dan Narative
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <Slider
                              className="mt-2"
                              min={1}
                              max={3}
                              marks={clarity_mark}
                              step={null} // Makes the slider snap to the marks
                              onChange={handleClarity}
                              value={clarity}
                              tipFormatter={(value) => clarity_mark[value]} // Show tooltip with mark label
                            />
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                            <h5>Creativity Level</h5>
                            <p>
                              Atur tingkat kebebasan menjawab asisten AI Anda
                              selama melakukan interaksi Low dan High
                            </p>
                          </div>
                          <div className="col-md-5 col-sm-6 mb-4">
                            <div className="rc slider-container">
                              <Slider
                                min={0}
                                max={1}
                                step={0.01}
                                value={creativity}
                                onChange={handleCreativity}
                              />
                              <div className="rc slider-label">
                                <span>Low</span>
                                <span>{creativity}</span>
                                <span>High</span>
                              </div>
                            </div>
                          </div>
                          <div className="w-100 d-none d-md-block"></div>
                          {featureCustomProfile == "yes" && (
                            <>
                              <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                                <h5>Custom Profile</h5>
                                <p className="mb-0">
                                  Atur kebebasan menjawab asisten AI Anda.
                                </p>
                              </div>
                              <div className="col-md-7 col-sm-6 mb-4">
                                <textarea
                                  className="form-control mb-1"
                                  name="custom_profile"
                                  value={custom_profile}
                                  maxLength={5000}
                                  {...register("custom_profile", {
                                    required: false,
                                  })}
                                  placeholder="Max. 5000 Character"
                                  onChange={handleCustomeProfile}
                                  style={{
                                    minHeight: "150px",
                                    overflow: "hidden",
                                  }}
                                />
                                <div className="text-end">
                                  {custom_profile ? custom_profile.length : 0}
                                  /5000
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-md-11 mb-2">
                            <button
                              type="submit"
                              class="btn btn-success waves-effect waves-light float-end w-sm"
                              disabled={loading}
                            >
                              {loading ? (
                                <>
                                  <Spinner
                                    className="flex-shrink-0"
                                    animation="border"
                                    size="sm"
                                  />
                                </>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </SimpleBar>
              ) : (
                <SimpleBar
                  className="nav-assistant border-end border-bottom p-4"
                  ref={simpleBarRef}
                >
                  <div className="mx-n3 pt-4 px-4">
                    <div id="folder-list" className="mb-2">
                      <div className="row mt-4">
                        <div className="col-md-4 col-sm-6">
                          <div className="profile-user d-flex justify-content-center mx-auto mb-4">
                            <img
                              src={imagePreview ? imagePreview : upload}
                              className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                              alt="user-profile-image"
                              style={{ height: "11rem", width: "11rem" }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                          <div className="mb-4">
                            <label className="form-label w-100">
                              Assistant Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              {...register("name", {
                                required: true,
                              })}
                              disabled
                            />
                          </div>
                          <div className="mb-4">
                            <label className="form-label w-100">Gender</label>
                            <Controller
                              name="gender"
                              control={control}
                              rules={{
                                required: "Gender is required.",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  styles={CustomStyles(theme)}
                                  className="custom-select"
                                  maxMenuHeight={200}
                                  options={genders}
                                  components={{ DropdownIndicator }}
                                  isDisabled={true}
                                  isSearchable={true}
                                />
                              )}
                            />
                            {errors.gender && (
                              <p className="errorMsg text-danger fs-7">
                                {errors.gender.message}
                              </p>
                            )}
                          </div>
                          <div className="mb-4">
                            <label className="form-label w-100">Language</label>
                            <input
                              type="text"
                              className="form-control"
                              name="language"
                              value="Auto Language"
                              disabled
                            />
                          </div>
                        </div>
                        <hr></hr>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Age Group</h5>
                          <p>
                            Atur kisaran umur asisten AI Anda ketika melakukan
                            interaksi
                          </p>
                        </div>
                        <div className="col-md-4 col-sm-6 mb-4">
                          <Controller
                            name="age_group"
                            control={control}
                            rules={{
                              required: "Age is required.",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                styles={CustomStyles(theme)}
                                className="custom-select"
                                maxMenuHeight={200}
                                options={ageGroups}
                                components={{ DropdownIndicator }}
                                isDisabled={true}
                                isSearchable={true}
                              />
                            )}
                          />
                          {errors.age_group && (
                            <p className="errorMsg text-danger fs-7">
                              {errors.age_group.message}
                            </p>
                          )}
                        </div>
                        <div className="w-100 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Area of Expertise</h5>
                          <p>
                            Area of Expertise Atur keahlian asisten AI Anda
                            ketika melakukan interaksi
                          </p>
                        </div>
                        <div className="col-md-5 col-sm-6 mb-4">
                          <TagInput
                            onTagsChange={handleArea}
                            tags={areaTags}
                            setTags={setAreaTags}
                            onDelete={false}
                          />
                        </div>
                        <div className="w-100 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Salutation</h5>
                          <p>
                            Atur panggilan yang akan digunakan oleh asisten AI
                            Anda ketika melakukan interaksi
                          </p>
                        </div>
                        <div className="col-md-5 col-sm-6 mb-4">
                          <TagInput
                            onTagsChange={handleSalutation}
                            tags={salutationTags}
                            setTags={setSalutationTags}
                            onDelete={false}
                          />
                        </div>
                        <div className="w-100 d-none d-md-block"></div>
                        <div className="w-100 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Conversation Style</h5>
                          <p>
                            Atur gaya bahasa yang akan digunakan oleh asisten AI
                            Anda Intimate Casual Consultative Formal
                          </p>
                        </div>
                        <div className="col-md-5 col-sm-6 mb-4">
                          <TagInput
                            onTagsChange={handleConversation}
                            tags={conversationTags}
                            setTags={setConversationTags}
                            onDelete={false}
                          />
                        </div>
                        <div className="w-100 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Appearance</h5>
                          <p>
                            Atur persona asisten AI Anda, termasuk fisik, warna
                            rambut, warna kulit dan lainnya
                          </p>
                        </div>
                        <div className="col-md-5 col-sm-6 mb-4">
                          <TagInput
                            onTagsChange={handleAppearance}
                            tags={appearanceTags}
                            setTags={setAppearanceTags}
                            onDelete={false}
                          />
                        </div>
                        <div className="w-100 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Emoji Usage</h5>
                          <p>
                            Atur frekuensi penggunaan emoji yang akan digunakan
                            asisten AI Anda saat berinteraksi Never, Rarely,
                            Sometimes, Often, dan Always
                          </p>
                        </div>
                        <div className="col-md-5 col-sm-6 mb-4">
                          <Slider
                            className="mt-2"
                            min={1}
                            max={5}
                            marks={emoticon_marks}
                            step={null}
                            disabled={true}
                            value={emoticon}
                            tipFormatter={(value) => emoticon_marks[value]}
                          />
                        </div>
                        <div className="w-100 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Clarity Level</h5>
                          <p>
                            Atur jawaban dari setiap pertanyaan yang diberikan
                            oleh asisten AI Anda selama melakukan interaksi To
                            the point, Descriptive, dan Narative
                          </p>
                        </div>
                        <div className="col-md-5 col-sm-6 mb-4">
                          <Slider
                            className="mt-2"
                            min={1}
                            max={3}
                            marks={clarity_mark}
                            step={null} // Makes the slider snap to the marks
                            disabled={true}
                            value={clarity}
                            tipFormatter={(value) => clarity_mark[value]} // Show tooltip with mark label
                          />
                        </div>
                        <div className="w-100 d-none d-md-block"></div>
                        <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                          <h5>Creativity Level</h5>
                          <p>
                            Atur tingkat kebebasan menjawab asisten AI Anda
                            selama melakukan interaksi Low dan High
                          </p>
                        </div>
                        <div className="col-md-5 col-sm-6 mb-4">
                          <div className="rc slider-container">
                            <Slider
                              min={0}
                              max={1}
                              step={0.01}
                              value={creativity}
                              disabled={true}
                            />
                            <div className="rc slider-label">
                              <span>Low</span>
                              <span>{creativity}</span>
                              <span>High</span>
                            </div>
                          </div>
                        </div>
                        <div className="w-100 d-none d-md-block"></div>
                        {featureCustomProfile == "yes" && (
                          <>
                            <div className="col-md-4 col-sm-6 pe-md-5 mb-4">
                              <h5>Custom Profile</h5>
                              <p>Atur kebebasan menjawab asisten AI Anda.</p>
                            </div>
                            <div className="col-md-7 col-sm-6 mb-4">
                              <textarea
                                className="form-control"
                                name="custom_profile"
                                {...register("custom_profile", {
                                  required: false,
                                })}
                                disabled
                                style={{
                                  minHeight: "150px",
                                  overflow: "hidden",
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </SimpleBar>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assistant;
