import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import { API_URL } from "../../utilities/url";
import { FiPlus } from "react-icons/fi";
import storage from "../../services/storage";
import { FaRegTrashAlt } from "react-icons/fa";
import axios from "axios";
import authHeader from "../../services/auth.header";
import { HiOutlineRefresh } from "react-icons/hi";
import CryptoJS from "crypto-js";

var $ = require("jquery");

const User = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
    CryptoJS.enc.Utf8
  );
  const workspace = JSON.parse(decryptWS);

  useEffect(() => {
    if (localStorage.getItem("alert")) {
      toast.success("Success !", {
        position: "top-right",
        theme: "colored",
        transition: Slide,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      localStorage.removeItem("alert");
    }

    $("#datatables-reponsive").DataTable({
      dom:
        "<'row'<'col-sm-6'><'col-sm-6'B>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: true,
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-table-user-groups",
        data: function (data) {
          data.idt_user_workspace = workspace.idt_user_workspace;
        },
        type: "post",
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            localStorage.clear();
            navigate("/login");
          }
        },
      },
      columnDefs: [
        {
          targets: 5,
          createdCell: (td, cellData, rowData, row, col) =>
            ReactDOM.render(
              <div className="d-grid gap-2 d-md-flex justify-content-md">
                {workspace &&
                  workspace.role !== "operator" &&
                  workspace.name !== "Personal" && (
                    <a
                      onClick={() => deleteTable(cellData)}
                      className="btn btn-sm btn-danger btn-rounded"
                    >
                      <FaRegTrashAlt />
                    </a>
                  )}
                {/* <a
                  onClick={() => deleteTable(cellData)}
                  className="btn btn-sm btn-tertiary btn-rounded"
                >
                  <FaTrash />
                </a> */}
              </div>,
              td
            ),
        },
      ],
      columns: [
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = moment(data).format("DD-MM-YYYY");

            return data;
          },
        },
        { data: "name" },
        { data: "email" },
        { data: "msisdn" },
        {
          data: "role",
          render: function (data, type, row) {
            return data;
          },
        },
        { data: "idt_user_workspace_member" },
      ],
      initComplete: function () {
        var api = this.api();
        $("#btn-dt-search")
          .off(".DT")
          .on("click.DT", function (e) {
            api.search($("#dt-search").val()).draw();
          });
        $("#btn-dt-reload")
          .off(".DT")
          .on("click.DT", function (e) {
            $("#dt-search").val(null);
            api.search($("#dt-search").val()).draw();
          });
      },
    });
  }, [location.key]);

  const deleteTable = (data) => {
    axios
      .post(
        API_URL + "delete-user-group",
        { id: data },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          toast.success("Success to delete !", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
          });
          $("#datatables-reponsive").DataTable().ajax.reload();
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 401") {
          AuthService.logout();
          navigate("/login");
        }
      });
  };

  return (
    <div className="container-fluid p-0">
      <ToastContainer />
      <div className="row">
        <div className="col-12">
          <div className="card rounded-4 shadow-lg">
            <div className="card-header rounded-4">
              <div className="float-start">
                <h5 className="card-title mt-2">Workspace Member</h5>
              </div>
            </div>
            <div className="card-body table-responsive">
              <div className="d-block mb-3">
                <div className="d-inline-block mt-1 mb-2">
                  <div className="input-group input-group-navbar border">
                    <input
                      type="text"
                      id="dt-search"
                      className="form-control form-control-sm h-auto"
                      placeholder="Search"
                      aria-label="Search"
                    />
                    <button
                      className="btn btn-light h-auto"
                      id="btn-dt-search"
                      style={{ backgroundColor: "#e2e2e2" }}
                    >
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="align-middle"
                        height="16"
                        width="16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </button>
                    <button
                      className="btn btn-secondary h-auto"
                      id="btn-dt-reload"
                      style={{
                        backgroundColor: "#e2e2e2",
                        borderLeft: "2px solid #f3f6fb",
                        color: "#fff",
                      }}
                    >
                      <HiOutlineRefresh size={15} />
                    </button>
                  </div>
                </div>
                <div className="d-sm-block float-md-end">
                  {workspace &&
                    workspace.role !== "operator" &&
                    workspace.name !== "Personal" && (
                      <Link
                        to="/workspace-member/add"
                        className="btn btn-primary btn-md"
                      >
                        <FiPlus
                          className="align-middle"
                          size={14}
                          strokeWidth={4}
                        />{" "}
                        Add Member
                      </Link>
                    )}
                </div>
              </div>
              <table
                id="datatables-reponsive"
                className="table table-striped table-responsive dt-bootstrap5 no-footer"
              >
                <thead>
                  <tr>
                    <th>Created</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>MSISDN</th>
                    <th>Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
