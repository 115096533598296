import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import Ava from "../../../assets/img/avatars/avatar.jpg";
import Ava2 from "../../../assets/img/avatars/avatar-2.jpg";
import { TbDeviceIpad } from "react-icons/tb";
import { LuContact, LuRadioTower } from "react-icons/lu";
import { BsChatLeftText } from "react-icons/bs";
import { FaInfinity, FaUserAstronaut } from "react-icons/fa";
import Profile from "../../../assets/avatar-broken.png";
import { Link, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { AssistantContext } from "../../../provider/assistantContext";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { WorkspaceContext } from "../../../provider/workspaceContext";
import { SubscriptionContext } from "../../../provider/subscriptionContext";
import axios from "axios";
import { API_URL } from "../../../utilities/url";
import authHeader from "../../../services/auth.header";
import { format_number } from "../../../utilities/ext-function";

export default function DashboardPanel() {
  const { assistantSessionList } = useContext(AssistantContext);
  const { subscription } = useContext(SubscriptionContext);
  const { workspace } = useContext(WorkspaceContext);
  const { loadAssistant } = useContext(AssistantContext);
  const location = useLocation();
  const [dataCount, setDataCount] = useState(null);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        // backgroundColor: isSelected ? "#fca311" : "",
        // color: isSelected ? "#fff" : "#fff",
        // backgroundColor: isFocused ? "#fca311" : "",
        color: isFocused ? "#fff" : "#000",
      };
    },
  };

  const items = ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5", "Item 6"];
  const data2 = [
    { name: "Broadcast A", percentage: 50 },
    { name: "Broadcast B", percentage: 30 },
    { name: "Broadcast C", percentage: 70 },
    { name: "Broadcast D", percentage: 20 },
  ];
  const percentage = (3 / 7) * 100;
  const data3 = [
    { name: "Completed", value: 40 }, // Nilai progress
    { name: "Remaining", value: 100 - 40 }, // Sisa dari progress
  ];
  const colors2 = ["#fb8500", "#fee7cf"];

  useEffect(() => {
    if (workspace && workspace.idt_user_workspace) {
      loadAssistant(workspace.idt_user_workspace);

      axios
        .post(
          API_URL + "get-data-main-dashboard",
          { idt_workspace: workspace.idt_user_workspace },
          { headers: authHeader() }
        )
        .then((response) => {
          if (response.data.success === true) {
            setDataCount(response.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching contact sessions:", error);
        });
    }
  }, [workspace, location.key]);

  return (
    <div className="row">
      <div className="col-12 col-sm-6 col-xxl-35 d-flex">
        <div className="card flex-fill rounded-4 shadow-lg">
          <div className="card-body img-panel py-4">
            <div className="d-flex align-items-center mb-3">
              <div className="icon-panel-circle bg-primary-2 me-3">
                <FaUserAstronaut size={18} />
              </div>
              <h4 className="mb-1">
                <span className="text-orange">OSA </span>
                <span className="text-primary-2">Personal Assistant</span>
              </h4>
            </div>
            <hr className="separator-grey" />
            <div className="px-4">
              {assistantSessionList.length > 0 ? (
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar]} // Aktifkan modul Swiper
                  spaceBetween={10} // Jarak antar slide
                  slidesPerView={1} // Berapa slide yang ditampilkan dalam satu waktu
                  // navigation // Aktifkan tombol navigasi
                  pagination={{ clickable: true }} // Aktifkan pagination dengan klik
                  loop={true} // Aktifkan efek loop
                >
                  {assistantSessionList.map((item, index) => (
                    <SwiperSlide key={index} style={{ cursor: "default" }}>
                      <div className="mb-4">
                        <div className="d-flex align-items-start">
                          <img
                            src={item.profile_pic ? item.profile_pic : Profile}
                            className="img-top-profile img-fluid rounded-circle border border-2 border-secondary me-4"
                            height={100}
                            width={100}
                          />
                          <div className="flex-grow-1">
                            <p className="fs-26 fw-bold text-primary-2 mb-0">
                              {item.name}
                            </p>
                            <p className="text-primary-2 fw-bold mb-1">
                              Created{" "}
                              {moment(item.ts_created).format("DD MMMM YYYY")}
                            </p>
                            <p className="text-orange">
                              {item.sex == "M" ? "Male" : "Female"}
                            </p>
                          </div>
                        </div>
                        <div className="w-100">
                          <p className="text-dark limit-text">
                            {item.custom_profile ? (
                              item.custom_profile
                            ) : (
                              <div style={{ height: "131px" }}></div>
                            )}
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <div className="mb-4">
                  <div className="d-flex align-items-start">
                    <Skeleton
                      circle={true} // Membuat bentuknya jadi bulat seperti foto profil
                      height={100} // Tinggi sesuaikan dengan kebutuhan
                      width={100}
                      className="me-3 mb-2" // Lebar sesuaikan dengan kebutuhan
                    />
                    <div className="flex-grow-1">
                      <Skeleton height={34} className="mb-1" />
                      <Skeleton height={20} className="mb-0" />
                      <Skeleton height={20} className="mb-0" />
                    </div>
                  </div>
                  <div className="w-100">
                    <Skeleton height={128} className="mb-2" />
                  </div>
                </div>
              )}

              <hr className="separator-grey" />
              <Link to="/assistant" className="btn btn-primary btn-lg w-100">
                <FaUserAstronaut
                  size={18}
                  className="me-2"
                  style={{ marginTop: "-2px" }}
                />
                Personal Assistant
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-xxl-4 d-flex">
        <div className="card flex-fill rounded-4 shadow-lg">
          <div className="card-body img-panel py-4">
            <div className="d-flex align-items-center mb-3">
              <div className="icon-panel-circle bg-orange me-3">
                <LuRadioTower size={18} />
              </div>
              <h4 className="mb-1">
                <span className="text-orange">Broadcast</span>
              </h4>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
              {/* Bagian Teks di sisi kiri */}
              <div className="me-4">
                {dataCount ? (
                  <p className="mb-2 fs-32 text-dark fw-bold">
                    {format_number(0)}/
                    {subscription?.subscription_feature?.length > 0
                      ? format_number(
                          subscription.subscription_feature.find(
                            (feature) =>
                              feature.idt_product_feature === "BS_COUNT"
                          )?.value_number
                        ) || 0
                      : 0}{" "}
                  </p>
                ) : (
                  <Skeleton height={40} className="mb-3" />
                )}
                <p className="mb-2 fs-14 text-dark fw-bold">Active Broadcast</p>
              </div>

              {/* Bagian Pie Chart di sisi kanan */}
              <div
                style={{
                  position: "relative",
                  width: "100px",
                  height: "91px",
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={data3}
                      cx="50%"
                      cy="50%"
                      innerRadius={25}
                      outerRadius={45}
                      startAngle={90}
                      endAngle={-270}
                      paddingAngle={0}
                      dataKey="value"
                    >
                      {data3.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors2[index]} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
                {/* Letakkan teks di tengah lingkaran */}
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "14px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  100%
                </div>
              </div>
            </div>
            <hr className="separator-grey" />
            <table className="table table-striped">
              {data2.map((item, index) => (
                <tr key={index}>
                  <td className="pt-2 pb-2">{item.name}</td>
                  <td className="pt-2 pb-2 w-50">
                    <div className="progress">
                      <div
                        className="progress-bar bg-orange"
                        role="progressbar"
                        style={{ width: `${item.percentage}%` }}
                        aria-valuenow={item.percentage}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </td>
                  <td align="right" className="pt-2 pb-2">
                    500/800
                  </td>
                </tr>
              ))}
            </table>
            <hr className="separator-grey" />
            <Link to="/broadcast/add" class="btn btn-lg btn-primary w-100">
              <LuRadioTower
                size={18}
                className="me-2"
                style={{ marginTop: "-2px" }}
              />
              Add Broadcast
            </Link>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-xxl-25 d-flex">
        <div className="card flex-fill rounded-4 shadow-lg">
          <div className="card-body img-panel py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <div className="d-flex align-items-center mb-3">
                  <div className="icon-panel-circle bg-primary-2 me-3">
                    <BsChatLeftText size={18} />
                  </div>
                  <h4 className="mb-1">
                    <span className="text-primary-2">Chatbots</span>
                  </h4>
                </div>
                {dataCount ? (
                  <p className="mb-2 fs-32 text-dark fw-bold">
                    {format_number(dataCount.total_chatbots)}/
                    {subscription?.subscription_feature?.length > 0
                      ? format_number(
                          subscription.subscription_feature.find(
                            (feature) =>
                              feature.idt_product_feature === "OSA_COUNT"
                          )?.value_number
                        ) || 0
                      : 0}{" "}
                  </p>
                ) : (
                  <Skeleton
                    height={40}
                    style={{ width: "25%" }}
                    className="mb-3"
                  />
                )}
                <p className="mb-2 fs-14 text-dark fw-bold">Active Chatbot</p>
              </div>
            </div>
            <hr className="separator-grey" />
            <table className="table table-striped">
              <tr>
                <td className="pt-2 pb-2">Chatbot A</td>
                <td className="pt-2 pb-2">500</td>
                <td className="pt-2 pb-2">500</td>
              </tr>
              <tr>
                <td className="pt-2 pb-2">Chatbot B</td>
                <td className="pt-2 pb-2">500</td>
                <td className="pt-2 pb-2">500</td>
              </tr>
              <tr>
                <td className="pt-2 pb-2">Chatbot C</td>
                <td className="pt-2 pb-2">500</td>
                <td className="pt-2 pb-2">500</td>
              </tr>
              <tr>
                <td className="pt-2 pb-2">Chatbot D</td>
                <td className="pt-2 pb-2">500</td>
                <td className="pt-2 pb-2">500</td>
              </tr>
            </table>
            <hr className="separator-grey" />
            <Link to="/chatbot/add" className="btn btn-primary btn-lg w-100">
              <BsChatLeftText
                size={18}
                className="me-2"
                style={{ marginTop: "-2px" }}
              />
              Add Chatbot
            </Link>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-xxl-2 d-flex flex-column">
        {/* Card Pertama */}
        <div className="card flex-fill rounded-4 shadow-lg mb-3">
          <div className="card-body img-panel py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <div className="d-flex align-items-center">
                  <div className="icon-panel-circle bg-orange me-3">
                    <TbDeviceIpad size={20} />
                  </div>
                  <h4 className="mb-1">
                    <span className="text-primary-2">Device</span>{" "}
                    <span className="text-orange">Usage</span>
                  </h4>
                </div>
                <hr className="separator-grey" />
                <div className="progress">
                  <div
                    className="progress-bar bg-orange"
                    role="progressbar"
                    style={{
                      width: `${
                        subscription?.subscription_feature?.length > 0
                          ? format_number(
                              (dataCount?.total_devices /
                                subscription.subscription_feature.find(
                                  (feature) =>
                                    feature.idt_product_feature === "DEV_COUNT"
                                )?.value_number || 0) * 100
                            )
                          : 0
                      }%`,
                    }}
                    aria-valuenow={2}
                    aria-valuemin={0}
                    aria-valuemax={4}
                  ></div>
                </div>
                <div className="mt-3">
                  {dataCount ? (
                    <p className="fs-14 text-dark fw-bold">
                      {format_number(dataCount.total_devices)}/
                      {subscription?.subscription_feature?.length > 0
                        ? format_number(
                            subscription.subscription_feature.find(
                              (feature) =>
                                feature.idt_product_feature === "DEV_COUNT"
                            )?.value_number
                          ) || 0
                        : 0}{" "}
                      Total Device
                    </p>
                  ) : (
                    <Skeleton height={20} className="mb-3" />
                  )}
                  {dataCount ? (
                    <p className="fw-bold fs-16 text-orange mb-0">
                      {format_number(dataCount.active_devices)} Device Assigned
                    </p>
                  ) : (
                    <Skeleton height={30} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Card Kedua */}
        <div className="card flex-fill rounded-4 shadow-lg">
          <div className="card-body img-panel py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <div className="d-flex align-items-center">
                  <div className="icon-panel-circle bg-primary-2 me-3">
                    <LuContact size={20} />
                  </div>
                  <h4 className="mb-1">
                    <span className="text-primary-2">Contact</span>{" "}
                    <span className="text-orange">Usage</span>
                  </h4>
                </div>
                <hr className="separator-grey" />
                <div className="progress">
                  <div
                    className="progress-bar bg-primary-2"
                    role="progressbar"
                    style={{ width: `100%` }}
                    aria-valuenow={100}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="mt-3">
                  {dataCount ? (
                    <p className="fs-14 text-dark fw-bold">
                      {format_number(dataCount.total_contacts)}/
                      <FaInfinity
                        size={18}
                        style={{ marginTop: "-3px" }}
                      />{" "}
                      Total Contact
                    </p>
                  ) : (
                    <Skeleton height={20} className="mb-3" />
                  )}
                  <p className="fw-bold fs-16 text-orange mb-0">
                    100% Valid Contact
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
