import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import rehypeHighlight from "rehype-highlight";
import rehypeRaw from "rehype-raw";
import "katex/dist/katex.min.css";
import "highlight.js/styles/github.css";
import CardMeta from "./cardMeta";

const MarkdownRenderer = React.memo(
  ({ markdown, term, isCurrent }) => {
    const processedMarkdown = useMemo(() => {
      const highlightTerm = (text, term, isCurrent) => {
        if (!term) return text;

        const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const regex = new RegExp(`(${escapedTerm})(?![^<]*>|[^<>]*</)`, "gi");

        // Placeholder untuk teks yang tidak perlu di-highlight (URL, tag HTML)
        let placeholderIndex = 0;
        const placeholderMap = {};

        // Menangani URL dan tag HTML lainnya
        text = text.replace(
          /(?:href|src|www|http|https|ftp|mailto):[^"\s<>]+/gi,
          (match) => {
            const placeholder = `__URL_PLACEHOLDER_${placeholderIndex}__`;
            placeholderMap[placeholder] = match;
            placeholderIndex += 1;
            return placeholder;
          }
        );

        text = text.replace(/<[^>]+>/g, (match) => {
          const placeholder = `__HTML_TAG_PLACEHOLDER_${placeholderIndex}__`;
          placeholderMap[placeholder] = match;
          placeholderIndex += 1;
          return placeholder;
        });

        // Highlight term pada teks
        text = text.replace(
          regex,
          (match) =>
            `<span style="background-color: ${
              isCurrent ? "#f7b84b" : "transparent"
            }; color: ${isCurrent ? "black" : "inherit"};">${match}</span>`
        );

        // Kembalikan placeholder dengan teks asli
        Object.keys(placeholderMap).forEach((placeholder) => {
          const regexPlaceholder = new RegExp(placeholder, "g");
          text = text.replace(regexPlaceholder, placeholderMap[placeholder]);
        });

        return text;
      };

      const processMarkdown = (markdown, term, isCurrent) => {
        const latexPattern =
          /(\$\$[^$]*\$\$|\$[^$]*\$|\\\[([^\]]+?)\\\]|\\\(([^\)]+?)\\\]|\\text\{[^}]*\})/g;
        let placeholderIndex = 0;
        const latexMatches = [];

        let processedMarkdown = markdown
          .replace(/\\\[([^\]]+?)\\\]/g, "$$$1$$")
          .replace(/\\\(([^\)]+?)\\\)/g, "$$$1$$")
          .replace(latexPattern, (match) => {
            const placeholder = `__LA_TEX_PLACEHOLDER_${placeholderIndex}__`;
            latexMatches.push({ placeholder, match });
            placeholderIndex += 1;
            return placeholder;
          });

        processedMarkdown = highlightTerm(processedMarkdown, term, isCurrent);

        latexMatches.forEach(({ placeholder, match }) => {
          processedMarkdown = processedMarkdown.replace(
            new RegExp(placeholder, "g"),
            match
          );
        });

        return processedMarkdown;
      };

      return processMarkdown(markdown, term, isCurrent);
    }, [markdown, term, isCurrent]);

    return (
      <ReactMarkdown
        children={processedMarkdown}
        remarkPlugins={[remarkMath, remarkGfm]}
        rehypePlugins={[rehypeRaw, rehypeKatex, rehypeHighlight]}
        components={{
          a: ({ href, children }) => (
            <CardMeta href={href}>{children}</CardMeta>
          ),
        }}
      />
    );
  },
  (prevProps, nextProps) =>
    prevProps.markdown === nextProps.markdown &&
    prevProps.term === nextProps.term &&
    prevProps.isCurrent === nextProps.isCurrent
);

export default MarkdownRenderer;
