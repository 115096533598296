import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../../utilities/url";
import axios from "axios";
import AuthHeader from "../../services/auth.header";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { base64_dec } from "../../utilities/ext-function";
import CryptoJS from "crypto-js";
import storage from "../../services/storage";
import { Controller, useForm } from "react-hook-form";

var $ = require("jquery");

const EditContact = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const dt_user = JSON.parse(decryptUSR);
  const decryptWS = CryptoJS.AES.decrypt(
    storage.get("workspace"),
    "workspace"
  ).toString(CryptoJS.enc.Utf8);
  const workspace = JSON.parse(decryptWS);
  const [hideSpiner, setHideSpiner] = useState(true);
  const [load, setLoad] = useState(false);

  const [state, setState] = useState({
    name_first: "",
    name_last: "",
    title: "",
    email: "",
    msisdn: "",
    country: "",
    province: "",
    city: "",
    address: "",
    company_name: "",
    company_industry: "",
    contact_group: "",
    contact_group_list: "",
  });

  useEffect(() => {
    getData();

    // Datepicker
    $(".datepicker")
      .datepicker({
        format: "dd-mm-yyyy",
        autoclose: true,
        todayHighlight: true,
        endDate: "+0d",
      })
      .on("changeDate", function (e) {
        $(this).valid();
      });
  }, []);

  function getData() {
    setLoad(true);
    axios
      .post(
        API_URL + "contact",
        {
          idt_contact: base64_dec(location.pathname.split("/")[3]),
        },
        {
          headers: AuthHeader(),
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          var resp = res.data.data;
          setValue("name_first", resp.name_first);
          setValue("name_last", resp.name_last);
          setValue("title", resp.title);
          setValue("email", resp.email);
          setValue("msisdn", "+" + resp.msisdn);
          setValue("address", resp.address);
          setValue("country", resp.idt_md_country);
          setValue("province", resp.idt_md_province);
          setValue("city", resp.idt_md_city);
          setValue("company_name", resp.company_name);
          setValue("company_industry", resp.company_industry);

          // Group Contact
          axios
            .post(
              API_URL + "contact-group-list",
              {
                idt_user: dt_user.idt_user,
                idt_user_workspace: workspace.idt_user_workspace,
              },
              {
                headers: AuthHeader(),
              }
            )
            .then((res) => {
              if (res.data.success === true) {
                const selectedOptions = [];
                const maps = res.data.data.map(function (d) {
                  const map = {
                    value: d.idt_contact_group,
                    label: d.name,
                  };
                  if (
                    resp.contact_group_relation.some(
                      (g) => g.idt_contact_group === d.idt_contact_group
                    )
                  ) {
                    selectedOptions.push(map);
                  }
                  return map;
                });
                setValue("contact_group", selectedOptions);
                setSelectedOptionGroupContact(selectedOptions);
                setState((prevState) => ({
                  ...prevState,
                  ["contact_group_list"]: maps,
                }));
              }
            })
            .catch((error) => {
              if (error.response.status === 401) {
                localStorage.clear();
                navigate("/login");
              }
            });

          // Get Country + Phone
          axios
            .get(API_URL + "countries")
            .then((res) => {
              if (res.data.success === true) {
                const maps = res.data.data.map(function (d) {
                  const map = {
                    value: d.idt_md_country,
                    label: d.name,
                    phone: "+" + d.phonecode,
                  };
                  if (d.name === "Indonesia") {
                    setSelectedOption(map);

                    // Get Province
                    axios
                      .post(API_URL + "get-provinces", {
                        idt_md_country: d.idt_md_country,
                      })
                      .then((res) => {
                        if (res.data.success === true) {
                          const maps = res.data.data.map(function (d) {
                            const map = {
                              value: d.idt_md_province,
                              label: d.name,
                            };
                            if (d.idt_md_province === resp.idt_md_province) {
                              setSelectedOptionProvince(map);
                            }
                            return map;
                          });
                          setState((prevState) => ({
                            ...prevState,
                            ["province"]: maps,
                          }));

                          // Get City
                          axios
                            .post(API_URL + "get-cities", {
                              idt_md_province: resp.idt_md_province,
                            })
                            .then((res) => {
                              if (res.data.success === true) {
                                const maps = res.data.data.map(function (d) {
                                  const map = {
                                    value: d.idt_md_city,
                                    label: d.name,
                                  };
                                  if (d.idt_md_city === resp.idt_md_city) {
                                    setSelectedOptionCity(map);
                                  }
                                  return map;
                                });
                                setState((prevState) => ({
                                  ...prevState,
                                  ["city"]: maps,
                                }));
                              } else {
                                console.log(res.data.success);
                              }
                            })
                            .catch((error) => {
                              if (error.response.status === 401) {
                                localStorage.clear();
                                navigate("/login");
                              }
                            });
                        } else {
                          console.log(res.data.success);
                        }
                      })
                      .catch((error) => {
                        if (error.response.status === 401) {
                          localStorage.clear();
                          navigate("/login");
                        }
                      });
                  }
                  return map;
                });
                setState((prevState) => ({
                  ...prevState,
                  ["country"]: maps,
                }));
              }
              setLoad(false);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                localStorage.clear();
                navigate("/login");
              }
            });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  // Set Data
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOptionProvince, setSelectedOptionProvince] = useState();
  const [selectedOptionCity, setSelectedOptionCity] = useState();
  const [selectedOptionGroupContact, setSelectedOptionGroupContact] =
    useState();
  const handleSelect = (data) => {
    setSelectedOption(data);
    setState((prevState) => ({
      ...prevState,
      ["msisdn"]: data.phone,
    }));
  };
  const handleSelectProvince = (data) => {
    setSelectedOptionProvince(data);
    setSelectedOptionCity(null);
    // Get City
    axios
      .post(API_URL + "get-cities", {
        idt_md_province: data.value,
      })
      .then((res) => {
        if (res.data.success === true) {
          const maps = res.data.data.map(function (d) {
            const map = {
              value: d.idt_md_city,
              label: d.name,
            };
            return map;
          });
          setState((prevState) => ({
            ...prevState,
            ["city"]: maps,
          }));
        } else {
          console.log(res.data.success);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };
  const handleSelectCity = (data) => {
    setSelectedOptionCity(data);
  };
  const handleSelectGroupContact = (data) => {
    setSelectedOptionGroupContact(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 32,
      borderRadius: 4,
      minHeight: 32,
      borderColor: state.isFocused ? "#7f7f7f" : base.borderColor, // Ubah warna border saat fokus
      boxShadow: state.isFocused ? "0 0 0 0.1px #7f7f7f" : base.boxShadow, // Hilangkan shadow bawaan
      "&:hover": {
        borderColor: state.isFocused ? "#7f7f7f" : base.borderColor, // Warna hover
      },
    }),
    multiValue: (base) => ({
      ...base,
      marginTop: "-2px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: 0,
      margin: -2,
    }),
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    reset,
    setValue,
    getValues,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    axios
      .post(
        API_URL + "update-contact",
        {
          idt_contact: base64_dec(location.pathname.split("/")[3]),
          contact_group: Array.isArray(data.contact_group)
            ? data.contact_group.map((item) => item.value)
            : [],
          name_first: data.name_first,
          name_last: data.name_last,
          title: data.title,
          email: data.email,
          msisdn: data.msisdn.replace("+", ""),
          idt_md_country: 100,
          idt_md_province:
            typeof data.province === "object"
              ? data.province.value
              : data.province,
          idt_md_city:
            typeof data.city === "object" ? data.city.value : data.city,
          address: $("[name='address']").val(),
          company_name: $("[name='company_name']").val(),
          company_industry: $("[name='company_industry']").val(),
        },
        {
          headers: AuthHeader(),
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          localStorage.setItem("alertu", true);
          navigate("/contact");
        } else {
          toast.error("Update Failed", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });

          setHideSpiner(true);
          $("#b-submit").removeClass("disabled");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <div className="container-fluid p-0">
      <div className="row mb-2 mb-xl-3">
        <div className="col-auto">
          <h5>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/contact">Contact</Link>
                </li>
                <li className="breadcrumb-item active">Update Contact</li>
              </ol>
            </nav>
          </h5>
        </div>
        <ToastContainer />
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card rounded-4 shadow-lg">
            <div className="col-md-11 col-sm-12 mx-auto">
              <div className="card-header rounded-4">
                <h5 className="card-title text-center">Update Contact</h5>
                <ToastContainer />
              </div>
              <div className="card-body">
                <div className="card-body h-100">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            First Name
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.name_first ? "is-invalid" : ""
                              }`}
                              placeholder="First Name"
                              {...register("name_first", {
                                required: "First Name is required",
                              })}
                            />
                            <div className="invalid-feedback">
                              {errors.name_first?.message}
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Last Name
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <input
                              type="text"
                              name="name_last"
                              className={`form-control ${
                                errors.name_last ? "is-invalid" : ""
                              }`}
                              placeholder="Last Name"
                              {...register("name_last", {
                                required: "Last Name is required",
                              })}
                            />
                            <div className="invalid-feedback">
                              {errors.name_last?.message}
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Title
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <select
                              name="title"
                              className={`form-select ${
                                errors.title ? "is-invalid" : ""
                              }`}
                              {...register("title", {
                                required: "Title is required",
                              })}
                            >
                              <option value="">Select Title</option>
                              <option value="Bapak. / Mr. / Tn.">
                                Bapak. / Mr. / Tn.
                              </option>
                              <option value="Ibu. / Mrs. / Ny.">
                                Ibu. / Mrs. / Ny.
                              </option>
                              <option value="Nn. / Ms.">Nn. / Ms.</option>
                            </select>
                            <div className="invalid-feedback">
                              {errors.title?.message}
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Email
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <input
                              type="text"
                              name="email"
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              placeholder="Email"
                              {...register("email", {
                                required: "Email is required",
                                pattern: {
                                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                  message: "Invalid email format",
                                },
                              })}
                            />
                            <div className="invalid-feedback">
                              {errors.email?.message}
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Nomor MSISDN
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <input
                              type="text"
                              name="msisdn"
                              className={`form-control ${
                                errors.msisdn ? "is-invalid" : ""
                              }`}
                              placeholder="Nomor MSISDN"
                              {...register("msisdn", {
                                required: "Nomor MSISDN is required",
                                pattern: {
                                  value: /^\+?[1-9][0-9]{7,14}$/,
                                  message: "Invalid MSISDN format",
                                },
                              })}
                            />
                            <div className="invalid-feedback">
                              {errors.msisdn?.message}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Country
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <Select
                              name="country"
                              options={state.country}
                              placeholder="Select Country"
                              value={selectedOption}
                              onChange={handleSelect}
                              isSearchable={true}
                              isDisabled={true}
                              selected={selectedOption}
                              styles={customStyles}
                              maxMenuHeight={145}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  // primary25: "#fca311",
                                  // primary50: "#fca311",
                                  primary: "#fca311",
                                  // neutral80: "#000",
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Province
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <Controller
                              name="province"
                              control={control}
                              defaultValue={selectedOptionProvince}
                              rules={{ required: "Province is required" }}
                              render={({ field, fieldState }) => (
                                <>
                                  <Select
                                    {...field}
                                    options={state.province}
                                    placeholder="Select Province"
                                    value={selectedOptionProvince}
                                    onChange={(selected) => {
                                      field.onChange(selected);
                                      setSelectedOptionProvince(selected);
                                      handleSelectProvince(selected);
                                    }}
                                    isSearchable={true}
                                    styles={customStyles}
                                    maxMenuHeight={145}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fca311",
                                      },
                                    })}
                                  />
                                  {fieldState?.error && (
                                    <p className="invalid-feedback">
                                      {fieldState?.error?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            City
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <Controller
                              name="city"
                              control={control}
                              defaultValue={selectedOptionCity}
                              rules={{ required: "City is required" }}
                              render={({ field, fieldState }) => (
                                <>
                                  <Select
                                    {...field}
                                    options={state.city}
                                    placeholder="Select City"
                                    value={selectedOptionCity}
                                    onChange={(selected) => {
                                      field.onChange(selected);
                                      setSelectedOptionCity(selected);
                                    }}
                                    isSearchable={true}
                                    styles={customStyles}
                                    maxMenuHeight={145}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fca311",
                                      },
                                    })}
                                  />
                                  {fieldState?.error && (
                                    <div className="invalid-feedback d-block">
                                      {fieldState?.error?.message}
                                    </div>
                                  )}
                                </>
                              )}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Address
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <Controller
                              name="address"
                              control={control}
                              defaultValue={state.address}
                              rules={{ required: "Address is required" }} // Optional: Add validation rules
                              render={({ field, fieldState }) => (
                                <>
                                  <textarea
                                    {...field} // React Hook Form will handle the value and onChange
                                    className="form-control"
                                    placeholder="Address"
                                    rows={6}
                                  />
                                  {fieldState?.error && (
                                    <div className="invalid-feedback d-block">
                                      {fieldState?.error?.message}
                                    </div> // Error message display
                                  )}
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="mb-4"></hr>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Contact Group
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <Controller
                              name="contact_group"
                              control={control}
                              defaultValue={selectedOptionGroupContact}
                              rules={{ required: "Contact Group is required" }}
                              render={({ field, fieldState }) => (
                                <>
                                  <Select
                                    {...field}
                                    name="contact_group"
                                    options={state.contact_group_list}
                                    placeholder="Select Contact Group"
                                    value={selectedOptionGroupContact}
                                    onChange={(selectedOption) => {
                                      field.onChange(selectedOption);
                                      setSelectedOptionGroupContact(
                                        selectedOption
                                      );
                                    }}
                                    isSearchable={true}
                                    isMulti={true}
                                    styles={customStyles}
                                    maxMenuHeight={145}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#fca311",
                                      },
                                    })}
                                  />
                                  {fieldState?.error && (
                                    <div className="invalid-feedback d-block">
                                      {fieldState?.error?.message}
                                    </div>
                                  )}
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Company Name
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <Controller
                              name="company_name"
                              control={control}
                              defaultValue={state.company_name}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="text"
                                  className="form-control"
                                  placeholder="Company Name"
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Company Industry
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <Controller
                              name="company_industry"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <select
                                  {...field}
                                  className="form-select"
                                  placeholder="Company Industry"
                                >
                                  <option value="">
                                    Select Company Industry
                                  </option>
                                  <option value="Lead Developer">
                                    Lead Developer
                                  </option>
                                  <option value="Employee">Employee</option>
                                </select>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Link to="/contact" className="btn btn-danger btn-md">
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-success btn-md float-end"
                        disabled={load}
                      >
                        <span
                          className={`spinner-border spinner-border-xs me-2 ${
                            hideSpiner ? "d-none" : ""
                          }`}
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditContact;
