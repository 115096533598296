import { React, useContext, useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import { API_URL } from "../../utilities/url";
import axios from "axios";
import AuthHeader from "../../services/auth.header";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CryptoJS from "crypto-js";
import storage from "../../services/storage";
import { FaRegCopy } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import { ToastrContext } from "../../provider/toastrContext";
import { Controller, useForm } from "react-hook-form";
import { base64_dec } from "../../utilities/ext-function";

var $ = require("jquery");

const EditDevice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const dt_user = JSON.parse(decryptUSR);
  const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
    CryptoJS.enc.Utf8
  );
  const workspace = JSON.parse(decryptWS);
  const { showCopy } = useContext(ToastrContext);
  const [deviceGroupList, setDeviceGroupList] = useState([]);
  const [hideSpiner, setHideSpiner] = useState(true);

  useEffect(() => {
    getDeviceGroupList();
  }, []);

  useEffect(() => {
    if (deviceGroupList.length > 0) {
      getData();
    }
  }, [deviceGroupList]);

  async function getData() {
    axios
      .post(
        API_URL + "device",
        {
          idt_device: base64_dec(location.pathname.split("/")[3]),
        },
        {
          headers: AuthHeader(),
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          var resp = res.data.data;
          let data1 = null;
          let data2 = null;
          if (resp.verification_code) {
            [data1, data2] = resp.verification_code.split(" ");
          }
          setValue("device_name", resp.name);
          setValue("quota", resp.quota);
          setValue("service_type", resp.type_service);
          setValue("device_type", resp.type_device);
          setValue("msisdn", "+" + resp.msisdn);
          setValue("min_sending_period", resp.min_sending_period);
          setValue("max_sending_period", resp.max_sending_period);
          setValue("channel_secret", data1 || "");
          setValue("channel_at", data2 || "");
          setValue(
            "webhook_url",
            "https://api.otokata.id:5001/callback?s=" + resp.msisdn
          );
          if (resp && resp.idt_device_group) {
            const selectedOption = deviceGroupList.find(
              (option) => option.value === resp.idt_device_group
            );
            setValue("device_group", selectedOption);
          }
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  async function getDeviceGroupList() {
    axios
      .post(
        API_URL + "device-group-list",
        {
          idt_user: dt_user.idt_user,
          idt_user_workspace: workspace.idt_user_workspace,
        },
        {
          headers: AuthHeader(),
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.success === true) {
            const maps = res.data.data.map(function (d) {
              const map = {
                value: d.idt_device_group,
                label: d.name,
              };
              return map;
            });
            setDeviceGroupList(maps);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  }

  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 32,
      borderRadius: 4,
      minHeight: 32,
      borderColor: state.isFocused ? "#7f7f7f" : base.borderColor, // Ubah warna border saat fokus
      boxShadow: state.isFocused ? "0 0 0 0.1px #7f7f7f" : base.boxShadow, // Hilangkan shadow bawaan
      "&:hover": {
        borderColor: state.isFocused ? "#7f7f7f" : base.borderColor, // Warna hover
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: 0,
      margin: -2,
    }),
  };

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  const selectedDeviceType = watch("device_type");

  const onSubmit = (data) => {
    axios
      .post(
        API_URL + "update-device",
        {
          idt_device: base64_dec(location.pathname.split("/")[3]),
          idt_user_workspace: workspace.idt_user_workspace,
          name: data.device_name,
          type_device: data.device_type,
          msisdn: data.msisdn.replace("+", ""),
          type_service: data.service_type,
          channel_secret: data.channel_secret,
          channel_at: data.channel_at,
          webhook_url: data.webhook_url,
          idt_device_group: data.device_group.value,
          quota: data.quota,
          min_sending_period: data.min_sending_period,
          max_sending_period: data.max_sending_period,
        },
        {
          headers: AuthHeader(),
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          setHideSpiner(true);
          localStorage.setItem("alertu", true);
          navigate("/device");
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          setHideSpiner(true);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleGenerateWCL = () => {
    const newUUID = uuidv4();
    setValue(
      "webhook_url",
      "https://api.otokata.id:5001/callback?s=" + newUUID
    );
    clearErrors("webhook_url");
  };

  /* Copy */
  const handleCopyClick = () => {
    const webhookUrl = watch("webhook_url");
    if (webhookUrl) {
      navigator.clipboard.writeText(webhookUrl);
      showCopy("Webhook URL copied to clipboard");
    }
  };

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addDeviceGroup = () => {
    axios
      .post(
        API_URL + "add-device-group",
        {
          idt_user: dt_user.idt_user,
          idt_user_workspace: workspace.idt_user_workspace,
          name: $("[name='device_group_add']").val(),
          description: $("[name='description']").val(),
        },
        {
          headers: AuthHeader(),
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          toast.success("Add Device Group Successful", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          handleClose();
          getDeviceGroupList();
        } else {
          toast.error("Add Device Group Failed", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          handleClose();
        }
      })
      .catch((error) => {
        handleClose();
      });
  };

  return (
    <div className="container-fluid p-0">
      <div className="row mb-2 mb-xl-3">
        <div className="col-auto">
          <h5>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/device">Device</Link>
                </li>
                <li className="breadcrumb-item active">Edit Device</li>
              </ol>
            </nav>
          </h5>
        </div>
        <ToastContainer />
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card rounded-4 shadow-lg">
            <div className="col-md-11 col-sm-12 mx-auto">
              <div className="card-header rounded-4">
                <h5 className="card-title text-center">Edit Device</h5>
              </div>
              <div className="card-body">
                <div className="card-body h-100">
                  <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Add Device Group</Modal.Title>
                    </Modal.Header>
                    <form id="form-add">
                      <Modal.Body>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3 row">
                              <label className="col-form-label col-sm-4 text-sm-right">
                                Device Group Name
                                <span className="required">*</span>
                              </label>
                              <div className="col-sm-6 error-placeholder">
                                <input
                                  type="text"
                                  name="device_group_add"
                                  className="form-control"
                                  placeholder="Device Group Name"
                                />
                              </div>
                            </div>
                            <div className="mb-3 row">
                              <label className="col-form-label col-sm-4 text-sm-right">
                                Description
                              </label>
                              <div className="col-sm-6 error-placeholder">
                                <input
                                  type="text"
                                  name="description"
                                  className="form-control"
                                  placeholder="Description"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Link
                          to=""
                          id="b-submit-add"
                          onClick={() => addDeviceGroup()}
                          className="btn btn-primary btn-md float-end"
                        >
                          Add
                        </Link>
                      </Modal.Footer>
                    </form>
                  </Modal>

                  <form id="form-validation" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-md-6">
                        {/* Device Name */}
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Device Name<span className="required">*</span>
                          </label>
                          <div className="col-sm-8">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Device Name"
                              {...register("device_name", {
                                required: "Device Name is required",
                              })}
                            />
                            {errors.device_name && (
                              <span className="text-danger">
                                {errors.device_name.message}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Device Type */}
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Device Type<span className="required">*</span>
                          </label>
                          <div className="col-sm-8">
                            <div className="form-check form-check-inline">
                              <input
                                type="radio"
                                id="device_type_1"
                                className="form-check-input"
                                value="WHATSAPP"
                                {...register("device_type", {
                                  required: "Device Type is required",
                                })}
                              />
                              <label
                                htmlFor="device_type_1"
                                className="form-check-label"
                              >
                                WhatsApp
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                type="radio"
                                id="device_type_2"
                                className="form-check-input"
                                value="TELEGRAM"
                                {...register("device_type", {
                                  required: "Device Type is required",
                                })}
                              />
                              <label
                                htmlFor="device_type_2"
                                className="form-check-label"
                              >
                                Telegram
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                type="radio"
                                id="device_type_3"
                                className="form-check-input"
                                value="LINE"
                                {...register("device_type", {
                                  required: "Device Type is required",
                                })}
                              />
                              <label
                                htmlFor="device_type_3"
                                className="form-check-label"
                              >
                                Line
                              </label>
                            </div>
                            {/* Error message */}
                            {errors.device_type && (
                              <div className="mt-2">
                                <span className="text-danger">
                                  {errors.device_type.message}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Conditional Inputs */}
                        {selectedDeviceType !== "LINE" && (
                          <div className="mb-3 row">
                            <label className="col-form-label col-sm-4 text-sm-right">
                              Nomor MSISDN<span className="required">*</span>
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Nomor MSISDN"
                                {...register("msisdn", {
                                  required: "Nomor MSISDN is required",
                                })}
                              />
                              {errors.msisdn && (
                                <span className="text-danger">
                                  {errors.msisdn.message}
                                </span>
                              )}
                            </div>
                          </div>
                        )}

                        {selectedDeviceType === "LINE" && (
                          <>
                            <div className="mb-3 row">
                              <label className="col-form-label col-sm-4 text-sm-right">
                                Channel Secret
                                <span className="required">*</span>
                              </label>
                              <div className="col-sm-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Channel Secret"
                                  {...register("channel_secret", {
                                    required: "Channel Secret is required",
                                  })}
                                />
                                {errors.channel_secret && (
                                  <span className="text-danger">
                                    {errors.channel_secret.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="mb-3 row">
                              <label className="col-form-label col-sm-4 text-sm-right">
                                Channel Access Token
                                <span className="required">*</span>
                              </label>
                              <div className="col-sm-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Channel Access Token"
                                  {...register("channel_at", {
                                    required:
                                      "Channel Access Token is required",
                                  })}
                                />
                                {errors.channel_at && (
                                  <span className="text-danger">
                                    {errors.channel_at.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="mb-3 row">
                              <label className="col-form-label col-sm-4 text-sm-right">
                                Webhook URL<span className="required">*</span>
                              </label>
                              <div className="col-sm-8">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Webhook URL"
                                    readOnly
                                    {...register("webhook_url", {
                                      required: "Webhook URL is required",
                                    })}
                                  />

                                  <div
                                    className="input-group-text c-pointer"
                                    onClick={handleCopyClick}
                                  >
                                    <FaRegCopy />
                                  </div>
                                  <button
                                    className="btn btn-primary ms-2"
                                    type="button"
                                    onClick={handleGenerateWCL}
                                  >
                                    Generate
                                  </button>
                                </div>
                                {errors.webhook_url && (
                                  <span className="text-danger">
                                    {errors.webhook_url.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </>
                        )}

                        {/* Device Group */}
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Device Group<span className="required">*</span>
                          </label>
                          <div className="col-sm-8">
                            <Controller
                              name="device_group"
                              control={control}
                              rules={{ required: "Device Group is required" }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={deviceGroupList}
                                  styles={customStyles}
                                  placeholder="Select Device Group"
                                />
                              )}
                            />
                            {errors.device_group && (
                              <span className="text-danger">
                                {errors.device_group.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-sm-12 text-sm-right">
                            <Button variant="primary" onClick={handleShow}>
                              Add Device Group
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        {/* Service Type */}
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Service Type
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8">
                            <div className="form-group mt-1">
                              <div className="form-check form-check-inline">
                                <input
                                  type="radio"
                                  id="service_type_1"
                                  className="form-check-input"
                                  value="BROADCAST"
                                  {...register("service_type", {
                                    required: "Type Service is required",
                                  })}
                                />
                                <label
                                  htmlFor="service_type_1"
                                  className="form-check-label"
                                >
                                  Broadcast
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  type="radio"
                                  id="service_type_2"
                                  className="form-check-input"
                                  value="RECEIVER"
                                  {...register("service_type", {
                                    required: "Type Service is required",
                                  })}
                                />
                                <label
                                  htmlFor="service_type_2"
                                  className="form-check-label"
                                >
                                  Receiver
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  type="radio"
                                  id="service_type_3"
                                  className="form-check-input"
                                  value="HYBRID"
                                  {...register("service_type", {
                                    required: "Type Service is required",
                                  })}
                                />
                                <label
                                  htmlFor="service_type_3"
                                  className="form-check-label"
                                >
                                  Hybrid/Bot
                                </label>
                              </div>
                            </div>

                            {/* Error message */}
                            {errors.service_type && (
                              <div className="mt-2">
                                <span className="text-danger">
                                  {errors.service_type.message}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Quota Per Day */}
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Quota Per Day
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8">
                            <input
                              type="number"
                              className="form-control"
                              {...register("quota", {
                                required: "Quota is required",
                                valueAsNumber: true,
                              })}
                              placeholder="Quota"
                            />
                            {errors.quota && (
                              <span className="text-danger">
                                {errors.quota.message}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Min. Sending Period */}
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Min. Sending Period
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8">
                            <input
                              type="number"
                              className="form-control"
                              {...register("min_sending_period", {
                                required: "Minimum sending period is required",
                                valueAsNumber: true,
                              })}
                              placeholder="Min. Sending Period"
                            />
                            {errors.min_sending_period && (
                              <span className="text-danger">
                                {errors.min_sending_period.message}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Max. Sending Period */}
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Max. Sending Period
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8">
                            <input
                              type="number"
                              className="form-control"
                              {...register("max_sending_period", {
                                required: "Maximum sending period is required",
                                valueAsNumber: true,
                              })}
                              placeholder="Max. Sending Period"
                            />
                            {errors.max_sending_period && (
                              <span className="text-danger">
                                {errors.max_sending_period.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <Link to="/device" className="btn btn-danger btn-md">
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-success btn-md float-end"
                      >
                        <span
                          className={`spinner-border spinner-border-xs me-2 ${
                            hideSpiner ? "d-none" : ""
                          }`}
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDevice;
