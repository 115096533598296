import { React, useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";

const NavLinkContact = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");
  useEffect(() => {
    setCurrentPath(location.pathname.split("/")[2]);
  }, [location]);

  return (
    <div className="col-12 col-md-6 col-lg-12">
      <div className="card rounded-4 shadow-lg">
        <div className="card-header rounded-4">
          <NavLink
            to="/contact"
            className={
              currentPath === null
                ? "btn btn-orange-2 card-link"
                : "btn btn-link card-link"
            }
          >
            Contact
          </NavLink>
          <NavLink
            to="/contact/group"
            className={
              currentPath === "group"
                ? "btn btn-orange-2 card-link"
                : "btn btn-link card-link"
            }
          >
            Contact Group
          </NavLink>
          <NavLink
            to="/contact/profiler"
            className={
              currentPath === "profiler"
                ? "btn btn-orange-2 card-link"
                : "btn btn-link card-link"
            }
          >
            AI Contact Profiler
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default NavLinkContact;
