import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { API_URL, API_URL_PAYMENT } from "../../utilities/url";
import qris from "../../assets/img/payment/qris.png";
import shopay from "../../assets/img/payment/shopay.png";
import ovo from "../../assets/img/payment/ovo.png";
import gopay from "../../assets/img/payment/gopay.png";
import dana from "../../assets/img/payment/dana.png";
import bni from "../../assets/img/payment/BNI.png";
import bca from "../../assets/img/payment/BCA.png";
import bri from "../../assets/img/payment/BRI.png";
import mandiri from "../../assets/img/payment/mandiri.png";
import payment_img from "../../assets/payment.png";
import {
  formatText,
  format_number,
  generateRandomString,
} from "../../utilities/ext-function";
import authService from "../../services/auth.service";
import axios from "axios";
import authHeader from "../../services/auth.header";
import CryptoJS from "crypto-js";
import storage from "../../services/storage";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";

var $ = require("jquery");

const SubscriptionPayment = () => {
  const navigate = useNavigate();
  const { subscriptionPlanId } = useParams();
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const dt_user = JSON.parse(decryptUSR);
  const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
    CryptoJS.enc.Utf8
  );
  const workspace = JSON.parse(decryptWS);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [isCustom, setIsCustom] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = (value) => {
    setSelectedValue(value);
    setIsCustom(false);
  };

  const handleMethodClick = (value) => {
    setSelectedItem(null);
    setSelectedMethod(value);
  };

  const handleCustomInputChange = (e) => {
    const inputValue = e.target.value.replace(/\./g, "");
    if (!isNaN(inputValue)) {
      setSelectedValue(parseInt(inputValue));
    }
  };

  const handleCustomClick = () => {
    setSelectedValue(""); // Kosongkan input untuk pilihan "Lainnya"
    setIsCustom(true); // Aktifkan mode input custom
  };

  const items = [
    { id: 1, name: "Shopee Pay", value: "shopee_pay", image: shopay },
    { id: 2, name: "Gopay", value: "gopay", image: gopay },
    { id: 3, name: "Ovo", value: "ovo", image: ovo },
    { id: 4, name: "Dana", value: "dana", image: dana },
  ];
  const items2 = [
    { id: 1, name: "Transfer Bank BNI", value: "bni", image: bni },
    { id: 2, name: "Transfer Bank BCA", value: "bca", image: bca },
    { id: 3, name: "Transfer Bank BRI", value: "bri", image: bri },
    { id: 4, name: "Transfer Bank Mandiri", value: "mandiri", image: mandiri },
  ];
  // const items3 = [
  //   { id: 1, name: "VA Bank BNI", value: "bni", image: bni },
  //   { id: 2, name: "VA Bank BCA", value: "bca", image: bca },
  //   { id: 3, name: "VA Bank BRI", value: "bri", image: bri },
  //   { id: 4, name: "VA Bank Mandiri", value: "mandiri", image: mandiri },
  // ];

  const [selectedItem, setSelectedItem] = useState(null);
  const handleSelect = (id) => {
    setSelectedItem(id);
  };

  useEffect(() => {
    if (localStorage.getItem("alert")) {
      toast.success("Success !", {
        position: "top-right",
        theme: "colored",
        transition: Slide,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      localStorage.removeItem("alert");
    }
    // Get Subscription Plan
    axios
      .post(
        API_URL + "product-subscription-price-plan",
        { idsp: subscriptionPlanId },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.success == true) {
          response.data.data.plan_length === 0
            ? setSelectedValue(0)
            : response.data.data.plan_length < 12
            ? setSelectedValue(
                response.data.data.price_monthly_basic *
                  response.data.data.plan_length
              )
            : setSelectedValue(
                response.data.data.price_monthly_basic *
                  response.data.data.plan_length
              );
          setSelectedData(response.data.data);
        } else {
          navigate("/subscriptions");
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const generateOID = () => {
    const unixTimestamp = Math.floor(Date.now() / 1000);
    const randomString = generateRandomString(6);
    return `SUB-${randomString}-${unixTimestamp}`;
  };

  const submitPayment = () => {
    let updatedItem;
    if (selectedItem) {
      const { image, ...rest } = selectedItem;
      updatedItem = rest;
    } else {
      updatedItem = null;
    }

    const orderID = generateOID();
    const data = {
      order_id: orderID,
      idw: workspace.idt_user_workspace,
      original_amount: selectedValue,
      amount: selectedValue + selectedValue * 0.11,
      tax: selectedValue * 0.11,
      first_name: dt_user.user_profile.name_first,
      last_name: dt_user.user_profile.name_last,
      product_type: "subscription",
      product_description: "Subscription Plan",
      idspp: selectedData.idt_product_subscription_price_plan,
      callback: API_URL_PAYMENT + "payment/callback/status?order_id=" + orderID,
      email: dt_user.email,
      pty: selectedMethod,
      ptc: selectedItem ? selectedItem.value : null,
      phone: dt_user.msisdn ? dt_user.msisdn : "628111111111",
      payment_type:
        selectedMethod === "bank_transfer" || selectedMethod === "qris"
          ? selectedMethod
          : selectedItem.value,
      bank:
        selectedMethod === "bank_transfer" || selectedMethod === "qris"
          ? selectedItem
            ? selectedItem.value
            : null
          : null,
      items: [
        {
          id: 1,
          price: selectedValue + selectedValue * 0.11,
          quantity: 1,
          name: "subscription plan",
        },
      ],
    };

    setLoading(true);
    axios
      .post(API_URL + "add-transaction", data, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.success == true) {
          navigate("/transaction-payment/" + response.data.data.order_id);
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.message == "Request failed with status code 401") {
          authService.logout();
          navigate("/login");
        } else {
          toast.error(error.response?.data?.error, {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  return (
    <div className="container-fluid p-0">
      <div className="row mb-2 mb-xl-3">
        <div className="col-auto">
          <h5>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/subscriptions">Subscriptions</Link>
                </li>
                <li className="breadcrumb-item active">Subscription Payment</li>
              </ol>
            </nav>
          </h5>
        </div>
        <ToastContainer />
      </div>
      <div className="row">
        <div className="col-8">
          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="card-title mt-2">Subscription Payment</h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-8">
                  <table className="table table-payment">
                    <tbody>
                      <tr>
                        <td className="fw-bold">Subscription Name</td>
                        <td style={{ width: "5%" }}>:</td>
                        <td>
                          {selectedData ? (
                            selectedData.subscription.name
                          ) : (
                            <Skeleton height={18} />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Subscription Plan:</td>
                        <td style={{ width: "5%" }}>:</td>
                        <td>
                          {selectedData ? (
                            selectedData.plan_length === 0 ? (
                              "Unlimited"
                            ) : selectedData.plan_length < 12 ? (
                              "Monthly (3 Month)"
                            ) : (
                              "Annual (12 Month)"
                            )
                          ) : (
                            <Skeleton height={18} />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Subscription Price:</td>
                        <td style={{ width: "5%" }}>:</td>
                        <td>
                          {selectedData ? (
                            selectedData.plan_length === 0 ? (
                              "Unlimited"
                            ) : selectedData.plan_length < 12 ? (
                              format_number(
                                selectedData.price_monthly_basic *
                                  selectedData.plan_length
                              )
                            ) : (
                              format_number(
                                selectedData.price_monthly_basic *
                                  selectedData.plan_length
                              )
                            )
                          ) : (
                            <Skeleton height={18} />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row mt-4">
                <p className="fw-bold">Topup Method:</p>
                <div className="btn-group">
                  {/* Tombol pilihan dengan margin dan hover */}
                  <button
                    className={`btn btn-outline-custom me-2 p-3 fs-16 fw-bold rounded-3 ${
                      selectedMethod === "qris" ? "active" : ""
                    }`}
                    onClick={() => handleMethodClick("qris")}
                  >
                    <img src={qris} width={80} height={30} />
                  </button>
                  <button
                    className={`btn btn-outline-custom me-2 p-3 fs-16 fw-bold rounded-3 ${
                      selectedMethod === "wallet" ? "active" : ""
                    }`}
                    onClick={() => handleMethodClick("wallet")}
                  >
                    Wallet
                  </button>
                  <button
                    className={`btn btn-outline-custom me-2 p-3 fs-16 fw-bold rounded-3 ${
                      selectedMethod === "bank_transfer" ? "active" : ""
                    }`}
                    onClick={() => handleMethodClick("bank_transfer")}
                  >
                    Bank Transfer
                  </button>
                  {/* <button
                    className={`btn btn-outline-custom me-2 p-3 fs-16 fw-bold rounded-3 ${
                      selectedMethod === "Virtual Account" ? "active" : ""
                    }`}
                    onClick={() => handleMethodClick("Virtual Account")}
                  >
                    Virtual Account
                  </button> */}
                  <button
                    className={`btn btn-outline-custom me-2 p-3 fs-16 fw-bold rounded-3 ${
                      selectedMethod === "Credit Card" ? "active" : ""
                    }`}
                    onClick={() => handleMethodClick("Credit Card")}
                  >
                    Credit Card
                  </button>
                </div>
              </div>

              <div className="row mt-5">
                {!selectedMethod && (
                  <div className="container">
                    <p className="text-center">
                      Select your preferred payment method to complete your
                      credit top up.
                    </p>
                    <div className="d-flex justify-content-center">
                      <img
                        src={payment_img}
                        width={200}
                        height={200}
                        alt="Payment Method"
                      />
                    </div>
                  </div>
                )}
                {selectedMethod === "qris" && (
                  <div className="container">
                    <p className="text-center">
                      Select your preferred payment method to complete your
                      credit top up.
                    </p>
                    <div className="my-5 text-center">
                      <img
                        src={shopay}
                        width={70}
                        height={35}
                        className="mx-3"
                        alt="Shopay"
                      />
                      <img
                        src={gopay}
                        width={95}
                        height={30}
                        className="mx-3"
                        alt="Gopay"
                      />
                      <img
                        src={ovo}
                        width={95}
                        height={30}
                        className="mx-3"
                        alt="OVO"
                      />
                      <img
                        src={dana}
                        width={95}
                        height={30}
                        className="mx-3"
                        alt="DANA"
                      />
                    </div>
                    <p className="mt-4 text-center">
                      and other qris merchants.
                    </p>
                  </div>
                )}
                {selectedMethod === "wallet" && (
                  <div className="container">
                    <p className="text-center">
                      Select your preferred payment method to complete your
                      credit top up.
                    </p>
                    <ul className="list-group w-75 mx-auto">
                      {items.map((item) => (
                        <li
                          key={item.id}
                          className="list-group-item d-flex justify-content-between align-items-center"
                          style={{
                            borderBottom: "1px solid #dee2e6",
                            cursor: "pointer",
                          }}
                          onClick={() => handleSelect(item)} // Trigger radio button on li click
                        >
                          <div className="d-flex align-items-center">
                            <img
                              src={item.image}
                              alt={item.name}
                              width={60}
                              height={20}
                              className="me-3"
                            />
                            <span className="ms-3">{item.name}</span>
                          </div>
                          <input
                            type="radio"
                            name="itemSelect"
                            value={item.id}
                            checked={
                              selectedItem && selectedItem.id === item.id
                            } // Check if this item is selected
                            onChange={() => handleSelect(item)} // Update state on radio button change
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {selectedMethod === "bank_transfer" && (
                  <div className="container mb-4">
                    <p className="text-center">
                      Select your preferred payment method to complete your
                      credit top up.
                    </p>
                    <ul className="list-group w-75 mx-auto">
                      {items2.map((item) => (
                        <li
                          key={item.id}
                          className="list-group-item d-flex justify-content-between align-items-center"
                          style={{
                            borderBottom: "1px solid #dee2e6",
                            cursor: "pointer",
                          }}
                          onClick={() => handleSelect(item)} // Trigger radio button on li click
                        >
                          <div className="d-flex align-items-center">
                            <img
                              src={item.image}
                              alt={item.name}
                              width={60}
                              height={20}
                              className="me-3"
                            />
                            <span className="ms-3">{item.name}</span>
                          </div>
                          <input
                            type="radio"
                            name="itemSelect"
                            value={item.id}
                            checked={
                              selectedItem && selectedItem.id === item.id
                            } // Check if this item is selected
                            onChange={() => handleSelect(item)} // Update state on radio button change
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {/* {selectedMethod === "Virtual Account" && (
                  <div className="container mb-4">
                    <p className="text-center">
                      Select your preferred payment method to complete your
                      credit top up.
                    </p>
                    <ul className="list-group w-75 mx-auto">
                      {items3.map((item) => (
                        <li
                          key={item.id}
                          className="list-group-item d-flex justify-content-between align-items-center"
                          style={{
                            borderBottom: "1px solid #dee2e6",
                            cursor: "pointer",
                          }}
                          onClick={() => handleSelect(item)} 
                        >
                          <div className="d-flex align-items-center">
                            <img
                              src={item.image}
                              alt={item.name}
                              width={60}
                              height={20}
                              className="me-3"
                            />
                            <span className="ms-3">{item.name}</span>
                          </div>
                          <input
                            type="radio"
                            name="itemSelect"
                            value={item.id}
                            checked={
                              selectedItem && selectedItem.id === item.id
                            }
                            onChange={() => handleSelect(item)}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card-header">
              <div className="mb-4">
                <h5 className="card-title mt-2">Transaction Detail</h5>
                <hr></hr>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p className="fw-bold">Transaction Name</p>
                  <p className="fw-bold">Payment Method</p>
                  <p className="fw-bold">Original Price</p>
                  <p className="fw-bold">Tax</p>
                  <p className="fw-bold">Admin Fee</p>
                </div>
                <div className="col-md-6 text-end">
                  <p className="fw-bold">Topup Credit</p>
                  <p className="fw-bold">
                    {selectedMethod ? formatText(selectedMethod) : "-"}
                  </p>
                  <p className="fw-bold">{format_number(selectedValue)}</p>
                  <p className="fw-bold">
                    {format_number((selectedValue * 0.11).toFixed(0))}
                  </p>
                  <p className="fw-bold">0</p>
                </div>
                <hr></hr>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p className="mb-0 fw-bold">Total Payment</p>
                </div>
                <div className="col-md-6 text-end">
                  <p className="mb-0 fw-bold">
                    {format_number(
                      parseInt(selectedValue + selectedValue * 0.11)
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* {selectedValue && selectedMethod && ( */}
          <button
            className="btn btn-success btn-lg w-100"
            disabled={
              !(
                !loading &&
                selectedValue &&
                selectedMethod &&
                (selectedMethod === "qris" || selectedItem)
              )
            }
            onClick={() => submitPayment()}
          >
            Continue Payment
          </button>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPayment;
