import { useEffect, useState } from "react";
import {
  FaRegCheckCircle,
  FaRegFrown,
  FaRegMeh,
  FaRegSmile,
  FaRegTimesCircle,
} from "react-icons/fa";
import { MdOutlineCellTower } from "react-icons/md";
import { FiMinusCircle } from "react-icons/fi";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { Link } from "react-router-dom";

export default function BroadcastChart() {
  const data = [
    {
      name: "Jan",
      sukses: 300,
      gagal: 2400,
      invalid: 100,
    },
    {
      name: "Feb",
      sukses: 1398,
      gagal: 2210,
      invalid: 1000,
    },
    {
      name: "Mar",
      sukses: 2000,
      gagal: 2290,
      invalid: 1300,
    },
    {
      name: "Apr",
      sukses: 3908,
      gagal: 2000,
      invalid: 3400,
    },
    {
      name: "May",
      sukses: 4800,
      gagal: 2181,
      invalid: 2234,
    },
    {
      name: "Jun",
      sukses: 3800,
      gagal: 2500,
      invalid: 2765,
    },
    {
      name: "Jul",
      sukses: 4300,
      gagal: 2100,
      invalid: 1765,
    },
    {
      name: "Aug",
      sukses: 4800,
      gagal: 2181,
      invalid: 2065,
    },
    {
      name: "Sep",
      sukses: 3800,
      gagal: 2500,
      invalid: 2205,
    },
    {
      name: "Oct",
      sukses: 4300,
      gagal: 2100,
      invalid: 2505,
    },
    {
      name: "Nov",
      sukses: 3800,
      gagal: 2500,
      invalid: 2705,
    },
    {
      name: "Des",
      sukses: 4300,
      gagal: 2100,
      invalid: 3205,
    },
  ];

  const data2 = [
    { name: "Positif", value: 40 },
    { name: "Netral", value: 30 },
    { name: "Negatif", value: 20 },
    { name: "Unsubs", value: 10 },
  ];

  const colors = ["#00c49f", "#fb8500", "#fc003b", "#0088fe"];

  const data3 = [
    { name: "Completed", value: 40 }, // Nilai progress
    { name: "Remaining", value: 100 - 40 }, // Sisa dari progress
  ];

  const colors2 = ["#fb8500", "#fee7cf"];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="row">
      <div className="col-lg-4">
        <div className="card rounded-4 shadow-lg">
          <div className="card-body rounded-4 header-chart">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex align-items-center">
                <div className="icon-panel-circle bg-primary-2 me-3">
                  <MdOutlineCellTower size={18} />
                </div>
                <div className="mt-1">
                  <h4 className="mb-1 fs-14">4 Running Broadcast</h4>
                  <h4 className="mb-0 fs-18">3000 / 4000</h4>
                </div>
              </div>
              {/* <div className="text-end">dada</div> */}
            </div>
            <hr className="separator-grey" />
            <div className="py-4 px-3">
              {data2.length > 0 ? (
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar]} // Aktifkan modul Swiper
                  spaceBetween={10} // Jarak antar slide
                  slidesPerView={1} // Berapa slide yang ditampilkan dalam satu waktu
                  // navigation // Aktifkan tombol navigasi
                  pagination={{ clickable: true }} // Aktifkan pagination dengan klik
                  loop={true} // Aktifkan efek loop
                >
                  {data2.map((item, index) => (
                    <SwiperSlide key={index} style={{ cursor: "default" }}>
                      <div className="mb-4">
                        <div className="d-flex justify-centent-between">
                          <div className="flex-grow-1">
                            <p className="fs-18 fw-bold text-primary-2 mb-0">
                              Broadcast A
                            </p>
                            <p className="fw-bold text-primary-2 mb-0">
                              Active {moment().format("DD MMM YYYY")}
                            </p>
                          </div>
                          <div>
                            {/* <p className="fs-26 fw-bold text-primary-2 mb-0">
                              {item.value}
                            </p> */}
                          </div>
                        </div>
                        <div className="d-flex gap-3 mt-2 mb-3">
                          <div className="flex-fill">
                            <div className="border border-2 rounded-3 p-2 fw-bold">
                              <p className="text-success mb-0">Delivered</p>
                              <p className="mb-0">900</p>
                              <p className="mb-0">Contact</p>
                            </div>
                          </div>
                          <div className="flex-fill">
                            <div className="border border-2 rounded-3 p-2 fw-bold">
                              <p className="text-info mb-0">Read</p>
                              <p className="mb-0">900</p>
                              <p className="mb-0">Contact</p>
                            </div>
                          </div>
                          <div className="flex-fill">
                            <div className="border border-2 rounded-3 p-2 fw-bold">
                              <p className="text-danger mb-0">Failed</p>
                              <p className="mb-0">900</p>
                              <p className="mb-0">Contact</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mb-3">
                          <div className="mb-3 text-center">
                            <a class="btn btm-sm btn-orange">
                              <span>View More</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <div className="mb-4">
                  <div className="d-flex align-items-start">
                    <Skeleton
                      circle={true} // Membuat bentuknya jadi bulat seperti foto profil
                      height={100} // Tinggi sesuaikan dengan kebutuhan
                      width={100}
                      className="me-3 mb-2" // Lebar sesuaikan dengan kebutuhan
                    />
                    <div className="flex-grow-1">
                      <Skeleton height={34} className="mb-1" />
                      <Skeleton height={20} className="mb-0" />
                      <Skeleton height={20} className="mb-0" />
                    </div>
                  </div>
                  <div className="w-100">
                    <Skeleton height={128} className="mb-2" />
                  </div>
                </div>
              )}
            </div>
            <hr className="separator-grey" />
            <div class="col-md-12">
              <div className="mb-3">
                <Link to="/broadcast/add" class="btn btn-lg btn-primary w-100">
                  <MdOutlineCellTower size={24} className="me-2" />
                  <span>Add Broadcast</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-8">
        <div className="card rounded-4 shadow-lg">
          <div className="card-header rounded-4 header-chart">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title mb-0">Finished Broadcast</h5>
                <p className="text-dark mb-0">Total 5000 Contact</p>
              </div>
              <div className="d-flex align-items-center">
                <span className="me-2" style={{ width: "100px" }}>
                  Filter Period:
                </span>
                <select
                  className="form-select me-2"
                  style={{ width: "100px" }}
                  aria-label="Filter Periode 1"
                >
                  <option value="2024">2024</option>
                  <option value="2023">2023</option>
                </select>
                <select
                  className="form-select"
                  style={{ width: "150px" }}
                  aria-label="Filter Periode 2"
                >
                  <option value="">All</option>
                  <option value="periode1">January</option>
                  <option value="periode2">February</option>
                </select>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="card rounded-4 border-soft-grey">
              <div className="card-body">
                <div className="row">
                  {/* Bagian untuk BroadcastChart dan list di satu baris */}
                  <div className="col-lg-7 d-lg-flex">
                    {/* BroadcastChart di sebelah kiri */}
                    <div className="col-lg-9">
                      <h5>Trend Broadcast</h5>
                      <div className="p-1">
                        <ResponsiveContainer
                          width="100%"
                          height={247}
                          style={{ marginLeft: "-25px" }}
                        >
                          <LineChart data={data}>
                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                            <XAxis
                              dataKey="name"
                              tick={{
                                fontSize: 10,
                                fontWeight: "500",
                                fill: "#333",
                              }}
                            />
                            <YAxis
                              tick={{
                                fontSize: 10,
                                fontWeight: "500",
                                fill: "#333",
                              }}
                            />
                            <Tooltip contentStyle={{ color: "black" }} />
                            {/* <Legend /> */}
                            <Line
                              type="monotone"
                              dataKey="sukses"
                              stroke="#00c49f"
                              activeDot={{ r: 4 }}
                            />
                            <Line
                              type="monotone"
                              dataKey="invalid"
                              stroke="#fb8500"
                              activeDot={{ r: 4 }}
                            />
                            <Line
                              type="monotone"
                              dataKey="gagal"
                              stroke="#fc003b"
                              activeDot={{ r: 4 }}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                    {/* List Sukses, Invalid, Gagal di sebelah kanan dengan responsive height */}
                    <div className="col-lg-3 d-flex flex-column justify-content-between">
                      <div
                        className="border rounded-3 border-soft-grey p-2 mb-2 d-flex flex-column justify-content-center h-100"
                        style={{
                          backgroundColor: "white",
                          padding: "5px",
                          boxSizing: "border-box",
                        }}
                      >
                        <div className="d-flex align-items-center overflow-hidden">
                          <div className="me-2">
                            <FaRegCheckCircle size={20} color="#00c49f" />
                          </div>
                          <div>
                            <h5 className="mb-1 text-nowrap fs-11">
                              Delivered
                            </h5>
                            <p
                              className="text-dark fs-10 mb-0 text-truncate"
                              style={{ margin: 0 }}
                            >
                              4250 contact
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="border rounded-3 border-soft-grey p-2 mb-2 d-flex flex-column justify-content-center h-100"
                        style={{
                          backgroundColor: "white",
                          padding: "10px",
                          boxSizing: "border-box",
                        }}
                      >
                        <div className="d-flex align-items-center overflow-hidden">
                          <div className="me-2">
                            <HiOutlineExclamationCircle
                              size={23}
                              color="#0088fe"
                            />
                          </div>
                          <div>
                            <h5 className="mb-1 text-nowrap fs-11">Read</h5>
                            <p
                              className="text-dark fs-10 mb-0 text-truncate"
                              style={{ margin: 0 }}
                            >
                              4250 contact
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="border rounded-3 border-soft-grey p-2 d-flex flex-column justify-content-center h-100"
                        style={{
                          backgroundColor: "white",
                          padding: "10px",
                          boxSizing: "border-box",
                        }}
                      >
                        <div className="d-flex align-items-center overflow-hidden">
                          <div className="me-2">
                            <FaRegTimesCircle size={20} color="#fc003b" />
                          </div>
                          <div>
                            <h5 className="mb-1 text-nowrap fs-11">Failed</h5>
                            <p
                              className="text-dark fs-10 mb-0 text-truncate"
                              style={{ margin: 0 }}
                            >
                              4250 contact
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      windowWidth >= 768
                        ? "col-lg-5 d-lg-flex"
                        : "col-lg-5 mt-5"
                    }
                  >
                    <div className="col-lg-8">
                      <h5>Target Responses</h5>
                      <div className="p-3">
                        <ResponsiveContainer width="100%" height={200}>
                          <PieChart>
                            <Pie
                              data={data2}
                              cx="50%"
                              cy="50%"
                              outerRadius={80}
                              fill="#8884d8"
                              dataKey="value"
                              // label
                            >
                              {data2.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={colors[index % colors.length]}
                                />
                              ))}
                            </Pie>
                            <Tooltip />
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                    {/* List Sukses, Invalid, Gagal di sebelah kanan dengan responsive height */}
                    <div className="col-lg-4 d-flex flex-column justify-content-between">
                      <div className="border rounded-3 border-soft-grey p-2 mb-2 d-flex flex-column justify-content-center h-100">
                        <div className="d-flex overflow-hidden">
                          <div className="me-2">
                            <FaRegSmile size={20} color="#00c49f" />
                          </div>
                          <div>
                            <h5 className="mb-1 text-nowrap fs-11">
                              Positive 40%
                            </h5>
                            <p className="text-dark fs-10 mb-0">4250 contact</p>
                          </div>
                        </div>
                      </div>
                      <div className="border rounded-3 border-soft-grey p-2 mb-2 d-flex flex-column justify-content-center h-100">
                        <div className="d-flex overflow-hidden">
                          <div className="me-2">
                            <FaRegMeh size={20} color="#fb8500" />
                          </div>
                          <div>
                            <h5 className="mb-1 text-nowrap fs-11">
                              Neutral 30%
                            </h5>
                            <p className="text-dark fs-10 mb-0">4250 contact</p>
                          </div>
                        </div>
                      </div>
                      <div className="border rounded-3 border-soft-grey p-2 mb-2 d-flex d-flex flex-column justify-content-center h-100">
                        <div className="d-flex overflow-hidden">
                          <div className="me-2">
                            <FaRegFrown size={20} color="#fc003b" />
                          </div>
                          <div>
                            <h5 className="mb-1 text-nowrap fs-11">
                              Negative 20%
                            </h5>
                            <p className="text-dark fs-10 mb-0">4250 contact</p>
                          </div>
                        </div>
                      </div>
                      <div className="border rounded-3 border-soft-grey p-2 d-flex d-flex flex-column justify-content-center h-100">
                        <div className="d-flex overflow-hidden">
                          <div className="me-2">
                            <FiMinusCircle size={20} color="#0088fe" />
                          </div>
                          <div>
                            <h5 className="mb-1 text-nowrap fs-11">
                              Unsubs 10%
                            </h5>
                            <p className="text-dark fs-10 mb-0">4250 contact</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
