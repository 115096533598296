import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { API_URL } from "../../utilities/url";
import ProfileImg from "../../assets/avatar-broken.png";
import axios from "axios";
import authHeader from "../../services/auth.header";
import Skeleton from "react-loading-skeleton";
import { HiOutlineCamera } from "react-icons/hi";
import Select from "react-select";
import storage from "../../services/storage";
import CryptoJS from "crypto-js";
import authService from "../../services/auth.service";
import { FiEye } from "react-icons/fi";
import { FaCog, FaInfinity, FaPencilAlt, FaTrash } from "react-icons/fa";
import { useForm } from "react-hook-form";
import upload from "../../assets/upload.png";
import { WorkspaceContext } from "../../provider/workspaceContext";
import { Button, Modal } from "react-bootstrap";
import { TiUserDelete } from "react-icons/ti";
import { RiUserUnfollowFill } from "react-icons/ri";
import { format_number } from "../../utilities/ext-function";

var $ = require("jquery");

const Account = () => {
  const { workspaceList, setWorkspaceList, setWorkspace } =
    useContext(WorkspaceContext);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("profile");
  const [showModal, setShowModal] = useState(false);
  const [subsplan, setSubsplan] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Ambil parameter "tab" dari URL
    const tabParam = searchParams.get("tab");
    if (tabParam) {
      if (tabParam === "workspace") {
        LoadWorkspace();
      } else if (tabParam === "subscription") {
        LoadUserSubsplan();
        LoadSubsplan();
      } else if (tabParam === "transaction") {
        LoadTransaction();
      }
      setActiveTab(tabParam);
    }
  }, [searchParams, location.key]);

  const navigate = useNavigate();
  const [hideSpiner, setHideSpiner] = useState(true);
  const [load, setLoad] = useState(false);
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const dt_user = JSON.parse(decryptUSR);

  useEffect(() => {
    getProfile();

    // Datepicker
    $(".datepicker")
      .datepicker({
        format: "dd-mm-yyyy",
        autoclose: true,
        todayHighlight: true,
        endDate: "+0d",
      })
      .on("changeDate", function (e) {
        $(this).valid();
      });

    var $validationForm = $("#profile-validation");
    $.validator.addMethod(
      "regex_phone",
      function (value, element, regexp) {
        if (regexp.constructor != RegExp) regexp = new RegExp(regexp);
        else if (regexp.global) regexp.lastIndex = 0;
        return this.optional(element) || regexp.test(value);
      },
      "Please enter a valid phone number."
    );
    $validationForm.validate({
      errorPlacement: function errorPlacement(error, element) {
        $(element)
          .parents(".error-placeholder")
          .append(error.addClass("invalid-feedback small d-block"));
      },
      highlight: function (element) {
        $(element).addClass("is-invalid");
      },
      unhighlight: function (element) {
        $(element).removeClass("is-invalid");
      },
      onfocusout: function (element) {
        return $(element).valid();
      },
      rules: {
        email: {
          remote: {
            url: API_URL + "exist_user_v2",
            type: "post",
            data: {
              email: function () {
                return $("[name='email']").val();
              },
            },
          },
        },
        confirm_password: {
          equalTo: 'input[name="password"]',
        },
        msisdn: {
          regex_phone: /^\+?[1-9][0-9]{7,14}$/,
          minlength: 9,
        },
        password: {
          minlength: 6,
        },
      },
      messages: {
        email: {
          remote: "Email is already taken.",
        },
      },
    });
    $validationForm.find("#b-submit").on("click", function () {
      if (!$validationForm.valid()) {
        return;
      }
      setHideSpiner(false);
      $("#b-submit").addClass("disabled");
      var data = new FormData();
      data.append("idt_user", dt_user.idt_user);
      data.append("name_first", $("[name='name_first']").val());
      data.append("name_last", $("[name='name_last']").val());
      data.append("dob", $("[name='dob']").val());
      data.append("country", $("[name='country']").val());
      data.append("msisdn", $("[name='msisdn']").val().replace("+", ""));
      data.append("photo_url", $("#avatar")[0].files[0]);

      axios
        .post(API_URL + "update-user-profile", data, {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toast.success("Update Successful", {
              position: "top-right",
              theme: "colored",
              transition: Slide,
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            });

            $("#card-profile-name").html(
              $("[name='name_first']").val() +
                " " +
                $("[name='name_last']").val()
            );

            setHideSpiner(true);
            $("#b-submit").removeClass("disabled");

            storage.set(
              "user",
              CryptoJS.AES.encrypt(
                JSON.stringify(res.data.data),
                "user"
              ).toString()
            );
          } else {
            toast.error("Update Failed", {
              position: "top-right",
              theme: "colored",
              transition: Slide,
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            });

            setHideSpiner(true);
            $("#b-submit").removeClass("disabled");
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    });
  }, []);

  async function getProfile() {
    $("#b-submit").addClass("disabled");
    axios
      .post(
        API_URL + "get-user-profile",
        { idt_user: dt_user.idt_user },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          var resp = response.data.data;
          setState((prevState) => ({
            ...prevState,
            ["name_first"]: resp.user_profile.name_first,
            ["name_last"]: resp.user_profile.name_last,
            ["dob"]: moment(resp.user_profile.dob).format("DD-MM-YYYY"),
            ["photo_url"]: resp.user_profile.photo_url,
          }));

          // Get Country + Phone
          axios
            .get(API_URL + "countries")
            .then((res) => {
              if (res.data.success === true) {
                const maps = res.data.data.map(function (d) {
                  const map = {
                    value: d.idt_md_country,
                    label: d.name,
                    phone: "+" + d.phonecode,
                  };
                  if (d.idt_md_country == resp.user_profile.idt_md_country) {
                    setSelectedOption(map);
                    setState((prevState) => ({
                      ...prevState,
                      ["msisdn"]: "+" + resp.msisdn,
                    }));
                  }
                  return map;
                });
                setState((prevState) => ({
                  ...prevState,
                  ["country"]: maps,
                }));
                $("#b-submit").removeClass("disabled");
              } else {
                console.log(res.data.success);
              }
            })
            .catch((error) => {
              if (error.response.status === 401) {
                localStorage.clear();
                navigate("/login");
              }
            });

          setLoad(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  }

  // Set Data
  const [selectedOption, setSelectedOption] = useState();
  const handleSelect = (data) => {
    setSelectedOption(data);
    setState((prevState) => ({
      ...prevState,
      ["msisdn"]: data.phone,
    }));
  };
  const [state, setState] = useState({
    email: "",
    password: "",
    confirm_password: "",
    firstname: "",
    lastname: "",
    dob: "",
    country: "",
    msisdn: "",
    img_profile: "",
  });

  const loadFile = (event) => {
    var image = document.getElementById("output");
    image.src = URL.createObjectURL(event.target.files[0]);
    setState((prevState) => ({
      ...prevState,
      ["img_profile"]: event.target.files[0],
    }));
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 32,
      borderRadius: 4,
      minHeight: 32,
      borderColor: state.isFocused ? "#7f7f7f" : base.borderColor, // Ubah warna border saat fokus
      boxShadow: state.isFocused ? "0 0 0 0.1px #7f7f7f" : base.boxShadow, // Hilangkan shadow bawaan
      "&:hover": {
        borderColor: state.isFocused ? "#7f7f7f" : base.borderColor, // Warna hover
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: 0,
      margin: -2,
    }),
  };

  // My Workspace
  function showModalTable(idt_user_workspace, name, img) {
    setShowModal(true);
    setValue("idt_user_workspace", idt_user_workspace);
    setValue("name", name);
    setValue("profile_pic_wr", img);
    setImagePreview(img ? img : upload);
  }
  const [imagePreview, setImagePreview] = useState(upload);
  const handleCloseModal = () => {
    setShowModal(false);
    reset();
    setValue("profile_pic_wr", null);
    setImagePreview(upload);
  };

  const revokeTable = (data) => {
    // const wrsGet = storage.get("wrs")
    //   ? JSON.parse(
    //       CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
    //         CryptoJS.enc.Utf8
    //       )
    //     )
    //   : null;
    // if (100 === wrsGet.idt_user_workspace) {
    //   const filteredWorkspace = workspaceList.filter(
    //     (workspace) => workspace.name === "Personal"
    //   );
    //   storage.set(
    //     "wrs",
    //     CryptoJS.AES.encrypt(
    //       JSON.stringify(filteredWorkspace[0]),
    //       "wrs"
    //     ).toString()
    //   );
    //   setWorkspace(filteredWorkspace[0]);
    // }
    // setWorkspaceList((prevList) =>
    //   prevList.filter((workspace) => workspace.idt_user_workspace !== 100)
    // );
    axios
      .post(
        API_URL + "revoke-workspace",
        { idt_user_workspace: data },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          toast.success("Success to delete !", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
          });
          const wrsGet = storage.get("wrs")
            ? JSON.parse(
                CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
                  CryptoJS.enc.Utf8
                )
              )
            : null;
          if (
            response.data.data.idt_user_workspace === wrsGet.idt_user_workspace
          ) {
            const filteredWorkspace = workspaceList.filter(
              (workspace) => workspace.name === "Personal"
            );
            storage.set(
              "wrs",
              CryptoJS.AES.encrypt(
                JSON.stringify(filteredWorkspace),
                "wrs"
              ).toString()
            );
            setWorkspace(filteredWorkspace[0]);
          }
          setWorkspaceList((prevList) =>
            prevList.filter(
              (workspace) =>
                workspace.idt_user_workspace !==
                response.data.data.idt_user_workspace
            )
          );
          $("#dt-reponsive").DataTable().ajax.reload();
          // $("#dt-reponsive").DataTable().ajax.reload(null, false);
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 401") {
          authService.logout();
          navigate("/login");
        }
      });
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    reset,
    setValue,
    getValues,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    setHideSpiner(false);
    axios
      .post(`${API_URL}update-workspace`, data, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          setHideSpiner(true);
          handleCloseModal();
          toast.success("Update Workspace Success", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          const wrsGet = storage.get("wrs")
            ? JSON.parse(
                CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
                  CryptoJS.enc.Utf8
                )
              )
            : null;
          if (
            response.data.data.idt_user_workspace === wrsGet.idt_user_workspace
          ) {
            storage.set(
              "wrs",
              CryptoJS.AES.encrypt(
                JSON.stringify(response.data.data),
                "wrs"
              ).toString()
            );
            setWorkspace(response.data.data);
          }
          setWorkspaceList((prevList) =>
            prevList.map((workspace) =>
              workspace.idt_user_workspace ===
              response.data.data.idt_user_workspace
                ? {
                    ...workspace,
                    name: response.data.data.name,
                    profile_pic: response.data.data.profile_pic,
                  }
                : workspace
            )
          );
          $("#dt-reponsive").DataTable().ajax.reload();
        } else {
          setHideSpiner(true);
          toast.error("Update Workspace Failed", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {});
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setValue("profile_pic_wr", file);
    }
  };

  const LoadSubsplan = () => {
    $("#dt-subsplan").DataTable({
      dom:
        "<'row'<'col-sm-6'><'col-sm-6'B>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: true,
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-table-subscription",
        type: "post",
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            authService.logout();
          }
        },
      },
      columnDefs: [
        {
          className: "dt-left",
          targets: [0],
        },
        {
          className: "dt-center",
          targets: [1, 2, 3, 4, 5],
        },
      ],
      order: [[1, "desc"]],
      columns: [
        { data: "name" },
        {
          data: "start_subscription",
          render: function (data, type, row) {
            if (data) {
              var data = moment(data).format("D MMMM YYYY HH:mm:ss");
            }

            return data;
          },
        },
        {
          data: "end_subscription",
          render: function (data, type, row) {
            if (data) {
              var data = moment(data).format("D MMMM YYYY HH:mm:ss");
            } else {
              var data = "-";
            }

            return data;
          },
        },
        {
          data: "grace_period",
          render: function (data, type, row) {
            if (data) {
              var data = moment(data).format("D MMMM YYYY HH:mm:ss");
            } else {
              var data = "-";
            }

            return data;
          },
        },
        {
          data: "name",
          render: function (data, type, row) {
            return "-";
          },
        },
        {
          data: "active",
          render: function (data, type, row) {
            if (data == "Y") {
              var data = "Active";
            } else {
              var data = "Expired";
            }

            return data;
          },
        },
      ],
      initComplete: function () {
        var api = this.api();
        $("#search_subsplan")
          .off(".DT")
          .on("keyup.DT", function (e) {
            api.search(this.value).draw();
          });
      },
    });
  };

  const LoadUserSubsplan = () => {
    axios
      .post(
        API_URL + "get-user-subscription",
        { status: "Y" },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setSubsplan(response.data.data);
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 401") {
          authService.logout();
          navigate("/login");
        }
      });
  };

  const LoadTransaction = () => {
    $("#dt-transaction").DataTable({
      dom:
        "<'row'<'col-sm-6'><'col-sm-6'B>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: true,
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-table-transaction",
        type: "post",
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            authService.logout();
          }
        },
      },
      columnDefs: [
        {
          className: "dt-left",
          targets: [0, 2, 3],
        },
        // {
        //   className: "dt-right",
        //   targets: [6],
        // },
        {
          className: "dt-center",
          targets: [4, 6, 5, 7, 8],
        },
      ],
      order: [[0, "desc"]],
      columns: [
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = moment(data).format("DD-MM-YYYY HH:mm:ss");

            return data;
          },
        },
        {
          data: "order_id",
          render: function (data, type, row) {
            return `<a data-id="${data}" data-status="${row.status}" class="btn btn-link"> ${data}</a>`;
          },
        },
        {
          data: "product_type",
        },
        {
          data: "product_description",
          render: function (data, type, row) {
            if (row.product_type == "topup") {
              var data = data + " " + format_number(row.nominal);
            } else {
              var data = data + " " + row.subscription_name;
            }

            return data;
          },
        },
        {
          data: "payment_type",
        },
        {
          data: "payment_channel",
        },
        {
          data: "total",
          render: function (data, type, row) {
            return format_number(data);
          },
        },
        {
          data: "ts_payment_expiry",
          render: function (data, type, row) {
            var data = moment(data).format("DD-MM-YYYY HH:mm:ss");

            return data;
          },
        },
        {
          data: "status",
          render: function (data, type, row) {
            if (data == "pending") {
              return `<span class="badge bg-primary">${data}</span>`;
            } else if (data == "success") {
              return `<span class="badge bg-success">${data}</span>`;
            } else {
              return `<span class="badge bg-danger">${data}</span>`;
            }
          },
        },
      ],
      initComplete: function () {
        var api = this.api();
        $("#search_transaction")
          .off(".DT")
          .on("keyup.DT", function (e) {
            api.search(this.value).draw();
          });
        $("#dt-transaction").on("click", "a", function (e) {
          e.preventDefault();
          const idOrder = $(this).data("id");
          const status = $(this).data("status");
          if (status == "pending") {
            navigate(`/transaction-payment/${idOrder}`);
          } else {
            navigate(`/transaction-status/${idOrder}`);
          }
        });
      },
    });
  };

  const LoadWorkspace = () => {
    $("#dt-reponsive").DataTable({
      dom:
        "<'row'<'col-sm-6'><'col-sm-6'B>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: true,
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-table-workspace",
        type: "post",
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            authService.logout();
          }
        },
      },
      columnDefs: [
        {
          className: "dt-left",
          targets: [0, 1, 7],
        },
        {
          className: "dt-center",
          targets: [2, 3, 4, 5, 6],
        },
        {
          targets: 7,
          createdCell: (td, cellData, rowData, row, col) =>
            ReactDOM.render(
              <div className="d-grid gap-2 d-md-flex justify-content-md">
                {rowData.name !== "Personal" && rowData.role !== "operator" && (
                  <a
                    onClick={() =>
                      showModalTable(
                        rowData.idt_user_workspace,
                        rowData.name,
                        rowData.profile_pic
                      )
                    }
                    className="btn btn-sm btn-success btn-rounded"
                  >
                    <FaPencilAlt />
                  </a>
                )}
                {rowData.role !== "creator" && (
                  <a
                    onClick={() => revokeTable(cellData)}
                    className="btn btn-sm btn-tertiary btn-rounded"
                  >
                    <RiUserUnfollowFill size={14} />
                  </a>
                )}
              </div>,
              td
            ),
        },
      ],
      order: [[0, "desc"]],
      columns: [
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = moment(data).format("DD-MM-YYYY");

            return data;
          },
        },
        { data: "name" },
        {
          data: "total_member",
        },
        {
          data: "total_member",
          render: function (data, type, row) {
            var data = "-";

            return data;
          },
        },
        {
          data: "total_device",
        },
        {
          data: "total_contact",
        },
        // {
        //   data: "name",
        //   render: function (data, type, row) {
        //     var data = `-`;

        //     return data;
        //   },
        // },
        {
          data: "role",
          render: function (data, type, row) {
            if (!data) {
              data = "creator";
            }

            return data;
          },
        },
        { data: "idt_user_workspace" },
      ],
      initComplete: function () {
        var api = this.api();
        $("#search")
          .off(".DT")
          .on("keyup.DT", function (e) {
            api.search(this.value).draw();
          });
      },
    });
  };

  return (
    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <ul
                className="nav nav-pills card-header-pills pull-right"
                role="tablist"
              >
                <li className="nav-item">
                  <Link
                    to="?tab=profile"
                    className={`nav-link ${
                      activeTab === "profile" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("profile")}
                  >
                    Profile
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="?tab=subscription"
                    className={`nav-link ${
                      activeTab === "subscription" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("subscription")}
                  >
                    My Subscription
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="?tab=workspace"
                    className={`nav-link ${
                      activeTab === "workspace" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("workspace")}
                  >
                    My Workspace
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="?tab=transaction"
                    className={`nav-link ${
                      activeTab === "transaction" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("transaction")}
                  >
                    My Transaction
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="?tab=setting"
                    className={`nav-link ${
                      activeTab === "setting" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("setting")}
                  >
                    Setting
                  </Link>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content">
                {/* Profile */}
                <div
                  className={`tab-pane fade ${
                    activeTab === "profile" ? "show active" : ""
                  }`}
                  id="profile"
                  role="tabpanel"
                >
                  <div className="row">
                    <div className="col-md-4 col-xl-3">
                      <div className="card mb-3">
                        {/* <div className="card-header"></div> */}
                        <div className="card-body h-100">
                          {load === true ? (
                            <div>
                              <div className="profile-ava mb-2">
                                <label className="-label" htmlFor="avatar">
                                  <HiOutlineCamera />
                                  <span className="me-1"></span>
                                  <span className="fs-11"> Change Image</span>
                                </label>
                                <input
                                  id="avatar"
                                  type="file"
                                  accept=".png, .jpg, .jpeg"
                                  onChange={loadFile}
                                />
                                <img
                                  src={
                                    state.photo_url
                                      ? state.photo_url
                                      : ProfileImg
                                  }
                                  id="output"
                                  className="img-fluid rounded-circle avatar-p border border-2 border-secondary"
                                  alt="output"
                                />
                              </div>

                              <h5
                                className="card-title text-center mb-0"
                                id="card-profile-name"
                              >
                                {state.name_first} {state.name_last}
                              </h5>
                              <div className="text-muted text-center mb-2">
                                Lead Developer
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="text-center">
                                <Skeleton
                                  className="img-fluid rounded-circle mx-auto mb-2"
                                  width={128}
                                  height={128}
                                />
                                <Skeleton
                                  height={20}
                                  className="card-title mb-0 w-50"
                                />
                                <Skeleton
                                  height={14}
                                  className="text-muted mb-2 w-50"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 col-xl-9">
                      <div className="card">
                        {/* <div className="card-header"></div> */}
                        <div className="card-body h-100">
                          <form id="profile-validation">
                            <div className="row">
                              <div className="mb-3 col-md-6 error-placeholder">
                                <label className="form-label">First Name</label>
                                <span className="required">*</span>
                                <input
                                  type="text"
                                  name="name_first"
                                  defaultValue={state.name_first}
                                  className="form-control"
                                  placeholder="First Name"
                                />
                              </div>
                              <div className="mb-3 col-md-6 error-placeholder">
                                <label className="form-label">Last Name</label>
                                <span className="required">*</span>
                                <input
                                  type="text"
                                  name="name_last"
                                  defaultValue={state.name_last}
                                  className="form-control"
                                  placeholder="Last Name"
                                />
                              </div>
                              <div className="mb-3 col-md-6 error-placeholder">
                                <label className="form-label">
                                  Date of Birth
                                </label>
                                <span className="required">*</span>
                                <input
                                  type="text"
                                  name="dob"
                                  defaultValue={state.dob ? state.dob : null}
                                  className="form-control datepicker required"
                                  placeholder="Date of Birth"
                                />
                              </div>
                              <div className="mb-3 col-md-6 error-placeholder">
                                <label className="form-label">Country</label>
                                <span className="required">*</span>
                                <Select
                                  name="country"
                                  options={state.country}
                                  placeholder="Select Country"
                                  value={selectedOption}
                                  onChange={handleSelect}
                                  isSearchable={true}
                                  selected={selectedOption}
                                  styles={customStyles}
                                  maxMenuHeight={145}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      // primary25: "#fca311",
                                      // primary50: "#fca311",
                                      primary: "#fca311",
                                      // neutral80: "#000",
                                    },
                                  })}
                                />
                              </div>
                              <div className="mb-3 col-md-6 error-placeholder">
                                <label className="form-label">
                                  Mobile Phone
                                </label>
                                <span className="required">*</span>
                                <input
                                  type="text"
                                  name="msisdn"
                                  // value={state.msisdn}
                                  defaultValue={state.msisdn}
                                  className="form-control"
                                  placeholder="Mobile Phone"
                                />
                              </div>
                            </div>
                            <Link
                              to=""
                              id="b-submit"
                              className="btn btn-success btn-md float-end"
                            >
                              <span
                                className={`spinner-border spinner-border-xs me-2 ${
                                  hideSpiner ? "d-none" : ""
                                }`}
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Update
                            </Link>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Subscription */}
                <div
                  className={`tab-pane fade ${
                    activeTab === "subscription" ? "show active" : ""
                  }`}
                  id="subscription"
                  role="tabpanel"
                >
                  <div className="row">
                    <div className="col-12 col-sm-6 col-xxl-4 d-flex">
                      <div
                        className="card flex-fill"
                        style={{
                          backgroundColor: subsplan ? subsplan.color1 : "#fff",
                        }}
                      >
                        <div className="card-body img-panel py-4 shadow-lg">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <h3 className="mb-1">
                                {subsplan ? (
                                  `Otokata Proud ${subsplan.name} Account`
                                ) : (
                                  <Skeleton />
                                )}
                              </h3>
                              <p className="mb-2">
                                {subsplan ? (
                                  subsplan.end_subscription ? (
                                    moment(subsplan.end_subscription).isAfter(
                                      moment()
                                    ) ? (
                                      (() => {
                                        const daysLeft = moment(
                                          subsplan.end_subscription
                                        ).diff(moment(), "days");
                                        const hoursLeft = moment(
                                          subsplan.end_subscription
                                        ).diff(moment(), "hours");
                                        const minutesLeft = moment(
                                          subsplan.end_subscription
                                        ).diff(moment(), "minutes");

                                        return `Active until ${moment(
                                          subsplan.end_subscription
                                        ).format("D MMMM YYYY")} (${
                                          daysLeft > 0
                                            ? `${daysLeft} days left`
                                            : hoursLeft > 0
                                            ? `${hoursLeft} hours left`
                                            : `${minutesLeft} minutes left`
                                        })`;
                                      })()
                                    ) : (
                                      `Expiration date passed`
                                    )
                                  ) : (
                                    `Unlimited Active`
                                  )
                                ) : (
                                  <Skeleton />
                                )}
                              </p>
                              <div className="progress mt-4">
                                {subsplan ? (
                                  subsplan.end_subscription ? (
                                    moment(subsplan.end_subscription).isAfter(
                                      moment()
                                    ) ? (
                                      (() => {
                                        const totalDuration = moment(
                                          subsplan.end_subscription
                                        ).diff(
                                          moment(subsplan.start_subscription),
                                          "days"
                                        );
                                        const elapsedTime = moment().diff(
                                          moment(subsplan.start_subscription),
                                          "days"
                                        );
                                        const progress = Math.min(
                                          (elapsedTime / totalDuration) * 100,
                                          100
                                        ); // Maksimum 100%

                                        return (
                                          <div
                                            className="progress-bar bg-warning"
                                            role="progressbar"
                                            style={{
                                              width: `${progress}%`,
                                              backgroundColor: subsplan
                                                ? subsplan.color2
                                                : "#e5a54b",
                                            }}
                                            aria-valuenow={progress}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>
                                        );
                                      })()
                                    ) : (
                                      <div
                                        className="progress-bar w-100"
                                        style={{
                                          backgroundColor: subsplan
                                            ? subsplan.color2
                                            : "#e5a54b",
                                        }}
                                        role="progressbar"
                                        aria-valuenow="100"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    )
                                  ) : (
                                    <div
                                      className="progress-bar w-100"
                                      style={{
                                        backgroundColor: subsplan
                                          ? subsplan.color2
                                          : "#e5a54b",
                                      }}
                                      role="progressbar"
                                      aria-valuenow="100"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  )
                                ) : (
                                  <div
                                    className="progress-bar w-100"
                                    style={{
                                      backgroundColor: subsplan
                                        ? subsplan.color2
                                        : "#e5a54b",
                                    }}
                                    role="progressbar"
                                    aria-valuenow="75"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                )}
                              </div>
                              <div className="d-flex justify-content-between mt-1">
                                <span className="text-blck fs-12">
                                  {subsplan ? (
                                    moment(subsplan.start_subscription).format(
                                      "D MMMM YYYY"
                                    )
                                  ) : (
                                    <Skeleton />
                                  )}
                                </span>
                                <span className="text-blck fs-12">
                                  {subsplan ? (
                                    subsplan.end_subscription ? (
                                      moment(subsplan.end_subscription).format(
                                        "D MMMM YYYY"
                                      )
                                    ) : (
                                      "Unlimited"
                                    )
                                  ) : (
                                    <Skeleton />
                                  )}
                                </span>
                              </div>
                              <div className="d-flex justify-content-between mt-4 mb-0">
                                <div className="mt-2 fw-bold text-dark">
                                  <img
                                    src={
                                      dt_user.user_profile.photo_url
                                        ? dt_user.user_profile.photo_url
                                        : ProfileImg
                                    }
                                    className="avatar img-top-profile rounded-circle border border-2 border-secondary me-2"
                                    style={{ objectFit: "cover" }}
                                  />
                                  {dt_user.user_profile.name_first}{" "}
                                  {dt_user.user_profile.name_last}
                                </div>
                              </div>
                              {subsplan
                                ? subsplan.end_subscription && (
                                    <>
                                      {moment(subsplan.end_subscription).diff(
                                        moment(),
                                        "days"
                                      ) <= 7 && (
                                        <div className="d-flex justify-content-between mt-4 mb-0">
                                          <div></div>
                                          <a className="btn btn-primary btn-md">
                                            Renew
                                          </a>
                                        </div>
                                      )}
                                    </>
                                  )
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-8 col-xxl-8 d-flex">
                      <div
                        className="card flex-fill rounded-3 shadow-lg"
                        style={{ backgroundColor: "#f7f7f7" }}
                      >
                        <div className="card-body py-4">
                          <div className="d-lg-flex justify-content-around gap-4">
                            <div className="flex-grow-1">
                              <div className="d-flex justify-content-between mb-2">
                                <h4 className="fs-14 mb-1">Daily Credit</h4>
                                <h4 className="fs-14 mb-1">
                                  {subsplan ? (
                                    <>
                                      {format_number(
                                        subsplan.subscription_feature.find(
                                          (feature) =>
                                            feature.idt_product_feature ===
                                            "CREDIT_DAILY"
                                        )?.value_number
                                      ) ?? "-"}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </h4>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <h4 className="fs-14 mb-1">
                                  Max Additional Workspace
                                </h4>
                                <h4 className="fs-14 mb-1">
                                  {subsplan ? (
                                    <>
                                      {format_number(
                                        subsplan.subscription_feature.find(
                                          (feature) =>
                                            feature.idt_product_feature ===
                                            "WS_COUNT"
                                        )?.value_number
                                      ) ?? "-"}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </h4>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <h4 className="fs-14 mb-1">
                                  Max Additional Assistant
                                </h4>
                                <h4 className="fs-14 mb-1">
                                  {subsplan ? (
                                    <>
                                      {format_number(
                                        subsplan.subscription_feature.find(
                                          (feature) =>
                                            feature.idt_product_feature ===
                                            "ASSISTANT_COUNT"
                                        )?.value_number
                                      ) ?? "-"}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </h4>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <h4 className="fs-14 mb-1">Max Device Usage</h4>
                                <h4 className="fs-14 mb-1">
                                  {subsplan ? (
                                    <>
                                      {format_number(
                                        subsplan.subscription_feature.find(
                                          (feature) =>
                                            feature.idt_product_feature ===
                                            "DEV_COUNT"
                                        )?.value_number
                                      ) ?? "-"}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </h4>
                              </div>
                              <div className="d-flex justify-content-between mb-3">
                                <h4 className="fs-14 mb-1">
                                  Max Contact Usage
                                </h4>
                                <h4 className="fs-14 mb-1">
                                  {subsplan ? (
                                    <>
                                      <FaInfinity />
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </h4>
                              </div>
                              <div>
                                <Link to="/comparation-subs" className="mb-0">
                                  See detail comparison
                                </Link>
                              </div>
                            </div>
                            <div className="flex-grow-1 d-block">
                              <div className="border border-2 rounded-3 h-100 p-4 text-center">
                                <h2 className="mb-2">
                                  Upgrade your usage today
                                </h2>
                                <h3 className="mb-4">
                                  Experience more flexibility
                                </h3>
                                <Link
                                  to="/subscriptions"
                                  className="btn btn-primary btn-lg w-75"
                                >
                                  Change Subscription
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card shadow-lg">
                        <div className="card-body table-responsive">
                          <h4 className="mb-5">Subscription History</h4>
                          <table
                            id="dt-subsplan"
                            className="table table-striped table-responsive dt-bootstrap5 no-footer"
                          >
                            <thead>
                              <tr>
                                <th>Subscription Plan</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Grace Period</th>
                                <th>Usage</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr></tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* My Workspace */}
                <div
                  className={`tab-pane fade ${
                    activeTab === "workspace" ? "show active" : ""
                  }`}
                  id="workspace"
                  role="tabpanel"
                >
                  <div className="card-body table-responsive">
                    <div className="col-md-12">
                      <div className="card shadow-lg">
                        <div className="card-body table-responsive">
                          <h4 className="mb-5">My Workspace</h4>
                          <table
                            id="dt-reponsive"
                            className="table table-striped table-responsive dt-bootstrap5 no-footer"
                          >
                            <thead>
                              <tr>
                                <th>Created</th>
                                <th>Name</th>
                                <th>Total Member</th>
                                <th>Total Credit</th>
                                <th>Total Device</th>
                                <th>Total Contact</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr></tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* My Transaction */}
                <div
                  className={`tab-pane fade ${
                    activeTab === "transaction" ? "show active" : ""
                  }`}
                  id="transaction"
                  role="tabpanel"
                >
                  <div className="card-body table-responsive">
                    <div className="col-md-12">
                      <div className="card shadow-lg">
                        <div className="card-body table-responsive">
                          <h4 className="mb-5">My Transaction</h4>
                          <table
                            id="dt-transaction"
                            className="table table-striped table-responsive dt-bootstrap5 no-footer"
                          >
                            <thead>
                              <tr>
                                <th>Created</th>
                                <th>Order ID</th>
                                <th>Product Type</th>
                                <th>Product Description</th>
                                <th>Payment Type</th>
                                <th>Payment Channel</th>
                                <th>Total Payment</th>
                                <th>Expiry Date</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr></tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Setting */}
                <div
                  className={`tab-pane fade ${
                    activeTab === "setting" ? "show active" : ""
                  }`}
                  id="setting"
                  role="tabpanel"
                >
                  <p>Setting Content</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        {showModal && <ToastContainer toastClassName="toaster" />}
        <Modal.Header closeButton>
          <Modal.Title>Update Workspace</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3 row">
                  <div className="col-md-4">
                    <div className="profile-ava2 mb-2">
                      <label className="-label rounded-4" htmlFor="avatar2">
                        <HiOutlineCamera />
                        <span className="me-1"></span>
                        <span className="fs-11"> Change Image</span>
                      </label>
                      <input
                        id="avatar2"
                        type="file"
                        name="photo_url"
                        className="profile-img-file-input"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                      <img
                        src={imagePreview ? imagePreview : upload}
                        id="avatar"
                        className="img-fluid rounded-4 avatar-p border border-2 border-secondary"
                        alt="avatar"
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <label className="col-form-label col-sm-12 text-sm-right">
                      Workspace Name
                      <span className="required">*</span>
                    </label>
                    <div className="col-sm-10 error-placeholder">
                      <input
                        type="text"
                        {...register("name", {
                          required: "Workspace Name is required",
                        })}
                        className={`form-control ${
                          errors.name ? "is-invalid" : ""
                        }`}
                      />
                      {errors.name && (
                        <div className="invalid-feedback">
                          {errors.name.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleCloseModal}>
              Cancel
            </Button>
            <button type="submit" className="btn btn-primary btn-md float-end">
              <span
                className={`spinner-border spinner-border-xs me-2 ${
                  hideSpiner ? "d-none" : ""
                }`}
                role="status"
                aria-hidden="true"
              ></span>
              Update
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default Account;
