import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import axios from "axios";
import logo from "../../assets/logo.png";
import { API_URL } from "../../utilities/url";
import CryptoJS from "crypto-js";
import storage from "../../services/storage";
import ModalExpired from "../../components/modalExpired";
import { FiLock } from "react-icons/fi";

var $ = require("jquery");

const CreatePassword = () => {
  const year = moment().format("YYYY");
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.split("/")[2];
  const [alertMessage, setAlertMessage] = useState("");
  const [hideAlert, setHideAlert] = useState(true);
  const [hidePassword, setHidePassword] = useState("password");
  const [hidePassword2, setHidePassword2] = useState("password");
  const [isTokenExpired, setTokenExpired] = useState(false);

  useEffect(() => {
    // Validate State
    if (!location.state) {
      if (!storage.get("dtf")) {
        navigate("/register");
      } else {
        storage.set("dtf", storage.get("dtf"), 5);
      }
    } else {
      storage.set("dtf", location.state, 5);
    }
  }, []);
  /* Form Submit */
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    reValidateMode: "onBlur",
  });
  const validatePassword = (value) => {
    const hasUppercase = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpchar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);
    if (!hasUppercase || !hasNumber || !hasSpchar) {
      return false;
    }
    return true;
  };
  const validateConfirmPassword = (value) => {
    const { new_password } = getValues();
    return value === new_password || "Password don't match.";
  };
  const handleChangePass = (event) => {
    const value = event.target.value;
    value.length >= 8
      ? $("#pass-length").removeClass("invalid").addClass("valid")
      : $("#pass-length").removeClass("valid").addClass("invalid");
    /[A-Z]/.test(value)
      ? $("#pass-upper").removeClass("invalid").addClass("valid")
      : $("#pass-upper").removeClass("valid").addClass("invalid");
    /\d/.test(value)
      ? $("#pass-number").removeClass("invalid").addClass("valid")
      : $("#pass-number").removeClass("valid").addClass("invalid");
    /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value)
      ? $("#pass-spchar").removeClass("invalid").addClass("valid")
      : $("#pass-spchar").removeClass("valid").addClass("invalid");
  };
  const handleCloseModal = () => {
    setTokenExpired(false);
  };
  const onSubmit = (data) => {
    const dtf = storage.get("dtf");
    const formData = {
      email: CryptoJS.AES.decrypt(dtf.ec, "email").toString(CryptoJS.enc.Utf8),
      name_first: CryptoJS.AES.decrypt(dtf.fc, "firstname").toString(
        CryptoJS.enc.Utf8
      ),
      name_last: CryptoJS.AES.decrypt(dtf.lc, "lastname").toString(
        CryptoJS.enc.Utf8
      ),
      pic: CryptoJS.AES.decrypt(dtf.pc, "picture").toString(CryptoJS.enc.Utf8),
      password: data.password,
    };
    axios
      .post(API_URL + "register-with-google", formData)
      .then((response) => {
        if (response.data.success === true) {
          navigate("/activation-success");
        } else {
          setAlertMessage("Session has been expired.");
          setHideAlert(false);
        }
      })
      .catch((error) => {});
  };

  /* Function */
  const togglePassword = () => {
    if (hidePassword === "password") {
      setHidePassword("text");
      return;
    }
    setHidePassword("password");
  };
  const togglePassword2 = () => {
    if (hidePassword2 === "password") {
      setHidePassword2("text");
      return;
    }
    setHidePassword2("password");
  };

  return (
    <div className="main d-flex justify-content-center w-100 bg-full-login">
      <main className="content d-flex p-4">
        <div className="container d-flex flex-column">
          <Link to="/" style={{ width: 100 }}>
            <img
              className="img-logo-login d-none d-sm-block"
              src={logo}
              alt="logo"
            />
          </Link>
          <div className="row h-100">
            <div className="col-sm-10 col-md-8 col-lg-5 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center ms-2 me-2 mb-3">
                  <img
                    className="img-logo-login d-md-none d-lg-none"
                    src={logo}
                    alt="logo"
                  />
                </div>
                <div className="card bg-white-transparent rounded-4">
                  <div className="text-center mt-4">
                    <h3 className="h3 text-orange">Create New Password</h3>
                  </div>
                  <div className="card-body pt-0">
                    <ModalExpired
                      show={isTokenExpired}
                      handleClose={handleCloseModal}
                    />
                    <div className="m-sm-5">
                      <div
                        className={`alert alert-danger alert-dismissible ${
                          hideAlert ? "d-none" : "d-block"
                        }`}
                        role="alert"
                      >
                        <button
                          onClick={() => setHideAlert(true)}
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                        ></button>
                        <div className="alert-message">
                          <strong className="text-danger">
                            {alertMessage}
                          </strong>
                        </div>
                      </div>
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        id="login-validation"
                      >
                        <div className="mb-3 error-placeholder">
                          <label className="form-label">New Password</label>
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text">
                              <FiLock size={20} />
                            </div>
                            <input
                              type={hidePassword}
                              className="form-control"
                              name="new_password"
                              {...register("new_password", {
                                required: true,
                                minLength: 8,
                                validate: validatePassword,
                              })}
                              onChange={handleChangePass}
                            />
                            <div className="input-group-text">
                              {hidePassword === "password" ? (
                                <RiEyeFill
                                  size={20}
                                  className="c-pointer"
                                  onClick={togglePassword}
                                />
                              ) : (
                                <RiEyeOffFill
                                  size={20}
                                  className="c-pointer"
                                  onClick={togglePassword}
                                />
                              )}
                            </div>
                          </div>
                          {errors.new_password &&
                            errors.new_password.type === "required" && (
                              <p className="errorMsg text-danger fs-7">
                                Password is required.
                              </p>
                            )}
                          {errors.new_password &&
                            errors.new_password.type === "minLength" && (
                              <p className="errorMsg text-danger fs-7">
                                Password min 8 character.
                              </p>
                            )}
                          {errors.new_password &&
                            errors.new_password.type === "validate" && (
                              <p className="errorMsg text-danger fs-7">
                                Password must contain at least uppercase letter
                                , number and special character.
                              </p>
                            )}
                        </div>
                        <div className="mb-3 error-placeholder">
                          <label className="form-label">Confirm Password</label>
                          <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text">
                              <FiLock size={20} />
                            </div>
                            <input
                              type={hidePassword2}
                              name="password"
                              autoComplete="on"
                              className="form-control pe-5 password-input"
                              {...register("password", {
                                required: true,
                                validate: validateConfirmPassword,
                              })}
                            />
                            <div className="input-group-text">
                              {hidePassword2 === "password" ? (
                                <RiEyeFill
                                  size={20}
                                  className="c-pointer"
                                  onClick={togglePassword2}
                                />
                              ) : (
                                <RiEyeOffFill
                                  size={20}
                                  className="c-pointer"
                                  onClick={togglePassword2}
                                />
                              )}
                            </div>
                          </div>
                          {errors.password &&
                            errors.password.type === "required" && (
                              <p className="errorMsg text-danger fs-7">
                                Password is required.
                              </p>
                            )}
                          {errors.password &&
                            errors.password.type === "validate" && (
                              <p className="errorMsg text-danger fs-7">
                                Password don't match.
                              </p>
                            )}
                        </div>
                        <div className="row mt-4 mb-3">
                          <div
                            id="password-contain"
                            style={{ display: "block" }}
                            className="p-3 bg-light mb-2 rounded"
                          >
                            <h5 className="fs-13">Password must contain:</h5>
                            <p id="pass-length" className="invalid fs-12 mb-2">
                              Minimum <b>8 characters</b>
                            </p>
                            <p id="pass-upper" className="invalid fs-12 mb-2">
                              At least <b>uppercase</b> letter (A-Z)
                            </p>
                            <p id="pass-number" className="invalid fs-12 mb-2">
                              A least <b>number</b> (0-9)
                            </p>
                            <p id="pass-spchar" className="invalid fs-12 mb-0">
                              A least <b>special character</b> (!@#$%^*|,./?
                              etc.)
                            </p>
                          </div>
                          <div className="mt-2">
                            <button
                              className="btn btn-primary btn-lg w-100"
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CreatePassword;
