import { useEffect, useState } from "react";
import { FaRegFrown, FaRegMeh, FaRegSmile } from "react-icons/fa";
import { FiMinusCircle } from "react-icons/fi";
import { RiChatNewLine } from "react-icons/ri";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
} from "recharts";
import { Link } from "react-router-dom";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";

export default function TransactionChart() {
  const data = [
    {
      name: "Jan",
      sukses: 300,
      gagal: 2400,
      invalid: 100,
    },
    {
      name: "Feb",
      sukses: 1398,
      gagal: 2210,
      invalid: 1000,
    },
    {
      name: "Mar",
      sukses: 2000,
      gagal: 2290,
      invalid: 1300,
    },
    {
      name: "Apr",
      sukses: 3908,
      gagal: 2000,
      invalid: 3400,
    },
    {
      name: "May",
      sukses: 4800,
      gagal: 2181,
      invalid: 2234,
    },
    {
      name: "Jun",
      sukses: 3800,
      gagal: 2500,
      invalid: 2765,
    },
    {
      name: "Jul",
      sukses: 4300,
      gagal: 2100,
      invalid: 1765,
    },
    {
      name: "Aug",
      sukses: 4800,
      gagal: 2181,
      invalid: 2065,
    },
    {
      name: "Sep",
      sukses: 3800,
      gagal: 2500,
      invalid: 2205,
    },
    {
      name: "Oct",
      sukses: 4300,
      gagal: 2100,
      invalid: 2505,
    },
    {
      name: "Nov",
      sukses: 3800,
      gagal: 2500,
      invalid: 2705,
    },
    {
      name: "Des",
      sukses: 4300,
      gagal: 2100,
      invalid: 3205,
    },
  ];

  const data2 = [
    { name: "Positif", value: 40 },
    { name: "Netral", value: 30 },
    { name: "Negatif", value: 20 },
    { name: "Unsubs", value: 10 },
  ];

  const colors = ["#00c49f", "#fb8500", "#fc003b", "#0088fe"];

  const data3 = [
    { name: "Completed", value: 40 }, // Nilai progress
    { name: "Remaining", value: 100 - 40 }, // Sisa dari progress
  ];

  const colors2 = ["#fb8500", "#fee7cf"];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="row">
      <div className={windowWidth >= 1800 ? "col-lg-9 col-12" : "col-12"}>
        <div className="card">
          <div className="card-header header-chart">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title mb-0">Subscription Income</h5>
                <p className="text-dark mb-0">
                  Total this month <b>15.000</b>
                </p>
              </div>
              <div className="d-flex align-items-center">
                <span className="me-2" style={{ width: "100px" }}>
                  Filter Periode:
                </span>
                <select
                  className="form-select me-2"
                  style={{ width: "100px" }}
                  aria-label="Filter Periode 1"
                >
                  <option value="2024">2024</option>
                  <option value="2023">2023</option>
                </select>
                {/* <select
                  className="form-select"
                  style={{ width: "150px" }}
                  aria-label="Filter Periode 2"
                >
                  <option value="">Semua</option>
                  <option value="periode1">Januari</option>
                  <option value="periode2">Februari</option>
                </select> */}
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="card" style={{ border: "1px solid #000" }}>
              <div className="card-body">
                <div className="row">
                  {/* Bagian untuk ChatbotChart dan list di satu baris */}
                  <div className="col-lg-7 d-lg-flex">
                    {/* ChatbotChart di sebelah kiri */}
                    <div className="col-lg-12">
                      <h5>Subscription Income Trend</h5>
                      <div className="p-1">
                        <ResponsiveContainer
                          width="100%"
                          height={300}
                          style={{ marginLeft: "-15px" }}
                        >
                          <BarChart data={data}>
                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip contentStyle={{ color: "black" }} />
                            {/* <Legend /> */}
                            <Bar dataKey="sukses" fill="#00c49f" />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      windowWidth >= 768
                        ? "col-lg-5 d-lg-flex"
                        : "col-lg-5 mt-5"
                    }
                  >
                    <div className="col-lg-8">
                      <h5>Best Earners</h5>
                      <div className="p-3">
                        <ResponsiveContainer width="100%" height={300}>
                          <PieChart>
                            <Pie
                              data={data2}
                              cx="50%"
                              cy="50%"
                              outerRadius={100}
                              fill="#8884d8"
                              dataKey="value"
                              // label
                            >
                              {data2.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={colors[index % colors.length]}
                                />
                              ))}
                            </Pie>
                            <Tooltip />
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                    {/* List Sukses, Invalid, Gagal di sebelah kanan dengan responsive height */}
                    <div className="col-lg-4 d-flex flex-column justify-content-between">
                      <div className="border rounded-3 border-dark p-2 mb-2 d-flex flex-column justify-content-center h-100">
                        <div className="d-flex">
                          <div className="me-2">
                            <FaRegSmile size={20} color="#00c49f" />
                          </div>
                          <div>
                            <h5 className="mb-1">Chatbot Toko OL 1</h5>
                            <p className="text-dark fs-11 mb-0">4250 IDR</p>
                          </div>
                        </div>
                      </div>
                      <div className="border rounded-3 border-dark p-2 mb-2 d-flex flex-column justify-content-center h-100">
                        <div className="d-flex">
                          <div className="me-2">
                            <FaRegMeh size={20} color="#fb8500" />
                          </div>
                          <div>
                            <h5 className="mb-1">Chatbot Toko OL 2</h5>
                            <p className="text-dark fs-11 mb-0">4250 IDR</p>
                          </div>
                        </div>
                      </div>
                      <div className="border rounded-3 border-dark p-2 mb-2 d-flex d-flex flex-column justify-content-center h-100">
                        <div className="d-flex">
                          <div className="me-2">
                            <FaRegFrown size={20} color="#fc003b" />
                          </div>
                          <div>
                            <h5 className="mb-1">Chatbot Toko OL 3</h5>
                            <p className="text-dark fs-11 mb-0">4250 IDR</p>
                          </div>
                        </div>
                      </div>
                      <div className="border rounded-3 border-dark p-2 d-flex d-flex flex-column justify-content-center h-100">
                        <div className="d-flex">
                          <div className="me-2">
                            <FiMinusCircle size={20} color="#0088fe" />
                          </div>
                          <div>
                            <h5 className="mb-1">Chatbot Toko OL 4</h5>
                            <p className="text-dark fs-11 mb-0">4250 IDR</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={windowWidth >= 1800 ? "col-lg-3" : "col-lg-4"}>
        <div className="card">
          <div className="card-header header-chart">
            <div className="float-start">
              <h5 className="card-title mb-0">Total Balance</h5>
            </div>
          </div>
          <div className="card-body">
            <div className="card" style={{ border: "1px solid #000" }}>
              <div className="card-body">
                <div className="row">
                  {/* Bagian untuk ChatbotChart dan list di satu baris */}
                  <div className="col-lg-12">
                    <a className="btn btn-lg btn-primary w-100 d-flex justify-content-between align-items-center mb-3">
                      <div>
                        <p className="mb-0 fs-30 text-start">21.000</p>
                      </div>
                    </a>
                    <p>From 4.500 active member</p>
                    <p>Last withdrawl : 17 August 2024</p>
                  </div>
                  <div className="col-md-12">
                    <Link
                      to="/chatbot/add"
                      className="btn btn-lg btn-info w-100 d-flex justify-content-center align-items-center"
                      style={{
                        height: "125px",
                        background: "#13213c",
                      }}
                    >
                      <MdOutlineAccountBalanceWallet
                        size={35}
                        className="me-2"
                      />
                      <span className="fs-20">Withdaw</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
