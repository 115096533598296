import React, { useRef, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { FaUser, FaClipboardList } from "react-icons/fa"; // Import ikon dari react-icons
import SimpleBar from "simplebar-react";

const RightComponent = () => {
  const simpleBarRef = useRef(null);
  const simpleBarRef2 = useRef(null);
  const assistantData = [
    { id: 1, name: "Assistant 1" },
    { id: 2, name: "Assistant 2" },
    { id: 1, name: "Assistant 1" },
    { id: 2, name: "Assistant 2" },
    { id: 1, name: "Assistant 1" },
    { id: 2, name: "Assistant 2" },
    { id: 1, name: "Assistant 1" },
    { id: 1, name: "Assistant 1" },
    { id: 2, name: "Assistant 2" },
    { id: 1, name: "Assistant 1" },
    { id: 2, name: "Assistant 2" },
    { id: 1, name: "Assistant 1" },
    { id: 2, name: "Assistant 2" },
    { id: 1, name: "Assistant 1" },
  ];

  const templateData = [
    { id: 1, name: "Template 1" },
    { id: 2, name: "Template 2" },
  ];

  const [key, setKey] = useState("assistant"); // Tab aktif

  return (
    <div className="container">
      <Tabs
        id="data-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="w-100 d-flex justify-content-between"
        fill
      >
        {/* Tab Assistant */}
        <Tab
          eventKey="assistant"
          title={
            <span>
              <FaUser className="mt-1 mb-1" size={20} /> <br></br> Assistant
            </span>
          }
        >
          <div className="position-relative">
            <SimpleBar
              className="chat-messages p-2"
              style={{ overflowX: "hidden" }}
              ref={simpleBarRef}
            >
              <div className="px-4">
                {assistantData.map((item) => (
                  <div
                    className="card mb-3 bg-dark c-pointer"
                    key={item.id}
                    onClick={() => alert(item.name)}
                  >
                    <div className="card-body p-2">
                      <p className="mb-0 text-white fs-12">{item.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </SimpleBar>
          </div>
          <div className="flex-grow-0 py-3 px-4 border-top">
            <div className="input-group">
              <input
                type="file"
                id="file-upload"
                className="d-none"
                // onChange={handleFileUpload}
              />
              <input
                type="text"
                className="form-control"
                placeholder="Type your message"
                // value={messageText}
                // onChange={handleMessageText}
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") {
                //     handleSendMessage();
                //   }
                // }}
              />
              <button
                className="btn btn-success"
                // onClick={() => handleSendMessage()}
              >
                Send
              </button>
            </div>
          </div>
        </Tab>

        {/* Tab Template */}
        <Tab
          eventKey="template"
          title={
            <span>
              <FaClipboardList className="mt-1 mb-1" size={20} /> <br></br>{" "}
              Template
            </span>
          }
        >
          <div className="position-relative">
            <SimpleBar className="chat-messages p-2" ref={simpleBarRef2}>
              <div className="px-4">
                {templateData.map((item) => (
                  <div
                    className="card mb-3 bg-dark c-pointer"
                    key={item.id}
                    onClick={() => alert(item.name)}
                  >
                    <div className="card-body p-2">
                      <p className="mb-0 text-white fs-12">{item.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </SimpleBar>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default RightComponent;
