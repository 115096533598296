import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaTimes } from "react-icons/fa";
import TagInput from "./tagInput";

export default function AddFunction({
  functionS,
  handleFunctionS,
  show,
  handleClose,
}) {
  // Modal Function
  const [actionT, setActionT] = useState("api");
  const [rows, setRows] = useState([
    {
      name: null,
      type: "string",
      description: null,
      enum: [],
      required: false,
    },
  ]);
  const [rows2, setRows2] = useState([
    {
      column: null,
      value: null,
    },
  ]);
  const handleClosed = () => {
    reset();
    setRows([
      {
        name: null,
        type: "string",
        description: null,
        enum: [],
        required: false,
      },
    ]);
    setRows2([
      {
        column: null,
        value: null,
      },
    ]);
    handleClose();
  };

  // Fungsi untuk menangani perubahan input
  const handleInputChange = (index, field, value) => {
    const newRows = [...rows];
    const oldValue = newRows[index][field];
    newRows[index][field] = value;
    setRows(newRows);

    // Update rows2 array based on the old and new values
    const updatedRows2 = rows2.map((row2) => {
      if (row2.value === `$otokata.${oldValue}` || !row2.value) {
        return { ...row2, value: `$otokata.${value}` };
      }
      return row2;
    });

    setRows2(updatedRows2);
  };
  const handleInputChange2 = (index, field, value) => {
    const newRows = [...rows2];
    newRows[index][field] = value;
    setRows2(newRows);
  };
  const handleEnum = (index, tags) => {
    const newRows = [...rows];
    newRows[index].enum = tags;
    setRows(newRows);
  };
  const handleAddRow = () => {
    setRows([
      ...rows,
      { name: "", type: "string", description: "", enum: [], required: false },
    ]);
  };
  const handleDeleteRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };
  const handleAddColumn = () => {
    const columnBody = rows.filter((row) => row.name !== null);
    setRows2([
      ...rows2,
      {
        column: null,
        value: columnBody.length > 0 ? `$otokata.${columnBody[0].name}` : null,
      },
    ]);
  };
  const handleDeleteColumn = (index) => {
    const newRows = rows2.filter((_, i) => i !== index);
    setRows2(newRows);
  };

  const handleActionT = (e) => {
    setActionT(e.target.value);
    if (e.target.value === "api") {
      setValue("bodyT", "json");
    } else {
      setValue("bodyT", null);
    }
    if (e.target.value === "api") {
      setValue("method", "GET");
    } else {
      setValue("method", "INSERT");
    }
  };

  useEffect(() => {
    setValue("actionT", "api");
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const handleModalSubmit = (e) => {
    e.preventDefault(); // Mencegah submit form utama
    handleSubmit(onSubmitFunction)(e); // Menjalankan submit khusus modal
  };

  const onSubmitFunction = (data) => {
    const columnBody = rows2.filter((row) => row.column !== null);
    const datas = {
      name: data.functionName,
      description: data.description,
      parameters: rows,
      active: "Y",
      exec_type: data.actionT,
      endpoint: data.endpoint,
      method: data.method,
      headers: data.headers,
      body_type: data.bodyT,
      body:
        actionT === "api"
          ? data.body
          : columnBody.length > 0
          ? columnBody
          : null,
      credentials: data.credentials ? data.credentials : null,
      response_filter: data.responseFilter ? data.responseFilter : null,
      response_prompt: data.responsePrompt ? data.responsePrompt : null,
      response_media_type: data.responseMediaT ? data.responseMediaT : null,
      response_media_url: data.responseMediaU ? data.responseMediaU : null,
    };
    handleFunctionS(datas);
    handleClosed();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClosed}
        backdrop="static"
        size="lg"
        centered
      >
        <form onSubmit={handleModalSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add Function Setting</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-sm-right">
                Function Name
                <span className="required">*</span>
              </label>
              <div className="col-sm-6 error-placeholder">
                <input
                  type="text"
                  {...register("functionName", {
                    required: "Function Name is required",
                    pattern: {
                      value: /^[^\d\s][\w]*$/,
                      message: "Cannot start with a number and space character",
                    },
                  })}
                  className={`form-control ${
                    errors.functionName ? "is-invalid" : ""
                  }`}
                />
                {errors.functionName && (
                  <div className="invalid-feedback">
                    {errors.functionName.message}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-sm-right">
                Description
                <span className="required">*</span>
              </label>
              <div className="col-sm-6 error-placeholder">
                <textarea
                  type="text"
                  rows={6}
                  {...register("description", {
                    required: "Description is required",
                  })}
                  className={`form-control ${
                    errors.description ? "is-invalid" : ""
                  }`}
                />
                {errors.description && (
                  <div className="invalid-feedback">
                    {errors.description.message}
                  </div>
                )}
              </div>
            </div>
            <div className="subheader-container">
              <h4 className="subheader-title">Parameter</h4>
              <div className="horizontal-line"></div>
            </div>
            <div className="mb-3 row">
              <div className="table-like">
                {/* Header */}
                <div className="table-row">
                  <div className="table-header">Name</div>
                  <div className="table-header">Type</div>
                  <div className="table-header">Description</div>
                  <div className="table-header">Enum</div>
                  <div className="table-header">Required</div>
                  <div className="table-header">Action</div>
                </div>

                {/* Input Rows */}
                {rows.map((row, index) => (
                  <div className="table-row" key={index}>
                    {/* Name Input */}
                    <div className="table-cell">
                      <input
                        type="text"
                        className="form-control"
                        value={row.name}
                        onChange={(e) =>
                          handleInputChange(index, "name", e.target.value)
                        }
                      />
                    </div>
                    {/* Type Select Option */}
                    <div className="table-cell">
                      <select
                        className="form-select"
                        value={row.type}
                        onChange={(e) =>
                          handleInputChange(index, "type", e.target.value)
                        }
                      >
                        <option value="string">String</option>
                        <option value="number">Number</option>
                        <option value="boolean">Boolean</option>
                        <option value="array">Array</option>
                        <option value="object">Object</option>
                      </select>
                    </div>
                    {/* Description Input */}
                    <div className="table-cell">
                      <textarea
                        className="form-control"
                        value={row.description}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "description",
                            e.target.value
                          )
                        }
                        rows={4}
                      />
                    </div>
                    {/* Enum Input */}
                    <div className="table-cell">
                      <div>
                        <TagInput
                          onTagsChange={(tags) => handleEnum(index, tags)}
                          tags={row.enum}
                        />
                      </div>
                    </div>
                    {/* Required Checkbox */}
                    <div className="table-cell">
                      <input
                        type="checkbox"
                        checked={row.required}
                        onChange={(e) =>
                          handleInputChange(index, "required", e.target.checked)
                        }
                      />
                    </div>
                    {/* Action Button */}
                    <div className="table-cell">
                      <a
                        className="btn btn-sm btn-danger"
                        onClick={() => handleDeleteRow(index)}
                      >
                        <FaTimes />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-3">
                <button
                  type="button"
                  className="btn btn-primary w-auto"
                  onClick={handleAddRow}
                >
                  Add Parameter
                </button>
              </div>
            </div>
            <div className="subheader-container">
              <h4 className="subheader-title">Action</h4>
              <div className="horizontal-line"></div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-sm-right">
                Action Type
                <span className="required">*</span>
              </label>
              <div className="col-sm-3 error-placeholder">
                <select
                  {...register("actionT", {
                    required: "Action Type Filter is required",
                  })}
                  onChange={handleActionT}
                  className={`form-select ${
                    errors.actionT ? "is-invalid" : ""
                  }`}
                >
                  <option value="api">API</option>
                  <option value="google-sheet">Google Sheet</option>
                </select>
                {errors.actionT && (
                  <div className="invalid-feedback">
                    {errors.actionT.message}
                  </div>
                )}
              </div>
            </div>
            <div className="subheader-container">
              <h4 className="subheader-title">Request Settings</h4>
              <div className="horizontal-line"></div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-sm-right">
                {actionT === "api" ? "Endpoint" : "Spreadsheet Name"}
                <span className="required">*</span>
              </label>
              <div className="col-sm-6 error-placeholder">
                <input
                  type="text"
                  {...register("endpoint", {
                    required:
                      actionT === "api"
                        ? "Endpoint is required"
                        : "Spreadsheet Name is required",
                  })}
                  className={`form-control ${
                    errors.endpoint ? "is-invalid" : ""
                  }`}
                />
                {errors.endpoint && (
                  <div className="invalid-feedback">
                    {errors.endpoint.message}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-sm-right">
                Method
                <span className="required">*</span>
              </label>
              <div className="col-sm-3 error-placeholder">
                <select
                  {...register("method", {
                    required: "Method is required",
                  })}
                  className={`form-select ${errors.method ? "is-invalid" : ""}`}
                >
                  {actionT === "api" ? (
                    <>
                      <option value="GET">GET</option>
                      <option value="POST">POST</option>
                      <option value="PUT">PUT</option>
                      <option value="DELETE">DELETE</option>
                      <option value="PATCH">PATCH</option>
                    </>
                  ) : (
                    <>
                      <option value="INSERT">INSERT</option>
                      <option value="READ">READ</option>
                    </>
                  )}
                </select>
                {errors.method && (
                  <div className="invalid-feedback">
                    {errors.method.message}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-sm-right">
                Headers
              </label>
              <div className="col-sm-6 error-placeholder">
                <input
                  type="text"
                  {...register("headers")}
                  className={`form-control ${
                    errors.headers ? "is-invalid" : ""
                  }`}
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-sm-right">
                {actionT === "api" ? "Body Type" : "Sheet Name"}
                <span className="required">*</span>
              </label>
              {actionT === "api" ? (
                <div className="col-sm-3 error-placeholder">
                  <select
                    {...register("bodyT", {
                      required: "Body Type is required",
                    })}
                    className={`form-select ${
                      errors.bodyT ? "is-invalid" : ""
                    }`}
                  >
                    <option value="json">JSON</option>
                    <option value="form-data">FORM-DATA</option>
                    <option value="raw">RAW</option>
                    <option value="xml">XML</option>
                    <option value="binary">BINARY</option>
                    <option value="x-www-url-encode">X-WWW-URL-ENCODE</option>
                  </select>
                  {errors.bodyT && (
                    <div className="invalid-feedback">
                      {errors.bodyT.message}
                    </div>
                  )}
                </div>
              ) : (
                <div className="col-sm-6 error-placeholder">
                  <input
                    type="text"
                    {...register("bodyT", {
                      required: "Sheet Name is required",
                    })}
                    className={`form-control ${
                      errors.bodyT ? "is-invalid" : ""
                    }`}
                  />
                  {errors.bodyT && (
                    <div className="invalid-feedback">
                      {errors.bodyT.message}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-sm-right">
                Body
              </label>
              <div className="col-sm-6 error-placeholder">
                {actionT === "api" ? (
                  <textarea
                    type="text"
                    rows={6}
                    {...register("body")}
                    className={`form-control ${
                      errors.body ? "is-invalid" : ""
                    }`}
                  />
                ) : (
                  <>
                    <div className="table-like">
                      {/* Header */}
                      <div className="table-row">
                        <div className="table-header">Column</div>
                        <div className="table-header">Value</div>
                        <div className="table-header">Action</div>
                      </div>
                      {/* Input Rows */}
                      {rows2.map((row2, index) => (
                        <div className="table-row" key={index}>
                          {/* Name Input */}
                          <div className="table-cell">
                            <input
                              type="text"
                              className="form-control"
                              value={row2.column}
                              onChange={(e) =>
                                handleInputChange2(
                                  index,
                                  "column",
                                  e.target.value
                                )
                              }
                              style={{ width: "200px" }}
                            />
                          </div>
                          {/* Type Select Option */}
                          <div className="table-cell">
                            <select
                              className="form-select"
                              value={row2.value}
                              onChange={(e) =>
                                handleInputChange2(
                                  index,
                                  "value",
                                  e.target.value
                                )
                              }
                              style={{ width: "120px" }}
                            >
                              {rows.map((row) => (
                                <option value={`$otokata.${row.name}`}>
                                  {row.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          {/* Action Button */}
                          <div className="table-cell">
                            <a
                              className="btn btn-sm btn-danger"
                              onClick={() => handleDeleteColumn(index)}
                            >
                              <FaTimes />
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="mt-3">
                      <button
                        type="button"
                        className="btn btn-primary w-auto"
                        onClick={handleAddColumn}
                      >
                        Add Column
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-sm-right">
                Credentials
              </label>
              <div className="col-sm-6 error-placeholder">
                <input
                  type="text"
                  {...register("credentials")}
                  className={`form-control ${
                    errors.credentials ? "is-invalid" : ""
                  }`}
                />
                {errors.credentials && (
                  <div className="invalid-feedback">
                    {errors.credentials.message}
                  </div>
                )}
              </div>
            </div>
            <div className="subheader-container">
              <h4 className="subheader-title">Response Settings</h4>
              <div className="horizontal-line"></div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-sm-right">
                Response Filter
              </label>
              <div className="col-sm-6 error-placeholder">
                <input
                  type="text"
                  {...register("responseFilter")}
                  className={`form-control ${
                    errors.responseFilter ? "is-invalid" : ""
                  }`}
                />
                {errors.responseFilter && (
                  <div className="invalid-feedback">
                    {errors.responseFilter.message}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-sm-right">
                Response Prompt
              </label>
              <div className="col-sm-6 error-placeholder">
                <textarea
                  type="text"
                  rows={6}
                  {...register("responsePrompt")}
                  className={`form-control ${
                    errors.responsePrompt ? "is-invalid" : ""
                  }`}
                />
                {errors.responsePrompt && (
                  <div className="invalid-feedback">
                    {errors.responsePrompt.message}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-sm-right">
                Response Media Type
              </label>
              <div className="col-sm-3 error-placeholder">
                <select
                  {...register("responseMediaT")}
                  className={`form-select ${
                    errors.responseMediaT ? "is-invalid" : ""
                  }`}
                >
                  <option value="">- SELECT -</option>
                  <option value="text">TEXT</option>
                  <option value="image">IMAGE</option>
                  <option value="audio">AUDIO</option>
                  <option value="video">VIDEO</option>
                  <option value="document">DOCUMENT</option>
                </select>
                {errors.responseMediaT && (
                  <div className="invalid-feedback">
                    {errors.responseMediaT.message}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-sm-right">
                Response Media URL
              </label>
              <div className="col-sm-6 error-placeholder">
                <input
                  type="text"
                  {...register("responseMediaU")}
                  className={`form-control ${
                    errors.responseMediaU ? "is-invalid" : ""
                  }`}
                />
                {errors.responseMediaU && (
                  <div className="invalid-feedback">
                    {errors.responseMediaU.message}
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Cancel
            </Button>
            <button className="btn btn-success" type="submit">
              Save Changes
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
