import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import axios from "axios";
import { API_URL } from "../../utilities/url";
import logo from "../../assets/logo.png";
import authHeader from "../../services/auth.header";
import CryptoJS from "crypto-js";
import { FaGoogle } from "react-icons/fa";

var $ = require("jquery");

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [alertMessage, setAlertMessage] = useState("");
  const [hideAlert, setHideAlert] = useState(true);
  const [hidePassword, setHidePassword] = useState("password");
  const [hidePassword2, setHidePassword2] = useState("password");

  const [state, setState] = useState({
    email: "",
    password: "",
    confirm_password: "",
    firstname: "",
    lastname: "",
    dob: "",
    country: "",
    msisdn: "",
  });

  useEffect(() => {
    if (location.state) {
      delete location.state;
    }
  }, []);

  /* Form Submit */
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: "onBlur",
  });

  const password = useRef({});
  password.current = watch("password", "");
  const validatePassword = (value) => {
    const hasUppercase = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpchar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);
    if (!hasUppercase || !hasNumber || !hasSpchar) {
      return false;
    }
    return true;
  };
  const validateConfirmPassword = (value) => {
    return value === password.current;
  };
  const handleChangePass = (event) => {
    const value = event.target.value;
    value.length >= 8
      ? $("#pass-length").removeClass("invalid").addClass("valid")
      : $("#pass-length").removeClass("valid").addClass("invalid");
    /[A-Z]/.test(value)
      ? $("#pass-upper").removeClass("invalid").addClass("valid")
      : $("#pass-upper").removeClass("valid").addClass("invalid");
    /\d/.test(value)
      ? $("#pass-number").removeClass("invalid").addClass("valid")
      : $("#pass-number").removeClass("valid").addClass("invalid");
    /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value)
      ? $("#pass-spchar").removeClass("invalid").addClass("valid")
      : $("#pass-spchar").removeClass("valid").addClass("invalid");
  };
  const validateEmail = async (value) => {
    const response = await axios.post(API_URL + "exist_user_v2", {
      email: value,
    });
    const isEmailTaken = response.data;
    if (!isEmailTaken) {
      return false;
    }
    return true;
  };

  const onSubmit = (data) => {
    axios
      .post(API_URL + "register", data)
      .then((response) => {
        if (response.data.success === true) {
          navigate("/register-success");
        }
      })
      .catch((error) => {
        console.log("There was an error!", error);
      });
  };

  const registerGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `${response.token_type} ${response.access_token}`,
          },
        })
        .then((response) => {
          /* Post to API */
          axios
            .post(
              API_URL + "exist_user",
              { email: response.data.email },
              {
                headers: authHeader(),
              }
            )
            .then((res) => {
              if (res.data.success === true) {
                setAlertMessage("Email has been registered.");
                setHideAlert(false);
              } else {
                navigate("/create-password", {
                  state: {
                    ec: CryptoJS.AES.encrypt(
                      response.data.email,
                      "email"
                    ).toString(),
                    // password: data.password,
                    fc: CryptoJS.AES.encrypt(
                      response.data.given_name,
                      "firstname"
                    ).toString(),
                    lc: CryptoJS.AES.encrypt(
                      response.data.family_name,
                      "lastname"
                    ).toString(),
                    pc: CryptoJS.AES.encrypt(
                      response.data.picture,
                      "picture"
                    ).toString(),
                  },
                });
              }
            })
            .catch((error) => {
              console.log("There was an error!", error);
            });
        })
        .catch((error) => {
          console.log("There was an error!", error);
        });
    },
  });

  /* Function */
  const togglePassword = () => {
    if (hidePassword === "password") {
      setHidePassword("text");
      return;
    }
    setHidePassword("password");
  };
  const togglePassword2 = () => {
    if (hidePassword2 === "password") {
      setHidePassword2("text");
      return;
    }
    setHidePassword2("password");
  };

  const handlePaste = (e) => {
    e.preventDefault();
  };

  return (
    <div className="main d-flex justify-content-center w-100 bg-full-login">
      <main className="content d-flex p-4">
        <div className="container d-flex flex-column">
          <Link to="/" style={{ width: 100 }}>
            <img
              className="img-logo-login d-none d-sm-block"
              src={logo}
              alt="logo"
            />
          </Link>
          <div className="row h-100">
            <div className="col-sm-10 col-md-8 col-lg-5 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center ms-2 me-2 mb-3">
                  <img
                    className="img-logo-login d-md-none d-lg-none"
                    src={logo}
                    alt="logo"
                  />
                </div>
                <div className="card bg-white-transparent rounded-4">
                  <div className="text-center mt-4">
                    <h4 className="h4 text-orange">Create an Account</h4>
                  </div>
                  <div className="card-body pt-0">
                    <div className="m-sm-5">
                      <div
                        className={`alert alert-danger alert-dismissible ${
                          hideAlert ? "d-none" : "d-block"
                        }`}
                        role="alert"
                      >
                        <button
                          onClick={() => setHideAlert(true)}
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                        ></button>
                        <div className="alert-message">
                          <strong className="text-danger">
                            {alertMessage}
                          </strong>
                        </div>
                      </div>
                      <div className="mt-3">
                        <a
                          onClick={() => registerGoogle()}
                          className="btn btn-lg btn-secondary-fill w-100 waves-effect waves-light"
                        >
                          <FaGoogle className="me-2" size={18} /> Register with
                          Google
                        </a>
                      </div>
                      <div className="separator-or mt-2 mb-2">
                        <h6 className="text-dark">OR</h6>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3 error-placeholder">
                          <label className="form-label text-dark">
                            First Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.name_first ? "is-invalid" : ""
                            }`}
                            name="name_first"
                            {...register("name_first", {
                              required: true,
                            })}
                          />
                          {errors.name_first &&
                            errors.name_first.type === "required" && (
                              <div className="invalid-feedback">
                                First Name is required.
                              </div>
                            )}
                        </div>
                        <div className="mb-3 error-placeholder">
                          <label className="form-label text-dark">
                            Last Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.name_last ? "is-invalid" : ""
                            }`}
                            name="name_last"
                            {...register("name_last", {
                              required: true,
                            })}
                          />
                          {errors.name_last &&
                            errors.name_last.type === "required" && (
                              <div className="invalid-feedback">
                                Last Name is required.
                              </div>
                            )}
                        </div>
                        <div className="mb-3 error-placeholder">
                          <label className="form-label text-dark">
                            Email
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className={`form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            type="text"
                            name="email"
                            {...register("email", {
                              required: true,
                              pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                            })}
                          />
                          {errors.email && errors.email.type === "required" && (
                            <div className="invalid-feedback">
                              Email is required.
                            </div>
                          )}
                          {errors.email && errors.email.type === "pattern" && (
                            <div className="invalid-feedback">
                              Email is not valid.
                            </div>
                          )}
                        </div>
                        <div className="mb-3 error-placeholder">
                          <label className="form-label text-dark">
                            Password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <input
                              type={hidePassword}
                              name="password"
                              autoComplete="on"
                              className={`form-control password-input ${
                                errors.password ? "is-invalid" : ""
                              }`}
                              {...register("password", {
                                required: true,
                                minLength: 8,
                                validate: validatePassword,
                              })}
                              onChange={handleChangePass}
                            />
                            {errors.password &&
                              errors.password.type === "required" && (
                                <div className="invalid-feedback">
                                  Password is required.
                                </div>
                              )}
                            {errors.password &&
                              errors.password.type === "minLength" && (
                                <div className="invalid-feedback">
                                  Password min 8 character.
                                </div>
                              )}
                            {errors.password &&
                              errors.password.type === "validate" && (
                                <div className="invalid-feedback">
                                  Password must contain at least uppercase
                                  letter, number and special character.
                                </div>
                              )}
                            <a
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                              // href="#"
                              onClick={togglePassword}
                            >
                              {hidePassword === "password" ? (
                                <RiEyeFill size={20} />
                              ) : (
                                <RiEyeOffFill size={20} />
                              )}
                            </a>
                          </div>
                        </div>
                        <div className="mb-3 error-placeholder">
                          <label className="form-label text-dark">
                            Confirm Password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <input
                              type={hidePassword2}
                              name="cpassword"
                              autoComplete="off"
                              onPaste={handlePaste}
                              className={`form-control password-input ${
                                errors.cpassword ? "is-invalid" : ""
                              }`}
                              {...register("cpassword", {
                                required: true,
                                minLength: 8,
                                validate: validateConfirmPassword,
                              })}
                            />
                            {errors.cpassword &&
                              errors.cpassword.type === "required" && (
                                <div className="invalid-feedback">
                                  Password is required.
                                </div>
                              )}
                            {errors.cpassword &&
                              errors.cpassword.type === "minLength" && (
                                <div className="invalid-feedback">
                                  Password min 8 character.
                                </div>
                              )}
                            {errors.cpassword &&
                              errors.cpassword.type === "validate" && (
                                <div className="invalid-feedback">
                                  Password don't match.
                                </div>
                              )}
                            <a
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                              // href="#"
                              onClick={togglePassword2}
                            >
                              {hidePassword2 === "password" ? (
                                <RiEyeFill size={20} />
                              ) : (
                                <RiEyeOffFill size={20} />
                              )}
                            </a>
                          </div>
                        </div>
                        <div
                          id="password-contain"
                          style={{ display: "block" }}
                          className="p-3 bg-light mb-2 rounded"
                        >
                          <h5 className="fs-13">Password must contain:</h5>
                          <p id="pass-length" className="invalid fs-12 mb-2">
                            Minimun <b>8 characters</b>
                          </p>
                          <p id="pass-upper" className="invalid fs-12 mb-2">
                            At least one <b>uppercase letter</b> (A-Z)
                          </p>
                          <p id="pass-number" className="invalid fs-12 mb-2">
                            At least one number <b>number</b> (0-9)
                          </p>
                          <p id="pass-spchar" className="invalid fs-12 mb-0">
                            At least one
                            <b>special character</b> (!@#$%^*|,./? etc.)
                          </p>
                        </div>
                        <div className="mt-4">
                          <button
                            className="btn btn-lg btn-success w-100"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>
                        <div className="mt-4 text-center">
                          <p className="mb-0 text-dark">
                            Already have an account?{" "}
                            <NavLink
                              to="/login"
                              className="fw-semibold text-primary text-decoration-underline link-underline-opacity-100-hover"
                            >
                              {" "}
                              Sign In{" "}
                            </NavLink>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Register;
