import { React, useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { API_URL } from "../../utilities/url";
import axios from "axios";
import AuthService from "../../services/auth.service";
import AuthHeader from "../../services/auth.header";
import { useForm } from "react-hook-form";
import CryptoJS from "crypto-js";
import storage from "../../services/storage";

const AddMember = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const decryptWS = CryptoJS.AES.decrypt(
    storage.get("workspace"),
    "workspace"
  ).toString(CryptoJS.enc.Utf8);
  const workspace = JSON.parse(decryptWS);

  useEffect(() => {}, [location.key]);

  const [state, setState] = useState({
    email: "",
    password: "",
    name: "",
    address: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //Form validate
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: "onBlur",
  });

  const regSubmit = async (data) => {
    const datas = {
      role: data.role,
      email: data.email,
      idt_user_workspace: workspace.idt_user_workspace,
    };
    axios
      .post(API_URL + "add-user-group", datas, {
        headers: AuthHeader(),
      })
      .then((response) => {
        if (response.data.success === true) {
          localStorage.setItem("alert", true);
          navigate("/workspace-member");
        } else {
          if (
            response.data.message === "Failed to store user, email has exist"
          ) {
            toast.error("Email has exist !", {
              position: "top-right",
              theme: "colored",
              transition: Slide,
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
            });
          } else {
            toast.error(response.data.message, {
              position: "top-right",
              theme: "colored",
              transition: Slide,
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
            });
          }
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 401") {
          AuthService.logout();
          navigate("/login");
        }
      });
  };
  return (
    <div className="container-fluid p-0">
      <ToastContainer />
      <div className="row mb-2 mb-xl-3">
        <div className="col-auto">
          <h5>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/workspace-member">Workspace Member</Link>
                </li>
                <li className="breadcrumb-item active">Add Member</li>
              </ol>
            </nav>
          </h5>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card rounded-4 shadow-lg">
            <div className="card-body">
              <form onSubmit={handleSubmit(regSubmit)}>
                <div className="row">
                  <div className="mb-3 col-md-3">
                    <label className="form-label">Email</label>
                    <span className="required">*</span>
                    <input
                      type="text"
                      {...register("email", {
                        required: true,
                        pattern: {
                          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: "Invalid email address",
                        },
                      })}
                      className="form-control"
                      placeholder="Email"
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p className="errors">{errors.email.message}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-3">
                    <label className="form-label">Role</label>
                    <span className="required">*</span>
                    <select
                      {...register("role", { required: "Role is required" })}
                      className="form-select"
                      onChange={(e) =>
                        setState({ ...state, option: e.target.value })
                      }
                    >
                      <option value="">- Choose Role -</option>
                      <option value="admin">Admin</option>
                      <option value="operator">Operator</option>
                    </select>
                    {errors.role && (
                      <p className="errors">{errors.role.message}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="mt-3 col-md-3">
                    <button type="submit" className="btn btn-info btn-md">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMember;
