import { useEffect, useState } from "react";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { Link } from "react-router-dom";

export default function ChatbotPanel() {
  const data = [
    {
      name: "Jan",
      sukses: 300,
      gagal: 2400,
      invalid: 100,
    },
    {
      name: "Feb",
      sukses: 1398,
      gagal: 2210,
      invalid: 1000,
    },
    {
      name: "Mar",
      sukses: 2000,
      gagal: 2290,
      invalid: 1300,
    },
    {
      name: "Apr",
      sukses: 3908,
      gagal: 2000,
      invalid: 3400,
    },
    {
      name: "May",
      sukses: 4800,
      gagal: 2181,
      invalid: 2234,
    },
    {
      name: "Jun",
      sukses: 3800,
      gagal: 2500,
      invalid: 2765,
    },
    {
      name: "Jul",
      sukses: 4300,
      gagal: 2100,
      invalid: 1765,
    },
    {
      name: "Aug",
      sukses: 4800,
      gagal: 2181,
      invalid: 2065,
    },
    {
      name: "Sep",
      sukses: 3800,
      gagal: 2500,
      invalid: 2205,
    },
    {
      name: "Oct",
      sukses: 4300,
      gagal: 2100,
      invalid: 2505,
    },
    {
      name: "Nov",
      sukses: 3800,
      gagal: 2500,
      invalid: 2705,
    },
    {
      name: "Des",
      sukses: 4300,
      gagal: 2100,
      invalid: 3205,
    },
  ];

  const data2 = [
    { name: "Positif", value: 40 },
    { name: "Netral", value: 30 },
    { name: "Negatif", value: 20 },
    { name: "Unsubs", value: 10 },
  ];

  const colors = ["#00c49f", "#fb8500", "#fc003b", "#0088fe"];

  const data3 = [
    { name: "Completed", value: 40 }, // Nilai progress
    { name: "Remaining", value: 100 - 40 }, // Sisa dari progress
  ];

  const colors2 = ["#fb8500", "#fee7cf"];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card rounded-4 shadow-lg">
          <div className="card-body p-4">
            <div className="row">
              <div className="col-md-3 custom-border-col">
                <h5 className="card-title">Chatbot Interactions Today</h5>
                <div className="d-flex justify-content-between">
                  <p className="mb-0">Subscription:</p>
                  <p className="mb-0">50 Chat Interactions</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mb-0">Free:</p>
                  <p className="mb-0">230 Chat Interactions</p>
                </div>
              </div>
              <div className="col-md-3 custom-border-col">
                <h5 className="card-title">New Member Today</h5>
                <div className="d-flex justify-content-between">
                  <p className="mb-0">Subscription</p>
                  <p className="mb-0">50 New Member</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mb-0">Free</p>
                  <p className="mb-0">230 New Member</p>
                </div>
              </div>
              <div className="col-md-3 custom-border-col">
                <h5 className="card-title">Exit Member Today</h5>
                <div className="d-flex justify-content-between">
                  <p className="mb-0">Subscription</p>
                  <p className="mb-0">50 Exit Member</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mb-0">Free</p>
                  <p className="mb-0">230 Exit Member</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-md-flex justify-content-between">
                  <div className="flex-grow-1 me-md-2">
                    {" "}
                    {/* Tambahkan flex-grow-1 dan margin kanan */}
                    <h5 className="card-title">Subscription Income</h5>
                    <div className="d-flex justify-content-between">
                      <p className="mb-0">This Month </p>
                      <p className="mb-0">IDR 23.000</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="mb-0">Total Balance</p>
                      <p className="mb-0">IDR 223.000</p>
                    </div>
                  </div>
                  <div className="d-md-flex flex-column custom-border-button">
                    <Link
                      to="/transaction"
                      className="btn btn-primary mb-2 w-100"
                      style={{ background: "#13213c" }}
                    >
                      <LiaFileInvoiceSolid size="20" className="me-1" />
                      Transaction
                    </Link>
                    <a
                      className="btn btn-primary w-100"
                      style={{ background: "#13213c" }}
                    >
                      <HiOutlineCurrencyDollar size="20" className="me-1" />
                      Withdraw
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
