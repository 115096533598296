import React, { createContext, useState } from "react";

export const MetadataContext = createContext();

export const MetadataProvider = ({ children }) => {
  const [metadataCache, setMetadataCache] = useState([]);

  return (
    <MetadataContext.Provider value={{ metadataCache, setMetadataCache }}>
      {children}
    </MetadataContext.Provider>
  );
};
