import React, { useRef, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { FaUser, FaClipboardList } from "react-icons/fa"; // Import ikon dari react-icons
import { RiCustomerService2Fill } from "react-icons/ri";
import SimpleBar from "simplebar-react";
import Ava from "../../../assets/img/avatars/avatar.jpg";
import Ava2 from "../../../assets/img/avatars/avatar-2.jpg";
import Ava3 from "../../../assets/img/avatars/avatar-3.jpg";
import Ava4 from "../../../assets/img/avatars/avatar-4.jpg";
import Ava5 from "../../../assets/img/avatars/avatar-5.jpg";

const RightComponent = () => {
  const simpleBarRef = useRef(null);
  const simpleBarRef2 = useRef(null);
  const assistantData = [
    { id: 1, name: "Assistant 1" },
    { id: 2, name: "Assistant 2" },
    { id: 1, name: "Assistant 1" },
    { id: 2, name: "Assistant 2" },
    { id: 1, name: "Assistant 1" },
    { id: 2, name: "Assistant 2" },
    { id: 1, name: "Assistant 1" },
    { id: 1, name: "Assistant 1" },
    { id: 2, name: "Assistant 2" },
    { id: 1, name: "Assistant 1" },
    { id: 2, name: "Assistant 2" },
    { id: 1, name: "Assistant 1" },
    { id: 2, name: "Assistant 2" },
    { id: 1, name: "Assistant 1" },
  ];

  const templateData = [
    { id: 1, name: "Template 1" },
    { id: 2, name: "Template 2" },
  ];

  const [key, setKey] = useState("assistant"); // Tab aktif

  return (
    <div className="row">
      <Tabs
        id="data-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 w-100"
        style={{ border: "0" }}
        fill
      >
        {/* Tab Assistant */}
        <Tab
          eventKey="assistant"
          className="mb-1"
          title={
            <>
              <RiCustomerService2Fill className="mt-1 mb-1 me-2" size={24} />
              <span className="fs-14">Profiling Assistant</span>
            </>
          }
        >
          <div className="position-relative">
            <SimpleBar
              className="chat-messages pe-3"
              ref={simpleBarRef}
              style={{ height: "calc(100vh - 400px)" }}
            >
              <div className="chat-message-right pb-4">
                <div>
                  <img
                    src={Ava}
                    className="rounded-circle me-1"
                    alt="Chris Wood"
                    width="40"
                    height="40"
                  />
                  <div className="text-muted small text-nowrap mt-2">
                    2:33 am
                  </div>
                </div>
                <div className="flex-shrink-1 bg-light rounded py-2 px-3 me-3">
                  <div className="fw-bold mb-1">You</div>
                  Lorem ipsum dolor sit amet, vis erat denique in, dicunt
                  prodesset te vix.
                </div>
              </div>

              <div className="chat-message-left pb-4">
                <div>
                  <img
                    src={Ava3}
                    className="rounded-circle me-1"
                    alt="Bertha Martin"
                    width="40"
                    height="40"
                  />
                  <div className="text-muted small text-nowrap mt-2">
                    2:34 am
                  </div>
                </div>
                <div className="flex-shrink-1 bg-light rounded py-2 px-3 ms-3">
                  <div className="fw-bold mb-1">Bertha Martin</div>
                  Sit meis deleniti eu, pri vidit meliore docendi ut, an eum
                  erat animal commodo.
                </div>
              </div>

              <div className="chat-message-right pb-4">
                <div>
                  <img
                    src={Ava}
                    className="rounded-circle me-1"
                    alt="Chris Wood"
                    width="40"
                    height="40"
                  />
                  <div className="text-muted small text-nowrap mt-2">
                    2:35 am
                  </div>
                </div>
                <div className="flex-shrink-1 bg-light rounded py-2 px-3 me-3">
                  <div className="fw-bold mb-1">You</div>
                  Cum ea graeci tractatos.
                </div>
              </div>

              <div className="chat-message-left pb-4">
                <div>
                  <img
                    src={Ava3}
                    className="rounded-circle me-1"
                    alt="Bertha Martin"
                    width="40"
                    height="40"
                  />
                  <div className="text-muted small text-nowrap mt-2">
                    2:36 am
                  </div>
                </div>
                <div className="flex-shrink-1 bg-light rounded py-2 px-3 ms-3">
                  <div className="fw-bold mb-1">Bertha Martin</div>
                  Sed pulvinar, massa vitae interdum pulvinar, risus lectus
                  porttitor magna, vitae commodo lectus mauris et velit. Proin
                  ultricies placerat imperdiet. Morbi varius quam ac venenatis
                  tempus.
                </div>
              </div>

              <div className="chat-message-left pb-4">
                <div>
                  <img
                    src={Ava3}
                    className="rounded-circle me-1"
                    alt="Bertha Martin"
                    width="40"
                    height="40"
                  />
                  <div className="text-muted small text-nowrap mt-2">
                    2:37 am
                  </div>
                </div>
                <div className="flex-shrink-1 bg-light rounded py-2 px-3 ms-3">
                  <div className="fw-bold mb-1">Bertha Martin</div>
                  Cras pulvinar, sapien id vehicula aliquet, diam velit
                  elementum orci.
                </div>
              </div>
            </SimpleBar>
          </div>

          <div
            className="flex-grow-0 py-3 px-3 border-top"
            style={{ marginLeft: "-0.8rem" }}
          >
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Type your message"
              />
              <button className="btn btn-primary">Send</button>
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default RightComponent;
