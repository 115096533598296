import { React, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { API_URL } from "../../utilities/url";
import { FiPlus } from "react-icons/fi";
import { HiLink, HiOutlineRefresh } from "react-icons/hi";
import {
  FaCog,
  FaLine,
  FaPencilAlt,
  FaSnapchatSquare,
  FaTelegramPlane,
} from "react-icons/fa";
import { base64_enc } from "../../utilities/ext-function";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { Button } from "react-bootstrap";
import { TiWarningOutline } from "react-icons/ti";
import NavLinkDevice from "./nav-link-device";
import Countdown from "react-countdown";
import storage from "../../services/storage";
import CryptoJS from "crypto-js";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdOutlineChat } from "react-icons/md";

var $ = require("jquery");
var QRCode = require("qrcode");

const Device = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
    CryptoJS.enc.Utf8
  );
  const workspace = JSON.parse(decryptWS);
  const [hideCounter, setHideCounter] = useState(false);
  const [selectedType, setSelectedType] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("all");
  let calling = null;

  useEffect(() => {
    if (localStorage.getItem("alert")) {
      toast.success("Register Device Successfull", {
        position: "top-right",
        theme: "colored",
        transition: Slide,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      localStorage.removeItem("alert");
    }
    if (localStorage.getItem("alertu")) {
      toast.success("Update Device Successfull", {
        position: "top-right",
        theme: "colored",
        transition: Slide,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      localStorage.removeItem("alertu");
    }
  }, []);

  useEffect(() => {
    dt_init();
  }, [selectedType, selectedStatus, location.key]);

  const dt_init = () => {
    $("#datatables-reponsive").DataTable({
      dom:
        "<'row'<'col-sm-6'><'col-sm-6'B>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: true,
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-table-devices",
        data: function (data) {
          data.idt_user_workspace = workspace.idt_user_workspace;
          data.type = selectedType;
          data.status = selectedStatus;
        },
        type: "post",
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            localStorage.clear();
            navigate("/login");
          }
        },
      },
      order: [[9, "desc"]],
      columnDefs: [
        {
          className: "dt-center",
          targets: [5, 6, 7],
        },
        {
          targets: 0,
          createdCell: (td, cellData, rowData, row, col) => {
            const iconMap = {
              WHATSAPP: (
                <IoLogoWhatsapp
                  size={18}
                  color="#25D366"
                  className="me-1"
                  style={{ marginTop: "-2px" }}
                />
              ),
              TELEGRAM: (
                <FaTelegramPlane
                  size={18}
                  color="#0088CC"
                  className="me-1"
                  style={{ marginTop: "-2px" }}
                />
              ),
              LINE: (
                <FaLine
                  size={18}
                  color="#06C655"
                  className="me-1"
                  style={{ marginTop: "-2px" }}
                />
              ),
              SNAPCHAT: (
                <FaSnapchatSquare
                  size={18}
                  color="#F7F400"
                  className="me-1"
                  style={{ marginTop: "-2px" }}
                />
              ),
              DEFAULT: (
                <MdOutlineChat
                  size={18}
                  color="#FCA311"
                  className="me-1"
                  style={{ marginTop: "-2px" }}
                />
              ),
            };

            const icon = iconMap[rowData.type_device] || iconMap.DEFAULT;

            ReactDOM.render(
              <>
                {icon}
                {cellData}
              </>,
              td
            );
          },
        },
        {
          targets: 9,
          createdCell: (td, cellData, rowData, row, col) =>
            ReactDOM.render(
              <div className="d-grid gap-2 d-md-flex justify-content-md">
                <a
                  onClick={() => editTable(cellData)}
                  className="btn btn-sm btn-primary btn-rounded"
                >
                  <FaPencilAlt />
                </a>
                <a
                  onClick={() =>
                    showModalTable(
                      rowData.idt_device,
                      rowData.idt_user,
                      rowData.idt_user_workspace,
                      rowData.state_dev,
                      rowData.status_dev,
                      rowData.name,
                      rowData.msisdn,
                      rowData.type_device
                    )
                  }
                  data-bs-toggle="modal"
                  data-bs-target="#centeredModal"
                  className="btn btn-sm btn-success btn-rounded"
                >
                  <FaCog />
                </a>
                {/* <a
                  onClick={() => deleteTable(cellData)}
                  className="btn btn-sm btn-tertiary btn-rounded"
                >
                  <FaTrash />
                </a> */}
              </div>,
              td
            ),
        },
      ],
      columns: [
        {
          data: "name",
        },
        {
          data: "user.user_profile.name_first",
          render: function (data, type, row) {
            var data = data + " " + row.user.user_profile.name_last;

            return data;
          },
        },
        { data: "type_service" },
        { data: "msisdn" },
        { data: "device_group.name" },
        { data: "quota" },
        { data: "min_sending_period" },
        { data: "max_sending_period" },
        {
          data: "state_dev",
          render: function (data, type, row) {
            if (data == "INACTIVE") {
              var data = "<span class='badge bg-danger'>" + data + "</span>";
            } else if (data == "LOGGED OUT") {
              var data = "<span class='badge bg-warning'>" + data + "</span>";
            } else {
              var data = "<span class='badge bg-success'>" + data + "</span>";
            }

            return data;
          },
        },
        {
          data: "idt_device",
        },
      ],
      initComplete: function () {
        var api = this.api();
        $("#btn-dt-search")
          .off(".DT")
          .on("click.DT", function (e) {
            api.search($("#dt-search").val()).draw();
          });
        $("#btn-dt-reload")
          .off(".DT")
          .on("click.DT", function (e) {
            $("#dt-search").val(null);
            api.search($("#dt-search").val()).draw();
          });
      },
    });
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  function editTable(data) {
    navigate("/device/edit/" + base64_enc(data));
  }

  function reloadTable() {
    $("#datatables-reponsive").DataTable().ajax.reload(null, false);
  }

  function connectQR(
    idt_device,
    idt_user,
    idt_user_workspace,
    state_dev,
    status_dev,
    name,
    msisdn,
    type_device
  ) {
    // Init
    $(".skl-qr").show();
    $("#warning-qr").hide();
    $("#canvas").hide();
    $("#regen-qr").hide();
    $("#countdown").hide();
    $("#qr-title").html(name + " - " + msisdn);
    setHideCounter(false);
    axios
      .post(API_URL + "connect-device", {
        idt_device: idt_device,
        idt_user_workspace: idt_user_workspace,
        name: name,
        state_dev: state_dev,
        status_dev: status_dev,
        idt_user: idt_user,
      })
      .then((res) => {
        if (res.data.errorcode == "0000") {
          var canvas = document.getElementById("canvas");
          if (type_device == "WHATSAPP") {
            QRCode.toCanvas(
              canvas,
              res.data.data,
              {
                color: {
                  dark: "#25D366",
                  light: "#0000", // Transparent background
                },
              },
              function (error) {
                $(".skl-qr").hide();
                $("#warning-qr").hide();
                $("#canvas").show();
              }
            );
          } else {
            QRCode.toCanvas(
              canvas,
              res.data.data,
              {
                color: {
                  dark: "#0088CC",
                  light: "#0000", // Transparent background
                },
              },
              function (error) {
                $(".skl-qr").hide();
                $("#warning-qr").hide();
                $("#canvas").show();
              }
            );
          }
          // Timer
          setHideCounter(true);
          calling = setInterval(function () {
            // Check QR
            axios
              .post(API_URL + "check-device", {
                idt_device: idt_device,
                name: name,
              })
              .then((res) => {
                if (res.data.first_response.data == "Connected") {
                  $("#btn-qr-close").trigger("click");
                  reloadTable();
                  clearInterval(calling);
                }
              })
              .catch((error) => {});
          }, 2000);
        } else {
          $(".skl-qr").hide();
          $("#warning-qr").show();
          $("#regen-qr").show();
        }
      })
      .catch((error) => {
        $(".skl-qr").hide();
        $("#warning-qr").show();
        $("#regen-qr").show();
      });
  }

  function disconnectQR(
    idt_device,
    idt_user,
    idt_user_workspace,
    state_dev,
    status_dev,
    name,
    msisdn,
    type_device
  ) {
    // Init
    $(".skl-qr").hide();
    $("#warning-qr").hide();
    $("#canvas").hide();
    $("#regen-qr").hide();
    $("#countdown").hide();
    $("#qr-title").html(name + " - " + msisdn);
    axios
      .post(API_URL + "disconnect-device", {
        idt_device: idt_device,
        idt_user_workspace: idt_user_workspace,
        name: name,
        idt_user: idt_user,
      })
      .then((res) => {
        $("#btn-qr-close").trigger("click");
        reloadTable();
      })
      .catch((error) => {});
  }

  function showModalTable(
    idt_device,
    idt_user,
    idt_user_workspace,
    state_dev,
    status_dev,
    name,
    msisdn,
    type_device
  ) {
    // Init
    $(".skl-qr").hide();
    $("#warning-qr").hide();
    $("#canvas").hide();
    $("#regen-qr").hide();
    $("#danger-qr").hide();
    $("#success-qr").hide();
    $("#disconnect-qr").hide();
    $("#countdown").hide();
    $("#qr-title").html(name + " - " + msisdn);
    $("#dd_device").val(idt_device);
    $("#dd_user").val(idt_user);
    $("#dd_workspace").val(idt_user_workspace);
    $("#dd_state_dev").val(state_dev);
    $("#dd_status_dev").val(status_dev);
    $("#dd_name").val(name);
    $("#dd_msisdn").val(msisdn);
    $("#dd_type_device").val(type_device);
    setHideCounter(false);

    // Check QR
    axios
      .post(API_URL + "check-device", {
        idt_device: idt_device,
        name: name,
      })
      .then((res) => {
        if (res.data.first_response.data == "Connected") {
          $(".skl-qr").hide();
          $("#warning-qr").hide();
          $("#danger-qr").hide();
          $("#success-qr").show();
          $("#success-qr").show();
          $("#disconnect-qr").show();
        } else {
          $(".skl-qr").hide();
          $("#warning-qr").show();
          $("#regen-qr").show();
          $("#danger-qr").hide();
          $("#success-qr").hide();
          $("#disconnect-qr").hide();
        }
        reloadTable();
      })
      .catch((error) => {
        $(".skl-qr").hide();
        $("#warning-qr").show();
        $("#regen-qr").show();
      });
  }

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      // Render a completed state
      $("#regen-qr").show();
    } else {
      // Render a countdown
      return <span>{seconds == 0 ? "" : seconds + " second remaining"}</span>;
    }
  };

  $(document).on("click", "#btn-qr-close", function () {
    clearInterval(calling);
  });

  return (
    <div className="container-fluid p-0">
      <NavLinkDevice />
      <div className="row">
        <div className="col-12">
          <div className="card rounded-4 shadow-lg">
            <div className="card-header rounded-4">
              <div className="float-start">
                <h5 className="card-title mt-2">Device</h5>
                <ToastContainer />
              </div>
            </div>
            <div className="card-body table-responsive">
              <div className="d-block mb-3">
                <div className="d-inline-block mt-1 mb-2">
                  <div className="d-flex align-items-center">
                    {/* Search Input and Buttons */}
                    <div className="input-group input-group-navbar border me-2">
                      <input
                        type="text"
                        id="dt-search"
                        className="form-control form-control-sm h-auto"
                        placeholder="Search"
                        aria-label="Search"
                      />
                      <button
                        className="btn btn-light h-auto"
                        id="btn-dt-search"
                        style={{ backgroundColor: "#e2e2e2" }}
                      >
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="align-middle"
                          height="16"
                          width="16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="11" cy="11" r="8"></circle>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                      </button>
                      <button
                        className="btn btn-secondary h-auto"
                        id="btn-dt-reload"
                        style={{
                          backgroundColor: "#e2e2e2",
                          borderLeft: "2px solid #f3f6fb",
                          color: "#fff",
                        }}
                      >
                        <HiOutlineRefresh size={15} />
                      </button>
                    </div>

                    {/* Dropdown (Select) */}
                    <select
                      className="form-select form-select-lg me-2"
                      value={selectedType}
                      onChange={handleTypeChange}
                    >
                      <option value="all">All Type</option>
                      <option value="HYBRID">HYBRID</option>
                      <option value="BROADCAST">BROADCAST</option>
                      <option value="RECEIVER">RECEIVER</option>
                    </select>
                    <select
                      className="form-select form-select-lg"
                      value={selectedStatus}
                      onChange={handleStatusChange}
                    >
                      <option value="all">All Status</option>
                      <option value="READY">READY</option>
                      <option value="LOGGED OUT">LOGGED OUT</option>
                      <option value="INACTIVE">INACTIVE</option>
                    </select>
                  </div>
                </div>
                <div className="d-sm-block float-md-end">
                  <Link to="/device/add" className="btn btn-primary btn-md">
                    <FiPlus
                      className="align-middle"
                      size={18}
                      strokeWidth={4}
                    />{" "}
                    Add Device
                  </Link>
                </div>
              </div>
              <table
                id="datatables-reponsive"
                className="table table-striped table-responsive dt-bootstrap5 no-footer"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Owner</th>
                    <th>Type</th>
                    <th>MSISDN</th>
                    <th>Group</th>
                    <th>QPD</th>
                    <th>Min. SP</th>
                    <th>Max. SP</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <button
        type="button"
        className="btn btn-primary my-1 d-none"
        data-bs-toggle="modal"
        data-bs-target="#centeredModal"
        id="btn-modal"
      ></button>
      <div
        className="modal fade"
        id="centeredModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-sm modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="qr-title">
                Scan QR
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="btn-qr-close"
              ></button>
            </div>
            <div className="modal-body m-2">
              <div className="text-center">
                <input type="hidden" id="dd_device" />
                <input type="hidden" id="dd_user" />
                <input type="hidden" id="dd_workspace" />
                <input type="hidden" id="dd_state_dev" />
                <input type="hidden" id="dd_status_dev" />
                <input type="hidden" id="dd_name" />
                <input type="hidden" id="dd_msisdn" />
                <input type="hidden" id="dd_type_device" />
                <div id="danger-qr">
                  <TiWarningOutline size={30} />
                  <p className="mb-0 fs-14 fw-bold">Device is Not Active</p>
                </div>
                <div id="warning-qr">
                  <TiWarningOutline size={30} />
                  <p className="mb-0 fs-14 fw-bold">
                    WhatsApp Client is Not Connected
                  </p>
                </div>
                <div id="success-qr">
                  <HiLink size={30} />
                  <p className="mb-0 fs-14 fw-bold">
                    WhatsApp Client is Connected
                  </p>
                </div>
                <Skeleton height={246} className="w-75 mb-4 skl-qr" />
                <canvas id="canvas" style={{ marginTop: -30 }}></canvas>
                <div>
                  <p className="fs-14 mb-0">
                    {hideCounter == true ? (
                      <Countdown
                        date={Date.now() + 20000}
                        renderer={renderer}
                      />
                    ) : null}
                  </p>
                  <Button
                    variant="success"
                    id="regen-qr"
                    onClick={() =>
                      connectQR(
                        $("#dd_device").val(),
                        $("#dd_user").val(),
                        $("#dd_workspace").val(),
                        $("#dd_state_dev").val(),
                        $("#dd_status_dev").val(),
                        $("#dd_name").val(),
                        $("#dd_msisdn").val(),
                        $("#dd_type_device").val()
                      )
                    }
                  >
                    Connect
                  </Button>
                  <Button
                    variant="danger"
                    id="disconnect-qr"
                    onClick={() =>
                      disconnectQR(
                        $("#dd_device").val(),
                        $("#dd_user").val(),
                        $("#dd_workspace").val(),
                        $("#dd_state_dev").val(),
                        $("#dd_status_dev").val(),
                        $("#dd_name").val(),
                        $("#dd_msisdn").val(),
                        $("#dd_type_device").val()
                      )
                    }
                  >
                    Disconnect
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Device;
