import React, { useState } from "react";
import { FaTimes } from "react-icons/fa"; // Untuk ikon hapus

function TagInput({ tags = [], onTagsChange, onDelete = true }) {
  const [inputValue, setInputValue] = useState("");

  // Mengubah nilai input
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Menambahkan tag ketika Enter ditekan
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      const trimmedValue = inputValue.trim();
      if (!tags.includes(trimmedValue)) {
        onTagsChange([...tags, trimmedValue]);
        setInputValue(""); // Kosongkan input setelah tag ditambahkan
      }
    }
  };

  // Menghapus tag berdasarkan indeksnya
  const handleRemoveTag = (indexToRemove) => {
    const newTags = tags.filter((_, index) => index !== indexToRemove);
    onTagsChange(newTags);
  };

  return (
    <div className="tag-input-container" style={{ padding: 0, border: 0 }}>
      <ul className="tags">
        {tags.map((tag, index) =>
          onDelete === true ? (
            <li
              key={index}
              className="tag bg-primary"
              style={{ margin: "0 5px 5px 0" }}
            >
              {tag}
              <button
                type="button"
                className="btn btn-sm"
                onClick={() => handleRemoveTag(index)}
              >
                <FaTimes size={11} />
              </button>
            </li>
          ) : (
            <li key={index} className="tag2 bg-primary">
              {tag}
            </li>
          )
        )}
        {onDelete && (
          <li className="tag-input">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Type and press Enter"
              className="form-control"
              style={{
                padding: "0.25rem 0.7rem",
                border: "1px solid #ced4da",
              }}
            />
          </li>
        )}
      </ul>
    </div>
  );
}

export default TagInput;
