import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../../utilities/url";
import axios from "axios";
import AuthHeader from "../../services/auth.header";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Controller, useForm } from "react-hook-form";
import {
  FaPenAlt,
  FaPencilAlt,
  FaRegCopy,
  FaTimes,
  FaTrash,
  FaTrashAlt,
} from "react-icons/fa";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import CustomStyles from "../../components/customStyles";
import { ThemeContext } from "../../provider/themeContext";
import storage from "../../services/storage";
import CryptoJS from "crypto-js";
import AddFunction from "./component/addFunction";
import EditFunction from "./component/editFunction";
import { v4 as uuidv4 } from "uuid";
import { ToastrContext } from "../../provider/toastrContext";

var $ = require("jquery");

const DetailChatbot = () => {
  const { showCopy } = useContext(ToastrContext);
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const decryptWS = CryptoJS.AES.decrypt(
    storage.get("workspace"),
    "workspace"
  ).toString(CryptoJS.enc.Utf8);
  const workspace = JSON.parse(decryptWS);
  const [hideSpiner, setHideSpiner] = useState(true);
  const [load, setLoad] = useState(false);
  const dt_user = JSON.parse(localStorage.getItem("user"));
  const [assistant, setAssistant] = useState([]);
  const [functionS, setFunctionS] = useState([]);
  const [delFunctionS, setDelFunctionS] = useState([]);
  const [device, setDevice] = useState([]);
  const [leftItems, setLeftItems] = useState([]);
  const [rightItems, setRightItems] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get Assistant
        const assistantResponse = await axios.post(
          `${API_URL}get-personal-assistant-list`,
          { idt_workspace: workspace.idt_user_workspace, limit: 500, page: 1 },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        );
        if (assistantResponse.data.success === true) {
          const assistantData = assistantResponse.data.data.data.map((d) => ({
            value: d.idt_user_assistant,
            label: d.name,
          }));
          setAssistant(assistantData);
        }

        // Get Device
        const deviceResponse = await axios.post(
          `${API_URL}merged-device`,
          {
            idt_workspace: workspace.idt_user_workspace,
            idt_osa: id,
            // type_device: "notWebchat",
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        );
        if (deviceResponse.data.success === true) {
          const deviceData = deviceResponse.data.data.map((d) => ({
            value: d.idt_device,
            label: `${d.name} (${d.msisdn})`,
          }));
          setDevice(deviceData);
        }

        // Get Operator
        const operatorResponse = await axios.post(
          `${API_URL}user-group`,
          {
            idt_workspace: workspace.idt_user_workspace,
            role: ["admin", "operator"],
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        );
        if (operatorResponse.data.success === true) {
          if (operatorResponse.data.data) {
            const operatorData = operatorResponse.data.data.map((d) => ({
              id: d.idt_user_workspace_member,
              name: d.name,
            }));
            setLeftItems(operatorData);
          }
        }

        // Get Chatbot
        const chatbotResponse = await axios.post(
          `${API_URL}get-chatbot`,
          { id: id },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        );
        if (chatbotResponse.data.success === true) {
          const data = chatbotResponse.data.data;
          setValue("serviceName", data.name);
          setValue("chatbotDescription", data.description);
          setValue("expired", data.expired);
          setAssistant((prevAssistant) => {
            if (prevAssistant.length > 0) {
              setValue(
                "assistant",
                prevAssistant.find(
                  (option) => option.value === data.idt_user_assistant
                )
              );
            }
            return prevAssistant;
          });
          setValue(
            "chatbotKnowledge",
            data.topic ? data.topic.knowledge : null
          );
          setValue(
            "chatbotInstruction",
            data.topic ? data.topic.prompt_role : null
          );
          setValue(
            "externalKnowledge",
            data.device_webhook ? data.device_webhook.credential : null
          );
          // Set Function
          const functionSet = data.tool_definition.map((tool) => {
            // Mengonversi parameters dari JSON ke format array yang diinginkan
            const jsonData = JSON.parse(tool.parameters);
            // Mengonversi ke array dengan format yang diinginkan
            const resultArray = Object.entries(jsonData.properties).map(
              ([key, value]) => ({
                name: key,
                type: value.type.charAt(0).toUpperCase() + value.type.slice(1),
                description: value.description,
                enum: value.enum || [],
                required: jsonData.required.includes(key),
              })
            );
            return {
              idt_function: tool.idt_osa_tool_definition,
              name: tool.name,
              description: tool.description,
              parameters: resultArray, // Masukkan array parameters yang telah dibuat
              active: tool.active,
              exec_type: tool.exec_type,
              endpoint: tool.endpoint,
              method: tool.method,
              headers: tool.headers
                ? tool.headers == "null"
                  ? null
                  : tool.headers
                : null,
              body_type: tool.body_type,
              body: tool.body,
              credentials: tool.credentials,
              response_filter: tool.response_filter
                ? tool.response_filter
                : null,
              response_prompt: tool.response_prompt
                ? tool.response_prompt
                : null,
              response_media_type: tool.response_media_type
                ? tool.response_media_type
                : null,
              response_media_url: tool.response_media_url
                ? tool.response_media_url
                : null,
            };
          });
          setFunctionS((prevDefinitions) => [
            ...prevDefinitions,
            ...functionSet, // Menggabungkan array yang telah dimodifikasi dengan yang sudah ada
          ]);
          setDevice((prevDevice) => {
            if (prevDevice.length > 0) {
              const assignedDeviceIds = data.device_assigment.map(
                (item) => item.idt_device
              );
              const selectedDevices = prevDevice.filter((option) =>
                assignedDeviceIds.includes(option.value)
              );
              setValue("device", selectedDevices);
            }
            return prevDevice;
          });
          // Set Data Operator
          const operators = data.operator.map(
            (item) => item.idt_user_workspace_member
          );
          setLeftItems((prevLeftItems) => {
            const newLeftItems = prevLeftItems.filter(
              (operator) => !operators.includes(operator.id)
            );
            setRightItems((prevRightItems) => {
              const updatedRightItems = [...prevRightItems];
              operators.forEach((operatorId) => {
                const operatorToMove = prevLeftItems.find(
                  (operator) => operator.id === operatorId
                );
                if (
                  operatorToMove &&
                  !updatedRightItems.some(
                    (operator) => operator.id === operatorId
                  )
                ) {
                  updatedRightItems.push({
                    id: operatorToMove.id,
                    name: operatorToMove.name,
                  });
                }
              });
              return updatedRightItems;
            });
            return newLeftItems;
          });
          setValue(
            "w_link",
            data.webchat_link
              ? "https://webchat.otokata.id/" + data.webchat_link
              : null
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  /* Copy */
  const handleCopyClick = () => {
    const valueToCopy = getValues("w_link");
    if (valueToCopy) {
      navigator.clipboard.writeText(valueToCopy);
      showCopy("Webchat Link copied to clipboard");
    }
  };
  const handleCopyClick2 = () => {
    const valueToCopy = getValues("w_widget");
    if (valueToCopy) {
      navigator.clipboard.writeText(valueToCopy);
      showCopy("Webchat Widget copied to clipboard");
    }
  };

  // Menangani pemindahan item terpilih ke panel kanan
  const handleMoveToRight = (item) => {
    setLeftItems(leftItems.filter((i) => i.id !== item.id));
    setRightItems([...rightItems, item]);
  };

  // Menangani pemindahan item kembali ke panel kiri
  const handleMoveToLeft = (item) => {
    setRightItems(rightItems.filter((i) => i.id !== item.id));
    setLeftItems([...leftItems, item]);
  };

  // Menangani pemindahan semua item dari kiri ke kanan
  const handleMoveAllToRight = () => {
    setRightItems([...rightItems, ...leftItems]);
    setLeftItems([]);
  };

  // Menangani pemindahan semua item dari kanan ke kiri
  const handleMoveAllToLeft = () => {
    setLeftItems([...leftItems, ...rightItems]);
    setRightItems([]);
  };

  const [selectedST, setSelectedST] = useState("free");
  const handleSTChange = (event) => {
    setSelectedST(event.target.value);
  };
  const [selectedSA, setSelectedSA] = useState("public");
  const handleSAChange = (event) => {
    setSelectedSA(event.target.value);
  };

  const handleFunctionS = (data) => {
    setFunctionS((prevFunctionS) => [...prevFunctionS, data]);
  };

  const updateFunctionS = (index, data) => {
    setFunctionS((prevFunctionS) =>
      prevFunctionS.map((func, i) =>
        i === index ? { ...func, ...data } : func
      )
    );
  };

  const deleteFunctionS = (id, name, description) => {
    setFunctionS((prevFunctionS) =>
      prevFunctionS.filter(
        (func) => !(func.name === name && func.description === description)
      )
    );
    setDelFunctionS((prev) => [...prev, id]);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    reset,
    setValue,
    getValues,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    console.log(functionS);
    const createJsonSchemas = (functions) => {
      // Array untuk menyimpan schema dari tiap objek functionS
      const schemas = functions.map((func) => {
        const schema = {
          type: "object",
          properties: {},
          required: [],
        };
        // Proses setiap parameter di dalam array parameters
        func.parameters.forEach((param) => {
          schema.properties[param.name] = {
            type: param.type,
            description: param.description,
          };
          // Hanya tambahkan enum jika tidak kosong
          if (param.enum && param.enum.length > 0) {
            schema.properties[param.name].enum = param.enum;
          }
          // Jika required adalah true, tambahkan ke array required
          if (param.required) {
            schema.required.push(param.name);
          }
        });
        return {
          idt_function: func.idt_function ? func.idt_function : null,
          name: func.name,
          description: func.description,
          exec_type: func.exec_type,
          active: func.active,
          exec_type: func.exec_type,
          endpoint: func.endpoint,
          method: func.method,
          headers: func.headers,
          body_type: func.body_type,
          body: func.body,
          credentials: func.credentials,
          response_filter: func.response_filter,
          response_prompt: func.response_prompt,
          response_media_type: func.response_media_type,
          response_media_url: func.response_media_url,
          parameters: JSON.stringify(schema),
        };
      });
      return schemas;
    };

    // console.log(data);
    const datas = {
      idt_osa: id,
      name: data.serviceName,
      description: data.chatbotDescription,
      expired: data.expired,
      chatbotKnowledge: data.chatbotKnowledge,
      chatbotInstruction: data.chatbotInstruction,
      externalKnowledge: data.externalKnowledge,
      webchatLink: data.w_link,
      webchatWidget: data.w_widget,
      functions: createJsonSchemas(functionS),
      delFunction: delFunctionS,
      operator: rightItems.map((op) => op.id),
      device: data.device.map((device) => device.value),
      assistant: data.assistant.value,
      serviceType: selectedST,
      serviceAccess: selectedSA,
      workspace: workspace.idt_user_workspace,
    };
    console.log(datas);

    axios
      .post(`${API_URL}update-chatbot`, datas, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          localStorage.setItem("alert", true);
          navigate("/chatbot");
        } else {
          toast.error("Update OSA Chatbot Failed", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {});
  };

  // Generate
  const handleGenerateWCL = () => {
    const newUUID = uuidv4();
    setValue("w_link", "https://webchat.otokata.id/" + newUUID);
    clearErrors("w_link");
  };

  // Modal Add Function
  const [show, setShow] = useState(false);
  const [showU, setShowU] = useState(false);
  const handleShow = () => setShow(true);
  const handleShowU = () => setShowU(true);
  const handleClose = () => {
    setShow(false);
  };
  const handleCloseU = () => {
    setShowU(false);
    setShowEditModal(false);
  };
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [functionIndex, setFunctionIndex] = useState(null);
  const handleEditFunction = (index, row) => {
    setShowU(true);
    setFunctionIndex(index);
    setSelectedFunction(row);
    setShowEditModal(true);
  };

  return (
    <div className="container-fluid p-0">
      <div className="row mb-2 mb-xl-3">
        <div className="col-auto">
          <h5>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/chatbot">Chatbot</Link>
                </li>
                <li className="breadcrumb-item active">Detail OSA Chatbot</li>
              </ol>
            </nav>
          </h5>
        </div>
        <ToastContainer />
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card rounded-4 shadow-lg">
            <div className="col-md-11 col-sm-12 mx-auto">
              <div className="card-header rounded-4">
                <h5 className="card-title text-center">Detail OSA Chatbot</h5>
                <ToastContainer />
              </div>
              <div className="card-body">
                <div className="card-body h-100">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-md-10">
                        <div className="circle-number-container mb-4">
                          <div className="circle-number">1</div>
                          <div className="circle-text">
                            <span className="main-text">
                              Otokata Smart Assistant
                            </span>
                            <span className="sub-text">
                              Fill in the service name and assistant to be used.
                            </span>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            OSA Service Name
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-4 error-placeholder">
                            <input
                              type="text"
                              {...register("serviceName", {
                                required: "Service Name is required",
                              })}
                              className={`form-control ${
                                errors.serviceName ? "is-invalid" : ""
                              }`}
                            />
                            {errors.serviceName && (
                              <div className="invalid-feedback">
                                {errors.serviceName.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Description
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <textarea
                              {...register("chatbotDescription", {
                                required: "Description is required",
                              })}
                              className={`form-control ${
                                errors.chatbotDescription ? "is-invalid" : ""
                              }`}
                              rows={5}
                            />
                            {errors.chatbotDescription && (
                              <div className="invalid-feedback">
                                {errors.chatbotDescription.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Session Expiry (Min)
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-4 error-placeholder">
                            <input
                              type="number"
                              {...register("expired", {
                                required: "Expired is required",
                                min: {
                                  value: 5,
                                  message: "Minimum value is 5",
                                },
                                max: {
                                  value: 50,
                                  message: "Maximum value is 50",
                                },
                              })}
                              className={`form-control ${
                                errors.expired ? "is-invalid" : ""
                              }`}
                            />
                            {errors.expired && (
                              <div className="invalid-feedback">
                                {errors.expired.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Assistant
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-4 error-placeholder">
                            <Controller
                              name="assistant"
                              control={control}
                              className={`${
                                errors.assistant ? "is-invalid" : ""
                              }`}
                              rules={{
                                required: "Assistant is required.",
                              }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  styles={CustomStyles(theme)}
                                  className="custom-select"
                                  maxMenuHeight={200}
                                  options={assistant}
                                  placeholder="Select Assistant"
                                  onBlur={field.onBlur}
                                  onChange={(selected) => {
                                    field.onChange(selected);
                                    clearErrors("assistant");
                                  }}
                                  isSearchable={true}
                                  // isDisabled={!selectedCategory}
                                />
                              )}
                            />
                            {errors.assistant && (
                              <p className="errorMsg">
                                {errors.assistant.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className="circle-number-container mb-4">
                          <div className="circle-number">2</div>
                          <div className="circle-text">
                            <span className="main-text">
                              Knowledge Reference
                            </span>
                            <span className="sub-text">
                              Fill in the Knowledge Reference to be used.
                            </span>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Chatbot Knowledge
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <textarea
                              {...register("chatbotKnowledge")}
                              className={`form-control ${
                                errors.chatbotKnowledge ? "is-invalid" : ""
                              }`}
                              rows={5}
                            />
                            {errors.chatbotKnowledge && (
                              <div className="invalid-feedback">
                                {errors.chatbotKnowledge.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            External Knowledge
                          </label>
                          <div className="col-sm-2">
                            <select className="form-select">
                              <option value="otobaca">Otobaca</option>
                            </select>
                          </div>
                          <div className="col-sm-4 error-placeholder">
                            <input
                              type="text"
                              {...register("externalKnowledge")}
                              className={`form-control ${
                                errors.externalKnowledge ? "is-invalid" : ""
                              }`}
                            />
                            {errors.externalKnowledge && (
                              <div className="invalid-feedback">
                                {errors.externalKnowledge.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className="circle-number-container mb-4">
                          <div className="circle-number">3</div>
                          <div className="circle-text">
                            <span className="main-text">OSA Functions</span>
                            <span className="sub-text">
                              Fill in the Smart Assistant Function to be used.
                            </span>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Select Function
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <a
                              className="btn btn-info mb-3"
                              onClick={handleShow}
                            >
                              Create Funtion
                            </a>
                            {functionS.length > 0 && (
                              <table className="table table-striped w-100">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th className="d-none d-md-table-cell">
                                      Parameters
                                    </th>
                                    <th align="center" className="text-center">
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {functionS.map((row, index) => (
                                    <tr key={index}>
                                      <td>{row.name}</td>
                                      <td>{row.description}</td>
                                      <td className="d-none d-md-table-cell">
                                        {row.parameters
                                          .map((rowp) => rowp.name)
                                          .join(", ")}
                                      </td>
                                      <td
                                        align="center"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <a
                                          onClick={() =>
                                            handleEditFunction(index, row)
                                          }
                                          className="btn-none"
                                          style={{ color: "#0275d8" }}
                                        >
                                          <FaPencilAlt />
                                        </a>
                                        <a
                                          onClick={() =>
                                            deleteFunctionS(
                                              row.idt_function,
                                              row.name,
                                              row.description
                                            )
                                          }
                                          className="btn-none"
                                          style={{
                                            color: "#d9534f",
                                            marginLeft: "10px",
                                          }} // Optional spacing
                                        >
                                          <FaTrashAlt />
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className="circle-number-container mb-4">
                          <div className="circle-number">4</div>
                          <div className="circle-text">
                            <span className="main-text">
                              Chatbot Instruction
                            </span>
                            <span className="sub-text">
                              Fill define specific instruction or task for your
                              chabot.
                            </span>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Chatbot Instruction
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <textarea
                              {...register("chatbotInstruction")}
                              className={`form-control ${
                                errors.chatbotInstruction ? "is-invalid" : ""
                              }`}
                              rows={10}
                            />
                            {errors.chatbotInstruction && (
                              <div className="invalid-feedback">
                                {errors.chatbotInstruction.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className="circle-number-container mb-4">
                          <div className="circle-number">5</div>
                          <div className="circle-text">
                            <span className="main-text">Device Assignment</span>
                            <span className="sub-text">
                              Fill in device to be used.
                            </span>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Device Setting
                          </label>
                          <div className="col-sm-4 error-placeholder">
                            <Controller
                              name="device"
                              control={control}
                              className={`${errors.device ? "is-invalid" : ""}`}
                              // rules={{
                              //   required: "Device is required.",
                              // }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  isMulti={true}
                                  styles={CustomStyles(theme)}
                                  className="custom-select"
                                  maxMenuHeight={200}
                                  options={device}
                                  placeholder="Select Device"
                                  onBlur={field.onBlur}
                                  onChange={(selected) => {
                                    field.onChange(selected);
                                    clearErrors("device");
                                  }}
                                  isSearchable={true}
                                  // isDisabled={!selectedCategory}
                                />
                              )}
                            />
                            {errors.device && (
                              <p className="errorMsg text-danger fs-7">
                                {errors.device.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className="circle-number-container mb-4">
                          <div className="circle-number">6</div>
                          <div className="circle-text">
                            <span className="main-text">
                              Operator Assignment
                            </span>
                            <span className="sub-text">
                              Fill in operator to be used.
                            </span>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Select Operator
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <Row className="mt-3">
                              <Col xl={5}>
                                <Card>
                                  <Card.Header style={{ padding: "10px" }}>
                                    Available Operators
                                  </Card.Header>
                                  <Card.Body
                                    style={{
                                      padding: "10px",
                                      height: "200px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <ListGroup>
                                      {leftItems.map((item) => (
                                        <ListGroup.Item
                                          style={{ padding: "5px" }}
                                          key={item.id}
                                          action
                                          onClick={() =>
                                            handleMoveToRight(item)
                                          }
                                        >
                                          {item.name}{" "}
                                        </ListGroup.Item>
                                      ))}
                                    </ListGroup>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col
                                xl={2}
                                className="d-flex flex-column align-items-center justify-content-center"
                              >
                                <Button
                                  variant="secondary"
                                  style={{ margin: "10px" }}
                                  onClick={handleMoveAllToRight}
                                >
                                  {">>"}
                                </Button>
                                <Button
                                  variant="secondary"
                                  style={{ margin: "10px" }}
                                  onClick={handleMoveAllToLeft}
                                >
                                  {"<<"}
                                </Button>
                              </Col>
                              <Col xl={5}>
                                <Card>
                                  <Card.Header style={{ padding: "10px" }}>
                                    Selected Operators
                                  </Card.Header>
                                  <Card.Body
                                    style={{
                                      padding: "10px",
                                      height: "200px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <ListGroup>
                                      {rightItems.map((item) => (
                                        <ListGroup.Item
                                          style={{ padding: "5px" }}
                                          key={item.id}
                                          action
                                          variant="success"
                                          onClick={() => handleMoveToLeft(item)}
                                        >
                                          {item.name}
                                        </ListGroup.Item>
                                      ))}
                                    </ListGroup>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <hr />
                        <div className="circle-number-container mb-4">
                          <div className="circle-number">7</div>
                          <div className="circle-text">
                            <span className="main-text">
                              Webchat Access Configuration
                            </span>
                            <span className="sub-text">
                              Fill in Webchat Access to be used.
                            </span>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Webchat Link
                          </label>
                          <div className="col-sm-6 error-placeholder">
                            <div className="input-group mb-2 mr-sm-2">
                              <input
                                type="text"
                                {...register("w_link")}
                                className={`form-control ${
                                  errors.w_link ? "is-invalid" : ""
                                }`}
                                readOnly="readonly"
                              />
                              <div
                                className="input-group-text c-pointer"
                                onClick={handleCopyClick}
                              >
                                <FaRegCopy />
                              </div>
                              {errors.w_link && (
                                <div className="invalid-feedback">
                                  {errors.w_link.message}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <button
                              className="btn btn-secondary"
                              type="button"
                              onClick={handleGenerateWCL}
                            >
                              Generate
                            </button>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Webchat Widget
                          </label>
                          <div className="col-sm-6 error-placeholder">
                            <div className="input-group mb-2 mr-sm-2">
                              <input
                                type="text"
                                {...register("w_widget")}
                                className={`form-control ${
                                  errors.w_widget ? "is-invalid" : ""
                                }`}
                                readOnly="readonly"
                                // ref={inputRef2}
                              />
                              <div
                                className="input-group-text c-pointer"
                                onClick={handleCopyClick2}
                              >
                                <FaRegCopy />
                              </div>
                              {errors.w_widget && (
                                <div className="invalid-feedback">
                                  {errors.w_widget.message}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <button className="btn btn-secondary" type="button">
                              Generate
                            </button>
                          </div>
                        </div>
                        <hr />
                        <div className="circle-number-container mb-4">
                          <div className="circle-number">8</div>
                          <div className="circle-text">
                            <span className="main-text">
                              OSA Chatbot Publish
                            </span>
                            <span className="sub-text">
                              Fill the Chatbot Publish Settings to be used.
                            </span>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Service Type
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <div className="d-flex gap-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="service_type"
                                  value="free"
                                  checked={selectedST === "free"}
                                  onChange={handleSTChange}
                                  id="free"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="free"
                                >
                                  Free
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="service_type"
                                  value="subcription"
                                  checked={selectedST === "subcription"}
                                  onChange={handleSTChange}
                                  id="subcription"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="subcription"
                                >
                                  Subcription
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-3 text-sm-right">
                            Service Access
                            <span className="required">*</span>
                          </label>
                          <div className="col-sm-8 error-placeholder">
                            <div className="d-flex gap-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="service_access"
                                  value="public"
                                  checked={selectedSA === "public"}
                                  onChange={handleSAChange}
                                  id="public"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="public"
                                >
                                  Public
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="service_access"
                                  value="susbscriber"
                                  checked={selectedSA === "susbscriber"}
                                  onChange={handleSAChange}
                                  id="susbscriber"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="susbscriber"
                                >
                                  Subscriber
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <hr className="mb-4"></hr> */}
                    <div className="mt-4">
                      <Link to="/chatbot" className="btn btn-danger btn-md">
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-success btn-md float-end"
                      >
                        <span
                          className={`spinner-border spinner-border-xs me-2 ${
                            hideSpiner ? "d-none" : ""
                          }`}
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Update
                      </button>
                    </div>
                  </form>
                  {/* Modal */}
                  <AddFunction
                    functionS={functionS}
                    handleFunctionS={handleFunctionS}
                    show={show}
                    handleClose={handleClose}
                  />
                  {showEditModal && (
                    <EditFunction
                      show={showU}
                      i={functionIndex}
                      functionS={selectedFunction}
                      updateFunctionS={updateFunctionS}
                      handleClose={handleCloseU}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailChatbot;
