import { React, useEffect, useState } from "react";
import { useLocation, NavLink, Link } from "react-router-dom";

const NavLinkDevice = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState();
  useEffect(() => {
    setCurrentPath(location.pathname.split("/")[2]);
  }, [location]);

  return (
    <div className="col-12 col-md-6 col-lg-12">
      <div className="card rounded-4 shadow-lg">
        <div className="card-header rounded-4">
          <NavLink
            to="/device"
            className={
              currentPath == null
                ? "btn btn-orange-2 card-link"
                : "btn btn-link card-link"
            }
          >
            Device
          </NavLink>
          <NavLink
            to="/device/group"
            className={
              currentPath == "group"
                ? "btn btn-orange-2 card-link"
                : "btn btn-link card-link"
            }
          >
            Device Group
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default NavLinkDevice;
