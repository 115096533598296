import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import { API_URL } from "../../utilities/url";
import { FiPlus } from "react-icons/fi";
import storage from "../../services/storage";

var $ = require("jquery");

const Integration = () => {
  useEffect(() => {
    if (localStorage.getItem("alert")) {
      toast.success("Success !", {
        position: "top-right",
        theme: "colored",
        transition: Slide,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      localStorage.removeItem("alert");
    }

    $("#datatables-reponsive").DataTable({
      dom:
        "<'row'<'col-sm-6'l><'col-sm-6'B>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: true,
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-table-users",
        type: "post",
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            AuthService.logout();
          }
        },
      },
      columns: [
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = moment(data).format("DD-MM-YYYY");

            return data;
          },
        },
        { data: "user_profile.name_first" },
        { data: "user_profile.name_last" },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
      ],
      initComplete: function () {
        var api = this.api();
        $("#search")
          .off(".DT")
          .on("keyup.DT", function (e) {
            api.search(this.value).draw();
          });
      },
    });
  }, []);

  return (
    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="float-start">
                <h5 className="card-title mt-2">DataTables</h5>
                <ToastContainer />
              </div>
              <div className="float-end">
                <Link to="/test/add" className="btn btn-primary btn-md">
                  Tambah{" "}
                  <FiPlus className="align-middle" size={14} strokeWidth={4} />
                </Link>
              </div>
            </div>
            <div className="card-body table-responsive">
              <table
                id="datatables-reponsive"
                className="table table-striped table-responsive dt-bootstrap5 no-footer"
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Age</th>
                    <th>Start date</th>
                    <th>Salary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
